import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {DecisionService} from 'src/app/services/decision.service';
import {AlertService} from 'src/app/services/general/alert.service';
import {UserService} from 'src/app/services/user.service';
import {faCheck, faTrash} from '@fortawesome/free-solid-svg-icons';
import {ManagementPlanService} from 'src/app/services/managementplan.service';
import {FrameworkService} from 'src/app/services/framework.service';
import {ToastService} from 'src/app/services/general/toast.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit {

  //  icons
  faCheck = faCheck;
  faTrash = faTrash

  private _activeRouteSubscription: Subscription;

  id: any;

  decisionTable: any;
  decisionFramework: any;
  decisionPlan: any;

  taskToComplete: any = [];
  taskCompleted: any = [];

  currentOrganization: any = {};
  allItemsID = [];
  allSections = [];

  loading: boolean = false;

  //  filtri delle risposte organizzate per enel
  filters: any = {
    section_filtered: [],
    section: [],
  };

  additional_text_options = [
    {value: "level project", label: "Level Project"},
    {value: "level portfolio", label: "Level Portfolio"},
  ];

  sessions_option = [];

  therapy_options = [
    {
      value: "attack-therapy",
      label: this.translateService.instant('pages.task-management.attack-therapy')
    },
    {
      value: "course-modifying-therapy",
      label: this.translateService.instant('pages.task-management.course-modifying-therapy')
    },
    {
      value: "symptomatic-therapy",
      label: this.translateService.instant('pages.task-management.symptomatic-therapy')
    },
  ]

  constructor(private activeRoute: ActivatedRoute, private decisionService: DecisionService, private alertService: AlertService,
              private translateService: TranslateService, private userService: UserService, private alert: AlertService,
              private mgplanService: ManagementPlanService, private frameworkService: FrameworkService, private toast: ToastService) {
  }

  isAlexion() {
    return this.currentOrganization?.customs?.profile === 'alexion';
  }

  ngOnInit(): void {
    this.currentOrganization = this.userService.currentOrganization;
    if (this.isAlexion()) {
      this.additional_text_options = [
        {value: "therapy", label: this.translateService.instant('pages.task-management.therapy')},
        {value: "visit", label: this.translateService.instant('pages.task-management.visit')},
      ];
    }

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_decision;

      this.getDecisionTable();

      //  recupero le informazioni per questo tavolo decisionale
      this.getDecisionPlan();
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  init() {

    if (this.decisionPlan && this.decisionPlan?.tasks) {
      this.taskCompleted = this.decisionPlan.tasks.filter(x => x.has_completed == true);
      this.taskToComplete = this.decisionPlan.tasks.filter(x => x.has_completed == false);

      //  preparo i filtri
      this.filters.section_filtered = _.map(this.decisionPlan.tasks, 'description');
      this.filters.section_filtered = _.uniq(this.filters.section_filtered);

      this.filterOnChange();
    }
  }


  /*
  * aggiunge un nuovo task
  */
  addTask() {
    this.taskToComplete.unshift({
      decision_management_plan_id: this.decisionPlan.id,
      editMode: true,
      show: true,
      customs: {}
    });
  }

  /**
   * salvo un nuovo task
   */
  saveNewTask(task) {

    if (this.currentOrganization?.customs?.profile === 'alexion') {
      task.description = task.action;

      if (task.additional_text && ['therapy', 'visit'].includes(task.additional_text)) {
        if (task.additional_text === 'therapy') {
          if (task.customs?.therapy_type && ['course-modifying-therapy', 'symptomatic-therapy', 'attack-therapy'].includes(task.customs?.therapy_type)) {
            if (!task.action) {
              this.toast.error(
                this.translateService.instant('generic.error'),
                this.translateService.instant('pages.task-management.errors.no-action'),
              );
              return;
            }
          } else {
            this.toast.error(
              this.translateService.instant('generic.error'),
              this.translateService.instant('pages.task-management.errors.no-therapy-type'),
            );
            return;
          }
        }
        if (task.additional_text === 'visit') {
          if (!task?.customs?.session) {
            this.toast.error(
              this.translateService.instant('generic.error'),
              this.translateService.instant('pages.task-management.errors.no-session'),
            );
            return;
          }
        }
      } else {
        this.toast.error(
          this.translateService.instant('generic.error'),
          this.translateService.instant('pages.task-management.errors.no-task-type'),
        );
        return;
      }

    } else {
      if (!task.additional_text || !task.description || !task.action) {
        return
      }

      if (this.currentOrganization?.customs?.profile === 'enel') {
        if (task.additional_text == 'level project' && !task.item_id) {
          return
        }
      }
    }

    if (task.start_date && task.end_date && task.start_date > task.end_date) {
      this.toast.error(
        this.translateService.instant('generic.error'),
        this.translateService.instant('generic.errors.not-valid-dates'),
      );
      return;
    }

    this.mgplanService.saveTask(task, () => {
      this.getDecisionPlan();

      this.toast.success(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.created-task"));

    }, () => {
      this.toast.alert(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.not-created-task"));
    });

  }


  /*
  * setta un task come completato
  */
  checkCompletedTask(task, isCompleted) {

    if (isCompleted == true) {
      task.has_completed = true;
    } else {
      task.has_completed = false;
    }

    //  salvo sempre il task e recupro nuovamente il management plan
    this.mgplanService.updateTask(task, () => {
      this.getDecisionPlan();

      this.toast.success(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.updated-task"));

    }), () => {
      this.toast.error(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.not-updated-task"));
    };
  }

  /*
  * aggiorna le informazioni di un task
  */
  modifyTask(taskToModify, enable) {
    if (enable == true) {
      taskToModify.editMode = true;
    } else {
      taskToModify.editMode = false;

      //  salvo sempre il task e recupro nuovamente il management plan
      this.mgplanService.updateTask(taskToModify, () => {
        this.getDecisionPlan();

        this.toast.success(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.updated-task"));

      }, () => {
        this.toast.error(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.not-updated-task"));
      });
    }
  }

  /*
  * cancella un task
  */
  deleteTask(task) {

    this.alert.showQuestionWithError(this.translateService.instant("generic.alert-delete-task"), "", (res) => {
      if (res) {

        //  salvo sempre il task e recupro nuovamente il management plan
        this.mgplanService.deleteTask(task, () => {
          this.getDecisionPlan();

          this.toast.success(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.deleted-task"));

        }, () => {
          this.toast.error(this.translateService.instant("generic.management-plan"), this.translateService.instant("components.management-alert.deleted-task"));
        });
      }
    });
  }


  /**
   * Cambio il filtro delle sezioni enel
   */
  filterOnChange() {

    this.taskToComplete.forEach(task => {
      task.show = true;
    });
    this.taskCompleted.forEach(task => {
      task.show = true;
    });

    if (this.filters.section_selected) {

      this.taskToComplete.forEach(task => {
        if (task.description == this.filters.section_selected) {
          task.show = true;
        } else {
          task.show = false;
        }
      });

      this.taskCompleted.forEach(task => {
        if (task.description == this.filters.section_selected) {
          task.show = true;
        } else {
          task.show = false;
        }
      });
    }

  }


  /**
   * Recupero le informazioni del tavolo decisionale
   */
  getDecisionTable() {

    this.decisionService.get(this.id, (result: any) => {
      this.decisionTable = result;

      this.sessions_option = result?.evaluation_sessions.map((session) => {
        return {
          value: {
            id: session.id,
            evaluation_session: session.evaluation_session
          },
          label: this.translateService.instant('generic.session') + " " + session.evaluation_session
        };
      })

      //  recupero le informazioni del framework
      this.frameworkService.get(this.decisionTable.framework_id, (res: any) => {
        this.decisionFramework = res;

        //  costruisco l'array degli item disponibili
        this.decisionFramework.criteria.forEach(criterio => {
          if (criterio?.additional) {
            this.allItemsID.push(
              {
                id: criterio.additional.item_id,
                text: `${criterio.additional.check_n} - ${criterio.additional.sub_section} - ${criterio.title}`,
                section: criterio.additional.section
              }
            );
            this.allSections.push(criterio.additional.section);
          }
        });

        //  rendo unici gli elementi sull'array
        this.allSections = _.uniq(this.allSections);
      });
    });
  }


  /**
   * Recupero il management decision plan del tavolo relativo
   */
  getDecisionPlan() {

    this.loading = true;

    this.decisionService.getManagementPlan(this.id, (result: any) => {

      if (result) {
        this.decisionPlan = result;
        this.init();
      }

      this.loading = false;
    }, () => {
      this.loading = false;
    })
  }

  /**
   * Filtro tutti gli item in base alla sezione selezionata
   */
  filerItemBySection(section): any[] {
    return this.allItemsID.filter(item => item.section == section);
  }

}
