<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">{{'modals.modal.email-responses.title' | translate}}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <div class="container-fluid">
    <div class="container px-0">
      <div class="row" *ngIf="loading">
        <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
      <mat-table *ngIf="emailResponseDatatable" [dataSource]="emailResponseDatatable" class="table-primary">

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> {{ 'generic.email' | translate }} </mat-header-cell>
          <div class="row flex-column">
            <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
          </div>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> {{ 'generic.date' | translate }} </mat-header-cell>
          <div class="row flex-column">
            <mat-cell *matCellDef="let row"> {{(row.timestamp*1000) | date:'medium'}}</mat-cell>
          </div>
        </ng-container>

        <ng-container matColumnDef="event">
          <mat-header-cell *matHeaderCellDef> {{ 'generic.event' | translate }} </mat-header-cell>
          <div class="row flex-column">
            <mat-cell *matCellDef="let row">
              <mat-chip-list>
                <mat-chip selectable="false" style="justify-content:center;" [ngStyle]="{'background-color':emailColorByEvent(row)}" class="text-white">
                  {{row.event}}
                </mat-chip>
              </mat-chip-list>
          </mat-cell>
          </div>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
