<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container">

    <!-- Sezione alert -->
    <ng-container *ngIf="!loading">
      <mat-card *ngIf="!evaluation_session?.collecting" style="background-color: red; color: white;">
        {{'components.responses.decision-table-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="response_stamps?.completed" style="background-color: red; color: white;">
        {{'components.responses.responses-already-sent' | translate}}
      </mat-card>
    </ng-container>

      <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
          <mat-label>{{'generic.organization' | translate}}</mat-label>
          <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
          <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
          <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : translate('components.responses.undefined-due-date')" placeholder="{{'components.responses.due-date' | translate}}" readonly>
      </mat-form-field>
  </div>
</div>

<!-- Blocco 1 -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">1</span>
                      {{'generic.objectives' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">


                  <div class="col-12 px-2" *ngIf="primary_target.length > 0">
                    <div class="box px-0 box-two-row border-0 bg-transparent" *ngFor="let primaryTarget of primary_target">
                        <mat-label class="box-title px-3">{{'generic.primary' | translate}}</mat-label>
                        <mat-form-field class="input-primary col-12 col-lg-6">
                            <input matInput [ngModel]="primaryTarget.title" placeholder="{{'generic.title' | translate}}" readonly>
                        </mat-form-field>
                        <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                          <mat-label>{{'components.responses.reach-day' | translate}}</mat-label>
                          <input matInput [ngModel]="(primaryTarget.date| formatdate | date:'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
                          <!-- <input matInput [matDatepicker]="dp_primary" (focus)="dp_primary.open()"> -->
                          <!-- <mat-datepicker-toggle matSuffix [for]="dp_primary">
                              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                          </mat-datepicker-toggle>
                          <mat-datepicker #dp_primary></mat-datepicker> -->
                        </mat-form-field>
                        <mat-form-field class="input-primary col-5 col-lg-6 pt-1">
                          <input matInput placeholder="{{'generic.unit' | translate}}" [ngModel]="getUnitsService().getUnitValueByKey(primaryTarget.measure_unit)" readonly>
                        </mat-form-field>
                        <!-- <mat-form-field class="select-primary col-5 col-lg-6 px-1" appearance="fill">
                            <mat-label>Unità</mat-label>
                            <mat-select [ngModel]="primaryTarget.measure_unit" disabled>
                              <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                          <input matInput placeholder="{{'components.responses.target-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(primaryTarget.evaluation_criteria)" readonly>
                        </mat-form-field>
                        <!-- <mat-form-field class="select-primary col-12 col-lg-6 px-1 mt-2" appearance="fill">
                            <mat-label>Valore Obiettivo</mat-label>
                            <mat-select>
                                <mat-option value="" selected></mat-option>
                                <mat-option value="test02">Il più basso</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 px-0 mb-0" *ngIf="secondary_target.length > 0">
                      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0">{{'generic.secondary' | translate}}</mat-label>
                      </div>
                  </div>

                  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left" *ngFor="let secondaryTarget of secondary_target">
                      <div class="container">
                          <mat-accordion class="accordion" multi [togglePosition]="'before'">
                              <mat-expansion-panel class="px-0" expanded>
                                  <mat-expansion-panel-header>
                                      <mat-panel-title>
                                          <span>{{secondaryTarget.title}}</span>
                                          <i class="icon-circle_info d-flex"></i>
                                      </mat-panel-title>
                                  </mat-expansion-panel-header>

                                  <mat-form-field class="input-primary col-6 col-lg-6 px-1 mt-2">
                                      <mat-label>{{'components.responses.reach-day' | translate}}</mat-label>
                                      <input matInput [ngModel]="(secondaryTarget.date| formatdate | date:'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
                                      <!-- <input matInput [matDatepicker]="dp_secondary" (focus)="dp_secondary.open()">
                                      <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                                      </mat-datepicker-toggle>
                                      <mat-datepicker #dp_secondary></mat-datepicker> -->
                                  </mat-form-field>

                                  <!-- <mat-form-field class="select-primary col-5 col-lg-6 px-1 mt-2" appearance="fill">
                                      <mat-label>Unità</mat-label>
                                      <mat-select>
                                          <mat-option value="" selected></mat-option>
                                          <mat-option value="test02">Tempo</mat-option>
                                      </mat-select>
                                  </mat-form-field> -->
                                  <mat-form-field class="input-primary col-6 col-lg-6 px-1 mt-2">
                                    <input matInput placeholder="{{'generic.unit' | translate}}" [ngModel]="getUnitsService().getUnitValueByKey(secondaryTarget.measure_unit)" readonly>
                                  </mat-form-field>

                                  <!-- <mat-form-field class="select-primary col-12 col-lg-6 px-1 mt-2 mt-2" appearance="fill">
                                      <mat-label>Valore Obiettivo</mat-label>
                                      <mat-select>
                                          <mat-option value="" selected></mat-option>
                                          <mat-option value="test02">Il più basso</mat-option>
                                      </mat-select>
                                  </mat-form-field> -->

                                  <mat-form-field class="input-primary col-6 col-lg-6 px-1 mt-2">
                                    <input matInput placeholder="{{'components.responses.target-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(secondaryTarget.evaluation_criteria)" readonly>
                                  </mat-form-field>

                                  <mat-form-field class="input-primary col-6 col-lg-6 px-1 mt-2">
                                    <input matInput placeholder="{{'components.responses.assigned-value' | translate}}" [ngModel]="secondaryTarget.evaluation.weight" readonly>
                                  </mat-form-field>

                              </mat-expansion-panel>
                          </mat-accordion>
                      </div>
                  </div>

              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>


<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'generic.evaluations' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">

                  <div class="col-12 col-lg-6 px-0 mb-0" *ngIf="secondary_target.length > 0">
                      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0">{{'generic.criteria' | translate}}</mat-label>
                      </div>
                  </div>

                  <ng-container *ngIf="secondary_target.length > 0"></ng-container>
                  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left" *ngFor="let target of secondary_target">
                      <div class="container">
                          <mat-accordion class="accordion" multi [togglePosition]="'before'">
                              <mat-expansion-panel class="px-0" expanded>
                                  <mat-expansion-panel-header>
                                      <mat-panel-title>
                                          <span>{{target.title}}</span>
                                          <i class="icon-circle_info d-flex"></i>
                                      </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div class="row">
                                      <small class="col-12">{{'generic.evaluations' | translate}}</small>
                                      <ng-container *ngFor="let criteria of target.framework_criteria">
                                          <div class="col-10 my-1">{{criteria.title}}</div>
                                          <div class="col-2">{{criteria?.evaluation?.weight}}</div>
                                      </ng-container>
                                  </div>
                              </mat-expansion-panel>
                          </mat-accordion>
                      </div>
                  </div>

                  <div class="col-12 px-2">
                      <div class="box px-0 box-two-row border-0 bg-transparent">
                          <mat-label class="box-title px--">{{'components.responses.responses' | translate}}</mat-label>
                          <div class="row px-5">
                              <small class="col-12">{{'generic.evaluations' | translate}}</small>
                          </div>
                          <div class="row px-5" *ngFor="let proposal of proposals">
                              <small class="col-12">{{'generic.proposal' | translate}} {{proposal.title}}</small>
                              <ng-container *ngFor="let criteria of proposal.criteria">
                                  <div class="col-10 my-1">{{criteria.title}}</div>
                                  <div class="col-2">{{criteria?.answer?.confidence}}</div>
                              </ng-container>
                          </div>
                      </div>
                  </div>

                  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto" *ngIf="!response_stamps?.completed">
                      <button class="px-5" mat-flat-button color="primary" [disabled]="!evaluation_session?.collecting || loading"  [routerLink]="['../responses-evaluation']">{{'components.responses.ask-for-response' | translate}}</button>
                  </div>
                  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
                    <div *ngIf="response_stamps">
                      <span *ngIf="response_stamps?.completed"> {{'components.responses.info-sent-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
                      <span *ngIf="!response_stamps?.completed"> {{'components.responses.info-draft-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
                    </div>
                  </div>

              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>
