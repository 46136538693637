import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SurveyService} from '../../../services/surveys.service';
import {ModalAddAttachmentComponent} from '../../../modals/modal-add-attachment/modal-add-attachment.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalShowEvidencesComponent} from '../../../modals/modal-show-evidences/modal-show-evidences.component';
import {UserService} from '../../../services/user.service';
import {MatInputModule} from '@angular/material/input';
import {UnitsService} from '../../../services/config/units.service';

@Component({
  selector: 'app-single-question',
  templateUrl: './single-question.component.html',
  styleUrls: ['./single-question.component.scss'],
})
export class SingleQuestionComponent implements OnInit {
  @Input() question: any;
  @Input() isDeletable: any;
  @Input() isResponse: any = false;
  @Input() editable: any = false;
  @Input() survey: any;
  @Input() accordionOpened: any = true;
  @Output() questionDuplicated = new EventEmitter();
  @Output() questionDeleted = new EventEmitter();

  constructor(
    public surveyService: SurveyService,
    public dialog: MatDialog,
    public unitsService: UnitsService,
    public userService: UserService) {
  }

  choiceIdCounter = 1;

  public ngOnInit(): void {
    if (this.question?.choices?.length < 2) {
      this.addChoice();
      this.addChoice();
    }
    if (this.isResponse && !this.question.response) {
      this.question.response = {
        answer: '',
        choice: null,
        attachments: [],
        status: 'ANSWERED',
        feedback: '',
        customs: {
          confidence: null,
        }
      };
    }
    this.choiceIdCounter = this.question.choices.length + 1;
    this.onTypologyChange();
  }

  addChoice() {
    this.question?.choices?.push({
      id: this.choiceIdCounter++,
      title: '',
    });
  }

  removeChoice(id: number) {
    const index = this.question?.choices.findIndex(choice => choice.id === id);
    if (index !== undefined && index !== -1) {
      this.question.choices.splice(index, 1);
    }
  }

  get isCheckboxQuestion(): boolean {
    return this.surveyService.isCheckboxQuestion(this.question.typology);
  }

  get isConsensusQuestion(): boolean {
    return this.surveyService.isConsensusQuestion(this.question.typology);
  }

  get isMultipleChoiceQuestion(): boolean {
    return this.surveyService.isMultipleChoiceQuestion(this.question.typology);
  }

  get isQuantitativeQuestion(): boolean {
    return this.surveyService.isQuantitativeQuestion(this.question.typology);
  }

  get isOpenedQuestion(): boolean {
    return this.surveyService.isOpenedQuestion(this.question.typology);
  }

  public duplicateQuestion() {
    this.questionDuplicated.emit(JSON.parse(JSON.stringify(this.question)));
  }

  public removeQuestion() {
    this.questionDeleted.emit(this.question.id);
  }

  public settingsItemSelected(event) {
    event.stopPropagation();
  }

  get getCustoms() {
    return this.question.customs;
  }

  public onTypologyChange() {
    if (!this.isResponse && this.editable) {
      const keys = this.surveyService.selectOptions.map(option => option.key);
      const enabledKeys = this.surveyService.selectOptions
        .filter(option =>
          option.enabledTypologies.includes(this.question.typology)
        )
        .map(option => option.key);

      keys.filter(key => !enabledKeys.includes(key)).forEach(key => {
        this.getCustoms[key] = false;
      });

      if (this.isConsensusQuestion) {
        this.getCustoms.use_slider = true;
        this.getCustoms.min = 1;
        this.getCustoms.max = 100;
        this.getCustoms.step = 1;
      } else {
        this.getCustoms.use_slider = false;
      }
    }
  }

  public onClickAddNewEvidence(
    attachment_type: string
  ) {
    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        uploadUrl: 'upload/tmp-expert-proposal-file',
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {
      if (attachmentData) {
        if (['PUBMED', 'FILE'].includes(attachmentData.attachmentType)) {
          let obj = null;
          if (attachmentData.attachmentType === 'PUBMED') {
            obj = {
              type: 'PUBMED',
              PMID: attachmentData.attachmentObject.PMID,
              title: attachmentData.attachmentObject.ArticleTitle,
              link: attachmentData.attachmentObject.link,
              created_at: new Date().toISOString(),
              isNew: true,
            };
            if (this.isResponse) {
              this.question.response.attachments.push(obj);
            } else {
              this.question?.attachments?.push(obj);
            }
          } else {
            attachmentData.attachmentObject?.forEach((file) => {
              obj = {
                ...file,
                type: 'FILE',
                created_at: new Date().toISOString(),
                isNew: true,
              };
              if (this.isResponse) {
                this.question.response.attachments.push(obj);
              } else {
                this.question?.attachments?.push(obj);
              }
            });
          }
        }
      }
    });
  }

  public showAttachments() {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        attachments: this.question.attachments,
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }


}
