import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../services/decision.service';
import {ModalAddContestComponent} from '../modal-add-contest/modal-add-contest.component';
import {ProjectsService} from '../../services/projects.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-modal-add-project',
  templateUrl: './modal-add-project.component.html',
  styleUrls: ['./modal-add-project.component.scss']
})
export class ModalAddProjectComponent implements OnInit{
  dataModel = {
    title: '',
    description: '',
  };
  organization_id = null;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ModalAddContestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {decision_table_id: null},
    private projectService: ProjectsService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.organization_id = this.userService.getCurrentOrganization()?.id;
    if (!this.organization_id) {
      this.closeDialogue();
    }
  }

  onSubmit() {
    this.loading = true;
    this.projectService.createProject(this.organization_id, this.dataModel, (result) => {
      this.closeDialogue(result);
    }, () => {
      this.loading = false;
    });
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }
}
