<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate></form>

<div class="container-fluid container-custom d-flex align-items-center">
  <div class="container">
    <div class="row text-center input-custom">
      <div [@visibilityChanged]="counter == 1" class="col-12" [hidden]="counter != 1" name="1">
        <mat-form-field class="col-8 input-onboarding input-invalid">
          <!-- TODO_TRADUZIONE -->
          <mat-label>Nome</mat-label>
          <input matInput type="text" name="first_name" [(ngModel)]="model.first_name" required>
        </mat-form-field>
      </div>
      <div [@visibilityChanged]="counter == 2" class="col-12 animation" [hidden]="counter != 2" name="2">
        <mat-form-field class="col-8 input-onboarding input-invalid">
          <!-- TODO_TRADUZIONE -->
          <mat-label>Cognome</mat-label>
          <input matInput type="text" name="last_name" [(ngModel)]="model.last_name" required>
        </mat-form-field>
      </div>
      <div [@visibilityChanged]="counter == 3" class="col-12" [hidden]="counter != 3" name="3">
        <mat-form-field class="col-8 input-onboarding input-invalid">
          <!-- TODO_TRADUZIONE -->
          <mat-label>Password</mat-label>
          <input matInput type="password" name="password" [(ngModel)]="model.password" required>
        </mat-form-field>
        <mat-form-field class="col-8 input-onboarding input-invalid">
          <!-- TODO_TRADUZIONE -->
          <mat-label>Conferma password</mat-label>
          <input matInput type="password" name="confirmed_password" [(ngModel)]="model.confirmed_password" required>
        </mat-form-field>
      </div>
      <div [@visibilityChanged]="counter == 4" class="col-12" [hidden]="counter != 4" name="4">
        <mat-form-field class="col-8 input-onboarding input-invalid">
          <!-- TODO_TRADUZIONE -->
          <mat-label>Nome organizzazione</mat-label>
          <input matInput type="text" name="organization_name" [(ngModel)]="model.organization_name" required>
        </mat-form-field>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 d-flex justify-content-between">
        <button type="button" [hidden]="counter === 1" class="btn-custom" (click)="decreaseNumb()">
          <i class="icon-Backward-arrow-small"></i>
        </button>

        <button type="button" [hidden]="doneButton === true" [ngClass]="counter === 1 ? 'ml-auto' : '' " class="btn-custom" (click)="increaseNumb()">
          <i class="icon-Forward-arrow-small"></i>
        </button>

        <button type="button" [hidden]="doneButton === false" class="doneButton px-5 py-2" (click)="onSubmit(form)">
          {{'generic.buttons.confirm' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
