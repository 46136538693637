import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AnalysisMethodsService {


  constructor(private translateService: TranslateService) {
  }

  private _analysisMethods: any = [
    {
      type: 1,
      title: this.translateService.instant("service.analysismethods.multicriteria"),
      description: this.translateService.instant("service.analysismethods.multicriteria-description"),
      tipDescription: this.translateService.instant("service.analysismethods.multicriteria")
    },
    {
      type: 2,
      title: this.translateService.instant("service.analysismethods.consensus"),
      description: this.translateService.instant("service.analysismethods.consensus-description"),
      tipDescription: this.translateService.instant("service.analysismethods.consensus")
    },
    // {
    //   type: 3,
    //   title: this.translateService.instant("service.analysismethods.voting"),
    //   description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
    //   tipDescription: "Tips per analisi del voting"
    // },
    // {
    //   type: 4,
    //   title: this.translateService.instant("service.analysismethods.delphi"),
    //   description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet",
    //   tipDescription: "Tips per analisi del metodo delphi"
    // },
    {
      type: 5,
      title: this.translateService.instant("service.analysismethods.multiobjective"),
      description: this.translateService.instant("service.analysismethods.multiobjective-description"),
      tipDescription: this.translateService.instant("service.analysismethods.multiobjective")
    },
    // {
    //   type: 6,
    //   title: this.translateService.instant("service.analysismethods.advanced-checklist"),
    //   description: this.translateService.instant("service.analysismethods.advanced-checklist-description"),
    //   tipDescription: this.translateService.instant("service.analysismethods.advanced-checklist")
    // }
  ]


  getValueByType(container, type, returnedValue = 'title') {

    let found = "";
    if (type != null && typeof type != "undefined") {
      container.forEach(e => {
        if (e.type == type)
          return found = e[returnedValue];
      });
    }

    return found;
  }


  get methods() {
    return this._analysisMethods;
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllAnalysisMethods() {
    return this._analysisMethods.slice(0).sort((a, b) => (a.title > b.title) ? 1 : -1);
  }


  /**
   * Restituisce il valore di una metodo di analisi se lo trova
   * @param type
   */
  public getAnalysisMethodValueByType(type: string) {

    if (type == null || typeof type == "undefined")
      return '';

    return this.getValueByType(this._analysisMethods, type);
  }

  public getAnalysisMethodTitleByType(type: string) {
    if (type == null || typeof type == "undefined")
      return '';

    return this.getValueByType(this._analysisMethods, type, 'title');
  }

  /** E' un framework multiobiettivo? */
  public isMultiobjectiveByType(type: string): boolean {

    if (type == null || typeof type == "undefined")
      return false;

    if (type == "5")
      return true;

    return false;
  }

  /** E' un framework multicriterio? */
  public isMulticriteriaByType(type: string): boolean {

    if (type == null || typeof type == "undefined")
      return false;

    if (type == "1")
      return true;

    // return this.isAdvancedChecklistByType(type);
  }


  /** E' un framework advanced checklist? */
  // public isAdvancedChecklistByType(type: string): boolean {

  //   if (type == null || typeof type == "undefined")
  //     return false;

  //   if (type == "6")
  //     return true;

  //   return false;
  // }

  /** E' un framework consensus? */
  public isConsensusByType(type: string): boolean {

    if (type == null || typeof type == "undefined")
      return false;

    if (type == "2")
      return true;

    return false;
  }

  public getAllAnalysisMethodsForSelect() {
    return this._analysisMethods.slice(0);
  }

}
