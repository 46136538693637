 <!-- <div class="container">

  <div class="row">

    <div class="col-12 px-2 mb-4">
      <table mat-table [dataSource]="tableSource" class="table-primary">

         Target Column --
        <ng-container matColumnDef="target_name">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.target-name' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.target_name}}
          </mat-cell>
        </ng-container>

         Category Column --
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.category' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.category}}
             </mat-cell>
        </ng-container>

        KPI Column
        <ng-container matColumnDef="kpi">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.kpi' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.kpi}}
          </mat-cell>
        </ng-container>

        Unit Column--
        <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.unit' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.unit}}
          </mat-cell>
        </ng-container>

         Criteria Column
        <ng-container matColumnDef="criteria">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.criteria' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.criteria}}
             </mat-cell>
        </ng-container>

        Planned Target Valued Column --
        <ng-container matColumnDef="planned_target_valued">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.planned-target-valued' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.planned_target_valued}}
          </mat-cell>
        </ng-container>

        Planned Achived Date Column --
        <ng-container matColumnDef="planned_achived_date">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.planned_achived_date' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.planned_achived_date}}
          </mat-cell>
        </ng-container>

         Actual Achived Date Column
        <ng-container matColumnDef="actual_achived_date">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.actual_achived_date' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.actual_achived_date}}
          </mat-cell>
        </ng-container>


       Actual Achived Value Column
        <ng-container matColumnDef="actual_achived_value">
          <mat-header-cell *matHeaderCellDef>{{'pages.decision-table-closing.component.actual_achived_value' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.actual_achived_value}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

    </div>
  </div>
</div> -->
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
<!-- BOX OBIETTIVI PRIMARI -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'generic.objectives' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12 box px-0 box-two-row"  *ngIf="model.primary_target">
            <div class="col-lg-12">
              <div class="row">

                <div class="col-lg-4">

                  <mat-label class="box-title px-2">{{'components.decision-table.new-decision-table.compile-proposal.primary' | translate}}</mat-label>

                  <mat-form-field class="input-primary p-2">
                    <input matInput name="proposal_target_primary_title" [ngModel]="model.primary_target.title" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.title' | translate}}" readonly>
                  </mat-form-field>

                  <mat-form-field class="input-primary p-2">
                    <input matInput name="proposal_target_primary_expected_value" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.desidered-value' | translate}}" [(ngModel)]="model.primary_target.expected_value" autocomplete="off" readonly>
                  </mat-form-field>

                  <mat-form-field class="input-primary p-2">
                    <input matInput name="proposal_target_primary_evaluation_criteria" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(model.primary_target.evaluation_criteria)" readonly>
                  </mat-form-field>
                </div>
                <div class="col-lg-4">

                  <mat-form-field class="datepicker-primary col-12 py-3" appearance="fill">
                    <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="dp_primary" name="proposal_target_primary_date" class="color-default ng-trim-ignore" [(ngModel)]="model.primary_target.date" required disabled>
                      <mat-datepicker-toggle matSuffix [for]="dp_primary">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #dp_primary disabled="true"></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="select-primary col-12" appearance="fill">
                    <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.measure-unit' | translate}}</mat-label>
                    <mat-select name="proposal_target_primary_criteria_unit" [(ngModel)]="model.primary_target.measure_unit" disabled>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!--TODO_TRADUZIONI -->
                <!-- Planned input -->
                <div class="col-lg-4">
                  <mat-form-field class="datepicker-primary col-12 py-3 px-1" appearance="fill" (click)="dp_secondary.open()">
                    <mat-label>{{'pages.decision-table.decision-table-closing.component.planned_achived_date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="dp_secondary" name="planned_achived_date" class="color-default ng-trim-ignore" [(ngModel)]="targetPrimary.achived.planned_achived_date" required>
                      <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #dp_secondary disabled="false"></mat-datepicker>
                  </mat-form-field>

                  <mat-form-field class="input-primary col-12">
                    <input matInput name="actual_achived_value" placeholder="{{'pages.decision-table.decision-table-closing.component.actual_achived_value' | translate}}" [(ngModel)]="targetPrimary.achived.actual_achived_value" autocomplete="off">
                  </mat-form-field>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- BOX OBIETTIVI SECONDARI -->
        <div class="row">

          <div class="col-lg-6 p-0" *ngFor="let targetSecondary of model.secondary_targets; let i = index">
            <ng-container>
              <div class="box box-two-row">
                <mat-label class="box-title px-3">{{'components.decision-table.new-decision-table.compile-proposal.secondary' | translate}}</mat-label>
                <mat-form-field class="input-primary col-7">
                  <input matInput name="proposal_target_secondary_title{{i}}" [ngModel]="targetSecondary.title" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.title' | translate}}" readonly>
                </mat-form-field>
                <mat-form-field class="datepicker-primary col-5" appearance="fill" (click)="dp_secondary.open()">
                  <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="dp_secondary" name="proposal_target_secondary_date{{i}}" class="ng-trim-ignore color-default" [(ngModel)]="targetSecondary.date" required disabled>
                    <mat-datepicker-toggle matSuffix [for]="dp_secondary">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp_secondary disabled="true"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="input-primary col-7">
                  <input matInput name="proposal_target_secondary_evaluation_criteria{{i}}" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(targetSecondary.evaluation_criteria)" readonly>
                </mat-form-field>
                <mat-form-field class="select-primary col-5" appearance="fill">
                  <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.measure-unit' | translate}}</mat-label>
                  <mat-select name="proposal_target_secondary_criteria_unit{{i}}" [(ngModel)]="targetSecondary.measure_unit" disabled class="disabled">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="input-primary col-8">
                  <input matInput name="proposal_target_secondary_expected_value{{i}}" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.desidered-value' | translate}}" [(ngModel)]="targetSecondary.expected_value" readonly>
                </mat-form-field>
                <!--TODO_TRADUZIONI -->
                <!-- Planned input -->
                <mat-form-field class="datepicker-primary col-6 py-3 px-1" appearance="fill" (click)="dp_tertiary.open()">
                  <mat-label>{{'pages.decision-table.decision-table-closing.component.planned_achived_date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="dp_tertiary" name="planned_achived_date{{i}}" class="color-default ng-trim-ignore" [(ngModel)]="targetSecondary.achived.planned_achived_date" required>
                    <mat-datepicker-toggle matSuffix [for]="dp_tertiary">
                      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #dp_tertiary disabled="false"></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="input-primary col-6">
                  <input matInput name="actual_achived_value{{i}}" placeholder="{{'pages.decision-table.decision-table-closing.component.actual_achived_value' | translate}}" [(ngModel)]="targetSecondary.achived.actual_achived_value" autocomplete="off">
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>
<div class="buttons-container container py-4">
  <!-- <button type="button" class="button-primary float-left"  mat-button backbutton [routerLink]="['/dashboard']" >
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
  </button> -->
  <button type="button" class="button-primary float-right" [disabled]="loading" mat-button (click)="onSubmit(form)">
      <i class="icon-Forward-arrow-small"></i>
      <span>{{'generic.buttons.save' | translate}}</span>
  </button>
</div>
</form>
