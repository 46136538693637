import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ModalNewProposalComponent} from "../modal-new-proposal/modal-new-proposal.component";

@Component({
  selector: 'app-modal-show-proposals',
  templateUrl: './modal-show-proposals.component.html',
  styleUrls: ['./modal-show-proposals.component.scss']
})
export class ModalShowProposalsComponent {
  suggestedProposals = [];
  displayedColumns = [];
  decisionTable = {};

  constructor(public dialogRef: MatDialogRef<ModalShowProposalsComponent>, @Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog) {
    this.suggestedProposals = this.data?.suggestedProposals;
    this.displayedColumns = this.data?.fields || ['title', 'type', 'created_at', 'actions'];
    this.decisionTable = this.data?.decisionTable;
  }

  public closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }

  viewProposal(proposal) {

    let data = {};
    data = {
      newProposal: false,
      decision_table: this.decisionTable,
      proposal,
    };

    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }
}
