import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { NotificationsService, NotificationType, SimpleNotificationsComponent } from 'angular2-notifications';
import { ToastService } from 'src/app/services/general/toast.service';
import {ViewEncapsulation} from '@angular/core';

export enum ToastType {
  basic,
  success,
  alert,
  warn,
  info
}

@Component({
  selector: 'toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  styles: ['body div.simple-notification-wrapper { z-index:10000; }'],
  encapsulation: ViewEncapsulation.None
})
export class ToastNotificationComponent extends SimpleNotificationsComponent {

  @ViewChild('notification') notificationHtml: TemplateRef<any>;

  //  setting per il servizio di notifiche
  notificationOptions = {
    position: ["top", "right"],
    showProgressBar: false,
    timeOut: 4000,
    clickToClose: true
  }

  //  icone e classi per le notifiche
  notificationIcons = {
    success: {
      icon: 'check_circle_outline'
    },
    error: {
      icon: 'error_outline'
    },
    alert: {
      icon: 'warning_amber'
    },
    warn: {
      icon: 'warning_amber'
    },
    info: {
      icon: 'info'
    },
  }

  subscription: any;
  currentMessage: any = {};

  constructor(private notify: NotificationsService, private toast: ToastService, private changeDetection: ChangeDetectorRef) {
    super(notify, changeDetection);
  }

  ngOnInit(): void {
    this.subscription = this.toast.onMessage().subscribe(context => {

      if (this.notificationHtml) {
        this.currentMessage = context;
        this.notify.html(this.notificationHtml, NotificationType.Bare, null, null, context);
      }

    });
  }


}
