<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="header-with-actions">
      <mat-expansion-panel expanded hideToggle="true">
        <mat-expansion-panel-header class="event-disabled d-flex align-items-center">
            <mat-panel-title>
                {{'pages.user.all-users-management.component.manage-users' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container background-filters py-3 px-5 mt-3">
          <div class="row justify-content-between">
            <div class="purple-color">
              <h2>{{'generic.search-for' | translate}}</h2>
            </div>
            <div>
              <button class="clear-filter py-2 px-4" (click)="onClearSearch()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
            </div>
          </div>

          <!-- SECONDA ROW FILTRI-->
          <div class="row d-flex justify-content-between mx-0 mt-0">
            <div class="col-12 col-md-12 mt-4">
              <mat-form-field class="full-width">
                <input matInput name="searchKeywords" [(ngModel)]="searchTerm" (input)="onSearch()" type="text" placeholder="{{'pages.user.all-users-management.component.search-name-surname-email' | translate}}" #searchInput autocomplete="off">
                <mat-icon class="with-cursor" *ngIf="searchTerm" matSuffix aria-label="Clear" (click)="searchTerm=''; onSearch()">close</mat-icon>
                <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="onSearch()">search</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- ADD USER BUTTON -->
        <div class="row">
          <div class="col-12 d-flex justify-content-end align-items-center">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" [routerLink]="['./new']">
              <mat-icon class="mr-2">add_circle_outlined</mat-icon>
              {{'pages.user.all-users-management.component.add-user' | translate}}
            </button>
          </div>
        </div>

        <div class="row">

          <div class="col-12 px-2 mb-4">
            <mat-table [dataSource]="usersData" class="table-primary">

              <!-- FirstName Column -->
              <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef>{{'generic.name' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <span *ngIf="element?.user?.details?.first_name">{{element?.user?.details?.first_name}}</span>
                  <span *ngIf="!element?.user?.details?.first_name">{{element?.user?.name}}</span>
                </mat-cell>
              </ng-container>

              <!-- LastName Column -->
              <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef>{{'generic.surname' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''"> {{element?.user?.details?.last_name}} </mat-cell>
              </ng-container>

              <!--Roles Column-->
              <ng-container matColumnDef="roles">
                <mat-header-cell *matHeaderCellDef>{{'generic.roles.role' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <ng-container *ngFor="let organization_roles of element.roles; let i = index">
                    <span *ngIf="i < 3">
                      {{organization_roles?.role?.role}} &nbsp;
                    </span>
                    <span *ngIf="i >= 3">...</span>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <!--Tipo utente Column-->
              <ng-container matColumnDef="user_type">
                <mat-header-cell *matHeaderCellDef>{{'generic.access-level' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <span *ngIf="element.roles?.length > 0">{{ getUserTypes().getUserTypeValueByKey(element.roles[0]?.name)}}</span>
                </mat-cell>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>{{'generic.email' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" class="left" [ngClass]="element.deleted_at ? 'element-deleted' : ''"> {{element?.user?.email}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="reset-password">
                <mat-header-cell *matHeaderCellDef>{{ 'generic.password-reset' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex my-2" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <button *ngIf="element.user?.date_user_creation" mat-button color="primary" (click)="onClickResetPassword(element.id)"><small>{{ 'generic.password-reset' | translate }}</small> </button>
                  <button *ngIf="!element.user?.date_user_creation" mat-button color="primary" [routerLink]="['./new', element.id]"><small>{{'pages.user.all-users-management.component.create-user' | translate}}</small> </button>
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex my-2" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <button mat-button color="primary" [routerLink]="['./'+element.id]" routerLinkActive="router-link-active"><small>{{'pages.user.all-users-management.component.edit-profile' | translate}}</small> <mat-icon>forward</mat-icon></button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of users"></mat-paginator> -->

          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button [routerLink]="['../']" routerLinkActive="router-link-active">
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.back' | translate}}
    </button>
  </div>
