// loading.component.ts
import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-loading',
  templateUrl: './generic-loading.component.html',
  styleUrls: ['./generic-loading.component.scss'],
})
export class GenericLoadingComponent implements OnChanges {
  @Input() loading: boolean = false;
  @Output() isLoadingEmitter = new EventEmitter<boolean>();


  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoadingEmitter) {
      this.loading = changes.isLoadingEmitter.currentValue;
    }
  }
}
