import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnalysisMethodsService } from 'src/app/services/config/analysis-methods.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { WorkingSectorsService } from 'src/app/services/config/workingSectors.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-all-frameworks',
  templateUrl: './all-frameworks.component.html',
  styleUrls: ['./all-frameworks.component.scss']
})
export class AllFrameworksComponent implements OnInit {

  public openMenu: boolean = false;
  isOver = false;
  isAdmin = false;

  @ViewChild('searchInput') searchInput: ElementRef;
  subscriptionToSearchEvent: Observable<any>;
  loading: boolean = false;

  private maxNumOfFrameworkShowed: number = 6;
  currentNumOfFrameworkShowed: number = this.maxNumOfFrameworkShowed;

  frameworkData: any[] = [];
  frameworkFilteredList: any[] = [];

  frameworkSelected = null;

  searchModel: any = {
      sector_key: undefined,
      subsector_key: undefined,
      analysis_type: undefined,
      searchKeywords: undefined
  }

  constructor(private analysisMethodsService: AnalysisMethodsService, private frameworkService: FrameworkService,
    private workingSectorService: WorkingSectorsService, private userService: UserService,
    private router : Router,private activeRoute: ActivatedRoute) { }

  ngAfterViewInit() {

    //  mi registro all'evento di keyup della barra di ricerca
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((i: any) => i.currentTarget.value),
      debounceTime(1000)
    ).subscribe(() => this.onClickSearch())

  }

  ngOnInit(): void {

    this.init()
  }


  init() {

    //  controllo se l'utente è un admin
    if (this.userService.getCurrentRole() == 'ADMIN') {
      this.isAdmin = true;
    }

    this.loading = true;
    this.frameworkService.all((result) => {
      this.frameworkData = result;
      this.frameworkFilteredList = JSON.parse(JSON.stringify(this.frameworkData));
      this.loading = false;
    }, () => this.loading = false)

  }


  public getAnalysisMethodsService() {
    return this.analysisMethodsService;
  }

  public getWorkingSectorService() {
    return this.workingSectorService;
  }

  public getSubsectorsArrayFiltered (sectorKey) {
    return this.workingSectorService.getAllsubsectorsForSelectBySectorKey(sectorKey);
  }


  onClickShowMore() {
    this.currentNumOfFrameworkShowed += this.maxNumOfFrameworkShowed;
  }

  onClickSearch() {
    this.onChangeSearchFilter();
  }


  /**
   * Gestione della ricerca di un framework al cambiamento di un parametro
   */
  onChangeSearchFilter(filterType:string = '') {

    this.frameworkSelected = null;

    if (filterType == 'sector')
      this.searchModel.subsector_key = null;

    this.loading = true;
    this.frameworkService.search(
      {
        'sector': this.searchModel.sector_key,
        'subsector': this.searchModel.subsector_key,
        'analysis': this.searchModel.analysis_type,
        'searchkey': this.searchModel.searchKeywords
      }, (response) => {
        this.frameworkFilteredList = [...response];
        this.loading = false;
      }, () => this.loading = false
    );
  }


  /**
   * Gestione del click su di un framework
   */
  onClickFramework(framework_index) {
    this.frameworkSelected = JSON.parse(JSON.stringify(this.frameworkFilteredList[framework_index]));
    this.router.navigate(['/framework/'+ this.frameworkSelected.id]);
  }



  /** Cancella i filtri di ricerca utilizzati */
  onClickResetFilters() {

    this.searchModel = {
      sector_key: undefined,
      subsector_key: undefined,
      analysis_type: undefined,
      searchKeywords: undefined
    }

    this.init();
  }

}
