import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';

//const name_favoriteOrganization = 'P_ORG';

@Injectable({
  providedIn: 'root',
})
export class SystemService {

  constructor(private backend:BackendService) {}


  /**
   * Recupera tutti i ruoli del sistema
   * @param callbackSuccess
   * @param callbackError
   */
  public getSystemRoles(
    callbackSuccess: any = () => {},
    callbackError: any = () => {}
  ) {
    this.backend.get('permissions/roles').subscribe(
      (result: any) => {
        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }

  /**
  * Recupero tutti i ruoli e i permessi del sistema
  */
  public getSystemRoleAndPermissions(
    callbackSuccess: any = () => {},
    callbackError: any = () => {}
  ) {
    this.backend.get('permissions/system-role-and-permissions').subscribe(
      (result: any) => {
        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }

}
