import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from 'src/app/services/user.service';
import {faCheck, faUser, faUserSecret, faUsers} from '@fortawesome/free-solid-svg-icons';
import {SystemService} from 'src/app/services/system.service';
import {OrganizationService} from 'src/app/services/organization.service';
import {ToastService} from "../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-modal-user-permissions',
  templateUrl: './modal-user-permissions.component.html',
  styleUrls: ['./modal-user-permissions.component.scss']
})
export class ModalUserPermissionComponent implements OnInit {

  faUser = faUser;
  faUsers = faUsers;
  faUserSecred = faUserSecret;
  faCheck = faCheck;

  userData: any;
  organization_user: any;

  hidePermissions: boolean = true;
  loading: boolean = false;

  user_permissions: any = []; //  tutti i permessi dell'utente
  user_role: any = {}; //  ruolo dell'utente
  currentPermissionsData: any = []; //  permessi attualmente associati all'utente (copia locale)
  currentRole: any = null;
  systemRoles: any = []; //  tutti i ruoli del sistema

  constructor(
    public dialogRef: MatDialogRef<ModalUserPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    public userService: UserService,
    private organizationService: OrganizationService,
    private systemService: SystemService,
    private toast: ToastService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {

    //  recupero tutti i ruoli e i permessi del sistema
    this.loading = true;

    //  recupero tutti i ruoli del sistema
    this.systemService.getSystemRoles((result) => {
      this.systemRoles = result;
      this.systemRoles.forEach(role => {
        role.name = role.name.toLowerCase();
      });

      //  recupero il ruolo e i permessi del membro corrente
      this.organizationService.getMemberRoleAndPermission(this.data.organization_user.organization_id, this.data.organization_user.id, (result) => {
        this.user_permissions = result;

        this.currentPermissionsData = JSON.parse(JSON.stringify(result));
        this.currentRole = JSON.parse(JSON.stringify(this.data.user.originalRoles[0].name));

        this.init();
        this.loading = false;
      }, () => {
      });
    });

  }

  init() {

    if (this.data?.user) {
      this.userData = this.data.user;
    }

    if (this.data.organization_user) {
      this.organization_user = this.data.organization_user;

      //  su tutti i permessi basati sul ruolo dell'utente devo costruire un array di permessi
      if (this.organization_user?.roles && this.organization_user.roles.length > 0) {
        this.user_role = this.organization_user.roles[0];
        this.user_role.name = this.user_role.name.toLowerCase();
      }
    }

  }

  //  Mostra o nasconde i permessi che non sono visibili all'utente e che sono ereditati dal ruolo
  //  questi permessi non sono modificabili dall'utente, anche se amministratore
  showAll() {
    this.hidePermissions = !this.hidePermissions;
  }

  //  Evento di cambio del ruolo nella modale
  // selectRoleOnChange() {
  //   let perm = this.getPermissionByRole(this.user_role.name);
  // }

  //  Recupero tutti i permessi basati sul ruolo che è stato selezionato
  // getPermissionByRole(roleName: any) {
  //   return [];
  //   // return this.roleAndPermissionData.find(x => x.name == roleName);
  // }

  //  Evento di click sul singolo permesso
  permissionOnClick(permission: any) {
    permission.owned = !permission.owned;
  }

  //  Help method per ottenere la classe del bottone
  getClassButton(permission: any) {
    if (permission.ownded == true || permission.owned == false) {
      return 'mat-stroked-button';
    }

    return 'mat-flat-button';
  }

  //  Help method per ottenere l'icona basata sul ruolo
  getIconType() {
    if (this.user_role?.name == 'admin') {
      return this.faUser;
    }
    if (this.user_role?.name == 'member') {
      return this.faUsers;
    }

    return this.faUserSecred;
  }

  //  Aggiorni i permessi per l'utente
  updatePermissions() {

    //  faccio il confronto tra i permessi che l'utente aveva all'inizio e con quelli che ha adesso
    let filterUserPermission = this.user_permissions.filter(x => x.hidden_to_user == false);
    let filterCurrentPermission = this.currentPermissionsData.filter(x => x.hidden_to_user == false);
    let permissionChanged = [];

    filterCurrentPermission.forEach(currentPermission => {
      let findPermission = filterUserPermission.find(x => x.name == currentPermission.name);
      if (findPermission && findPermission.owned != currentPermission.owned) {
        permissionChanged.push(currentPermission);
      }
    });

    const new_role = this.currentRole.toLowerCase() === this.user_role.name.toLowerCase() ? null : this.user_role.name;


    if (permissionChanged.length > 0 || new_role !== null) {
      this.organizationService.updateMemberPermissions(this.organization_user.organization_id, this.organization_user.id, permissionChanged, new_role, () => {
        this.toast.success(this.translateService.instant('pages.user.single-user-management.component.show-role-and-permission'), this.translateService.instant('modals.modal-user-permissions.permissions-updated-successfully'));
        this.ngOnInit();
      });
    }
  }


  public getUserService() {
    return this.userService;
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close({event: event});
  }

}
