<div class="container bg-header">
  <div class="row">
    <div class="col-6">
      <h1 mat-dialog-title class="text-white p-3 m-0">{{'modals.modal.your-organization.title' | translate}}</h1>
    </div>
    <div class="col-6 d-flex justify-content-end align-items-center">
      <p (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <div class="container">
    <div class="row">
      <div class="col-12" *ngFor="let organization of getUserService().getAllOrganizations(), let i = index">
        <mat-card class="box box-border mt-3 d-flex align-items-center">
          <div class="col-3">
            <img *ngIf="organization?.logo_link" [src]="organization?.logo_link" [ngClass]=" getUserService().currentOrganization.id === organization.id ? 'border-custom' : '' " (click)="changeCurrentOrganization(organization.id)" class="organization-logo" [routerLink]="['/home']" alt="">
            <img *ngIf="!organization?.logo_link" src="assets/img/placeholder-img-organization.jpg" [ngClass]=" getUserService().currentOrganization.id === organization.id ? 'border-custom' : '' " (click)="changeCurrentOrganization(organization.id)" class="organization-logo" [routerLink]="['/home']" alt="">
          </div>
          <div class="col-3">
            <p>{{'generic.name' | translate}}</p>
            <h3><strong>{{organization.name}}</strong></h3>
          </div>
          <div class="col-3">
            <p>{{'generic.roles.role' | translate}}</p>
            <h3><strong>CTO</strong></h3>
            <!-- <p *ngFor="let organization_role of getUserService().getProfessionalRolesForOrganization(organization); let i = index">
              {{organization_role.roles}}
            </p> -->
            <!-- <ng-container matColumnDef="roles">
              <mat-cell *matCellDef="let element">
                <ng-container *ngFor="let organization_roles of roles; let i = index">
                  <span *ngIf="i < 3">
                    {{organization_roles?.role?.role}} &nbsp;
                  </span>
                  <span *ngIf="i >= 3">...</span>
                </ng-container>
              </mat-cell>
            </ng-container> -->
            <!-- <p>{{organization.roles[i].role}}</p> -->
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="buttons-container container py-5 mb-3">
    <button class="button-primary float-left" mat-button (click)="closeDialogue()">
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.back' | translate}}
    </button>
  </div>
</div>
