import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddWorkExpComponent } from '../../../modals/modal-add-work-exp/modal-add-work-exp.component';
import { ModalAddEducationComponent } from '../../../modals/modal-add-education/modal-add-education.component';
import { ModalAddCertificateComponent } from 'src/app/modals/modal-add-certificate/modal-add-certificate.component';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/general/alert.service';
import { OrganizationService } from 'src/app/services/organization.service';
@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  user: any;

  organization_id: any;

  organization_user_id: any;

  curriculum_id: any;

  works : any[] = []

  educations : any[] = []

  certificates : any[] = []

  modelUser : any = {
    //? Campi di input
    first_name : null,
    last_name : null,
    roles : [] = [] ,
    cardRoles : [{}],
    email : null,
    phone : null,
    avatar_link : null
  }

  constructor(private dialog: MatDialog,private organizationService: OrganizationService ,private userService: UserService, private activeRoute: ActivatedRoute, private toast : ToastService,private translateService: TranslateService, private alert: AlertService) { }

  ngOnInit(): void {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.curriculum_id = routeParams.id_curriculum;
      this.organization_user_id = routeParams.id_user_organization;

      if (this.organization_user_id && this.curriculum_id) {
        this.getCurriculum();
        this.init();
      }
    });

  }

  init() {
    //  recupero le informazioni dell'utente corrente
    this.getUser();
  }


  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }



  getCurriculum() {
    this.userService.getCurriculum(this.curriculum_id, (result) => {
      if (result.working_experiences)
      this.works = result.working_experiences;
      this.educations = result.educations;
      this.certificates = result.certificates;
    });
  }

  /** Recupera le informazioni dell'utente corrente */
  getUser() {

    this.loading = true;

    this.organizationService.getOrganizationUser(this.organization_id, this.organization_user_id, (result) => {

        this.user = result;

        this.modelUser.id = this.user.id;
        this.modelUser.first_name = this.user.user?.details?.first_name;
        this.modelUser.last_name = this.user.user?.details?.last_name;
        this.modelUser.email = this.user.user.email;
        this.modelUser.originalRoles = this.user.roles;
        this.modelUser.details = this.user.user.details;
        this.modelUser.curricula = this.user.user.curricula;

        if (this.user.roles.length > 0)
          this.modelUser.cardRoles = [];

        this.user.roles.forEach(role => {
          if (role.role)
            this.modelUser.cardRoles.push({role_id: role.role.id});
        });

        //  cerco il numero di telefono primario
        let phone = this.user.user.contacts.find(x => x.type == 'PRIMARYPHONE');
        if (phone)
          this.modelUser.phone = phone.value;

        this.loading = false;
    }, (error) => {
      this.loading = false;
    })
  }

  openDialogAddWork(workExperience = undefined) {

    const dialogRef = this.dialog.open(ModalAddWorkExpComponent, {
      panelClass: 'medium-dialog',
      data: {
      curriculum_id : this.curriculum_id,
      workExperience : workExperience
      }
    });

    dialogRef.afterClosed().subscribe((result:any) =>{

      if (result?.event != null){
        this.getCurriculum();
      }
    })
  }

  openDialogAddEducation(education = undefined) {
    const dialogRef = this.dialog.open(ModalAddEducationComponent, {
      panelClass: 'medium-dialog',
      data: {
      curriculum_id : this.curriculum_id,
      education : education
      }
    });

    dialogRef.afterClosed().subscribe((result:any) =>{
      if (result?.event != null){
        this.getCurriculum();
      }
    })
  }

  openDialogAddCertificate(certificate = undefined) {
    const dialogRef = this.dialog.open(ModalAddCertificateComponent,{
      panelClass: 'medium-dialog',
      data: {
      curriculum_id : this.curriculum_id,
      certificate : certificate
      }
    });

    dialogRef.afterClosed().subscribe((result:any) =>{
      if (result?.event != null){
        this.getCurriculum();
      }
    })
  }


  onClickAddWorkExp() {

    this.works.push({})
  }

  onClickAddEducation() {

    this.educations.push({})
  }

  onClickAddCertificate() {

    this.certificates.push({})
  }

  removeWorkExperience(work_id) {

    this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("pages.curriculum.component.alert-work-removed"), (ok) => {
      this.loading = true;
      this.userService.deleteWorkExperience(this.curriculum_id, work_id, () => {
        this.loading = false;
        this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.work-exp-removed'));
        this.ngOnInit();
      },()=> {
        this.loading = false;
        this.toast.error(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.error-work-exp-removed'));
      } );
    })
  }

  removeEducation(education_id) {

    this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("pages.curriculum.component.alert-education-removed"), (ok) => {
      this.loading = true;
      this.userService.deleteEducation(this.curriculum_id, education_id, () => {
        this.loading = false;
        this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.education-removed'));
        this.ngOnInit();
      },()=> {
        this.loading = false;
        this.toast.error(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.error-education-removed'));
      } );
    })
  }
  removeCertificate(certificate_id) {
    this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("pages.curriculum.component.alert-certificate-removed"), (ok) => {
      this.loading = true;
      this.userService.deleteCertificate(this.curriculum_id, certificate_id, () => {
        this.loading = false;
        this.toast.success(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.certification-removed'));
        this.ngOnInit();
      },()=> {
        this.loading = false;
        this.toast.error(this.translateService.instant('generic.user'), this.translateService.instant('pages.user.curriculum.component.error-certification-removed'));
      } );
    })
  }

}
