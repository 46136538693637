import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Input
} from '@angular/core';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {UserService} from 'src/app/services/user.service';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {DecisionService} from '../../../services/decision.service';
import {OrganizationService} from '../../../services/organization.service';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

export interface DecisionTableData {
  decision_name: string;
  created_at: Date;
  scheme_type: string;
  role: string;
  action: string;
  closing_date: string;
}


@Component({
  selector: 'app-all-decision-table',
  templateUrl: './all-decision-table.component.html',
  styleUrls: ['./all-decision-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AllDecisionTableComponent implements OnInit{
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<EvaluationSession>>;
  @ViewChild('decisionTablesPaginator') decisionTablesPaginator: MatPaginator;
  @ViewChild('decisionTablesSort') decisionTablesSort = new MatSort();
  @ViewChild('contestsSort') contestsSort = new MatSort();
  @ViewChild('sessionsSort') sessionsSort = new MatSort();
  @Input() input_decision_tables: any;
  @Input() projectId: any;


  dataSource: MatTableDataSource<DecisionTableData>;
  columnsToDisplay = ['decision_name', 'created_at', 'closing_date', 'scheme_type', 'actions'];
  innerDisplayedColumns = ['created_at', 'status', 'role', 'action'];
  innerDisplayedContestColumns = ['title', 'created_at', 'status', 'action'];
  expandedElement: DecisionTable | null;
  expandedElementListType = null;
  expandibleTypes = {
    sessions: 'evaluation_sessions',
    contests: 'contests',
  };

  loading = false;
  decision_tables = [];

  searchModel: any = {
    startDate: undefined,
    endDate: undefined,
    name: ''
  };

  toppings = new FormControl('');
  toppingList: string[] = [
    'Responsible',
    'Consulted',
    'Accountable',
    'Informed',
  ];

  methods = [];
  selectedMethods = new FormControl('');


  constructor(
    public analysisMethodsService: AnalysisMethodsService,
    public processRoleService: ProcessRolesService,
    public userService: UserService,
    public cd: ChangeDetectorRef,
    public organizationService: OrganizationService,
    public decisionService: DecisionService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
  }
  ngOnInit(): void {
    let url = '';
    this.route.pathFromRoot.forEach((router) => {
      url += router.snapshot.url.map(segment => segment.path).join('/') + '/';
    });

    this.init();
  }

  init() {
    if (this.input_decision_tables) {
      this.decision_tables = JSON.parse(JSON.stringify(this.input_decision_tables));
      this.dataSource = new MatTableDataSource<DecisionTableData>(this.decision_tables);
      setTimeout(() => {
        this.dataSource.paginator = this.decisionTablesPaginator;
        this.dataSource.sort = this.decisionTablesSort;
      }, 100);
    } else {
      this.loading = true;
      this.userService.getUserDecisionTables((result) => {
        this.decision_tables = result.map((table) => {
          table.decision_name =   table.decision_name.charAt(0).toUpperCase()
            + table.decision_name.slice(1);
          return table;
        });
        this.dataSource = new MatTableDataSource<DecisionTableData>(result);
        setTimeout(() => {
          this.dataSource.paginator = this.decisionTablesPaginator;
          this.dataSource.sort = this.decisionTablesSort;
        }, 100);
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    }
    this.methods = JSON.parse(
      JSON.stringify(this.analysisMethodsService.methods)
    );
  }

  toggleRow(element: DecisionTable, listToView: string) {
    const isSameElementAndListType = element.id === this.expandedElement?.id && listToView === this.expandedElementListType;

    if (isSameElementAndListType) {
      this.expandedElement = null;
    } else {
      this.loading = true;

      const handleSuccess = (result: any[], property: string) => {
        const decisionTable = this.decision_tables.find((decision) => decision.id === element.id);
        decisionTable[property] = new MatTableDataSource(result);
        this.dataSource = new MatTableDataSource<DecisionTableData>(this.decision_tables);
        this.expandedElement = element;
        this.cd.detectChanges();
        this.expandedElementListType = listToView;
        this.applyFilterToDataSource();
        this.loading = false;
      };

      const handleFailure = () => {
        this.loading = false;
      };

      if (listToView === this.expandibleTypes.contests) {
        this.decisionService.getDecisionTableContests(element.id,
          (result) => handleSuccess(result, listToView),
          handleFailure
        );
      }

      if (listToView === this.expandibleTypes.sessions) {
        this.decisionService.getDecisionEvaluationSessionByUser(element.id,
          (result) => handleSuccess(result, listToView),
          handleFailure
        );
      }
    }
  }

  onClickResetFilters() {

    this.searchModel = {
      startDate: undefined,
      endDate: undefined,
      name: ''
    };

    this.toppings = new FormControl('');
    this.selectedMethods = new FormControl('');

    this.dataSource.data = [...this.decision_tables];
  }


  applyFilterToDataSource() {
    this.dataSource.data = [...this.decision_tables];
    if (this.searchModel.name.length > 0) {
      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        return item.decision_name
          .toLowerCase()
          .includes(this.searchModel.name.toLowerCase());
      });
    }
    if (this.searchModel.startDate) {
      const startDateObj = new Date(this.searchModel.startDate);

      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        const createdAt = new Date(item.created_at);
        return createdAt >= startDateObj;
      });
    }

    if (this.searchModel.endDate) {
      const endDateObj = new Date(this.searchModel.endDate);
      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        const createdAt = new Date(item?.created_at);
        return createdAt <= endDateObj;
      });
    }

    if (this.selectedMethods.value.length > 0) {
      this.dataSource.data = this.dataSource.filteredData.filter(
        (decisionTable) => {
          let tmp = false;
          // @ts-ignore
          this.selectedMethods.value.forEach((method) => {
            // @ts-ignore
            if (method.type === decisionTable?.framework?.analysis_type) {
              tmp = true;
            }
          });
          return tmp;
        }
      );
    }

    if (this.toppings.value.length > 0) {
      this.dataSource.data = this.dataSource.filteredData.filter(
        (decisionTable) => {
          let tmp = false;
          // @ts-ignore
          for (const teamMember of decisionTable.team_member) {
            if (this.toppings.value.includes(teamMember.process_role)) {
              tmp = true;
            }
          }
          return tmp;
        }
      );
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public getDecisionCreateRoute() {
    return this.userService.currentOrganizationHasFeature('projects') ? '/projects/' + this.projectId + '/decision' : '/decision';
  }
}

export interface DecisionTable {
  id: string;
  decision_name: string;
  created_at: Date;
  status: string;
  evaluation_sessions?: EvaluationSession[] | MatTableDataSource<EvaluationSession>;
  contests?: Contest[] | MatTableDataSource<Contest>;

}

export interface EvaluationSession {
  created_at: Date;
  status: string;
}

export interface Contest {
  created_at: Date;
  status: string;
}
