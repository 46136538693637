import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { AnalysisMethodsService } from 'src/app/services/config/analysis-methods.service';
import { AlertService } from 'src/app/services/general/alert.service';
import { ResponseService } from 'src/app/services/response.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-responses-weight-criteria',
  templateUrl: './responses-weight-criteria.component.html',
  styleUrls: ['./responses-weight-criteria.component.scss']
})
export class ResponsesWeightCriteriaComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  timeoutId: any;
  timerId: any;
  loading: boolean = false;

  id: any;
  allInfo: any

  decision_table:any;

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router, private analysisService: AnalysisMethodsService,
    private alert: AlertService,private translateService: TranslateService) {

    //  faccio partire il timer con l'inattività dell'utene
    this.checkTimeOut();
    this.checkTimer();
    // this.launchTrackEvent();

    //  mi registro agli eventi di inattività dell'utente
    this._userInactiveSubscription = this._userInactive.subscribe(() => {
      this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("generic.alert-active-work-questionnaire"), (res) => {
        if (res) this.userActive = true;
      });
    });
  }


  //  Controllo dell'inattività dell'utente per gestire i conteggi del tempo
  _userInactive: Subject<any> = new Subject();
  _userInactiveSubscription: any;
  userActive: boolean = true;
  eventDetectionActive = true;
  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:scroll')
  @HostListener('window:mousemove')
  checkUserActivity() {
    if (this.eventDetectionActive) {
      clearTimeout(this.timeoutId);
      this.checkTimeOut();
    }
  }
  checkTimeOut() {
    this.timeoutId = setTimeout(
      () => {
        this._userInactive.next(void 0);
        this.userActive = false;
      }, environment.checkInactivityTime
    );
  }

  checkTimer() {
    this.timerId = setTimeout(
      () => {
        this.checkTimer();
        if (this.userActive)
          this.responseService.setTimerEvent(this.id, {event: 'track'});
      }, environment.trackEventTime
      // }, environment.checkInactivityTime
    );
  }

  // launchTrackEvent() {
  //   this.timerId = setTimeout(
  //     () => {
  //       this.launchTrackEvent();
  //       if (this.userActive)
  //         this.responseService.setTimerEvent(this.id, {event: 'track'});
  //     }, environment.trackEventTime
  //   );
  // }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_evaluation_session;
      if (this.id) {

        this.getSummary();
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();

    if (this._userInactiveSubscription) {
      this._userInactiveSubscription.unsubscribe();
    }

    clearTimeout(this.timerId);
  }

  //  Controlla se posso controllare gli eventi o meno
  checkEvents() {

    //  controllo se ho già inviato le domande o se il questionario è stato fermato, a questo punto non invio nessun tipo di evento
    if (this.allInfo?.evaluation_session.collecting == false || this.allInfo.response_stamps?.completed == true) {
      clearTimeout(this.timeoutId);
      clearTimeout(this.timerId);
      this.eventDetectionActive = false;
    }
  }

  /** Recupero tutte le info delle risposte */
  getSummary() {
    this.loading = true;
    this.responseService.getSummary(this.id, (result) => {

      this.decision_table = result.decision_table;
      this.allInfo = result;

      this.checkEvents();

      this.loading = false;

      //  se sul tavolo non sono abilitati i pesi, porto l'utente direttamente a rispondere al questionario
      if (this.allInfo?.evaluation_session?.settings?.weights_provided_users == false) {
        this.router.navigate(['../responses-evaluation'], {relativeTo: this.activeRoute})
      }

    }, () => {
      this.loading = false;
      this.router.navigate(['/home']);
    })
  }


  /** Evento di salvataggio dei target */
  weightCriteriaSaved() {
    this.getSummary();
  }


  public getAnalysisService() {
    return this.analysisService;
  }

}
