import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';
import {error} from 'protractor';

@Injectable({
  providedIn: 'root',
})

export class OrganizationService {
    public organizationCharts: any = [];

    constructor(private backend: BackendService) {
    }



    /**
     * Recupera le informazioni di una organizzazione
     * @param id_organization
     * @param success_callback
     * @param error_callback
     */
    public getAllOrganization(id_organization: any, success_callback = (data: any) => {
    },                        error_callback = (error: any) => {
    }) {

        this.backend.get('organization/all').subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Recupera le informazioni di una organizzazione
     * @param id_organization
     * @param success_callback
     * @param error_callback
     */
    public getOrganization(id_organization: any, success_callback = (data: any) => {
    },                     error_callback = (error: any) => {
    }) {
        this.backend.get('organization/' + id_organization).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Salva una nuova organizzazione
     * @param model
     * @param success_callback
     * @param error_callback
     */
    public saveOrganization(model: any, success_callback = (data: any) => {
    },                      error_callback = (error: any) => {
    }) {

        this.backend.post('organization', model).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Salva una nuova organizzazione
     * @param model
     * @param success_callback
     * @param error_callback
     */
    public updateOrganization(model: any, success_callback = (data: any) => {
    },                        error_callback = (error: any) => {
    }) {

        this.backend.put(`organization/${model.id}`, model).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Aggiunge un settore ad una organizzazione
     * @param sector
     * @param success_callback
     * @param error_callback
     */
    public addOrganizationSector(organization_id: string, sector: any, success_callback = (data: any) => {
    },                           error_callback = (error: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/sector`, {sector}).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Rimuove un settore per una organizzazione
     * @param organization_id
     * @param sector
     * @param success_callback
     * @param error_callback
     */
    public removeOrganizationSector(organization_id: string, sector_id: string, success_callback = (data: any) => {
    },                              error_callback = (error: any) => {
    }) {

        this.backend.destroy(`organization/${organization_id}/sector/${sector_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Recupera un ruolo
     * @param id_role
     * @param id_organization
     * @param success_callback
     * @param error_callback
     */
    public getOrganizationRole(id_organization: any, id_role: any, success_callback = (data: any) => {
    },                         error_callback = (error: any) => {
    }) {

        this.backend.get(`organization/${id_organization}/role/` + id_role).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Salva un ruolo completo
     * @param model
     * @param success_callback
     * @param error_callback
     */
    public saveOrganizationRole(organization_id: string, model: any, success_callback = (data: any) => {
    },                          error_callback = (error: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/role`, model).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });

    }


    /**
     * Recupero tutti i ruoli
     * @param success_callback
     * @param error_callback
     */
    public allOrganizationRoles(organization_id: string, success_callback = (data: any) => {
    },                          error_callback = (error: any) => {
    }) {

        this.backend.get(`organization/${organization_id}/role/all`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

    /**
     * Cancello un ruolo
     * @param organization_id
     * @param role_id
     * @param success_callback
     * @param error_callback
     */
    public deleteOrganizationRole(organization_id, role_id, success_callback = (data: any) => {
    },                            error_callback = (data: any) => {
    }) {

        this.backend.destroy(`organization/${organization_id}/role/${role_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

    public deleteOrganizationUser(organization_id, organization_user_id, success_callback = (data: any) => {
    },                            error_callback = (error: any) => {
    }) {

        this.backend.destroy(`organization/${organization_id}/user/${organization_user_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

    public resetOrganizationUser(organization_id, organization_user_id, success_callback = (data: any) => {
    },                           error_callback = (error: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/user/${organization_user_id}/reset-user`, null).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

    /**
     * Aggiorno un ruolo
     * @param organization_id
     * @param role_id
     * @param model
     * @param success_callback
     * @param error_callback
     */
    public updateOrganizationRole(organization_id, role_id, model: any, success_callback = (data: any) => {
    },                            error_callback = (error: any) => {
    }) {

        this.backend.put(`organization/${organization_id}/role/${role_id}`, model).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Salva un utente di una organizzazione
     * @param modelUser
     * @param success_callback
     * @param error_callback
     */
    public saveOrganizationUser(organization_id: string, modelUser: any, success_callback = (data: any) => {
    },                          error_callback = (error: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/user`, modelUser).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });

    }


    /**
     * Aggiorno i dati per un utente di una organizzazione
     * @param organization_id
     * @param organization_user_id
     * @param modelUser
     * @param success_callback
     * @param error_callback
     */
    public updateOrganizationUser(organization_id, organization_user_id, modelUser: any, success_callback = (data: any) => {
    },                            error_callback = (error: any) => {
    }) {

        this.backend.put(`organization/${organization_id}/user/${organization_user_id}`, modelUser).subscribe(result => {
            success_callback(result);
        }, (error) => {
            console.log(error);
            this.backend.showErrors(error);
            error_callback(error);
        });

    }


    /**
     * Recupero tutti gli utenti
     * @param success_callback
     * @param error_callback
     */
    public allOrganizationUsers(organization_id: string, success_callback = (data: any) => {
    },                          error_callback = (error: any) => {
    }) {

        this.backend.get('organization/' + organization_id + '/user/all').subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Recupera un componente dell'organizzazione per id
     * @param organization_user_id
     * @param success_callback
     * @param error_callback
     */
    public getOrganizationUser(organization_id, organization_user_id, success_callback = (data: any) => {
    },                         error_callback = (error: any) => {
    }) {

        this.backend.get(`organization/${organization_id}/user/${organization_user_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

    /**
     * Recupera un componente dell'organizzazione per id
     * @param email
     * @param organization_user_id
     * @param success_callback
     * @param error_callback
     */
    public getOrganizationUsersByEmail(email, organization_id, success_callback = (data: any) => {
    },                                 error_callback = (error: any) => {
    }) {
      this.backend
        .get(`organization/${organization_id}/user/searchbyemail/${email}`)
        .subscribe(
          (result) => {
            success_callback(result);
          },
          (error) => {
            this.backend.showErrors(error);
            error_callback(error);
          }
        );
    }


    /**
     * Recupera tutti i documenti dell'organizzazione
     * @param organization_id
     * @param success_callback
     * @param error_callback
     */
    public allOrganizationDocuments(organization_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
        this.backend.get(`organization/${organization_id}/document/all`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Cerco tra i nomi dei documenti di una organizzazione
     * @param organization_id
     * @param success_callback
     * @param error_callback
     */
    public searchOrganizationDocument(organization_id: string, searchtext: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.get(`organization/${organization_id}/document/search?query=${searchtext}`).subscribe(result => {
          success_callback(result);
      }, (error) => {
          this.backend.showErrors(error);
          error_callback(error);
      });
    }


    /**
     * Cancella un documento da una organizzazione
     * @param organization_id
     * @param document_id
     * @param success_callback
     * @param error_callback
     */
    public deleteDocument(organization_id: string, document_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.post(`organization/${organization_id}/document/delete`, {document_id: document_id}).subscribe(result => {
          success_callback(result);
      }, (error) => {
          // this.backend.showErrors(error);
          error_callback(error);
      });
    }


    /**
     * Recupera tutti i template email dell'organizzazione
     * @param organization_id
     * @param success_callback
     * @param error_callback
     */
    public allOrganizationTemplatesEmail(organization_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.get(`organization/${organization_id}/template-email/all`).subscribe(result => {
          success_callback(result);
      }, (error) => {
          this.backend.showErrors(error);
          error_callback(error);
      });
    }

  /**
   * Recupera tutti i gruppi dell'organizzazione
   * @param organization_id
   * @param success_callback
   * @param error_callback
   */
  public allOrganizationCharts(organization_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
    this.backend.get(`organization/${organization_id}/organization-charts`).subscribe(result => {
      this.organizationCharts = result;
      success_callback(result);
    }, (error) => {
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

    /**
   * Cancella un template email da una organizzazione
   * @param organization_id
   * @param document_id
   * @param success_callback
   * @param error_callback
   */
    public deleteTemplateEmail(organization_id: string, template_email_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.post(`organization/${organization_id}/template-email/delete`, {template_email_id: template_email_id}).subscribe(result => {
          success_callback(result);
      }, (error) => {
          this.backend.showErrors(error);
          error_callback(error);
      });
    }

  /**
   * Cancella un template email da una organizzazione
   * @param organization_id
   * @param organization_chart_id
   * @param success_callback
   * @param error_callback
   */
  public deleteOrganizationChart(organization_id: string, organization_chart_id: string, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
    this.backend.destroy(`organization/${organization_id}/organization-charts/${organization_chart_id}`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

    /**
     * Salva un nuovo template per le email
     * @param organization_id
     * @param success_callback
     * @param error_callback
     */
    public saveTemplateEmail(organization_id: string, templateModel: any, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.post(`organization/${organization_id}/template-email/save`, templateModel).subscribe(result => {
          success_callback(result);
      }, (error) => {
          this.backend.showErrors(error);
          error_callback(error);
      });
    }

  /**
   * Salva un nuovo gruppo per l'organizzazione
   * @param organization_id
   * @param organization_chart_model
   * @param success_callback
   * @param error_callback
   */
  public saveOrganizationChart(organization_id: string, organization_chart_model: any, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
    this.backend.post(`organization/${organization_id}/organization-charts`, organization_chart_model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


    /**
     * Aggiorna un template per le email
     * @param organization_id
     * @param templateModel
     * @param success_callback
     * @param error_callback
     */
    public updateTemplateEmail(organization_id: string, templateModel:any, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
      this.backend.put(`organization/${organization_id}/template-email/${templateModel.id}`, templateModel).subscribe(result => {
          success_callback(result);
      }, (error) => {
          this.backend.showErrors(error);
          error_callback(error);
      });
    }

  /**
   * Aggiorna un gruppo dell'organizzazione
   * @param organization_id
   * @param success_callback
   * @param error_callback
   */
  public updateOrganizationChart(organization_id: string, chartModel:any, success_callback = (data: any) => {}, error_callback = (error: any) => {} ) {
    this.backend.put(`organization/${organization_id}/organization-charts/${chartModel.id}`, chartModel).subscribe(result => {
      success_callback(result);
    }, (error) => {
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


    /**
     * Salvo un ruolo per un utente di una organizzazione in particolare
     * @param organization_id
     * @param organization_user_id
     * @param success_callback
     * @param error_callback
     */
    public saveUserRole(organization_id, organization_user_id, modelRoles: any, success_callback = (data: any) => {
    },                  error_callback = (data: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/user/${organization_user_id}/role`, modelRoles).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Sostituisce un ruolo con un'altro
     * @param organization_id
     * @param organization_user_id
     * @param old_organization_role_id
     * @param new_organization_role_id
     * @param success_callback
     * @param error_callback
     */
    public replaceUserRole(organization_id, organization_user_id, old_organization_role_id, new_organization_role_id, success_callback = (data: any) => {
    },                     error_callback = (data: any) => {
    }) {

        this.backend.put(`organization/${organization_id}/user/${organization_user_id}/role/${old_organization_role_id}`, {role_id: new_organization_role_id}).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Aggiungo un nuovo ruolo
     * @param organization_id
     * @param organization_user_id
     * @param new_organization_role_id
     * @param success_callback
     * @param error_callback
     */
    public addUserRole(organization_id, organization_user_id, new_organization_role_id, success_callback = (data: any) => {
    },                 error_callback = (data: any) => {
    }) {

        this.backend.post(`organization/${organization_id}/user/${organization_user_id}/role`, {role_id: new_organization_role_id}).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Cancello un ruolo per un determinato utente
     * @param organization_id
     * @param organization_user_id
     * @param role_id
     * @param success_callback
     * @param error_callback
     */
    public deleteUserRole(organization_id, organization_user_id, organization_role_id, success_callback = (data: any) => {
    },                    error_callback = (data: any) => {
    }) {

        this.backend.destroy(`organization/${organization_id}/user/${organization_user_id}/role/${organization_role_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Recupera il ruolo e i permessi di un membor
     * @param organization_id
     * @param organization_user_id
     * @param success_callback
     * @param error_callback
     */
    public getMemberRoleAndPermission(organization_id, organization_user_id, success_callback = (data: any) => {
    },                                error_callback = (data: any) => {
    }) {

        this.backend.get(`permissions/memebr-role-and-permissions/${organization_user_id}`).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }


    /**
     * Aggiorna in blocco i permessi di un membro
     * @param organization_id
     * @param organization_user_id
     * @param array_of_permissions
     * @param success_callback
     * @param error_callback
     */
    public updateMemberPermissions(organization_id, organization_user_id, array_of_permissions, new_role, success_callback = (data: any) => {
    },                             error_callback = (data: any) => {
    }) {

        this.backend.post(`permissions/add-permissions/${organization_user_id}`, {permissions: array_of_permissions, new_role}).subscribe(result => {
            success_callback(result);
        }, (error) => {
            this.backend.showErrors(error);
            error_callback(error);
        });
    }

}
