import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {ResponseService} from 'src/app/services/response.service';
import {UserService} from 'src/app/services/user.service';
import {HeaderTitleService} from "../../../services/general/header-title.service";

@Component({
  selector: 'app-responses-welcome',
  templateUrl: './responses-welcome.component.html',
  styleUrls: ['./responses-welcome.component.scss']
})
export class ResponsesWelcomeComponent implements OnInit {
  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  id: any;

  allInfo;

  nextPage: any[] = ['../explain'];

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table: any;
  organization: any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];
  problem_statement: any;

  evaluations: any = [];

  constructor(private headerTitleService: HeaderTitleService, private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router, private analysisService: AnalysisMethodsService,
              private unitService: UnitsService, private criteriaService: CriteriaService, private userService: UserService) {
  }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_evaluation_session;
      this.userService.lastVisitedEvaluationIdQuestionnaire = this.id;


      if (this.userService.currentOrganization.customs?.profile && this.userService.currentOrganization.customs.profile == 'enel') {
        this.nextPage = ['../responses-evaluation'];
      }

      if (this.id) {

        this.loading = true;
        this.responseService.getSummary(this.id, (result) => {

          this.allInfo = result;

          this.team_member = result.team_member;
          this.evaluation_session = result.evaluation_session;
          this.response_stamps = result.response_stamps;
          this.decision_table = result.decision_table;
          this.organization = result.decision_table.organization;
          this.primary_target = result.primary_target;
          this.secondary_target = result.secondary_target;
          this.framework_criteria = result.framework_criteria;
          this.proposals = result.proposals;
          this.evaluations = result.evaluations;
          this.problem_statement = result.problem_statement;
          this.headerTitleService.setTitle(this.decision_table.decision_name)

          this.loading = false;
          this.init();
        }, () => {
          this.loading = false;
          this.router.navigate(['/home']);
        })
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }


  public getAnalysisService() {
    return this.analysisService;
  }


  init() {
  }

  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

}
