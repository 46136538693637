<ng-container *ngIf="decision_table?.framework.analysis_type">
  <app-multiobjective-responses-evaluation *ngIf="getAnalysisService().isMultiobjectiveByType(decision_table?.framework.analysis_type)" [evaluationSessionId]="id" [allDecisionInfo]="allInfo" (responseEvaluationSavedEvent)="evaluationSaved()" (attachmentOnClickEvent)="attachmentOnClickEvent($event)"></app-multiobjective-responses-evaluation>
</ng-container>

<ng-container *ngIf="decision_table?.framework.analysis_type">
  <app-multicriteria-responses-evaluation *ngIf="getAnalysisService().isMulticriteriaByType(decision_table?.framework.analysis_type)" [evaluationSessionId]="id" [allDecisionInfo]="allInfo" (responseEvaluationSavedEvent)="evaluationSaved()" (attachmentOnClickEvent)="attachmentOnClickEvent($event)"></app-multicriteria-responses-evaluation>
</ng-container>

<ng-container *ngIf="decision_table?.framework.analysis_type">
  <app-consensus-responses-evaluation *ngIf="getAnalysisService().isConsensusByType(decision_table?.framework.analysis_type)" [evaluationSessionId]="id" [allDecisionInfo]="allInfo" (responseEvaluationSavedEvent)="evaluationSaved()" (attachmentOnClickEvent)="attachmentOnClickEvent($event)"></app-consensus-responses-evaluation>
</ng-container>
