<!-- header -->
<app-header></app-header>

<!-- full outlet -->
<section class="content">
  <app-modal-privacy-policy [documents]="this.userService?.userInfo?.documentsToAccept" *ngIf="this.userService?.userInfo?.documentsToAccept?.length > 0; else canAccess"></app-modal-privacy-policy>
  <ng-template #canAccess>
    <router-outlet></router-outlet>
  </ng-template>
</section>

<!-- footer -->
<app-footer></app-footer>

<!-- Custom Notification Controller -->
<toast-notification></toast-notification>
