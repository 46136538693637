import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../services/organization.service";
import {ConfigService} from "../../services/config.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, NgForm} from "@angular/forms";
import {UserTypeService} from "../../services/config/user-types.service";
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-reset-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'actions'];
  usersData: any = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<ModalChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService, private configService: ConfigService,
    private userTypeService: UserTypeService, private userService: UserService,
    private toast: ToastService,
    private translateService: TranslateService
  ) {
  }

  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  loading: boolean = false;

  organization_id: string;
  user: any

  ngOnInit(): void {
    this.data = JSON.parse(JSON.stringify(this.data))

    this.user = this.data?.user
    this.organization_id = this.data?.user?.organization_id;

  }

  changePassword() {
    if (this.newPassword === this.confirmPassword) {
      const data = {
        current_password: this.currentPassword,
        new_password: this.newPassword
      };
      this.loading = true;

      this.userService.changePassword(data, (result) => {
        this.loading = false;
        alert("cambiata");
      }, () => {
        this.loading = false;
        alert("errore");
      });
    }
  }

  onSubmit(form: NgForm) {

    // this.userService.resetPassword(this.user, (result) => {
    //   this.loading = false;
    //   this.toast.success(this.translateService.instant('generic.password-reset'), this.translateService.instant('modal-reset-password-ok'));
    //   this.closeDialogue({ event: 'resetted', data: this.user });
    // }, () => {
    //   this.loading = false;
    // });

    if (this.newPassword === this.confirmPassword) {
      const data = {
        current_password: this.currentPassword,
        new_password: this.newPassword,
        confirm_password: this.confirmPassword
      };
      this.loading = true;

      this.userService.changePassword(data, (result) => {
          this.loading = false;
          this.toast.success(this.translateService.instant('generic.change-password'), this.translateService.instant('modal.modal-change-password-ok'));
          this.closeDialogue({ event: 'resetted', data: this.user });
        }, () => {
          this.loading = false;
      });
    }
    else {
      this.toast.warn(this.translateService.instant('generic.change-password'), this.translateService.instant('modal.modal-change-password-unmatch'));
    }
  }

  closeDialogue(event: any = null) {

    this.dialogRef.close({event: event});
  }

}

