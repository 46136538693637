import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute
} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {AlertService} from '../services/general/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router, private alert: AlertService, private translateService: TranslateService, private currentRoute: ActivatedRoute) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions: string[] = this.getInheritedPermissions(route);
    if (permissions.length > 0) {
      if (this.checkPermissions(permissions)) {
        return true;
      } else {
        this.alert.showAlert(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('generic.unauthorized'));
        return false;
      }
    } else {
      return true;
    }
  }

  private checkPermissions(routePermissions) {
    const permissions = this.userService.getCurrentPermissions();
    return routePermissions.every((permission) => {
      return permissions.some((prm) => prm.name === permission);
    });
  }

  private getInheritedPermissions(route: ActivatedRouteSnapshot): string[] {
    let permissions: string[] = [];
    // Traverse the route tree upwards and merge permissions
    while (route != null) {
      let routePermissions = [];
      routePermissions = route.data.permissions as string[];
      // console.log(routePermissions);

      if (routePermissions) {
        permissions = this.mergePermissions(
          permissions,
          routePermissions
        );
      }
      if (route.parent) {
        route = route.parent;
      } else {
        break;
      }
    }

    return permissions;
  }

  mergePermissions(
    parentPermissions: string[],
    childPermissions: string[]
  ): string[] {
    return [...new Set([...parentPermissions, ...childPermissions])];
  }

}
