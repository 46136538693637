<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="row">
  <div class="col-12">
    <span>{{'components.attachment.file-attachment.notify-max-size' | translate}} {{humanizeFileSize | number: '1.0-2' }}Mb</span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" style="height: 200px; border: 2px dashed gray; padding: 50px; margin: 20px;">
      <p>
        {{'components.attachment.file-attachment.drag-files-here' | translate}}
      </p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <label>
      <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
    </label>
  </div>
</div>

<div class="row">
  <div class="upload-item col-12" *ngFor="let f of files; let i = index;">
    <div class="upload-item-content">
      <div class="filename">
        <div class="filename-left">
          <i class="ionicon ion-ios-copy"></i>
          <span>{{ f.name }}</span>
        </div>
        <div class="filename-right">
          <i class="ionicon ion-close-round" (click)="cancelUpload(f.id)"></i>
        </div>
      </div>
      <div class="progress-content">
        <div class="progress">
          <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'" [class.is-done]="f?.progress?.data?.percentage === 100"></span>
        </div>
      </div>
      <div class="progress-text-content">
        <span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100">
          <span>{{ f.progress?.data?.percentage }}% </span>
          <span *ngIf="f.progress?.data?.percentage !== 100">{{'generic.uploading' | translate}}</span>
          <span *ngIf="f.progress?.data?.percentage === 100">{{'generic.done' | translate}} </span>
        </span>
        <span class="speed-and-eta-text" *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
          <span>{{ f.progress?.data?.speedHuman }} </span>
          <span>ETA {{ f.progress?.data?.etaHuman }}</span>
        </span>
      </div>
    </div>
  </div>
</div>

<!-- <div class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-6 is-offset-3">
          <div class="upload-box">
            <div class="upload-box-header">
              <div class="control-circles">
                <span class="circle is-green"></span>
                <span class="circle is-yellow"></span>
                <span class="circle is-red"></span>
              </div>
            </div>
            <div class="upload-box-content">
              <div class="drop-container" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
                <p>
                  Drag files here or
                  <label class="upload-button">
                    <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple> browse
                  </label>
                  to upload.
                </p>
              </div>
              <div class="upload-item" *ngFor="let f of files; let i = index;">
                <div class="upload-item-content">
                  <div class="filename">
                    <div class="filename-left">
                      <i class="ionicon ion-ios-copy"></i>
                      <span>{{ f.name }}</span>
                    </div>
                    <div class="filename-right">
                      <i class="ionicon ion-close-round" (click)="cancelUpload(f.id)"></i>
                    </div>
                  </div>
                  <div class="progress-content">
                    <div class="progress">
                      <span class="bar" [style.width]="f?.progress?.data?.percentage + '%'" [class.is-done]="f?.progress?.data?.percentage === 100"></span>
                    </div>
                  </div>
                  <div class="progress-text-content">
                    <span class="progress-text" [class.is-done]="f?.progress?.data?.percentage === 100">
                      <span>{{ f.progress?.data?.percentage }}% </span>
                      <span *ngIf="f.progress?.data?.percentage !== 100">Uploading...</span>
                      <span *ngIf="f.progress?.data?.percentage === 100">Done</span>
                    </span>
                    <span class="speed-and-eta-text" *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
                      <span>{{ f.progress?.data?.speedHuman }} </span>
                      <span>ETA {{ f.progress?.data?.etaHuman }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
