import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {DecisionService} from 'src/app/services/decision.service';
import {FrameworkService} from 'src/app/services/framework.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {UserService} from 'src/app/services/user.service';
import {AlertService} from '../../../services/general/alert.service';
import swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-proposal-blank',
  templateUrl: './new-proposal-blank.component.html',
  styleUrls: ['./new-proposal-blank.component.scss'],
})
export class NewProposalBlankComponent implements OnInit {
  @Input() framework: any;
  @Input() decision_table: any;
  @Input() contest: any = null;
  @Output() proposalSavedEvent = new EventEmitter();

  frameworkSelected: any;
  decisionTableTargets: any;

  loading = false;

  model: any = {
    primary_target: [],
    secondary_targets: [],
    proposals: [],
  };

  userorganization: any = [];
  currentOrganization: any = {};

  constructor(
    private frameworkService: FrameworkService,
    private unitsService: UnitsService,
    private criteriaService: CriteriaService,
    private userService: UserService,
    private toast: ToastService,
    private decisionService: DecisionService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private router: Router,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    if (this.framework) {
      this.init();
    }
  }

  init() {
    if (!this.decision_table || !this.framework) {
      this.toast.error(
        this.translateService.instant('modals.modal-new-proposal.new-proposal'),
        this.translateService.instant(
          'components.proposals.new-proposal.error-new-proposal'
        )
      );
      return;
    }

    this.loading = true;
    this.decisionService.getTableTargets(
      this.decision_table.id,
      (result) => {
        this.decisionTableTargets = result;
        this.prepareTargets();
        this.loading = false;
      },
      () => (this.loading = false)
    );

    this.loading = true;
    this.frameworkService.get(
      this.framework.id,
      (response) => {
        this.frameworkSelected = JSON.parse(JSON.stringify(response));

        this.loading = false;
        this.prepareProposal();
        this.prepareAttachment();

        this.userorganization = this.userService.getAllOrganizations();
        this.currentOrganization = this.userorganization.find(
          (x) => x.id == this.frameworkSelected.organization_id
        );
      },
      () => (this.loading = false)
    );
  }

  /**
   * Prepara gli obiettivi sulla base del framework selezionato
   */
  prepareTargets() {
    this.model.primary_target = {};
    this.model.secondary_targets = [];

    //  Primary Target
    this.model.primary_target = this.decisionTableTargets.find(
      (x) => x.type == 'primary'
    );
    //  Secondary Target
    this.model.secondary_targets = this.decisionTableTargets.filter(
      (x) => x.type == 'secondary'
    );
  }

  /**
   * Setta una proposta sulla base del framework selezionato
   */
  prepareProposal() {
    this.model.proposals = [];
    this.addNewProposal();
  }

  /**
   * Setto gli allegati nei criteri
   */
  prepareAttachment() {
    this.model.proposals.forEach((proposal) => {
      proposal.criteria.forEach((criterio) => {
        criterio.attachment = [];
        if (criterio.attachment_pubmed) {
          criterio.attachment_pubmed.forEach((pubmed) => {
            criterio.attachment.push(pubmed);
          });
        }
        if (criterio.attachment_files) {
          criterio.attachment_files.forEach((file) => {
            criterio.attachment.push(file);
          });
        }
      });
    });
  }

  /**
   * Aggiunge una proposal vuota
   */
  addNewProposal() {
    const newProposal = {title: null, criteria: []};

    for (const criterio of this.frameworkSelected.criteria) {
      const compiledCriterio = {
        title: criterio.title,
        typology: criterio.typology,
        measure_unit: criterio.measure_unit,
        options: criterio.options,
        value: null,
        framework_criteria_id: criterio.id,
        additional: criterio.additional,
      };
      newProposal.criteria.push(compiledCriterio);
    }

    this.model.proposals.push(newProposal);
  }

  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit(form: NgForm) {
    //  validazione dei dati
    if (!this.proposalsValid) {
      this.toast.error(
        this.translateService.instant('generic.warning'),
        this.translateService.instant(
          'components.proposals.new-proposal.error-form-validation'
        )
      );
      return;
    }

    if (!form.form.valid) {
      return;
    }

    if (this.contest) {
      this.model.isProposalContest = true;
      this.model.contest_id = this.contest.id;
    }

    this.model.fromQuestionnaire = this.decision_table.fromQuestionnaire;

    this.loading = true;
    this.decisionService.addNewProposal(
      this.decision_table.id,
      this.model,
      (result) => {
        this.loading = false;
        this.proposalSavedEvent.emit(result);
        if (this.contest) {
          swal.fire({
            title: '',
            text: this.translateService.instant(
              'components.responses.suggest.success-new-proposal'
            ),
            confirmButtonText: this.translateService.instant('generic.go-to-home'),
            icon: 'success'
          }).then(() => {
            this.router.navigate(['/']);
          });
        } else {
          this.toast.success(
            this.translateService.instant('generic.success'),
            this.translateService.instant(
              'components.responses.suggest.success-new-proposal'
            )
          );
        }
      },
      (error) => (this.loading = false)
    );
  }

  GoBack() {
    this.proposalSavedEvent.emit();
  }

  /**
   * Aggiunta di un nuovo allegato
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(criterio: any, attachment_type: string) {
    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        criterio,
        uploadUrl: 'upload/tmp-proposal-file',
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {
      if (attachmentData) {
        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }

  /**
   * Rimozione di un allegato
   * @param attachIndex
   * @param criterio
   */
  onClickRemoveEvidence(attachIndex, criterio) {
    const attachment = criterio.attachment[attachIndex];

    switch (attachment.type) {
      case 'PUBMED':
        const pubmedIndex = criterio.attachment_pubmed.findIndex(
          (x) => x.PMID == attachment.PMID
        );
        if (pubmedIndex >= 0) {
          criterio.attachment_pubmed.splice(pubmedIndex, 1);
        }
        break;
      case 'FILE':
        const fileIndex = criterio.attachment_files.findIndex(
          (x) => x.id == attachment.id
        );
        if (fileIndex >= 0) {
          criterio.attachment_files.splice(fileIndex, 1);
        }
        break;
    }

    this.prepareAttachment();
  }

  /** Valida le proposte create */
  get proposalsValid() {

    for (const proposal of this.model.proposals) {
      if (!proposal.title) {
        return false;
      }

      for (const proposalCriteria of proposal.criteria) {
        //  se non è un criterio a scelta multipla valido il valore assegnato al criterio
        if (
          !this.criteriaService.criteriaIsMultipleChoice(
            proposalCriteria.typology
          )
        ) {
          if (!proposalCriteria.value) {
            return false;
          }
        }
        //  se invece è un criterio a scelta multipla devo validare se ci sono tutte le opzioni comprese di peso
        else {
          if (proposalCriteria.options) {
            for (const option of proposalCriteria.options) {
              if (!option.title || (typeof option.weight == undefined || option.weight == null)) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
      }
    }

    return true;
  }

  /**
   * Setto un allegato pubmed
   * @param attachmentPubmed
   */
  addPubmedAttachment(attachmentPubmed) {
    for (const proposal of this.model.proposals) {
      for (const proposalCriteria of proposal.criteria) {
        if (
          proposalCriteria.framework_criteria_id ==
          attachmentPubmed.criterio.framework_criteria_id
        ) {
          if (!proposalCriteria.attachment_pubmed) {
            proposalCriteria.attachment_pubmed = [];
          }

          proposalCriteria.attachment_pubmed.push({
            type: 'PUBMED',
            PMID: attachmentPubmed.attachmentObject.PMID,
            title: attachmentPubmed.attachmentObject.ArticleTitle,
            link: attachmentPubmed.attachmentObject.link,
          });

          break;
        }
      }
    }

    this.prepareAttachment();
  }

  removePubmedAttachment() {
  }

  /**
   * Serto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {
    for (const fileAttached of attachmentFile.attachmentObject) {
      for (const proposal of this.model.proposals) {
        for (const proposalCriteria of proposal.criteria) {
          if (
            proposalCriteria.framework_criteria_id ==
            attachmentFile.criterio.framework_criteria_id
          ) {
            if (!proposalCriteria.attachment_files) {
              proposalCriteria.attachment_files = [];
            }

            proposalCriteria.attachment_files.push({
              id: fileAttached.id,
              type: 'FILE',
              ext: fileAttached.ext,
              size: fileAttached.size,
              title: fileAttached.title,
              path: fileAttached.path,
              link: fileAttached.link,
            });

            break;
          }
        }
      }
    }

    this.prepareAttachment();
  }

  //  restituisce la stringa corretta in base al tipo di criterio
  getPlaceholderString(criteria_typology) {
    if (this.criteriaService.criteriaIsQuantitative(criteria_typology)) {
      return this.translateService.instant(
        'components.proposals.new-proposal.placeholders.insert-numeric-value'
      );
    }

    return this.translateService.instant(
      'components.proposals.new-proposal.placeholders.insert-description'
    );
  }

  public getUnitsService() {
    return this.unitsService;
  }

  public getCriteriaService() {
    return this.criteriaService;
  }
}
