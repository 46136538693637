import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private translateService: TranslateService) { }

  // Documentazione plugin: https://sweetalert2.github.io/

  public showQuestion(title: string, text: string, ok_callback = (ok: any) => { }, cancel_callback = (cancel: any) => { } ) {
    this.showQuestionOnScreen('warning', title, text, ok_callback, cancel_callback);
  };

  public showQuestionWithSuccess(title: string, text: string, ok_callback = (ok: any) => { }, cancel_callback = (cancel: any) => { } ) {
    this.showQuestionOnScreen('success', title, text, ok_callback, cancel_callback);
  }

  public showQuestionWithError(title: string, text: string, ok_callback = (ok: any) => { }, cancel_callback = (cancel: any) => { } ) {
    this.showQuestionOnScreen('error', title, text, ok_callback, cancel_callback);
  }

  //  Mostra un alert con un messaggio e la possibilità di scegliere tra si o no
  public showQuestionOnScreen(type: SweetAlertIcon, title: string, text: string, ok_callback = (ok: any) => { }, cancel_callback = (cancel: any) => { } ) {

    swal.fire({
      title: title,
      text: text,
      icon: type,
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('generic.answers.yes'),
      cancelButtonText: this.translateService.instant('generic.answers.no'),
    }).then((result) => {
      if (result.isConfirmed)
        ok_callback(result);
      else
        cancel_callback(result);
    })
  }


  showAlertWithError(title: string, text: string) {
    this.showAlert(title, text, 'error');
  }


  //  Mostra un alert con un messaggio e un bottone di OK
  public showAlert(title: string, text: string, type: SweetAlertIcon = 'warning') {

    swal.fire({
      title: title,
      text: text,
      icon: type
    });
  }
}
