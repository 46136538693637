<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="createMode">{{ 'modals.modal-survey-details.add-survey' | translate}}</span>
    <span *ngIf="!createMode">{{ 'modals.modal-survey-details.edit-survey' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<app-survey-details
  [projectId]="data?.projectId"
  [surveyId]="data?.surveyId"
  (surveyAdded)="closeDialogue($event)"
  (canceled)="closeDialogue()"
/>
