<ng-container>

  <ng-container *ngIf="context == 'survey-team'; else elseBlock">
    <form #surveyTeamForm="ngForm" (keydown.enter)="$event.preventDefault()" novalidate (ngSubmit)="addSurveyTeamMember(surveyTeamForm)">
      <mat-form-field class="input-primary col-5">
        <input
          matInput
          name="survey_team_member_name"
          [(ngModel)]="teamMember.name"
          placeholder="{{ 'generic.name-surname' | translate }}"
          required
        />
      </mat-form-field>
      <mat-form-field class="input-primary col-5"
                      (click)="onClickSearchUser(teamMember)">
        <input type="email"
               matInput
               name="survey_team_member_email"
               [value]="teamMember.email"
               autocomplete="false"
               (input)="onInputSearchUserChange($event.target.value, teamMember)"
               placeholder="{{ 'generic.email' | translate }}"
               [matAutocomplete]="auto"
               required
        >
        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="displayFn"
                          (optionSelected)="setUserInfoByEmail($event, teamMember)"
        >
          <mat-option *ngFor="let option of suggestedTeamMemberUsers" [value]="option">
            {{option.email}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <button type="submit" *ngIf="addButton" mat-flat-button color="primary" class="col-2 align-top">
        {{'generic.buttons.add' | translate}}
      </button>
    </form>
  </ng-container>
  <ng-template #elseBlock>
    <mat-form-field class="input-primary {{ process_role_field ? 'col-4' : 'col-4'}}">
      <input
        matInput
        name="team_member_name"
        [(ngModel)]="team_member.name"
        placeholder="{{ 'generic.name-surname' | translate }}"
        required
      />
    </mat-form-field>
    <mat-form-field class="input-primary {{ process_role_field ? 'col-4' : 'col-8'}}"
                    (click)="onClickSearchUser(team_member)">
      <input type="email"
             matInput
             name="team_member_email"
             [value]="team_member.email"
             autocomplete="false"
             (input)="onInputSearchUserChange($event.target.value, team_member)"
             placeholder="{{ 'generic.email' | translate }}"
             [matAutocomplete]="auto"
             required
      >
      <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="setUserInfoByEmail($event, team_member)"
      >
        <mat-option *ngFor="let option of suggestedTeamMemberUsers" [value]="option">
          {{option.email}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      *ngIf="false"
      class="select-primary col-6 col-md-3"
      appearance="fill"
    >
      <mat-label>{{
          "generic.roles.professional-role" | translate
        }}</mat-label>
      <mat-select
        name="team_member_professional_role"
        [(ngModel)]="
                            team_member.organization_professional_role_id
                          "
      >
        <mat-option value="" selected></mat-option>
        <mat-option
          *ngFor="let professionalRole of professionalRoles"
          [value]="professionalRole.id"
        >{{ professionalRole.role }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="process_role_field"
      class="select-primary col-4"
      appearance="fill"
    >
      <mat-label>{{
          "generic.roles.process-role" | translate
        }}</mat-label>
      <mat-select
        name="team_member_process_role"
        [(ngModel)]="team_member.process_role"
        required
      >
        <mat-option value="" selected></mat-option>
        <mat-option
          *ngFor="let processRole of process_roles"
          [value]="processRole.key"
        >{{
            processRolesService.getProcessRolesValueByKey(
              processRole.key
            )
          }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="false"
      class="select-primary col-3 col-md-2"
      appearance="fill"
    >
      <mat-label>{{
          "generic.weight" | translate
        }}</mat-label>
      <mat-select
        name="team_member_weight{{ i }}"
        [(ngModel)]="team_member.weight"
      >
        <mat-option value=""></mat-option>
        <mat-option [value]="100">100</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
  <!-- <div class="mt-4">
                    <div class="toggle-container col-4 d-inline-block" appearance="fill">
                        <mat-label>{{'components.decision-table.show-decision-table.ask-authorization-full-evaluation' | translate}}</mat-label>
                        <div>
                            <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                            <mat-slide-toggle class="toggle" name="team_member_complete_process_enabled{{i}}" [(ngModel)]="team_member.complete_process_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="toggle-container col-4 d-inline-block" appearance="fill">
                        <mat-label>{{'components.decision-table.show-decision-table.ask-authorization-review' | translate}}</mat-label>
                        <div>
                            <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                            <mat-slide-toggle class="toggle" name="team_member_review_enabled{{i}}" [(ngModel)]="team_member.review_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                    <div class="toggle-container col-4 d-inline-block" appearance="fill">
                        <mat-label>{{'components.decision-table.show-decision-table.ask-authorization-new-proposal' | translate}}</mat-label>
                        <div>
                            <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                            <mat-slide-toggle class="toggle" name="team_member_provide_new_proposal_enabled{{i}}" [(ngModel)]="team_member.provide_new_proposal_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                        </div>
                    </div>
                </div> -->
  <i
    *ngIf="removable"
    class="icon-cestino abs-tr main-color p-2"
    (click)="removeTeamMember()"
  ></i>
</ng-container>

<span class="text-center mt-4 col-12 main-color" *ngIf="team_member?.imported_by_organization_chart_id">
  {{ 'generic.imported-by' | translate }} "{{ getOrganizationChart(team_member?.imported_by_organization_chart_id)?.title }}"
</span>

