import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {OrganizationService} from 'src/app/services/organization.service';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {DecisionService} from 'src/app/services/decision.service';
import {HelperService} from 'src/app/services/general/helper.service';
import {SurveyService} from "../../services/surveys.service";


@Component({
  selector: 'app-modal-email-responses',
  templateUrl: './modal-email-responses.component.html',
  styleUrls: ['./modal-email-responses.component.scss']
})
export class ModalEmailResponsesComponent implements OnInit {

  loading = false;

  origin = null;
  origin_id;
  team_member_id;

  displayedColumns: string[] = ['email', 'date', 'event'];
  emailResponseDatatable;

  constructor(
    public dialogRef: MatDialogRef<ModalEmailResponsesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private decisionService: DecisionService,
    private surveyService: SurveyService
  ) {
  }

  ngOnInit(): void {
    this.origin = this.data.origin || 'DECISION_TABLE';
    this.team_member_id = this.data.team_member_id;
    this.origin_id = this.data.origin_id;

    if (this.origin && this.origin_id && this.team_member_id) {
      this.init();
    }
  }

  init() {
    this.loading = true;

    switch (this.origin) {
      case 'DECISION_TABLE':
        this.decisionService.getEmailResponsesForTeamMember(this.origin_id, this.team_member_id, (result) => {
          this.emailResponseDatatable = new MatTableDataSource(result);

          this.loading = false;
        }, () => this.loading = false);
        break;
      case 'SURVEY':
        this.surveyService.getEmailResponsesForSurveyTeamMember(this.origin_id, this.team_member_id, (result) => {
          this.emailResponseDatatable = new MatTableDataSource(result);

          this.loading = false;
        }, () => this.loading = false);
        break;
    }
  }

  //  resituisce le informazioni di colore per mostrare lo stato di un invio email
  emailColorByEvent(row) {

    return HelperService.getColorByEmailEvent(row.event);
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close({event});
  }

}
