import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../services/general/toast.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loading: boolean = false;

  model : any = {
    //? Campi di input
    name : null,
    surname : null,
    email : null,
    password : null,
    confirm_password : null
  }

  constructor(private translateService : TranslateService,private toast: ToastService) { }


  ngOnInit(): void {

  }

  onSubmit(form: NgForm) {
    if(this.model.name == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.register.component.error-name'));
      return;
    }

    if(this.model.surname == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.register.component.error-surname'));
      return;
    }

    if(this.model.email == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.register.component.error-email'));
      return;
    }

    if(this.model.password == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.register.component.error-password'));
      return;
    }
    if(this.model.confirm_password == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.register.component.error-password'));
      return;
    }
    if (!form.form.valid)
    return;


    // this.saveNewUser();
  }

}
