import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-decision-management-plan',
  templateUrl: './decision-management-plan.component.html',
  styleUrls: ['./decision-management-plan.component.scss']
})
export class DecisionManagementPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
