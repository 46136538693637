import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseTypesService {

  constructor(private translateService: TranslateService) { }

  private _consensusResponse: any = [
    {
      type: "AGREE",
      value: this.translateService.instant('components.responses.consensus.agree')
    },
    {
      type: "DISAGREE",
      value: this.translateService.instant('components.responses.consensus.disagree')
    },
    {
      type: "ABSTAINED",
      value: this.translateService.instant('components.responses.consensus.abstained')
    }
  ]


  getValueByType(container, type, returnedValue = 'value') {

    let found = "";

    if (type != null && typeof type != "undefined") {
      container.forEach(e => {
        if (e.type == type)
          return found = e[returnedValue];
      });
    }

    return found;
  }

  getConsensusValueByType (type: string) {
    if (type == null || typeof type == "undefined")
      return '';

    return this.getValueByType(this._consensusResponse, type);
  }

  getConsensusAgreeType () {
    return "AGREE";
  }
  getConsensusDisagreeType() {
    return "DISAGREE";
  }
  getConsensusAbstainedType() {
    return "ABSTAINED";
  }



  isConsensusAgree(value: string) {
    if (value && value == "AGREE")
      return true;

    return false;
  }
  isConsensusDisagree(value: string) {
    if (value && value == "DISAGREE")
      return true;

    return false;
  }
  isConsensusAbstained(value: string) {
    if (value && value == "ABSTAINED")
      return true;

    return false;
  }

}
