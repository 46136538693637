import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderTitleService {

  title = new BehaviorSubject('');

  constructor(private titleService: Title, private translateService: TranslateService) { }

  public setTitle(title: string) {
    this.titleService.setTitle(this.translateService.instant(title));
    this.title.next(title);
  }

  public getTitle(): string {
    return this.titleService.getTitle();
  }

  public setSubtitle(subtitle:string) {
    this.setTitle(this.translateService.instant(this.title.value) + ": " + subtitle);
  }


}
