import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value && typeof value == 'number') {
      const minutes: number = Math.floor(value / 60);
      return minutes + ':' + (value - minutes * 60) + ' m';
    }

    return value;
  }

}
