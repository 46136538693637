import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {DecisionService} from '../../../services/decision.service';
import {HeaderTitleService} from "../../../services/general/header-title.service";

@Component({
  selector: 'app-new-contest-proposal-component',
  templateUrl: './new-contest-proposal-component.component.html',
  styleUrls: ['./new-contest-proposal-component.component.scss']
})
export class NewContestProposalComponentComponent implements OnInit {
  token: string = null;
  contest = {
    title: null,
    closing_date: null,
    decision_table: null,
  };
  loading = false;

  constructor(private route: ActivatedRoute, private decisionService: DecisionService, private titleService: HeaderTitleService) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.token = params.get('token');
        this.init();
        return this.token;
      })
    ).subscribe();
  }

  init() {
    this.loading = true;
    this.decisionService.getContest(this.token, (result) => {
      this.contest = result;
      this.titleService.setTitle(result.title);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
