<!-- <div class="row" >
  <mat-progress-bar mode="determinate" color="primary" [value]="loadingValue" ></mat-progress-bar>
</div> -->

<div class="container-fluid container-custom d-flex align-items-center">
  <div class="container">
    <div class="row text-center">
      <div class="col-12 my-4">
        <img src="assets/img/butterfly-logo.png" class="img-fluid mx-auto mb-3" alt="">
      </div>
      <div class="col-12 text-white">
        <p class="p-custom">Take better, faster, safer decisions</p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div *ngIf="loginError" class="col-12 mt-5 text-center">
        <button class="btn-custom rounded px-5 py-2" (click)="login()">{{'generic.buttons.login' | translate}}</button>
      </div>
      <div  class="col-12 mt-5 text-center">
        <!-- <button class="btn-custom rounded px-5 py-2" (click)="register()">{{'generic.buttons.register' | translate}}</button> -->
      </div>
    </div>
  </div>
</div>
