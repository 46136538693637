<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">
        {{'modal.modal-change-password' | translate}}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p role="button" (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <!-- <form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="container">
      <div class="row">
        <div class="col-12 my-4">
            <mat-label class="mat-h2" >{{ 'generic.change-password' | translate}}: {{ user.user.email }}</mat-label>
        </div>
      </div>
    </div>
    <div class="buttons-container container py-5 mb-3">
      <button class="button-primary float-right" [disabled]="loading" mat-button (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        <span>{{'generic.buttons.reset-and-send' | translate}}</span>
      </button>
    </div>
  </form> -->
  <form #form="ngForm" (keydown.enter)="$event.preventDefault()">

    <mat-card class="box mt-3 py-4">
      <mat-form-field class="col-12">
      <mat-label>{{'modal.modal-current-password' | translate}}</mat-label>
      <input matInput type="password" [(ngModel)]="currentPassword" name="currentPassword" required>
    </mat-form-field>
    </mat-card>

    <mat-card class="box mt-3 py-4">
      <mat-form-field class="col-12">
      <mat-label>{{'modal.modal-new-password' | translate}}</mat-label>
      <input matInput type="password" [(ngModel)]="newPassword" name="newPassword" required>
    </mat-form-field>
    </mat-card>

    <mat-card class="box mt-3 py-4">
      <mat-form-field class="col-12">
      <mat-label>{{'modal.modal-confirm-password' | translate}}</mat-label>
      <input matInput type="password" [(ngModel)]="confirmPassword" name="confirmPassword" required>
    </mat-form-field>
    </mat-card>

    <button mat-raised-button color="primary" (click)="onSubmit(form)">{{'modal.button-change-password' | translate}}</button>

  </form>
</div>
