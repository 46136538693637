import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-decision-table-details',
  templateUrl: './decision-table-details.component.html',
  styleUrls: ['./decision-table-details.component.scss']
})
export class DecisionTableDetailsComponent {
  @Input() decision_table: any;
  @Input() evaluation_session: any;
  @Input() show_indexes = true;

  attachmentsDisplayedColumns = ['name', 'type', 'loaded_at'];

  getAttachmentsByType(type) {
    return this.decision_table?.problem_statement?.attachments.filter((attach) => {
      const isImage = attach.type === "FILE" && ['jpg', 'png', 'jpeg'].includes(attach.ext.toLowerCase());
      if (type === "IMAGE") {
        return isImage;
      }
      return !isImage;
    });
  }

  getAttachmentsEvidences() {
    return this.getAttachmentsByType("FILE/PUBMED");
  }

  getAttachmentImages() {
    return this.getAttachmentsByType("IMAGE");
  }
}
