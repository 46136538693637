import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private translateService: TranslateService) { }


  //  restituisce un array associativo raggruppato per chiave
  static groupBy(arrayData, key)
  {
    if (arrayData != null && typeof arrayData != undefined && key != null)
      return _.groupBy(arrayData, key);

    return [];
  }

  //  restituisce un colore basato su un evento delle email
  static getColorByEmailEvent(event) {

    switch (event) {
      case 'processed': //   la mail è stata processata e sarà inviata a breve
        return '#bca900'
      case 'dropped':
        return '#ff0000' //  la mail è stata droppata per diversi motivi e non sarà consegnata
      case 'deferred':
        return '#bca900' //  la mail non è stata consegnata ma verranno effettuati altri tentativi di consegna
      case 'bounce':
        return '#ff0000' //  la mail è sarà consegnata perchè Sendgrid ha trovato incongruenze nella mail o nel suo destinatario
      case 'delivered':
        return '#00bc1d' //  la mail è stata consegnata
      case 'open':
        return '#00bc1d' //  la mail è stata aperta o dall'utente o dal client di posta
      case 'click':
        return '#00bc1d' //  l'utente ha cliccato su un link presente nella mail
      case 'spamreport':
        return '#ff0000' //  l'utente ha segnalato la mail come spam
      case 'unsubscribe':
        return '#ff0000' //  l'utente si è disiscritto dalla newsletter (evento non gestito)
      default:
        break;
    }
  }
}
