<div class="container-fluid">
  <div class="container mt-5" style="max-width: 400px;">
    <form class="p-4 rounded mat-elevation-z3">
      <h2 class="mb-4">Cambio Password</h2>

      <mat-form-field appearance="outline" class="mb-4">
        <mat-label>Password attuale</mat-label>
        <input matInput type="password" [(ngModel)]="currentPassword" name="currentPassword" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mb-4">
        <mat-label>Nuova password</mat-label>
        <input matInput type="password" [(ngModel)]="newPassword" name="newPassword" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mb-4">
        <mat-label>Conferma nuova password</mat-label>
        <input matInput type="password" [(ngModel)]="confirmPassword" name="confirmPassword" required>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="changePassword()">Cambia password</button>
    </form>
  </div>
  <div class="container my-5">
    <button class="button-primary" mat-button [routerLink]="['../']"
            routerLinkActive="router-link-active">
      <i class="icon-Backward-arrow-small"></i>
      {{'routing.title.user-show' | translate}}
    </button>
  </div>
</div>
