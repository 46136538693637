import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;

  model: any = {
    email: null,
    password: null
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    // if(this.model.email == null ) {
    //   this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.login.component.error-email'));
    //   return;
    // }

    // if(this.model.password == null ) {
    //   this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.login.component.error-password'));
    //   return;
    // }

    // if (!form.form.valid)
    // return;


    // this.saveNewUser();
  }

}
