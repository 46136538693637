<div class="container">
  <mat-card class="login-card">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width">
          <mat-label>Titolo</mat-label>
          <input matInput [(ngModel)]="dataModel.title" name="title" required>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Descrizione</mat-label>
          <textarea matInput [(ngModel)]="dataModel.description" name="description" required></textarea>
        </mat-form-field>
        <div class="row justify-content-center my-3">
          <a mat-button color="warn" (click)="closeDialogue()" class="mr-2">{{"generic.buttons.cancel-and-back" | translate}}</a>
          <button mat-raised-button color="primary" type="submit">{{"generic.send" | translate}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
