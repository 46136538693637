<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">
        <span *ngIf="!model.id">{{'generic.new' | translate}}</span>
        <span *ngIf="model.id">{{'generic.edit' | translate}}</span>
        {{'modal.modal-organization-chart' | translate}}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p role="button" (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="container">
      <div class="row">
        <div class="col-12 my-4">
          <mat-form-field class="input-big full-width p-0">
            <input matInput name="template_name" [(ngModel)]="model.title"
                   placeholder="{{'generic.title' | translate}}" required autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-12 my-4">
          <mat-form-field class="full-width p-0">
              <mat-label>{{ 'generic.description' | translate}}</mat-label>
              <textarea matInput name="description" class="description" autocomplete="off" rows="3" [(ngModel)]="model.description"></textarea>
          </mat-form-field>
        </div>
        <div class="col-12 my-4">
            <mat-table [dataSource]="usersData" class="table-primary">

              <!-- FirstName Column -->
              <ng-container matColumnDef="first_name">
                <mat-header-cell *matHeaderCellDef>{{'generic.name' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <span *ngIf="element?.user?.details?.first_name">{{element?.user?.details?.first_name}}</span>
                  <span *ngIf="!element?.user?.details?.first_name">{{element?.user?.name}}</span>
                </mat-cell>
              </ng-container>

              <!-- LastName Column -->
              <ng-container matColumnDef="last_name">
                <mat-header-cell *matHeaderCellDef>{{'generic.surname' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" [ngClass]="element.deleted_at ? 'element-deleted' : ''"> {{element?.user?.details?.last_name}} </mat-cell>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef>{{'generic.email' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" class="left" [ngClass]="element.deleted_at ? 'element-deleted' : ''"> {{element?.user?.email}} </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex my-2" [ngClass]="element.deleted_at ? 'element-deleted' : ''">
                  <mat-checkbox [checked]="element.selected" name="selectUser" class="select-checkbox" (change)="checkElement(element)"></mat-checkbox>
                </mat-cell>
              </ng-container>


              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
      </div>
    </div>
    <div class="buttons-container container py-5 mb-3">
      <button class="button-primary float-right" [disabled]="loading || selectedUsers.length === 0 || this.model?.title?.length === null" mat-button (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        <span *ngIf="!model.id">{{'generic.buttons.save' | translate}}</span>
        <span *ngIf="model.id">{{'generic.buttons.modify' | translate}}</span>
      </button>
    </div>
  </form>
</div>
