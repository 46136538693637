import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalNewProposalComponent} from '../../../modals/modal-new-proposal/modal-new-proposal.component';
import {MatDialog} from '@angular/material/dialog';
import {DecisionService} from '../../../services/decision.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ProcessRolesService} from '../../../services/config/process-roles.service';
import {ToastService} from '../../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-show-contest-component',
  templateUrl: './show-contest-component.component.html',
  styleUrls: ['./show-contest-component.component.scss']
})
export class ShowContestComponentComponent implements OnInit {
  @Input() contest: any;
  @Input() decision_table: any;
  @ViewChild("proposalsPaginator") proposalsPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  proposalDisplayedColumns: string[] = ['title', 'proposed_by', 'proposed_at', 'status', 'actions'];
  proposals = [];
  proposalsDataSource: MatTableDataSource<unknown>;

  constructor(
    public dialog: MatDialog,
    public decisionService: DecisionService,
    private processRolesService: ProcessRolesService,
    private toast: ToastService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getContestProposals();
  }

  public initProposalsDataSource() {
    this.proposalsDataSource = new MatTableDataSource(this.proposals.reverse());
    this.proposalsDataSource.paginator = this.proposalsPaginator;
    this.proposalsDataSource.sort = this.sort;
  }

  showProposal(proposal: any) {
    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        newProposal: false,
        decision_table: this.decision_table,
        proposal,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  acceptProposal(proposal) {
    this.decisionService.acceptContestProposal(proposal.id, () => {
      proposal.is_contest_proposal_accepted = true;
      this.toast.success(
        this.translateService.instant('generic.proposal'),
        this.translateService.instant('components.contests.show-contest.component.proposal-is-accepted')
      );
    });
  }

  getContestProposals() {
    this.decisionService.getContestProposals(this.contest.id, (result) => {
      this.proposals = result;
      this.initProposalsDataSource();
    });
  }

}


// TODO Contests: tema success chiedere a michele
