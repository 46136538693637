<div class="container-fluid container-custom">
  <div class="container main-buttons">

    <div class="row">
      <div class="col-6 col-lg-3" *ngIf="getUserService()?.currentOrganization">
        <div class="box-custom " [routerLink]="['/organization/'+getUserService()?.currentOrganization.id]">
          <i class="icon-organizations text-white"></i>
        </div>
        <p class="text-white mt-2 text-center">{{'pages.home.component.manage-oganizations' | translate}}</p>
      </div>

      <div class="col-6 col-lg-3">
        <div class="box-custom" [routerLink]="['/framework/all']">
          <i class="icon-decisional-schemes text-white"></i>
        </div>
        <p class="text-white mt-2 text-center">{{'pages.home.component.surf-decision-schemas' | translate}}</p>
      </div>

      <div class="col-6 col-lg-3">
        <div class="box-custom" [routerLink]="[getDecisionRoute()]">
          <i class="icon-decisional-table text-white"></i>
        </div>
        <p class="text-white mt-2 text-center">{{'pages.home.component.check-decision-table' | translate}}</p>
      </div>

      <div class="col-6 col-lg-3">
        <div class="box-custom">
          <i class="icon-academy text-white"></i>
        </div>
        <p class="text-white mt-2 text-center">{{'pages.home.component.education' | translate}}</p>
      </div>
    </div>
  </div>
</div>
