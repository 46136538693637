<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<app-generic-loading [loading]="isLoadingEmitter"></app-generic-loading>

<!-- Gestione della timeline -->
<div class="container-fluid accordion-container">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">1</span>
                      {{'components.decision-table.decision-table-dashboard.project-timeline' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                  <div class="stepper-container col-12">
                      <p class="headline float-left">
                          {{'components.decision-table.decision-table-dashboard.evaluation-session' | translate}}
                      </p>
                      <p class="headline float-right">
                          {{'generic.report' | translate}}
                      </p>
                      <div class="timeline">
                          <div style="cursor: pointer;" (click)="onClickEvaluationSession(i)" class="step-container" [ngClass]="session.collecting == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_1; let i = index">
                            <ng-container>
                              <div class="step" [ngClass]="{'selected-step': session.id === currentEvaluationSessionDashboard.id && !session?.collecting}">{{i + 1}}</div>
                              <div class="step-label" *ngIf="session.evaluation_start">{{session.evaluation_start | formatdate | date: 'short'}} <br>
                                <span *ngIf="!session.collecting">{{session.evaluation_end | formatdate | date:'short'}}</span>
                              </div>
                              <div class="step-label" *ngIf="!session.evaluation_start">{{'components.decision-table.decision-table-dashboard.not-started-yet' | translate}}</div>
                            </ng-container>
                          </div>
                          <div class="step-container icon-container" *ngIf="evaluation_sessions_row_1.length < evaluation_sessions_by_row">
                            <div class="step">
                                <i class="icon-Flag"></i>
                            </div>
                          </div>
                          <!-- <div class="step-container current">
                              <div class="step">2</div>
                              <div class="step-label">17 Luglio 2020</div>
                          </div> -->
                      </div>
                  </div>
                  <div class="stepper-container col-12" *ngIf="evaluation_sessions_row_1.length >= evaluation_sessions_by_row">
                      <div class="timeline until-end">
                          <div class="step-container" [ngClass]="session.tracker == true ? 'current' : ''" *ngFor="let session of evaluation_sessions_row_2; let i = index">
                            <ng-container>
                              <div class="step">{{evaluation_sessions_by_row + i+1}}</div>
                              <div class="step-label">{{session.decision_start}}</div>
                            </ng-container>
                          </div>
                          <div class="step-container icon-container">
                            <div class="step">
                                <i class="icon-Flag"></i>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row col-12 d-flex justify-content-between mb-3">
                <button
                  mat-flat-button
                  color="primary"
                  class="ml-2"
                  *ngIf="weights_from_framework_or_table_exists"
                  (click)="editSessionWeights(false)"
                >
                  {{
                  "components.decision-table.show-decision-table.view-weights"
                    | translate
                  }}
                </button>
                <button class="ml-auto" mat-flat-button color="primary" [routerLink]="['/decision/'+decision_table.id+'/show']" routerLinkActive="router-link-active" >{{'components.decision-table.decision-table-dashboard.go-to-table-management' | translate}}</button>

                <button
                    *ngIf="isVirtualAssistantEnabled$ | async"
                    class="size-small danger text-white"
                    mat-flat-button
                    (click)="onClickModalDashboardAiExplanation()"
                >
                  {{'modal.modal-virtual-assistant.title' | translate}}
                </button>

              </div>

          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<app-consensus-decision-table-dashboard *ngIf="loadingComponent && getAnalysisService().isConsensusByType(decision_table?.framework?.analysis_type)"
  [decision_table]="decision_table"
  [evaluation_session_dashboard]="currentEvaluationSessionDashboard"
></app-consensus-decision-table-dashboard>

<app-multiobjective-decision-table-dashboard *ngIf="loadingComponent && getAnalysisService().isMultiobjectiveByType(decision_table?.framework?.analysis_type)"
  [decision_table]="decision_table"
  [evaluation_session_dashboard]="currentEvaluationSessionDashboard"
></app-multiobjective-decision-table-dashboard>

<app-multicriteria-decision-table-dashboard *ngIf="loadingComponent && getAnalysisService().isMulticriteriaByType(decision_table?.framework?.analysis_type)"
  [decision_table]="decision_table"
  [evaluation_session_dashboard]="currentEvaluationSessionDashboard"
></app-multicriteria-decision-table-dashboard>
