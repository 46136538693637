import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const feature = route.data.feature;
    const redirectRoute = route.data.redirect;
    const featureIsNot = route.data.featureIsNot || false;

    if (!featureIsNot && !this.userService.currentOrganizationHasFeature(feature)) {
      this.handleRedirect(redirectRoute);
    }

    if (featureIsNot && this.userService.currentOrganizationHasFeature(feature)) {
      this.handleRedirect(redirectRoute);
    }

    return true;
  }
  private handleRedirect(redirectRoute: string): void {
    if (redirectRoute) {
      this.router.navigate([redirectRoute]);
    } else {
      this.router.navigate(['/']);
    }
  }

}
