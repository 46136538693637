<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">1</span>
                      {{'pages.decision-management-plan.component.decision-table' | translate}} 1
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                  <div class="container">
                      <mat-form-field class="datepicker-primary col-3" appearance="fill">
                          <mat-label>{{'pages.decision-management-plan.component.opening-date' | translate}}</mat-label>
                          <input matInput [matDatepicker]="dp_start">
                          <mat-datepicker-toggle matSuffix [for]="dp_start">
                              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                          </mat-datepicker-toggle>
                          <mat-datepicker #dp_start></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="select-primary col-5 wp-normal color-default" appearance="fill">
                          <mat-label>{{'pages.decision-management-plan.component.decisional-process-name' | translate}}</mat-label>
                          <mat-select class="color-default" name="1" value="1" disabled>
                              <mat-option value="1" selected>{{'pages.decision-management-plan.component.process-name' | translate}} Lorem ipsum dolor sit</mat-option>
                          </mat-select>
                      </mat-form-field>

                      <mat-form-field class="select-primary col-4" appearance="fill">
                          <mat-label>{{'generic.roles.responsible' | translate}}</mat-label>
                          <mat-select class="color-default" name="1" value="1" disabled>
                              <mat-option value="1" selected>{{'generic.name-surname' | translate}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>

              <div class="row">
                  <br />
                  <br />
                  {{'pages.decision-management-plan.component.stepper-timeline' | translate}}
                  <br />
                  <br />
              </div>

              <div class="row col-12 d-flex justify-content-end mb-3">
                  <div class="mr-5 label-info-button">
                    {{'generic.table' | translate}} <span class="green-color">{{'generic.status.open' | translate}}</span>
                  </div>
                  <button mat-flat-button color="primary">
                    {{'generic.buttons.enter-table' | translate}}
                  </button>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'pages.decision-management-plan.component.decision-table' | translate}} 2
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                  <div class="container">
                      <mat-form-field class="datepicker-primary col-3" appearance="fill">
                          <mat-label>{{'pages.decision-management-plan.component.opening-date' | translate}}</mat-label>
                          <input matInput [matDatepicker]="dp_start_1">
                          <mat-datepicker-toggle matSuffix [for]="dp_start_1">
                              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                          </mat-datepicker-toggle>
                          <mat-datepicker #dp_start_1></mat-datepicker>
                      </mat-form-field>

                      <mat-form-field class="select-primary col-5 wp-normal color-default" appearance="fill">
                          <mat-label>{{'pages.decision-management-plan.component.decisional-process-name' | translate}}</mat-label>
                          <mat-select class="color-default" name="1" value="1" disabled>
                              <mat-option value="1" selected>{{'pages.decision-management-plan.component.process-name' | translate}} Lorem ipsum dolor sit</mat-option>
                          </mat-select>
                      </mat-form-field>

                      <mat-form-field class="select-primary col-4" appearance="fill">
                          <mat-label>{{'generic.roles.responsible' | translate}}</mat-label>
                          <mat-select class="color-default" name="1" value="1" disabled>
                              <mat-option value="1" selected>{{'generic.name-surname' | translate}}</mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>

              <div class="row">
                  <br />
                  <br />
                  {{'pages.decision-management-plan.component.stepper-timeline' | translate}}
                  <br />
                  <br />
              </div>

              <div class="row col-12 d-flex justify-content-end mb-3">
                  <div class="mr-5 label-info-button">
                    {{'generic.table' | translate}} <span class="green-color">{{'generic.status.open' | translate}}</span>
                  </div>
                  <button mat-flat-button color="primary">{{'generic.buttons.enter-table' | translate}}
                  </button>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>

<div class="container-fluid box-full-width bg-white py-4 border-0">
  <div class="container px-4">
      <div class="row">
          <button class="col-12 big-button" mat-raised-button>
              <i class="icon-big_add"></i>
              {{'pages.decision-management-plan.component.add-decision-table' | translate}}
          </button>
      </div>
  </div>
</div>

<!-- Buttons -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button>
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.butons.save-and-back' | translate}}
  </button>
  <button class="button-primary float-right" mat-button>
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.butons.save-and-close' | translate}}
  </button>
</div>
