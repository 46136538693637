import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {HelperService} from 'src/app/services/general/helper.service';
import {ModalShowEvidencesComponent} from '../../../../modals/modal-show-evidences/modal-show-evidences.component';
import {MatDialog} from '@angular/material/dialog';
import {ModalShowProposalsComponent} from '../../../../modals/modal-show-proposals/modal-show-proposals.component';
import {UserService} from '../../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-multicriteria-decision-table-dashboard',
  templateUrl: './multicriteria-decision-table-dashboard.component.html',
  styleUrls: ['./multicriteria-decision-table-dashboard.component.scss']
})
export class MulticriteriaDecisionTableDashboardComponent implements OnInit, OnChanges {

  @Input() decision_table: any;
  @Input() evaluation_session_dashboard: any;

  currentDecisionTable: any;
  currentEvaluationSessionDashboard: any;

  constructor(
    private loaderService: ScriptLoaderService,
    private userService: UserService,
    private translateService: TranslateService,
    private suggestionService: SuggestionService, private processRoleService: ProcessRolesService, private dialog: MatDialog) {
  }


  loadingGraphics = true;

  // feedbackData:[] = [];
  // criteriaData: [] = [];

  displayedColumnsCriteria: string[] = ['criteriatitle', 'suggest', 'risk', 'contradiction'];
  displayedColumns: string[] = ['expert', 'answers', 'time', 'cost', 'total_cost', 'new_proposal', 'evidence'];

  ngOnInit(): void {
    //  inizializzo i componenti
    if (this.decision_table && this.evaluation_session_dashboard) {
      this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
      this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    }

    //  recupero le informazioni per questo tavolo decisionale
    this.getDecisionTableData();

    //  carico i grafici
    this.loaderService.loadChartPackages('corechart', 'gauge', 'timeline').subscribe(() => {

      this.loadingGraphics = false;
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
    this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    this.init();
  }


  getDecisionTableData() {
    this.init();
  }

  showExpertEvideces(data) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: data?.name || data?.email,
        attachments: data.attachment_expert_pubmed.concat(data.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }


  init() {

    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {
      if (this.isAlexion()) {
        this.drawAlexionCharts();
        this.drawTimeLine();
      } else {
        this.prepareAnswers();
        this.prepareChartContradiction();
        this.prepareChartResponse();

        this.drawChartTargets();
        this.drawChartWeightTargets();
      }
    }
  }

  isAlexion() {
    return this.userService.currentOrganization?.customs?.profile === 'alexion';
  }

  /**
   * Prepara le risposte filtrando solo quelle che sono state completate dagli esperti
   */
  prepareAnswers() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        const responseFiltered: any = [];
        criterio.response.forEach(response => {
          if (response?.team_member?.response_stamps && response?.team_member?.response_stamps.completed == true) {
            responseFiltered.push(response);
          }
        });
        criterio.response_filtered = responseFiltered;
      });
    });
  }

  showSuggestedProposals(proposals) {
    const data = {
      suggestedProposals: proposals,
      decisionTable: this.decision_table,
    };
    this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
  }


  /**
   * Prepara il grafico delle risposte in percentuale alla scelta fatta dagli utenti
   */
  prepareChartContradiction() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        if (criterio.response.length > 0) {
          criterio.response_agree_count = criterio.response_filtered.filter(x => x.consent_value == 'AGREE').length;
          criterio.response_disagree_count = criterio.response_filtered.filter(x => x.consent_value == 'DISAGREE').length;
          criterio.response_abstained_count = criterio.response_filtered.filter(x => x.consent_value == 'ABSTAINED').length;
          criterio.total_response = criterio.response_filtered.length;

          if (criterio.total_response > 0) {
            criterio.response_agree_perc = Math.floor((criterio.response_agree_count / criterio.total_response) * 100);
            criterio.response_disagree_perc = Math.floor((criterio.response_disagree_count / criterio.total_response) * 100);
            criterio.response_abstained_perc = Math.floor((criterio.response_abstained_count / criterio.total_response) * 100);
          }
        }
      });
    });
  }

  /**
   * Prepara il grafico delle delle risposte complessive da parte degli esperti
   */
  prepareChartResponse() {

    //  setto l'array con tutti i membri presenti e vedo proposta per proposta se hanno risposto a tutte le domande
    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      //  TODO_MICHELE: dal backend devono arrivare solo i membri che sono consulted
      proposal.team_members = proposal.team_members.filter(x => this.processRoleService.isConstulted(x.process_role));

      // proposal.team_members = proposal.team_members.filter(x => x.process_role == 'CONSULTED');
      // proposal.criteria.forEach(criterio => {
      //   criterio.response.forEach(response => {
      //     if (response.team_member.process_role == "CONSULTED") {
      //       if (proposal.team_members.filter(x => x.email == response.team_member.email).length <= 0)
      //         proposal.team_members.push(response.team_member);
      //     }
      //   });
      // });
    });
  }

  drawAlexionCharts() {
    this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
    this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));

    interface SessionResults {
      results_on_target: {
        score_perc: number;
      }[];
    }

    let riskCurrentSession = null;

    const data = Object.values(this.currentDecisionTable?.decisionTableResult?.sessionResults)?.map((sessionResult: SessionResults, index: number) => {
      if (this.currentEvaluationSessionDashboard?.evaluation_session === (index + 1)) {
        riskCurrentSession = sessionResult?.results_on_target[0]?.score_perc;
      }
      return [
        this.translateService.instant('generic.session') + ' ' + (index + 1),
        sessionResult?.results_on_target[0]?.score_perc,
      ];
    });

    const gauge_data = google.visualization.arrayToDataTable([
      ['Label', 'Value'],
      ['Risk', riskCurrentSession],
    ]);

    const gauge_options = {
      redFrom: 90, redTo: 100,
      yellowFrom: 75, yellowTo: 90,
      minorTicks: 5
    };

    const gauge_chart = new google.visualization.Gauge(document.getElementById('chart_div_current_evaluation_session_risk'));

    gauge_chart.draw(gauge_data, gauge_options);

    const line_data = google.visualization.arrayToDataTable([
      ['Session', 'Risk score'],
      ...data,
    ]);

    const options = {
      title: this.translateService.instant('components.decision-table.decision-table-dashboard.index-risk-sessions'),
      hAxis: {
        title: this.translateService.instant('generic.sessions')
      },
      vAxis: {
        title: this.translateService.instant('generic.risk-index-perc'),
        minValue: 0,
        maxValue: 100
      },
    };

    const line_chart = new google.visualization.LineChart(document.getElementById('chart_div_evaluation_session_risks'));

    line_chart.draw(line_data, options);

    const div = document.getElementById('chart_div_current_evaluation_session_risk');

    const table = div.querySelector('table');

    if (table) {
      table.classList.add('mr-auto', 'ml-auto');
    }
  }

  /** Disegna i grafici delle proposte */
  drawChartTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_criteria_on_target.length <= 0) {
      return;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_criteria_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    const groupedCriteria = HelperService.groupBy(groupedSTD['Expert (STD)'], 'criteria');
    const groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  prendo il primo elemento dell'array e costruisco i nomi dei criteri
    const chartData: any = [[...['Title'], ...Object.keys(groupedCriteria), ...[{role: 'annotation'}]]];
    Object.keys(groupedTarget).forEach(targetKey => {

      //  inizializzo l'array con il nome della proposta
      const arrayOfTargetData: any = [targetKey];

      //  itero i criteri per questa proposata creando l'array dei dati
      groupedTarget[targetKey].forEach(target => {
        arrayOfTargetData.push({v: target.weight_perc, f: `${target.weight_perc}%`});
      });

      //   //  aggiungo una stringa vuota per poter disegnare il grafico
      arrayOfTargetData.push('');

      //   //  assegno all'array del grafico i dati appena completati
      chartData.push(arrayOfTargetData);
    });

    const data = google.visualization.arrayToDataTable(chartData);

    const view = new google.visualization.DataView(data);

    const chart = new google.visualization.BarChart(document.getElementById('chart_div_target'));

    chart.draw(view, {
      height: 350,
      legend: {position: 'top', maxLines: 3},
      bar: {groupWidth: '75%'},
      isStacked: true
    });
  }


  /** Disegna i grafici dei pesi degli obiettivi */
  drawChartWeightTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_on_target.length <= 0) {
      return;
    }

    //  recupero i punteggi per i pesi standard
    const groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    const groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  costruisco i nomi dei titoli di riferimento per il grafico
    const chartData: any = [['Proposal', 'Score']];

    //  ciclo i target per settare i datisul diagramma
    Object.keys(groupedTarget).forEach(key => {
      Object.keys(groupedTarget[key]).forEach(proposalKey => {
        chartData.push([groupedTarget[key][proposalKey].proposal, groupedTarget[key][proposalKey].score_perc]);
      });
    });

    const data = google.visualization.arrayToDataTable(chartData);

    const view = new google.visualization.DataView(data);

    const chart = new google.visualization.PieChart(document.getElementById('chart_div_weight_target'));

    chart.draw(view, {
      height: 350,
      legend: {position: 'top', maxLines: 3}
    });
  }


  public getSuggestionService() {
    return this.suggestionService;
  }

  drawTimeLine() {
    const container = document.getElementById('chart_div_timeline');
    const chart = new google.visualization.Timeline(container);

    const therapies = this.decision_table?.decisionTableResult?.tasks?.filter((task) => task.additional_text === 'therapy');
    const visits = this.decision_table?.decisionTableResult?.tasks?.filter((task) => task.additional_text === 'visit');
    const evaluation_sessions = this.decision_table?.evaluation_sessions;

    const data = new google.visualization.DataTable();
    data.addColumn({ type: 'string', id: 'Type' });
    data.addColumn({ type: 'string', id: 'Label' });
    data.addColumn({ type: 'date', id: 'Start' });
    data.addColumn({ type: 'date', id: 'End' });

    therapies.forEach(therapy => data.addRow([this.translateService.instant('generic.therapies'), therapy.action, new Date(therapy.start_date), new Date(therapy.end_date)]));

    visits.forEach(visit => {
      const startDate = new Date(visit.customs?.visit_date);

      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);

      data.addRow([this.translateService.instant('generic.visits'), this.translateService.instant('generic.visit'), startDate, endDate]);
    });

    const getEvaluationRisk = (evaluation_session_id) => {
      return this.decision_table?.decisionTableResult?.sessionResults[evaluation_session_id]?.results_on_target[0]?.score_perc;
    };

    evaluation_sessions.forEach(session => data.addRow([
      this.translateService.instant('generic.sessions'),
      this.translateService.instant('generic.session') + ' ' + session.evaluation_session + '. ' + this.translateService.instant('generic.risk') + ': ' + getEvaluationRisk(session.id) + '%',
      new Date(session.evaluation_start),
      new Date(session.evaluation_end)
    ]));

    chart.draw(data);
  }
}
