import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AnalysisMethodsService } from 'src/app/services/config/analysis-methods.service';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import { ResponseService } from 'src/app/services/response.service';

@Component({
  selector: 'app-responses-explain',
  templateUrl: './responses-explain.component.html',
  styleUrls: ['./responses-explain.component.scss']
})
export class ResponsesExplainComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  id: any;

  allInfo;

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table:any;
  organization:any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];

  evaluations: any = [];

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router, private analysisService: AnalysisMethodsService,
    private unitService: UnitsService, private criteriaService: CriteriaService) { }

    ngOnInit(): void {

      //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_evaluation_session;
      if (this.id) {

        this.loading = true;
        this.responseService.getSummary(this.id, (result) => {

          this.allInfo = result;

          this.team_member = result.team_member;
          this.evaluation_session = result.evaluation_session;
          this.response_stamps = result.response_stamps;
          this.decision_table = result.decision_table;
          this.organization = result.decision_table.organization;
          this.primary_target = result.primary_target;
          this.secondary_target = result.secondary_target;
          this.framework_criteria = result.framework_criteria;
          this.proposals = result.proposals;
          this.evaluations = result.evaluations;

          this.loading = false;
          this.init();
        }, () => {
          this.loading = false;
          this.router.navigate(['/home']);
        })
      }
    });
  }


  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }


  public getAnalysisService() {
    return this.analysisService;
  }




  init() {

    this.loading = true;

    //  setto i pesi per gli obiettivi secondari sul primario
    for (let target of this.secondary_target) {

      let evaluation_target_finded = this.evaluations.weigth_targets.find(x => x.target_id == target.id);
      if (evaluation_target_finded) {
        target.evaluation = evaluation_target_finded;
      } else {
        target.evaluation = { weight: '-' };
      }
    }

    for (let target of this.secondary_target) {
      target.framework_criteria = JSON.parse(JSON.stringify(this.framework_criteria));
      let evaluation_target_filtered = this.evaluations.weigth_criteria.filter(x => x.target_id == target.id);
      for(let criteria of target.framework_criteria) {
        let evaluation_criteria_finded = evaluation_target_filtered.find(x => x.framework_criteria_id == criteria.id);
        if (evaluation_criteria_finded) {
          criteria.evaluation = evaluation_criteria_finded;
        } else {
          criteria.evaluation = { weight: '-' };
        }
      }
    }

    //  setto le confidence per i criteri di ogni proposta
    for (let proposal of this.proposals) {
      for (let criteria of proposal.criteria) {
        let response = this.evaluations.answers.find(x => x.criteria_id == criteria.id);
        if (response) {
          criteria.answer = response;
        } else {
          criteria.answer = { confidence: '-' }
        }
      }
    }

    this.loading = false;
  }



  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }
}
