<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span
              class="index">1</span> {{'generic.generic-info' | translate}} </mat-panel-title></span>
          </mat-expansion-panel-header>

          <div class="row">

            <!-- INSERIMENTO IMMAGINE PROFILO E CV -->
            <div class="col-12 col-md-5 text-center">
              <img class="user-avatar my-3" *ngIf="!modelUser?.details?.avatar_link"
                   src="assets/img/user-placeholder.jpg" alt="">
              <img class="user-avatar my-3" *ngIf="modelUser?.details?.avatar_link"
                   [src]="modelUser.details.avatar_link">
              <div class="my-2 px-0 px-lg-4">
                <ng-container *ngIf="isLoggedUser">
                  <mat-card-subtitle class="text-center">
                    <div class="col-12 d-flex justify-content-center align-items-center m-0 text-center">
                      <div>{{'generic.language' | translate}}: <span>{{userService.userDetails?.language}}</span>
                      </div>
                    </div>
                  </mat-card-subtitle>
                  <button mat-button color="primary" class="my-2 col-12" *ngIf="currentLang == 'it'"
                          (click)="useLanguage('en')">
                    &nbsp; <span
                    class="mr-2 underline">{{'components.header.component.change-language' | translate}}</span>
                    <i class="icon-flag-en"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span><span class="path4"></span><span class="path5"></span><span
                      class="path6"></span><span class="path7"></span><span class="path8"></span><span
                      class="path9"></span><span class="path10"></span><span class="path11"></span><span
                      class="path12"></span></i>
                  </button>
                  <button mat-button color="primary" class="my-2 col-12" *ngIf="currentLang == 'en'"
                          (click)="useLanguage('it')">
                    &nbsp; <span
                    class="mr-2 underline">{{'components.header.component.change-language' | translate}}</span>
                    <i class="icon-flag-it"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span></i>
                  </button>
                  <button mat-button color="primary" class="my-2 col-12" (click)="onClickChangePassword()">
                    {{ 'routing.title.change-password' | translate }}
                  </button>
                  <button mat-button color="primary" class="my-2 col-12" (click)="onClickResetPassword()">
                    {{ 'routing.title.reset-password' | translate }}
                  </button>
                  <button mat-button color="primary" class="my-2 col-12"
                        (click)="onClickUploadPhoto()">{{'pages.user.single-user-management.component.upload-photo' | translate}}
                  &nbsp;<i class="icon-upload"></i></button>
                  <button mat-button color="primary" class="w-100 mt-4 btn-custom btn-custom-primary border-0 roundend shadow py-2"
                          (click)="openDialogYourOrganizations()">{{'pages.user.user-profile.component.your-organization' | translate}}
                  </button>
                </ng-container>
                <button *ngIf="modelUser.id && modelUser.curricula" class="w-100 mt-4 btn-custom btn-custom-primary border-0 roundend shadow py-2"
                        [routerLink]="['curriculum/' + modelUser.curricula.id]" routerLinkActive="router-link-active">
                  <i class="icon-show"></i> &nbsp;
                  <span
                    class="text-uppercase">{{'pages.user.single-user-management.component.show-cv' | translate}}</span>
                </button>
                <button *ngIf="modelUser.id" class="w-100 mt-4 btn-custom btn-custom-primary border-0 roundend shadow py-2"
                        (click)="showRoleAndPermission()">
                  <fa-icon [icon]="faLock"></fa-icon> &nbsp;
                  <span
                    class="text-uppercase">{{'generic.access-level' | translate}}</span>
                </button>
                <button *ngIf="canDeleteOrganizationUser() && !user.deleted_at && !isLoggedUser" class="w-100 mt-4 btn-custom btn-custom-danger border-0 roundend shadow py-2"
                        (click)="deleteOrganizationUser()">
                  <fa-icon [icon]="faTrash"></fa-icon> &nbsp;
                  <span
                    class="text-uppercase">{{ "pages.user.single-user-management.component.delete-user" | translate }}</span>
                </button>
                <button *ngIf="canDeleteOrganizationUser() && user.deleted_at && !isLoggedUser" class="w-100 mt-4 btn-custom btn-custom-success border-0 roundend shadow py-2"
                        (click)="resetOrganizationUser()">
                  <fa-icon [icon]="faTrashRestore"></fa-icon> &nbsp;
                  <span
                    class="text-uppercase">{{'pages.user.all-users-management.component.undelete-user' | translate}}</span>
                </button>
              </div>
            </div>

            <!-- INPUT -->
            <div class="col-12 col-md-6">
              <mat-card class="box mt-3 py-4">
                <mat-form-field class="col-12">
                  <input matInput required type="text" placeholder="{{'generic.name' | translate}}" name="first_name"
                         [(ngModel)]="modelUser.first_name">
                </mat-form-field>
              </mat-card>

              <mat-card class="box mt-3 py-4">
                <mat-form-field class="col-12">
                  <input matInput required type="text" placeholder="{{'generic.surname' | translate}}" name="last_name"
                         [(ngModel)]="modelUser.last_name">
                </mat-form-field>
              </mat-card>

              <!-- <mat-card class="box mt-3 py-4" *ngFor="let cardRole of modelUser.cardRoles; let i = index">
                <ng-container>
                  <div class="row">
                    <div class="col-12 d-flex justify-content-end">
                      <mat-icon *ngIf="i > 0" class="i-custom" (click)="onClickRemoveRole(i)">close</mat-icon>
                    </div>
                  </div>
                  <mat-form-field class="col-12 col-lg-10 select-primary wp-normal color-default" appearance="fill">
                    <mat-label>{{'generic.roles.role' | translate}}</mat-label>
                    <mat-select class="color-default" [(ngModel)]="cardRole.role_id" name="role_{{i}}" [ngModelOptions]="{standalone: true}" (selectionChange)="onChangeRole(i)">
                      <mat-option [value]="undefinded" selected></mat-option>
                      <mat-option *ngFor="let role of organization_roles" [value]="role.id">{{role.role}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </mat-card> -->


              <!-- <div class="row">
                <div class="col-12 text-right">
                  <p class="p-custom mt-0" (click)="onClickAddRole()">{{'pages.user.single-user-management.component.add-role' | translate}}</p>
                </div>
              </div> -->

              <mat-card class="box mt-3 py-4">
                <mat-form-field class="col-12">
                  <input matInput type="email" name="email" [(ngModel)]="modelUser.email"
                         placeholder="{{'generic.email' | translate}}" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                         required [disabled]="modelUser.id ? true : false">
                </mat-form-field>
                <small class="text-muted d-block mx-3" *ngIf="!modelUser.id">
                  <fa-icon [icon]="faExclamationTriangle"></fa-icon> &nbsp; L'utente ricevrà su questa email una
                  password generata automaticamente.</small>
                <small class="text-muted d-block mx-3" *ngIf="!modelUser.id">Potrà cambiarla una volta entrato nel suo
                  profilo.</small>
              </mat-card>

              <ng-container *ngIf="isAlexion">
                <mat-card class="box mt-3 py-4">
                  <mat-form-field class="col-12">
                    <input matInput type="text" name="order_number" [(ngModel)]="modelUser.order_number"
                           placeholder="{{'swal.alexion.order-number.input-label' | translate}}" [required]="false">
                  </mat-form-field>
                  <small class="text-muted d-block mx-3">
                    <fa-icon [icon]="faExclamationTriangle"></fa-icon> &nbsp; {{'alexion.pages.user.single-user-management.component.order-number-description' | translate}}</small>
                </mat-card>
              </ng-container>


              <mat-card class="box mt-3 py-4">
                <mat-form-field class="col-12">
                  <input matInput type="email" name="secondary_email" [(ngModel)]="modelUser.secondary_email"
                         placeholder="{{'generic.secondary_email' | translate}}"
                         pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                </mat-form-field>
              </mat-card>

              <mat-card class="box mt-3 py-4">
                <mat-form-field class="col-12">
                  <input matInput type="tel" minlength="10" placeholder="{{'generic.phone' | translate}}" name="phone"
                         [(ngModel)]="modelUser.phone">
                </mat-form-field>
              </mat-card>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="container-fluid accordion-container" *ngIf="userAcceptedDocuments.length > 0 && isLoggedUser">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              {{'generic.privacy-documents' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row">
            <div class="col-12">

              <mat-table [dataSource]="dataSource" matSort class="table-primary">

                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.name' | translate }} </mat-header-cell>
                  <div class="row flex-column">
                    <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                  </div>
                </ng-container>

                <ng-container matColumnDef="version">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.version' | translate }}</mat-header-cell>
                  <div class="row flex-column">
                    <mat-cell style="justify-content: center;" *matCellDef="let row"> {{row.name}} </mat-cell>
                  </div>
                </ng-container>

                <ng-container matColumnDef="publication_date">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.publication-date' | translate }}
                  </mat-header-cell>
                  <mat-cell style="justify-content: center;"
                            *matCellDef="let row"> {{ row.created_at | date:'shortDate'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef
                                   class="text-center">{{ 'generic.action' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div class="row flex-column justify-content-between">
                      <a [href]="row.link" target="_blank" class="my-2 pl-0 pr-0"
                         mat-button
                         color="primary"
                      >
                        {{ 'generic.view' | translate }}
                      </a>
                      <button class="my-2 ml-3 pl-0 pr-0" *ngIf="row.valid && row.to_accept"
                              mat-button
                              color="warn"
                              (click)="revokeDocument(row.id)">

                        {{'generic.withdrawl' | translate}}
                      </button>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="container buttons-container py-4 mt-4">
    <button *ngIf="!isGuestUser" type="button" class="button-primary float-left" mat-button [routerLink]="isUserCreation?['../../']:['../']"
            routerLinkActive="router-link-active">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
    </button>
    <button *ngIf="isGuestUser && userService.lastVisitedEvaluationIdQuestionnaire" type="button" class="button-primary float-left" mat-button [routerLink]="['/responses/' + userService.lastVisitedEvaluationIdQuestionnaire + '/welcome/']"
            routerLinkActive="router-link-active">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.back-to-questionnaire' | translate}}
    </button>
    <button type="button" class="button-primary float-right" mat-button [disabled]="loading || isGuestUser" (click)="onSubmit(form)">
      <i class="icon-Forward-arrow-small"></i>
      <span *ngIf="!modelUser.id">{{'generic.buttons.save' | translate}}</span>
      <span *ngIf="modelUser.id">{{'generic.buttons.update' | translate}}</span>
    </button>
  </div>
</form>
