import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SurveyService} from '../../services/surveys.service';

@Component({
  selector: 'app-modal-survey-details',
  templateUrl: './modal-survey-details.component.html',
  styleUrls: ['./modal-survey-details.component.scss']
})
export class ModalSurveyDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalSurveyDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      projectId: null,
      surveyId: null,
    }) {
  }

  createMode = true;

  ngOnInit(): void {
    if (!this.data?.projectId) {
      this.closeDialogue();
    }

    if (this.data?.surveyId) {
      this.createMode = false;
    }
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }

}
