import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../services/decision.service';
import {FrameworkService} from '../../services/framework.service';
import {UnitsService} from '../../services/config/units.service';
import {CriteriaService} from '../../services/config/criteria.service';
import {AnalysisMethodsService} from '../../services/config/analysis-methods.service';
import {ToastService} from '../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-modal-edit-weights',
  templateUrl: './modal-edit-weights.component.html',
  styleUrls: ['./modal-edit-weights.component.scss']
})
export class ModalEditWeightsComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalEditWeightsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
  ) { }

  inputData: any = null;
  loading = false;

  ngOnInit(): void {
    if (this.data && Object.keys(this.data).length > 0) {
      this.inputData = JSON.parse(JSON.stringify(this.data));
    }
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }
}
