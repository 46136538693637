import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import { AlertService } from 'src/app/services/general/alert.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { ResponseService } from 'src/app/services/response.service';

@Component({
  selector: 'app-multiobjective-responses-weight-targets',
  templateUrl: './multiobjective-responses-weight-targets.component.html',
  styleUrls: ['./multiobjective-responses-weight-targets.component.scss']
})
export class MultiobjectiveResponsesWeightTargetsComponent implements OnInit {

  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId: any;
  @Output() responseWeightTargetSavedEvent = new EventEmitter();

  loading: boolean = false;


  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table:any;
  organization:any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];

  evaluations: any = [];

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router,
        private unitService: UnitsService, private criteriaService: CriteriaService, private toast: ToastService,
        private alert: AlertService, private translateService: TranslateService) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {

    this.loading = true;
    if (this.allDecisionInfo && this.evaluationSessionId) {

      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      this.secondary_target = this.allDecisionInfo.secondary_target;
      this.framework_criteria = this.allDecisionInfo.framework_criteria;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;

      this.init();
    }

    this.loading = false;
  }

  init() {
    this.loading = true;

    //  setto i pesi per gli obiettivi secondari sul primario
    for (let target of this.secondary_target) {

      let evaluation_target_finded = this.evaluations.weigth_targets.find(x => x.target_id == target.id);
      if (evaluation_target_finded) {
        target.evaluation = evaluation_target_finded;
      } else {
        target.evaluation = { weight: 0 };
      }
    }

    this.loading = false;
  }


  /** Verifica se sono rispettati tutti i valori prima di inviarli */
  verifySubmit(saveAsDraf:boolean = false) {

    //  la confidence può essere anche 0
    if (!this.targetEvaluationValid) {
      this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("components.responses.multiobjectives.alert-value-obj-zero") , (ok) => {
        this.onSubmit(saveAsDraf);
      });
      return
    }

    //  invio le risposte
    this.onSubmit(saveAsDraf);
  }

  onSubmit(saveAsDraf:boolean = false) {

    this.loading = true;

    this.responseService.saveEvaluationTargets(this.evaluationSessionId, {evaluation_targets: this.secondary_target}, (result) => {
      this.toast.success("Criteria weights",this.translateService.instant("generic.weights-criteria-saved"));
      this.loading = false;

      if (saveAsDraf) {
        this.responseWeightTargetSavedEvent.emit(true);
      } else {
        this.router.navigate(['../weight-criteria'], { relativeTo: this.activeRoute });
      }
    }, (error) => {
      this.loading = false;
    })
  }


  translate (string) {
    return this.translateService.instant(string);
  }


  /** valida i pesi degli obiettivi secondari sul primo */
  get targetEvaluationValid () {

    for (let target of this.secondary_target) {
      if (target.evaluation.weight == 0)
        return false;
    }
    return true;
  }


  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

}
