import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {AlertService} from 'src/app/services/general/alert.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {ResponseService} from 'src/app/services/response.service';
import {MatDialog} from '@angular/material/dialog';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {ModalNewProposalComponent} from '../../../../modals/modal-new-proposal/modal-new-proposal.component';
import {ModalShowProposalsComponent} from '../../../../modals/modal-show-proposals/modal-show-proposals.component';
import {AnalysisMethodsService} from '../../../../services/config/analysis-methods.service';
import {MatRadioChange} from '@angular/material/radio';
import {ResponseTypesService} from '../../../../services/config/response-types.service';
import {MatSelectChange} from "@angular/material/select";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: 'app-multicriteria-responses-evaluation',
  templateUrl: './multicriteria-responses-evaluation.component.html',
  styleUrls: ['./multicriteria-responses-evaluation.component.scss'],
})
export class MulticriteriaResponsesEvaluationComponent implements OnInit {
  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId: any;
  @Output() responseEvaluationSavedEvent = new EventEmitter();
  @Output() addNewEvidenceEvent = new EventEmitter();
  @Output() attachmentOnClickEvent = new EventEmitter();

  loading = false;

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table: any;
  organization: any;
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];
  suggestedProposals = [];
  enabledSuggestedProposalsButton = true;

  filters: any = {
    section_filtered: [],
    subsection_filtered: [],
    section: [],
  };

  exandedPanels = false;
  onlyNotResponsed = false;

  //  valore iniziale delle risposte
  initial_framework_criterias: string;

  evaluations: any = [];

  constructor(
    private activeRoute: ActivatedRoute,
    private responseService: ResponseService,
    private router: Router,
    private toast: ToastService,
    private criteriaService: CriteriaService,
    private alert: AlertService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private analysisService: AnalysisMethodsService,
    private responseType: ResponseTypesService,
    private userService: UserService
  ) {
  }

  public getAnalysisService() {
    return this.analysisService;
  }

  public get framework() {
    return this.allDecisionInfo?.evaluation_session?.decision_table?.framework;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (this.allDecisionInfo && this.evaluationSessionId) {
      this.suggestedProposals = this.allDecisionInfo?.suggested_proposals || [];
      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      // this.secondary_target = this.allDecisionInfo.secondary_target;
      this.framework_criteria = this.allDecisionInfo.framework_criteria;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;
      this.evaluations.answers.forEach((answer) => {
        if (answer.confidence === -1) {
          answer.confidence = null;
        }
      });
      this.init();
    }

    this.loading = false;
  }

  init() {
    this.loading = true;
    //  setto i pesi dei criteri per ogni proposta
    for (const criteria of this.framework_criteria) {
      criteria.proposals = JSON.parse(JSON.stringify(this.proposals));
      for (const proposal of criteria.proposals) {
        //  aggiungo le infomazioni dei criteri da valutare proposta per proposta
        const criteriaFound = proposal.criteria.find(
          (x) => x.framework_criteria_id == criteria.id
        );
        if (criteriaFound) {
          proposal.evaluation_criteria = criteriaFound;

          //  setto i feedback se presenti
          const response = this.evaluations.answers.find(
            (x) => x.criteria_id == criteriaFound.id
          );
          if (response) {
            proposal.evaluation_criteria.answer = response;
          } else {
            proposal.evaluation_criteria.answer = {confidence: null};
          }
        }

        if (proposal.evaluation_criteria?.additional) {
          this.filters.section.push({
            value: proposal.evaluation_criteria.additional.section,
            subsection: proposal.evaluation_criteria.additional.sub_section,
          });
        }

        //  setto le informazioni dei pesi precedentemente inseriti
        const evaluation_criteria_proposal_filterd =
          this.evaluations.weigth_proposals.filter(
            (x) => x.framework_criteria_id == criteria.id
          );
        const evaluation_criteria_proposal =
          evaluation_criteria_proposal_filterd.find(
            (x) => x.proposal_id == proposal.id
          );
        if (evaluation_criteria_proposal) {
          proposal.evaluation = evaluation_criteria_proposal;
        } else {
          proposal.evaluation = {weight: 0};
        }

        if (this.isAlexion()) {
          proposal.evaluation_criteria.answer.confidence = 100;
        }
      }
    }

    this.prepareAttachment();
    this.prepareExpertAttachment();

    //  salvo il valore iniziale delle risposte
    this.initial_framework_criterias = JSON.stringify(this.framework_criteria);

    //  inizializzo i filtri
    this.getSectionFilter();

    //  se avevo dei filtri selezionati cerco di ripristinarli
    if (this.filters.subsection_selected) {
      this.filters.section_selected_type = 'subsection';
      this.filterOnChange(this.filters.section_selected_type);
    } else if (this.filters.section_selected) {
      this.filters.section_selected_type = 'section';
      this.filterOnChange(this.filters.section_selected_type);
    }
    if (this.onlyNotResponsed == true) {
      this.filterResponsed();
    }

    this.setAnsweredDataToCriteria();

    if (this.isAlexion()) {
      this.setBlankFeedbacks();
    }

    if (this.response_stamps?.completed) {
      this.prepareAttachmentOnCompleted();
    }

    this.loading = false;
  }

  /**
   * Filtra le risposte tra quelle già risposte
   */
  filterResponsed() {
    for (const criteria of this.framework_criteria) {
      for (const proposal of criteria.proposals) {
        for (const proposalCriteria of proposal.criteria) {
          if (typeof proposalCriteria.answer != 'undefined') {
            if (this.onlyNotResponsed == true) {
              if (typeof proposalCriteria.answer.option_value != 'undefined' && proposalCriteria.answer.option_value != null) {
                criteria.hidden_only_response = true;
              }
            } else {
              criteria.hidden_only_response = false;
            }
          }
        }
      }
    }
  }

  showSuggestedProposals() {
    const data = {
      suggestedProposals: this.suggestedProposals,
      decisionTable: this.decision_table,
    };
    this.enabledSuggestedProposalsButton = false;
    const dialogRef = this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
    dialogRef.afterClosed().subscribe((event) => {
      this.enabledSuggestedProposalsButton = true;
    });
  }

  setAnsweredDataToCriteria() {
    this.framework_criteria.forEach((criterion) => {
      criterion.proposals.forEach((proposal) => {
        if (proposal.evaluation_criteria.answered) {
          proposal.evaluation_criteria.answer.feedback =
            proposal.evaluation_criteria.response.feedback;
          proposal.evaluation.weight =
            proposal.evaluation_criteria.response.confidence;
        }
      });
    });
  }

  /**
   * Setto gli allegati nei criteri
   */
  prepareAttachment() {
    for (const criteria of this.framework_criteria) {
      criteria.proposals.forEach((proposal) => {
        proposal.criteria.forEach((criterio) => {
          criterio.attachment = [];
          if (criterio.attachment_pubmed) {
            criterio.attachment_pubmed.forEach((pubmed) => {
              if (
                pubmed.team_member_id === undefined ||
                proposal.proposedby_team_member_id == pubmed.team_member_id
              ) {
                criterio.attachment.push(pubmed);
              }
              // console.log(pubmed);
            });
          }
          if (criterio.attachment_files) {
            criterio.attachment_files.forEach((file) => {
              if (
                file.team_member_id === undefined ||
                proposal.proposedby_team_member_id == file.team_member_id
              ) {
                criterio.attachment.push(file);
              }
            });
          }
        });
      });
    }
  }

  prepareAttachmentOnCompleted() {
    for (const criteria of this.framework_criteria) {
      criteria.proposals.forEach((proposal) => {
        proposal.criteria.forEach((criterion) => {
          criterion.attachment_expert.push(
            ...criterion.attachment_expert_files
          );
          criterion.attachment_expert.push(
            ...criterion.attachment_expert_pubmed
          );
        });
      });
    }
  }

  /** Setto gli allegati degli esperti nei criteri */
  prepareExpertAttachment() {
    for (const criteria of this.framework_criteria) {
      for (const proposal of criteria.proposals) {
        for (const criterio of proposal.criteria) {
          criterio.attachment_expert = [];

          const files = criterio.attachment_expert_files || [];
          const pubmed = criterio.attachment_expert_pubmed || [];

          criterio.attachment_expert = pubmed.concat(files);

        }
      }
    }
  }

  /**
   * Aggiunta di un nuovo allegato
   * @param proposal_index
   * @param criteria_index
   * @param attachment_type
   */
  onClickAddNewEvidence(
    proposal_index: any,
    criteria_index: any,
    attachment_type: string
  ) {
    // console.log("clicked")
    // this.addNewEvidenceEvent.emit({
    //   criterio,
    //   attachment_type,
    // });
    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        proposal: this.proposals[proposal_index],
        criterio: this.proposals[proposal_index].criteria[criteria_index],
        uploadUrl: 'upload/tmp-expert-proposal-file',
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {
      if (attachmentData) {
        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedExpertAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }

  /** Verifica se sono rispettati tutti i valori prima di inviarli */
  verifySubmit(saveAsDraf: boolean = false) {
    //  setto la confidence per tutti i criteri nelle proposte
    this.alignsConfidence();

    //  se sono in bozza nnon valido nulla
    if (saveAsDraf) {
      this.setConfidenceNotSetted();
      this.confirmSubmit(saveAsDraf);
      return;
    }

    //  controllo che i criteri siano validi
    if (!this.criteriaAreValid()) {
      this.toast.error(
        this.translateService.instant('generic.warning'),
        this.translateService.instant('generic.proposal-feedback-valid')
      );
      return;
    } else {
      for (const criteria of this.framework_criteria) {
        for (const proposal of criteria.proposals) {
          if (
            proposal.evaluation_criteria.answer.confidence === null
            || proposal.evaluation_criteria.answer.confidence < 0
          ) {
            proposal.evaluation_criteria.answer.confidence = 0;
          }
        }
      }
    }

    //  la confidence può essere anche 0
    if (!this.proposalConfidenceValid) {
      this.alert.showQuestion(
        this.translateService.instant('generic.warning'),
        this.translateService.instant(
          'components.responses.multicriteria.alert-proposal-confidence-value-zero'
        ),
        (ok) => {
          this.confirmSubmit(saveAsDraf);
        }
      );
      return;
    }

    //  invio le risposte
    this.confirmSubmit(saveAsDraf);
  }

  /** Messaggio di conferma di invio del questionario */
  confirmSubmit(saveAsDraf: boolean = false) {
    //  se un draft non faccio la domanda di invio questionario
    if (saveAsDraf) {
      this.onSubmit(saveAsDraf);
      return;
    }

    this.alert.showQuestion(
      this.translateService.instant('generic.warning'),
      this.translateService.instant('generic.alert-questionnaire-change'),
      (ok) => {
        this.onSubmit(saveAsDraf);
      }
    );
  }

  onSubmit(saveAsDraf: boolean = false) {
    const modelEvaluationProposalToSend = this.prepareEvaluationToSend();

    // console.log(this.prepareResponseToSend());
    // return;

    this.loading = true;
    this.responseService.saveEvaluationProposal(
      this.evaluationSessionId,
      {evaluation_proposals: modelEvaluationProposalToSend, saveAsDraft: saveAsDraf},
      (result) => {
        const modelResponseToSend = this.prepareResponseToSend();

        this.responseService.saveResponses(
          this.evaluationSessionId,
          {responses: modelResponseToSend, saveAsDraft: saveAsDraf},
          (result) => {
            this.loading = false;
            this.toast.success(
              this.translateService.instant('Answers'),
              this.translateService.instant('generic.answer-saved')
            );

            if (saveAsDraf) {
              this.responseEvaluationSavedEvent.emit(true);
            } else {
              this.loading = true;
              this.responseService.closeResponse(
                this.evaluationSessionId,
                (result) => {
                  this.loading = false;
                  this.toast.success(
                    this.translateService.instant('generic.questionnaire'),
                    this.translateService.instant('generic.questionnaire-sent')
                  );
                  this.router.navigate(['../thank-you'], {
                    relativeTo: this.activeRoute,
                  });
                },
                () => (this.loading = false)
              );
            }
          },
          () => (this.loading = false)
        );
      },
      (error) => (this.loading = false)
    );
  }

  /**
   * Setto un allegato pubmed
   * @param attachmentPubmed
   */
  addPubmedExpertAttachment(attachmentPubmed) {
    for (const criteria_proposal of this.framework_criteria) {
      for (const proposals of criteria_proposal.proposals) {
        for (const proposalCriteria of proposals.criteria) {
          if (proposalCriteria.id == attachmentPubmed.criterio.id) {
            if (!proposalCriteria.attachment_expert_pubmed) {
              proposalCriteria.attachment_expert_pubmed = [];
            }
            proposalCriteria.attachment_expert_pubmed.push({
              type: 'PUBMED',
              PMID: attachmentPubmed.attachmentObject.PMID,
              title: attachmentPubmed.attachmentObject.ArticleTitle,
              link: attachmentPubmed.attachmentObject.link,
              isNew: true,
            });
            break;
          }
        }
      }
    }
    this.prepareExpertAttachment();
  }

  /**
   * Serto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {
    for (const fileAttached of attachmentFile.attachmentObject) {
      for (const criteria_proposal of this.framework_criteria) {
        for (const proposals of criteria_proposal.proposals) {
          for (const proposalCriteria of proposals.criteria) {
            if (proposalCriteria.id === attachmentFile.criterio.id) {
              if (!proposalCriteria.attachment_expert_files) {
                proposalCriteria.attachment_expert_files = [];
              }
              proposalCriteria.attachment_expert_files.push({
                id: fileAttached.id,
                type: 'FILE',
                ext: fileAttached.ext,
                size: fileAttached.size,
                title: fileAttached.title,
                path: fileAttached.path,
                link: fileAttached.link,
                isNew: true,
              });
              break;
            }
          }
        }
      }
    }
    this.prepareExpertAttachment();
  }

  /**
   * Rimozione di un allegato
   * @param attachIndex
   * @param criterio
   */
  onClickRemoveEvidence(attachIndex, criterio) {
    const attachment = criterio.attachment_expert[attachIndex];

    switch (attachment.type) {
      case 'PUBMED':
        if (attachment.isNew) {
          this.removeAttachmentExpertPubmed(attachment, criterio);
        } else {
          this.responseService.removeAttachment(
            this.evaluationSessionId,
            {
              attachment_id: attachment.id,
              type: 'PUBMED',
            },
            () => {
              this.removeAttachmentExpertPubmed(attachment, criterio);
              this.toast.success(
                this.translateService.instant('components.responses.attached'),
                this.translateService.instant(
                  'components.responses.consensus.evaluation-success-remove-attachment'
                )
              );
            },
            () => {
              this.toast.error(
                this.translateService.instant('components.responses.attached'),
                this.translateService.instant(
                  'components.responses.consensus.evaluation-error-remove-attachment'
                )
              );
            }
          );
        }
        break;
      case 'FILE':
        if (attachment.isNew) {
          this.removeAttachmentExpertFile(attachment, criterio);
        } else {
          this.responseService.removeAttachment(
            this.evaluationSessionId,
            {
              attachment_id: attachment.id,
              type: 'FILE',
            },
            () => {
              this.removeAttachmentExpertFile(attachment, criterio);
              this.toast.success(
                this.translateService.instant('components.responses.attached'),
                this.translateService.instant(
                  'components.responses.consensus.evaluation-success-remove-attachment'
                )
              );
            },
            () => {
              this.toast.error(
                this.translateService.instant('components.responses.attached'),
                this.translateService.instant(
                  'components.responses.consensus.evaluation-error-remove-attachment'
                )
              );
            }
          );
        }
        break;
    }
  }

  removeAttachmentExpertPubmed(attachment, criterio) {
    const pubmedIndex = criterio.attachment_expert_pubmed.findIndex(
      (x) => x.PMID == attachment.PMID
    );
    if (pubmedIndex >= 0) {
      criterio.attachment_expert_pubmed.splice(pubmedIndex, 1);
    }

    this.prepareExpertAttachment();
  }

  removeAttachmentExpertFile(attachment, criterio) {
    const fileIndex = criterio.attachment_expert_files.findIndex(
      (x) => x.id == attachment.id
    );
    if (fileIndex >= 0) {
      criterio.attachment_expert_files.splice(fileIndex, 1);
    }

    this.prepareExpertAttachment();
  }

  /**
   * Preparo l'array delle valutazioni svuotandolo di tutto ciò che non è necessario
   */
  prepareEvaluationToSend() {
    const modelEvaluationProposalToSend: any = [];

    this.framework_criteria.forEach((criteria) => {
      const criteriotoSend: any = {};
      criteriotoSend.id = criteria.id;
      criteriotoSend.proposals = [];

      criteria.proposals.forEach((proposal) => {
        criteriotoSend.proposals.push({
          id: proposal.id,
          evaluation: proposal.evaluation,
        });
      });

      modelEvaluationProposalToSend.push(criteriotoSend);
    });

    return modelEvaluationProposalToSend;
  }

  optionSelected(data: MatRadioChange, proposalCriteria) {
    //  cerco il titolo della option che è staa selezionata
    const findTitle = proposalCriteria.options.find((x) => x.id == data.value);
    if (!findTitle) {
      return;
    }

    //  se non ho mai scritto nulla nel feedback setto il titolo della option
    if (!proposalCriteria.answer.feedback) {
      proposalCriteria.answer.feedback = findTitle.title;
    } else {
      //  se ho trovato il valore di default di una delle opzioni allora la posso sostituire altrimenti non devo fare nulla
      const valueFinded = proposalCriteria.options.find(
        (x) => x.title == proposalCriteria.answer.feedback
      );
      if (valueFinded) {
        proposalCriteria.answer.feedback = findTitle.title;
      }
    }
  }

  /**
   * Preparo l'array delle risposte svuotandolo di tutto ciò che non è necessario
   */
  prepareResponseToSend() {
    const modelAnswerToSend: any = [];

    this.framework_criteria.forEach((criterio) => {
      const criteriotoSend: any = {};
      criteriotoSend.id = criterio.id;
      criteriotoSend.proposals = [];

      criterio.proposals.forEach((proposal) => {
        const proposalCriteria = proposal.criteria.find(
          (x) => x.framework_criteria_id == criterio.id
        );

        proposalCriteria.answer.attachment = proposalCriteria.attachment.filter(
          (x) => x.isNew === true
        );
        proposalCriteria.answer.attachment_expert =
          proposalCriteria.attachment_expert.filter((x) => x.isNew === true);
        proposalCriteria.answer.attachment_expert_files =
          proposalCriteria.attachment_expert_files.filter(
            (x) => x.isNew === true
          );
        proposalCriteria.answer.attachment_expert_pubmed =
          proposalCriteria.attachment_expert_pubmed.filter(
            (x) => x.isNew === true
          );
        proposalCriteria.answer.attachment_files =
          proposalCriteria.attachment_files.filter((x) => x.isNew === true);
        proposalCriteria.answer.attachment_pubmed =
          proposalCriteria.attachment_pubmed.filter((x) => x.isNew === true);

        const evaluationCriteria: any = {
          id: proposal.evaluation_criteria.id,
          answer: proposalCriteria.answer,
        };
        criteriotoSend.proposals.push({
          evaluation_criteria: evaluationCriteria,
        });
      });

      modelAnswerToSend.push(criteriotoSend);
    });

    return modelAnswerToSend;
  }

  suggestProposalOnClick(proposal = null) {
    let data = {};
    this.decision_table.fromQuestionnaire = true;
    if (proposal) {
      data = {
        newProposal: false,
        decision_table: this.decision_table,
        proposal,
      };
    } else {
      data = {
        newProposal: true,
        decision_table: this.decision_table,
      };
    }

    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.suggestedProposals.push(result?.proposal);
      }
    });
  }

  //  evento di apertura di un allegato
  onClickAttachment(attachment: any) {
    this.attachmentOnClickEvent.emit(attachment);
  }

  /**
   * Allinea la confidence di tutti i criteri per ogni proposta per adattarsi alla UX
   */
  alignsConfidence() {
    this.framework_criteria.forEach((criteria) => {
      if (criteria.proposals.length > 0) {
        const confidence =
          criteria.proposals[0].evaluation_criteria.answer.confidence;
        criteria.proposals.forEach((proposal) => {
          proposal.evaluation_criteria.answer.confidence = confidence;
        });
      }
    });
  }

  translate(string) {
    return this.translateService.instant(string);
  }

  /** Valida le confidence delle proposte */
  get proposalConfidenceValid() {
    //  TODO_MICHELE: attualmente anche il valore 0 può essere utilizzato
    for (const criteria of this.framework_criteria) {
      for (const proposal of criteria.proposals) {
        if (
          !this.criteriaService.criteriaIsQuantitative(
            proposal.evaluation_criteria.typology
          ) &&
          proposal.evaluation_criteria.answer.confidence == 0
        ) {
          return false;
        }
      }
    }

    return true;
  }

  criteriaAreValid() {
    return this.framework_criteria.every((criterion) => this.criteriaIsCompleted(criterion));
  }


  setConfidenceNotSetted() {
    for (const criteria of this.framework_criteria) {
      for (const proposal of criteria.proposals) {
        if (proposal.evaluation_criteria.answer.confidence === null) {
          proposal.evaluation_criteria.answer.confidence = -1;
        }
      }
    }

    return true;
  }

  setCriteriaAnswerConfidence(criteria) {
    if (criteria.answer.confidence === null) {
      criteria.answer.confidence = 0;
    }
  }

  /** Restituisco il colore corretto se l'utente ha completato tutte le valutazioni delle proposte */
  getIconColorForProposalCriteria(criteria: any) {
    return this.criteriaIsCompleted(criteria) ? 'green-color' : 'orange-color';
  }

  criteriaIsCompleted(criteria) {
    return criteria.proposals.some((proposal) => this.criteriaProposalIsCompleted(proposal));
  }

  criteriaProposalIsCompleted(proposal) {
    const evalCriteria = proposal?.evaluation_criteria;

    return !(
      this.isInvalidMultichoiceCriteria(proposal) ||
      this.isInvalidEnelCriteria(proposal) ||
      this.isInvalidQualitativeCriteria(evalCriteria)
    );
  }

  private isInvalidMultichoiceCriteria(proposal) {
    const criteria = proposal?.evaluation_criteria;
    let is_invalid = false;

    if (!criteria?.answer?.option_value) {
      is_invalid = true;
    }

    if (!criteria?.answer?.feedback) {
      if (!this.isAlexion()) {
        is_invalid = true;
      }
    }

    if (this.isEnel()) {
      if (!criteria?.answer?.draft_action) {
        is_invalid = true;
      }
    }

    return (
      this.criteriaService.criteriaIsMultipleChoice(criteria?.typology) &&
      this.getAnalysisService().isMulticriteriaByType(this.framework.analysis_type) &&
      is_invalid
    );
  }

  private isInvalidEnelCriteria(proposal) {
    const criteria = proposal?.evaluation_criteria;
    return this.isEnel() && (!criteria?.answer?.feedback || !criteria?.answer?.draft_action);
  }

  private isInvalidQualitativeCriteria(evalCriteria) {
    let is_invalid = false;

    if (!evalCriteria?.answer?.feedback) {
      if (!this.isAlexion()) {
        is_invalid = true;
      }
    }

    return (
      this.criteriaService.criteriaIsQualitative(evalCriteria?.typology) &&
      is_invalid
    );
  }

  private isInvalidQuantitativeCriteria(evalCriteria) {
    return (
      this.criteriaService.criteriaIsQuantitative(evalCriteria?.typology) &&
      !evalCriteria?.answer?.feedback
    );
  }

  setBlankFeedbacks() {
    this.framework_criteria.forEach((criterion) => {
      criterion.proposals.forEach((proposal) => {
        proposal.evaluation_criteria.answer.feedback = '';
      });
    });
  }

  /** Aggiunge il campo feedback se non c'è */
  addFeedback(criteria_index, proposal_index) {
    this.framework_criteria[criteria_index].proposals[
      proposal_index
      ].evaluation_criteria.answer.feedback = '';
  }

  /** rimuove il campo feedback */
  removeFeedback(criteria_index, proposal_index) {
    delete this.framework_criteria[criteria_index].proposals[proposal_index]
      .evaluation_criteria.answer.feedback;
  }

  public getCriteriaService() {
    return this.criteriaService;
  }

  public isEnel() {
    return this.organization?.customs?.profile === 'enel';
  }

  public isAlexion() {
    return this.organization?.customs?.profile === 'alexion';
  }


  getSectionFilter() {
    this.filters.section_filtered = [];
    this.filters.section.forEach((element) => {
      if (this.filters.section_filtered.indexOf(element.value) <= -1) {
        this.filters.section_filtered.push(element.value);
      }
    });
  }

  getSubsectionFilter() {
    this.filters.subsection_filtered = [];
    this.filters.section.forEach((element) => {
      if (element.value == this.filters.section_selected) {
        if (
          this.filters.subsection_filtered.indexOf(element.subsection) <= -1
        ) {
          this.filters.subsection_filtered.push(element.subsection);
        }
      }
    });
  }

  filterOnChange($event: EventEmitter<MatSelectChange>) {
    //  se vengo da una selezione del settore mostro tutti i criteri
    if ($event.toString() == 'section') {
      this.filters.subsection_selected = undefined;
      for (const criteria of this.framework_criteria) {
        criteria.hidden = false;
      }
    }

    //  ricreo l'array delle sottosezioni
    this.getSubsectionFilter();

    //  se non ho selezionato un settore non filtro nulla
    if (!this.filters.section_selected) {
      return;
    }

    //  filtro
    for (const criteria of this.framework_criteria) {
      for (const proposal of criteria.proposals) {
        for (const proposalCriteria of proposal.criteria) {
          if (proposalCriteria?.additional) {
            if (proposalCriteria.framework_criteria_id == criteria.id) {
              //  se ho selezionato il sottosettore filtro per quello
              if (this.filters.subsection_selected) {
                if (
                  proposalCriteria.additional.sub_section ==
                  this.filters.subsection_selected
                ) {
                  criteria.hidden = false;
                } else {
                  criteria.hidden = true;
                }
              }
              //  altrimenti filtro per settore
              else {
                if (
                  proposalCriteria.additional.section ==
                  this.filters.section_selected
                ) {
                  criteria.hidden = false;
                } else {
                  criteria.hidden = true;
                }
              }
            }
          }
        }
      }
    }
  }
}
