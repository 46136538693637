<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<form #formSelectFramework="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

    <!-- Blocco 1 - Creazione Tavolo - Fase 1 -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">1</span>
                {{'components.decision-table.new-decision-table.select-framework.process-name' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
              <div class="row">
                <mat-form-field class="col-12 col-lg-6 input-big">
                  <input [disabled]="currentorganization?.customs?.profile === 'alexion'" matInput name="framework_name" [(ngModel)]="componentState.model.decision_name" placeholder="{{'components.decision-table.new-decision-table.select-framework.placeholder.insert-title' | translate}}" required autocomplete="off">
                </mat-form-field>
                <mat-form-field class="col-6 col-lg-3 select-primary wp-normal color-default py-4" appearance="fill">
                  <mat-label>{{'generic.organization' | translate}}</mat-label>
                  <mat-select disabled name="organization" [(ngModel)]="componentState.model.organization_id" required>
                    <mat-option [value]="componentState.model.organization_id" >{{ currentorganization.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="col-6 col-lg-3 datepicker-custom-button py-4" (click)="dp_delivery_date.open()">
                  <mat-label>{{'components.decision-table.new-decision-table.select-framework.estimated-closure-date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="dp_delivery_date" name="delivery_date" class="color-default ng-trim-ignore" [(ngModel)]="componentState.model.delivery_date" disabled>
                  <mat-datepicker-toggle matSuffix [for]="dp_delivery_date">
                    <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #dp_delivery_date disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 2 - Creazione Tavolo - Fase 1 -->
    <div class="accordion-container container-fluid px-0">
      <div class="container-fluid px-0">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header class="container">
              <mat-panel-title>
                <span class="index">2</span>
                {{'components.decision-table.new-decision-table.select-framework.choose-your-decision-scheme' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="container px-4">
              <div class="container background-filters py-3 px-5 mt-3">
                <div class="row justify-content-between">
                  <div class="purple-color">
                    <h2>{{'generic.search-for' | translate}}</h2>
                  </div>
                  <div>
                    <button class="clear-filter py-2 px-4" (click)="onClickResetFilters()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
                  </div>
                </div>

                <!-- PRIMA ROW FILTRI-->
                <div class="row d-flex justify-content-between align-items-center m-0">
                  <div class="col-12 col-md-3">
                    <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                      <mat-label>{{'generic.sector' | translate}}</mat-label>
                      <mat-select  #sectorInput name="searchFilterSecor" [(ngModel)]="searchModel.sector_key" (selectionChange)="onChangeSearchFilter('sector')" class="color-default">
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let sector of getWorkingSectorService().getAllSectorsForSelect()" [value]="sector.key">{{sector.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-md-3">
                    <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                      <mat-label>{{'generic.subsector' | translate}}</mat-label>
                      <mat-select name="searchFilterSubsecor" [(ngModel)]="searchModel.subsector_key" (selectionChange)="onChangeSearchFilter()" [disabled]="!sectorInput.value">
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let subsector of getSubsectorsArrayFiltered(sectorInput.value)" [value]="subsector.key">{{subsector.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-12 col-md-3">
                    <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                      <mat-label>{{'pages.frameworks.component.analysis-methodology' | translate}}</mat-label>
                      <mat-select name="searchFilterAnalysisType" [(ngModel)]="searchModel.analysis_type" (selectionChange)="onChangeSearchFilter()" class="color-default">
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let analysis_method of getAnalysisMethodsService().getAllAnalysisMethods()" [value]="analysis_method.type">{{analysis_method.title}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <!-- SECONDA ROW FILTRI-->
                <div class="row d-flex justify-content-between mx-0 mt-2">

                  <div class="col-12 col-md-12 mt-4">
                    <mat-form-field class="full-width">
                      <input matInput name="searchKeywords" (ngModelChange)="onSearchInputChange()" [(ngModel)]="searchModel.searchKeywords" type="text" placeholder="{{'pages.frameworks.all-frameworks.component.search-name' | translate}}" #searchInput>
                      <mat-icon class="with-cursor" *ngIf="searchModel.searchKeywords" matSuffix aria-label="Clear" (click)="searchModel.searchKeywords=''; onClickSearch()">close</mat-icon>
                      <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="onClickSearch()">search</mat-icon>
                    </mat-form-field>
                  </div>
                </div>

              </div>
              <div class="row justify-content-center mt-5" *ngIf="searchDone">
                  <ng-container *ngFor="let framework of componentState.frameworkFilteredList; let i = index" cols="12">
                    <ng-container *ngIf="framework.hidden == false">
                      <div class="col-12 col-md-6" *ngIf="i < currentNumOfFrameworkShowed">
                        <mat-card class="mb-4 border border-0 shadow cursor-pointer" (click)="onClickFramework(i)" [ngClass]="framework?.id == componentState.frameworkSelected?.id ? 'selected card-selected' : ''">
                          <div class="d-flex justify-content-between">
                            <img *ngIf="framework?.organization?.logo_link" [src]="framework?.organization?.logo_link" alt="" class="mb-2 organization-logo">
                            <img *ngIf="!framework?.organization?.logo_link" src="assets/img/placeholder-img-organization.jpg" alt="" class="mb-2 organization-logo">
                            <mat-icon *ngIf="framework?.id == componentState.frameworkSelected?.id">check</mat-icon>
                          </div>
                          <h2 class="purple-color"><strong>{{framework.title}}</strong></h2>
                          <p>{{framework.purpose_description}}</p>
                          <hr>
                          <div class="row">
                            <div class="col-6">
                              <p class="m-0">{{'pages.frameworks.component.analysis-methodology' | translate}}</p>
                              <h4 class="m-0 purple-color">{{getAnalysisMethodsService().getAnalysisMethodTitleByType(framework.analysis_type)}}</h4>
                            </div>
                          </div>
                          <div class="row scrollbar">
                            <div class="col-6" *ngFor="let sector of framework.sectors">
                              <p class="m-0">{{'generic.sector' | translate}}</p>
                              <div class="d-flex">
                                <h4 class="m-0 purple-color">{{getWorkingSectorService().getSectorValueByKey(sector.sector_key)}}</h4>
                                <mat-icon class="mt-0 ml-1 mat-icon-sector purple-color">{{getWorkingSectorService().getSectorIconByKey(sector.sector_key)}}</mat-icon>
                              </div>
                            </div>
                          </div>
                              <!-- <mat-slide-toggle style="pointer-events: none;" class="toggle py-2" [checked]="framework?.id == componentState.frameworkSelected?.id" ></mat-slide-toggle> -->
                        </mat-card>
                      </div>
                    </ng-container>
                  </ng-container>
                <ng-container *ngIf="componentState.frameworkFilteredList.length == 0">
                  <div class="alert alert-warning" *ngIf="componentState.allFrameworksCount === 0">
                    {{'components.decision-table.new-decision-table.select-framework.no-frameworks' | translate}}
                  </div>
                  <div  class="alert alert-warning" *ngIf="componentState.allFrameworksCount > 0">
                    {{'components.decision-table.new-decision-table.select-framework.no-filtered-frameworks' | translate}}
                  </div>
                </ng-container>
              </div>
              <div class="row">
                <ng-container >
                  <div class="col-4 box-button" [routerLink]="['/framework']">
                    <p class="my-0">
                      <i class="icon-Add mx-2"></i>
                      {{'components.decision-table.new-decision-table.select-framework.add-decision-scheme' | translate}}
                    </p>
                  </div>
                </ng-container>
              </div>

              <ng-template #noFrameworkFound class="row">
                <div class="col-12 text-center my-5 p-4">
                  <h3 class="color-green">{{'pages.frameworks.all-frameworks.component.no-framework' | translate}}</h3>
                  <p>{{'pages.frameworks.all-frameworks.component.search-sec-subsec-analyses' | translate}}</p>
                  <p class="new-framework-p cursor-pointer" [routerLink]="['/framework']">{{'pages.frameworks.all-frameworks.component.new-framework' | translate}}</p>
                </div>
              </ng-template>

              <div class="row d-flex justify-content-center">
                <div *ngIf="componentState.frameworkFilteredList.length > currentNumOfFrameworkShowed">
                  <button mat-button class="d-flex align-items-end" (click)="onClickShowMore()">{{'generic.show-more' | translate}} <mat-icon>keyboard_arrow_down</mat-icon></button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button (click)="backButtonOnClick($event)">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
    </button>
    <button type="submit" class="button-primary float-right" mat-button (click)="onSubmit(formSelectFramework)">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save' | translate}}
    </button>
  </div>
</form>
