<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container bg-header">
    <div class="row">
      <div class="col-6">
        <h1 mat-dialog-title class="text-white p-3 m-0">{{'pages.user.curriculum.component.licenses-certifi' | translate}}</h1>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <p (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
        <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
      </div>
    </div>
  </div>
  <div mat-dialog-content class="m-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-card class="box box-border mt-3">
            <mat-form-field class="col-12">
              <mat-label>{{'modals.modal-add-certificate.component.certification-name' | translate}}</mat-label>
              <input matInput type="text" name="certificate" [(ngModel)]="model.license" required placeholder="{{'modals.modal-add-certificate.placeholder.license' | translate}}">
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-12">
          <section class="my-3">
            <mat-checkbox name="date_end_selected" [ngModel]="model.date_end_selected" #box_date_end (change)="disableDateEnd()">
              {{'modals.modal-add-certificate.component.no-expiry-date' | translate}}
            </mat-checkbox>
          </section>
        </div>
        <div class="col-12 col-lg-4">
          <mat-card class="box box-border mt-3">
            <mat-form-field class="datepicker-custom-button" (click)="dp_start_date.open()">
              <mat-label>{{'generic.release-date' | translate}}</mat-label>
              <input required matInput [(ngModel)]="model.date_start"  [matDatepicker]="dp_start_date" name="start_date" class="color-default ng-trim-ignore" disabled>
              <mat-datepicker-toggle matSuffix [for]="dp_start_date">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #dp_start_date disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-12 col-lg-4">
          <mat-card *ngIf="!box_date_end.checked" id="date-end-card" class="box box-border mt-3">
            <mat-form-field class="datepicker-custom-button" (click)="dp_end_date.open()">
              <mat-label>{{'generic.exp-date' | translate}}</mat-label>
              <input required matInput [(ngModel)]="model.date_end"  [matDatepicker]="dp_end_date" name="end_date" class="color-default ng-trim-ignore" disabled>
              <mat-datepicker-toggle matSuffix [for]="dp_end_date">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #dp_end_date disabled="false"></mat-datepicker>
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-12">
          <mat-card class="box box-border mt-3">
            <mat-form-field class="col-12">
              <mat-label>{{'pages.user.curriculum.component.issuing-organization' | translate}}</mat-label>
              <input matInput type="text" name="organization" [(ngModel)]="model.organization" required placeholder="{{'modals.modal-add-certificate.placeholder.company' | translate}}">
            </mat-form-field>
          </mat-card>
        </div>
        <div class="col-12">
          <mat-card class="box box-border mt-3">
            <mat-form-field class="col-12">
              <mat-label>{{'generic.description' | translate}}</mat-label>
              <textarea class="description" matInput name="description" [(ngModel)]="model.description"  rows="5" name="purpose_description"  placeholder="{{'generic.description' | translate}}"></textarea>
            </mat-form-field>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="buttons-container container py-5 mb-3">
      <button class="button-primary float-left" mat-button (click)="closeDialogue()">
          <i class="icon-Backward-arrow-small"></i>
          {{'generic.buttons.back' | translate}}
      </button>
      <button class="button-primary float-right" [disabled]="loading" mat-button (click)="onSubmit(form)">
          <i class="icon-Forward-arrow-small"></i>
          {{'generic.buttons.confirm' | translate}}
      </button>
    </div>
  </div>
</form>
