import {Component, Input} from '@angular/core';
import {ModalAddAttachmentComponent} from "../../../modals/modal-add-attachment/modal-add-attachment.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss']
})
export class AttachmentsListComponent {
  @Input() attachments: any;
  @Input() buttonToAdd: boolean = false;
  @Input() editable: boolean = false;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  deleteAttachment(index) {
    if (index >= 0 && index < this.attachments.length) {
      this.attachments.splice(index, 1);
    }
  }

  public onClickAddNewEvidence(
    attachment_type: string
  ) {
    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        uploadUrl: 'upload/tmp-expert-proposal-file',
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {
      if (attachmentData) {
        if (["PUBMED", "FILE"].includes(attachmentData.attachmentType)) {
          let obj = null;
          if (attachmentData.attachmentType === "PUBMED") {
            obj = {
              type: 'PUBMED',
              PMID: attachmentData.attachmentObject.PMID,
              title: attachmentData.attachmentObject.ArticleTitle,
              link: attachmentData.attachmentObject.link,
              created_at: new Date().toISOString(),
              isNew: true,
            };
            this.attachments.push(obj);
          } else {
            attachmentData.attachmentObject?.forEach((file) => {
              obj = {
                ...file,
                type: 'FILE',
                created_at: new Date().toISOString(),
                isNew: true,
              };
              this.attachments.push(obj);
            })
          }
        }
      }
    });
  }
}
