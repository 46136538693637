<!-- Blocco 1 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{'pages.user.user-profile.component.registry' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <mat-card class="user-card col-8">
            <div class="row d-flex align-items-center">
              <div class="col-5">
                <mat-card-header class="mr-4">
                  <img *ngIf="getUserService().userDetails.avatar_link"
                       src={{getUserService().userDetails?.avatar_link}} class="img-fluid img-profile" alt="">
                  <img *ngIf="!getUserService().userDetails.avatar_link" src="assets/img/user-placeholder.jpg"
                       class="img-fluid img-profile" alt="">
                </mat-card-header>
              </div>
              <div class="col-7">
                <div class="col-12">
                  <mat-card-title>{{getUserService().userDetails?.first_name}} {{getUserService().userDetails?.last_name}}</mat-card-title>
                </div>

                <div class="col-12 mt-3">
                  <mat-card-subtitle>
                    <span>{{'generic.email' | translate}}:</span> {{getUserService().userInfo?.email}}
                  </mat-card-subtitle>
                </div>

                <div class="col-12 mt-3">
                  <mat-card-subtitle>
                    <span>{{'generic.phone' | translate}}: </span> {{getUserService().getPrimaryPhone()?.value}}
                  </mat-card-subtitle>
                </div>

                <mat-card-subtitle>
                  <div class="col-12 d-flex justify-content-start align-items-center m-0">
                    <div>{{'generic.language' | translate}}: <span>{{getUserService().userDetails?.language}}</span>
                    </div>
                    <div>
                      <button type="submit" *ngIf="currentLang == 'it'" class="d-flex align-items-center btn-lang"
                              (click)="useLanguage('en')">
                        &nbsp; <span
                        class="mr-2 underline">{{'components.header.component.change-language' | translate}}</span>
                        <i class="icon-flag-en"><span class="path1"></span><span class="path2"></span><span
                          class="path3"></span><span class="path4"></span><span class="path5"></span><span
                          class="path6"></span><span class="path7"></span><span class="path8"></span><span
                          class="path9"></span><span class="path10"></span><span class="path11"></span><span
                          class="path12"></span></i>
                      </button>
                      <button type="submit" *ngIf="currentLang == 'en'" class="d-flex align-items-center btn-lang"
                              (click)="useLanguage('it')">
                        &nbsp; <span
                        class="mr-2 underline">{{'components.header.component.change-language' | translate}}</span>
                        <i class="icon-flag-it"><span class="path1"></span><span class="path2"></span><span
                          class="path3"></span></i>
                      </button>
                    </div>
                  </div>
                </mat-card-subtitle>
                <div class="col-12 mt-3">
                  <mat-card-actions>
                    <!-- Aggiungi il pulsante per il cambio password -->
                    <button mat-button color="primary" [routerLink]="['./change-password']">
                      {{ 'generic.buttons.change-password' | translate }}
                    </button>
                  </mat-card-actions>
                </div>

                <div class="col-12 mt-3">
                  <mat-card-actions>
                    <!-- <mat-card-subtitle class="mat-card-role">{{'generic.roles.professional-role' | translate}}</mat-card-subtitle> -->
                    <mat-card-subtitle class="mat-card-organization cursor"
                                       (click)="openDialogYourOrganizations()">{{'pages.user.user-profile.component.your-organization' | translate}}</mat-card-subtitle>
                  </mat-card-actions>
                </div>
                <!-- <mat-card-subtitle class="mat-card-actions">
                  <div class="col-12">
                    <button mat-button>
                        <i class="icon-linkedin"></i>
                    </button>
                  </div>
                </mat-card-subtitle> -->
              </div>
            </div>
          </mat-card>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="userAcceptedDocuments.length > 0">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            {{'generic.privacy-documents' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-12">

            <mat-table [dataSource]="dataSource" matSort class="table-primary">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> {{ 'generic.name' | translate }} </mat-header-cell>
                <div class="row flex-column">
                  <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
                </div>
              </ng-container>

              <ng-container matColumnDef="version">
                <mat-header-cell *matHeaderCellDef> {{ 'generic.version' | translate }}</mat-header-cell>
                <div class="row flex-column">
                  <mat-cell style="justify-content: center;" *matCellDef="let row"> {{row.name}} </mat-cell>
                </div>
              </ng-container>

              <ng-container matColumnDef="publication_date">
                <mat-header-cell *matHeaderCellDef> {{ 'generic.publication-date' | translate }}
                </mat-header-cell>
                <mat-cell style="justify-content: center;"
                          *matCellDef="let row"> {{ row.created_at | date:'shortDate'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef
                                 class="text-center">{{ 'generic.action' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <div class="row flex-column justify-content-between">
                    <a [href]="row.link" target="_blank" class="my-2 pl-0 pr-0"
                       mat-button
                       color="primary"
                    >
                      {{ 'generic.view' | translate }}
                    </a>
                    <button class="my-2 ml-3 pl-0 pr-0" *ngIf="row.valid && row.to_accept"
                            mat-button
                            color="warn"
                            (click)="revokeDocument(row.id)">

                      {{'generic.withdrawl' | translate}}
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- Blocco 2 -->
<!-- <div class="container-fluid accordion-container">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'generic.info' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                  <div class="container">
                      <mat-form-field class="input-primary col-6 col-lg-4 my-4">
                          <input class="color-default" matInput placeholder="{{'pages.user.user-profile.component.info-trustability' | translate}}" value="Alta"
                              disabled>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-6 col-lg-4 my-4">
                          <input class="color-default" matInput placeholder="{{'pages.user.user-profile.component.info-n-tables' | translate}}"
                              value="5" disabled>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-6 col-lg-4 my-4">
                          <input class="color-default" matInput placeholder="{{'pages.user.user-profile.component.info-n-proposal' | translate}}" value="7"
                              disabled>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-6 col-lg-4 my-4">
                          <input class="color-default" matInput placeholder="{{'pages.user.user-profile.component.info-response-time' | translate}}"
                              value="1 giorno" disabled>
                      </mat-form-field>
                      <mat-form-field class="input-primary col-6 col-lg-4 my-4">
                          <input class="color-default" matInput placeholder="{{'pages.user.user-profile.component.info-n-tables-absent' | translate}}"
                              value="1" disabled>
                      </mat-form-field>
                  </div>
              </div>

          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div> -->

<!-- Blocco 3 -->
<!-- <div class="container-fluid accordion-container border-0">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">3</span>
                      {{'pages.user.user-profile.component.general-evaluation' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row mt-5 mb-3 sector-row">
                  <div class="col-6">
                      <div class="sector-container">
                          {{'generic.sector' | translate}}: <span>{{'generic.sectors.medicine.name' | translate}}</span>
                      </div>
                      <div class="stars-container">
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-empty"></i>
                          <i class="icon-star-empty"></i>
                      </div>
                  </div>
                  <div class="col-6 text-right" appearance="fill">
                      <button mat-flat-button color="primary">{{'pages.user.user-profile.component.see-statistics' | translate}}</button>
                  </div>
              </div>

              <div class="row mt-5 mb-3 sector-row">
                  <div class="col-6">
                      <div class="sector-container">
                        {{'generic.sector' | translate}}: <span>{{'generic.sectors.medicine.name' | translate}}</span>
                      </div>
                      <div class="stars-container">
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-empty"></i>
                          <i class="icon-star-empty"></i>
                      </div>
                  </div>
                  <div class="col-6 text-right" appearance="fill">
                      <button mat-flat-button color="primary">{{'pages.user.user-profile.component.see-statistics' | translate}}</button>
                  </div>
              </div>

              <div class="row mt-5 mb-3 sector-row">
                  <div class="col-6">
                      <div class="sector-container">
                        {{'generic.sector' | translate}}: <span>{{'generic.sectors.medicine.name' | translate}}</span>
                      </div>
                      <div class="stars-container">
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-full"></i>
                          <i class="icon-star-empty"></i>
                          <i class="icon-star-empty"></i>
                      </div>
                  </div>
                  <div class="col-6 text-right" appearance="fill">
                      <button mat-flat-button color="primary">{{'pages.user.user-profile.component.see-statistics' | translate}}</button>
                  </div>
              </div>

          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div> -->

<!-- Buttons -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['../']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.home' | translate}}
  </button>
</div>
