<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate [formGroup]="editorForm">

  <!-- Blocco 0 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index"></span>
              {{'pages.frameworks.component.decisional-schema-target' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 box flex-column my-0 pb-0 pt-3 px-3">
                <mat-label>{{'components.decision-table.new-decision-table.compile-problem-statement.problem-statement-title' | translate}}</mat-label> <br>
                <p>{{'infobox.problemstatement.description' | translate}}</p> <br>
                <p>{{'infobox.problemstatement.section' | translate}}</p> <br>
                <p>{{'infobox.problemstatement.one' | translate}}</p> <br>
                <p>{{'infobox.problemstatement.two' | translate}}</p> <br>
                <p>{{'infobox.problemstatement.three' | translate}}</p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Blocco 1 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              {{'components.decision-table.new-decision-table.compile-problem-statement.definition-thesis' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 box flex-column my-0 pb-0 pt-3 px-3">
              <app-rich-text-editor
                [formGroup]="editorForm"
                formControlName="thesis"
                [(ngModel)]="model.problem_statement_tesi"
                [name]="'thesis'"
                placeholder="{{'components.decision-table.new-decision-table.compile-problem-statement.definition-thesis' | translate}}"
              >
              </app-rich-text-editor>

<!--              <mat-form-field>-->
<!--                <mat-label>{{'generic.thesis' | translate}}</mat-label>-->
<!--                <textarea class="description" matInput rows="3" name="problem_statement_tesi" [(ngModel)]="model.thesis" placeholder="{{'components.decision-table.new-decision-table.compile-problem-statement.definition-thesis' | translate}}" required></textarea>-->
<!--              </mat-form-field>-->
            </div>
          </div>
          <div class="row pb-0 my-3">
            <div class="col-12 mt-3">
              <mat-label
                class="label-box font-weight-bold"
                *ngIf="model?.problem_statement_attachments?.length > 0"
              >
                {{
                "components.proposals.new-proposal.loaded-evidences"
                  | translate
                }}
              </mat-label>
              <div
                class="attachment-row"
                *ngFor="let attachment of model?.problem_statement_attachments"
              >
                          <span class="name">
                            <i class="icon-pdf"></i>
                            <a [href]="attachment.link" target="_blank">{{
                              attachment.title
                              }}</a>
                          </span>
                <span class="size float-right" (click)="removeAttachment(attachment)">
                            {{ "generic.buttons.delete" | translate }}
                  <i class="icon-cestino main-color"></i>
                          </span>
              </div>
              <div
                class="col-12 links-container"
              >
                <button
                  mat-button
                  color="primary"
                  [matMenuTriggerFor]="menuAttachment"
                >
                  <i class="icon-upload mr-2"></i>
                  {{
                  "components.proposals.new-proposal.add-new-evidences"
                    | translate
                  }}
                </button>
                <mat-menu #menuAttachment="matMenu">
                  <button
                    mat-menu-item
                    (click)="
                                addAttachment('PUBMED')
                              "
                  >
                    <mat-icon aria-hidden="false"
                    >file_upload
                    </mat-icon
                    >
                    Pubmed
                  </button>
                  <button
                    mat-menu-item
                    (click)="addAttachment('FILE')"
                  >
                    <mat-icon aria-hidden="false"
                    >file_upload
                    </mat-icon
                    >
                    File
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Blocco 2 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              {{'generic.context' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 box flex-column my-0 pb-0 pt-3 px-3">
              <app-rich-text-editor
                [formGroup]="editorForm"
                formControlName="context"
                [(ngModel)]="model.problem_statement_contesto"
                [name]="'context'"
                placeholder="{{'components.decision-table.new-decision-table.compile-problem-statement.define-context' | translate}}"
              >
              </app-rich-text-editor>
<!--              <mat-form-field>-->
<!--                <mat-label>{{'generic.context' | translate}}</mat-label>-->
<!--                <textarea class="description" matInput rows="3" name="problem_statement_contesto" [(ngModel)]="model.context" placeholder="{{'components.decision-table.new-decision-table.compile-problem-statement.define-context' | translate}}" required></textarea>-->
<!--              </mat-form-field>-->
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 3 -->
  <!-- <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded style="overflow: visible;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">3</span>
              {{'components.decision-table.new-decision-table.compile-problem-statement.to-be-consider' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container px-0">
            <div class="row">
              <ng-container *ngFor="let additional_context_data of model.additional_context_data; let i = index">
                <div class="col-12 box">
                  <div class="col-auto box-index">
                    {{i + 1}}
                  </div>
                  <mat-form-field class="col-10">
                    <mat-label>{{'generic.description' | translate}}</mat-label>
                    <textarea class="description" matInput rows="3" name="additional_data_context{{i}}" [(ngModel)]="additional_context_data.description" placeholder="{{'generic.description' | translate}}" required></textarea>
                  </mat-form-field>
                  <div class="icons-container">
                    <i class="icon-cestino grey-color float-right" (click)="removeAdditionalContextData(i)"></i>
                  </div>
                </div>

                <div class="col-12 links-container">
                  <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                    <i class="icon-upload mr-2"></i>
                    {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}}
                  </button>
                  <mat-menu #menuAttachment="matMenu">
                    <button mat-menu-item (click)="onClickAddNewEvidence(i, criterio, 'PUBMED')">
                      <mat-icon aria-hidden="false">file_upload</mat-icon> Pubmed
                    </button>
                    <button mat-menu-item (click)="onClickAddNewEvidence(i, criterio, 'FILE')">
                      <mat-icon aria-hidden="false">file_upload</mat-icon> File
                    </button>
                  </mat-menu>
                </div>

              </ng-container>
              <button type="button" class="col-12 big-button" mat-raised-button (click)="addAdditionalContextData()">
                <i class="icon-big_add"></i>
                {{'components.decision-table.new-decision-table.compile-problem-statement.add-more-context-data' | translate}}
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div> -->

  <div class="buttons-container container py-4">
    <button type="button" class="button-primary float-left" mat-button  (click)="backButtonOnClick($event)">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
    </button>
    <button type="submit" [disabled]="loading" class="button-primary float-right" mat-button (click)="onSubmit()">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save' | translate}}
    </button>
  </div>
</form>
