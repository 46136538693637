import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SurveyService} from '../../../services/surveys.service';
import {TranslateService} from '@ngx-translate/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {AlertService} from '../../../services/general/alert.service';
import {ToastService} from '../../../services/general/toast.service';

@Component({
  selector: 'app-survey-all-responses',
  templateUrl: './survey-all-responses.component.html',
  styleUrls: ['./survey-all-responses.component.scss']
})
export class SurveyAllResponsesComponent {

  constructor(
    public surveyService: SurveyService,
    public translateService: TranslateService,
    private loaderService: ScriptLoaderService,
    private toast: ToastService
  ) {
  }

  @Input() survey: any;

  tabIndex = 0;
  loading = false;
  showPrivateInfo = false;

  onChangeStatus() {
    this.loading = true;
    const model = {
      status: this.survey?.status
    };
    this.surveyService.changeSurveyStatus(this.survey?.id, model, (result) => {
      if (this.survey?.status === 'EVALUATING') {
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('modals.modal-survey-details.resposes-enabled')
        );
      }
      if (this.survey?.status === 'CLOSED') {
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('modals.modal-survey-details.resposes-blocked')
        );
      }
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  toggleAnonymousData() {
    this.showPrivateInfo = !this.showPrivateInfo;
  }
}
