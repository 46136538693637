<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{'generic.access-level' | translate}} {{ 'generic.user' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button>
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<!-- TODO_TRADUZIONI: di tutto il componente -->
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<mat-dialog-content>
<div class="container-fluid accordion-container" *ngIf="!loading">
    <mat-accordion class="accordion" hideToggle>
      <mat-expansion-panel expanded disabled>
        <ng-container>
          <div class="container">
            <div class="row">
              <div class="col-12 px-0 mb-0">
                <div class="row justify-content-around mb-4">
                  <mat-radio-group [(ngModel)]="user_role.name" [disabled]="userService.userDetails.id === organization_user.user_id" (ngModelChange)="updatePermissions()">
                    <mat-radio-button *ngFor="let role of systemRoles" [value]="role.name"><div>{{'role.'+role.name+'.title' | translate}}</div></mat-radio-button>
                  </mat-radio-group>
                </div>

                <mat-divider></mat-divider>

                <div class="row align-items-center justify-content-center mt-2 mb-4">
                  <div class="col-2 text-center">
                    <fa-icon style="font-size: 2rem;" [icon]="getIconType()"></fa-icon>
                  </div>
                  <div class="col-10">
                    <div>{{'role.'+user_role.name+'.title' | translate}}</div>
                    <div>{{'role.'+user_role.name+'.description' | translate}}</div>
                  </div>
                </div>

                <mat-divider></mat-divider>

                <ng-container *ngFor="let permission of currentPermissionsData">
                  <div class="row align-items-center justify-content-center m-0" *ngIf="permission.hidden_to_user == false || hidePermissions == false">
                    <div class="col-6">
                      <div class="row">{{'permission.'+permission.name | translate}}</div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col text-right">
                          <button (click)="permissionOnClick(permission)" mat-button [ngClass]="getClassButton(permission)" [disabled]="permission.hidden_to_user == true" color="primary">&nbsp;<fa-icon *ngIf="permission.owned" [icon]="faCheck"></fa-icon></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="d-flex justify-content-end my-3" *ngIf="currentPermissionsData.length > 0">
                  <button *ngIf="hidePermissions == true" mat-button class="d-flex align-items-end" (click)="showAll()">{{'generic.show-more' | translate}} <mat-icon>keyboard_arrow_down</mat-icon></button>
                  <button *ngIf="hidePermissions == false" mat-button class="d-flex align-items-end" (click)="showAll()">{{'generic.show-less' | translate}} <mat-icon>keyboard_arrow_up</mat-icon></button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </mat-expansion-panel>
    </mat-accordion>
</div>
</mat-dialog-content>

<div class="container buttons-container py-2 mb-2" style="min-height: 3.5rem;" *ngIf="!loading && !(userService.userDetails.id === organization_user.user_id)">
  <button type="button" class="button-primary float-right" mat-button [disabled]="loading" (click)="updatePermissions()"><i class="icon-Forward-arrow-small"></i><span>{{'generic.buttons.update' | translate}}</span></button>
</div>
