<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">{{attachmentData.title | translate}}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <div *ngIf="attachmentData.attachmentType == 'PUBMED'">
    <app-pubmed-attachment (attachElement)="attachElement($event)" ></app-pubmed-attachment>
  </div>
  <div *ngIf="attachmentData.attachmentType == 'FILE'">
    <app-file-attachment [data]="attachmentData" (attachElement)="attachElement($event)" ></app-file-attachment>
  </div>
</div>
