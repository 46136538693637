import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileViewerService {

  constructor(private backend:BackendService) {}


  /**
   * Recupera tutti i ruoli del sistema
   * @param callbackSuccess
   * @param callbackError
   */
  public getFileStream(document_id: number,
    callbackSuccess: any = () => {},
    callbackError: any = () => {}
  ) {
    const url = 'stream-document';

    let queryParams = new HttpParams();
    queryParams = queryParams.append("id", document_id);
    // console.log('getFileStream inizio');
    // this.backend.get(url).subscribe(
    this.backend.getBlob(url, queryParams).subscribe(
      (result: any) => {
        // console.log('getFileStream result', result.body);
        // console.log('getFileStream result', result.headers);
        callbackSuccess(result);
      },
      (error) => {
        console.log('error:', error);
        callbackError(error);
      }
    );
  }

  /**
   * Recupera le informazioni relative alla dashboard per una sessione di valutazione in particolare
   * @param decisiontable_id
   * @param success_callback
   * @param error_callback
   */
  // public getDocument(
  //   document_id: string,
  //   success_callback = (data: any) => {},
  //   error_callback = (error: any) => {}
  // ) {
  //   this.backend
  //     .get(
  //       `get-document?id=${document_id}`
  //     )
  //     .subscribe(
  //       (result) => {
  //         success_callback(result);
  //       },
  //       (error) => {
  //         console.log(error);
  //         this.backend.showErrors(error);
  //         error_callback(error);
  //       }
  //     );
  // }

}
