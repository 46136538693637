<section class="content">
  <div class="ng-star-inserted">
    <div class="container-fluid accordion-container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              {{
              "components.file-viewer"
              | translate
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row justify-content-center">

            <ngx-doc-viewer *ngIf="useNgxDocViewer" [url]="documentContent" [viewer]="viewer" disableContent="none"
              style="width: 60%; height: 50rem;" class=""></ngx-doc-viewer>

            <div *ngIf="!useNgxDocViewer">
              <iframe [src]="urlSafe" style="width: 100rem; height: 50rem"></iframe>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</section>
