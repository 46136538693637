// loading.component.ts
import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnChanges {
  @Input() loading: boolean = false;
  @Output() isGeneratingPdfChange = new EventEmitter<boolean>();


  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isGeneratingPdf) {
      this.loading = changes.isGeneratingPdf.currentValue;
    }
  }
}
