<div class="container-fluid container-custom d-flex align-items-center">
  <div class="container">
    <div class="row text-center">
      <div class="col-12 mt-5">
        <img src="../../assets/img/error404.png" class="img-fluid" alt="">
      </div>
      <div class="col-12 text-color">
        <h1 class="my-5">404 OOPS!</h1>
        <p class="my-5">Ci dispiace, la pagina che stavi cercando non è stata trovata...</p>
        <button [routerLink]="['/home']" routerLinkActive="router-link-active" class="btn-custom border-0 roundend px-5 py-2 custom-shadow">TORNA ALLA HOMEPAGE</button>
      </div>
    </div>
  </div>
</div>
