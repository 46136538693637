import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ScriptLoaderService} from 'angular-google-charts';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {ModalReportAttachmentComponent} from 'src/app/modals/modal-report-attachment/modal-report-attachment.component';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {ResponseTypesService} from 'src/app/services/config/response-types.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {DecisionService} from 'src/app/services/decision.service';
import {ToastService} from 'src/app/services/general/toast.service';
import { environment } from '../../../../environments/environment';

import * as _ from 'lodash';

@Component({
  selector: 'app-new-proposal',
  templateUrl: './new-proposal.component.html',
  styleUrls: ['./new-proposal.component.scss'],
})
export class NewProposalComponent implements OnInit {
  @Input() proposal: any;
  @Input() decision_table: any;
  @Input() current_organization: any;
  @Input() framework: any;
  @Output() proposalSavedEvent = new EventEmitter();
  @Output() deleteProposalEvent = new EventEmitter();

  decisionTableTargets: any;

  loading = false;
  loadingComponent = false;
  /** Il model che contiene tutti i dati visualizzati */
  currentProposal: any = {
    framework: {
      primary_target: { evaluation_criteria: null, title: '', type: 'primary' },
      secondary_targets: [],
      criteria: [],
    },
  };

  showPrivateInfo = false;
  isGeneratingPdf = false;

  @ViewChild('pdfreport') pdfReport;

  constructor(
    private toast: ToastService,
    private decisionService: DecisionService,
    private loaderService: ScriptLoaderService,
    private criteriaService: CriteriaService,
    private unitService: UnitsService,
    private suggestionService: SuggestionService,
    private responsesTypeService: ResponseTypesService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private analysisService: AnalysisMethodsService
  ) {}

  ngOnInit(): void {
    if (!this.proposal || !this.decision_table || !this.framework) {
      //  TODO_TRADUZIONI
      this.toast.error(
        'Revisione proposta',
        this.translateService.instant(
          'components.proposals.new-proposal.error-proposal-revise'
        )
      );
      return;
    }

    //  inizializzo i componenti
    if (this.proposal && this.decision_table && this.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
      this.getProposalData();
    }
  }

  init() {
    this.prepareTargets();
    this.prepareAnswers();
    this.prepareAttachment();
    this.prepareAttachmentEvents();
  }

  async savePDF() {
    await this.downloadPdf();
  }

  // Funzione per ottenere l'HTML
  getHtmlContent() {
    const contentElement = document.getElementById('to-print');
    return contentElement.outerHTML;
  }

  // Funzione per ottenere il CSS
  getCssContent(): string {
    let styles = '';
    // Converti StyleSheetList in array
    Array.from(document.styleSheets).forEach((styleSheet) => {
      try {
        Array.from(styleSheet.cssRules).forEach((rule) => {
          styles += rule.cssText;
        });
      } catch (e) {
        console.warn(
          'Access to stylesheet %s is denied. Ignoring it.',
          styleSheet.href
        );
      }
    });
    return styles;
  }

  prepareData() {
    const htmlContent = this.getHtmlContent();
    const cssContent = this.getCssContent();
    return {
      html: htmlContent,
      css: cssContent,
    };
  }

  downloadPdf() {
    try {
      // Mostra un indicatore di caricamento
      this.loading = true;

      this.isGeneratingPdf = true;

      // Chiama il metodo per generare e scaricare il PDF
      this.decisionService.generatePdfReport(
        // this.decision_table.id,
        this.currentProposal.id,
        (response: Blob) => {
          // this.isGeneratingPdf = false;
          const url = URL.createObjectURL(response['body']);
          window.open(url, '_blank');
          this.isGeneratingPdf = false;
        },
        (error: any) => {
          // Gestisci gli errori qui
          console.error('Errore durante la generazione del PDF:', error);
        }
      );
    } catch (error) {
      console.error('Errore:', error);
    } finally {
      // Nascondi l'indicatore di caricamento
      this.loading = false;
    }
  }

  /** Recupera le informazioni della proposal */
  getProposalData() {
    this.loading = true;

    //  recupero la proposta corrente con le risposte, il team e i calcoli deli valori di certezza e contraddizione
    this.decisionService.getProposalResponse(
      this.proposal.id,
      (result) => {
        this.currentProposal = result;
        this.currentProposal.fromQuestionnaire =
          this.decision_table.fromQuestionnaire;

        //  recupero i target per il tavolo decisionale corrente
        this.decisionService.getTableTargets(
          this.decision_table.id,
          (result) => {
            this.decisionTableTargets = result;

            if (!this.proposal.evaluation_session_id) {
              this.init();
              this.loadingComponent = true;
              this.loading = false;
              return;
            }

            //  recupero le informazioni dei risultati di questo tavolo se ci sono
            this.decisionService.getDecisionTableResult(
              this.decision_table.id,
              this.proposal.evaluation_session_id,
              (tableResult) => {
                this.currentProposal.decisionTableResult = tableResult;
                this.showPrivateInfo = !(
                  this.currentProposal.anonymous_survey_enabled === false
                );
                this.init();
                this.loadingComponent = true;
                this.loading = false;
              }
            );
          },
          () => (this.loading = false)
        );
      },
      () => (this.loading = false)
    );
  }

  /**
   * Prepara gli obiettivi sulla base del framework selezionato
   */
  prepareTargets() {
    this.currentProposal.primary_target = {};
    this.currentProposal.secondary_targets = [];

    //  Primary Target
    this.currentProposal.primary_target = this.decisionTableTargets.find(
      (x) => x.type == 'primary'
    );

    //  Secondary Target
    this.currentProposal.secondary_targets = this.decisionTableTargets.filter(
      (x) => x.type == 'secondary'
    );
  }

  /**
   * Prepara le risposte filtrando solo quelle che sono state completate dagli esperti
   */
  prepareAnswers() {
    this.currentProposal.criteria.forEach((criterio) => {
      const responseFiltered: any = [];
      criterio.response.forEach((response) => {
        if (
          response?.team_member?.response_stamps &&
          response?.team_member?.response_stamps.completed == true
        ) {
          responseFiltered.push(response);
        }
      });
      criterio.response = responseFiltered;
    });
  }

  /**
   * Setto gli allegati nei criteri
   */
  prepareAttachment() {
    this.currentProposal?.criteria?.forEach((criterio) => {
      criterio.attachment = [];
      criterio?.attachment_pubmed?.forEach((pubmed) => {
        criterio?.attachment.push(pubmed);
      });
      criterio?.attachment_files?.forEach((file) => {
        criterio?.attachment?.push(file);
      });
    });

    //  sovrascrivo per scatenare l'evento onchange di angual
    this.currentProposal = JSON.parse(JSON.stringify(this.currentProposal));
  }

  /**
   * Setto gli eventi sugli allegati nei criteri per ogni membro che ha partecipato
   */
  prepareAttachmentEvents() {
    this.currentProposal?.criteria?.forEach((criterio) => {
      criterio?.response?.forEach((response) => {
        response.attachment_events = [];
        response.attachment_events = criterio?.attachment_events?.filter(
          (x) => x.team_member_id == response.team_member_id
        );
      });
    });
  }

  answered() {
    if (
      this.currentProposal &&
      this.currentProposal.criteria &&
      this.currentProposal.criteria.length > 0
    ) {
      for (const criterion of this.currentProposal.criteria) {
        if (criterion.response && criterion.response.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Aggiunta di un nuovo
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(event: any) {
    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: event.attachment_type,
        criterio: event.criterio,
        uploadUrl: 'upload/tmp-proposal-file',
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {
      if (attachmentData) {
        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }

  /**
   * Setto un allegato pubmed
   * @param attachmentPubmed
   */
  addPubmedAttachment(attachmentPubmed) {
    for (const proposalCriteria of this.currentProposal?.criteria) {
      if (proposalCriteria?.id == attachmentPubmed?.criterio?.id) {
        if (!proposalCriteria.attachment_pubmed) {
          proposalCriteria.attachment_pubmed = [];
        }

        proposalCriteria?.attachment_pubmed?.push({
          type: 'PUBMED',
          PMID: attachmentPubmed?.attachmentObject?.PMID,
          title: attachmentPubmed?.attachmentObject?.ArticleTitle,
          link: attachmentPubmed?.attachmentObject?.link,
        });

        break;
      }
    }

    this.prepareAttachment();
  }

  /**
   * Setto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {
    for (const fileAttached of attachmentFile.attachmentObject) {
      for (const proposalCriteria of this.currentProposal.criteria) {
        if (proposalCriteria.id == attachmentFile.criterio.id) {
          if (!proposalCriteria.attachment_files) {
            proposalCriteria.attachment_files = [];
          }

          proposalCriteria.attachment_files.push({
            id: fileAttached.id,
            type: 'FILE',
            ext: fileAttached.ext,
            size: fileAttached.size,
            title: fileAttached.title,
            path: fileAttached.path,
            link: fileAttached.link,
          });

          break;
        }
      }
    }

    this.prepareAttachment();
  }

  /**
   * Evento di apertura della lista di allegati cliccati dall'esperto
   */
  onClickAttachmentEventList(event: any) {
    const dialogRef = this.dialog.open(ModalReportAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        proposal_criteria_id: event.criteria_id,
        team_member_id: event.team_member_id,
      },
    });

    dialogRef.afterClosed().subscribe((attachmentData) => {});
  }

  public deleteProposal(event: any) {
    this.deleteProposalEvent.emit(event);
  }

  saveAndBack() {
    this.proposalSavedEvent.emit();
  }

  saveAndAdd(proposal: any) {
    this.proposalSavedEvent.emit(proposal);
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  getUnitsService() {
    return this.unitService;
  }

  getSuggestionService() {
    return this.suggestionService;
  }

  getResponsesTypeService() {
    return this.responsesTypeService;
  }

  getAnalysisService() {
    return this.analysisService;
  }

  toggleAnonymousProposalInfo() {
    this.showPrivateInfo = !this.showPrivateInfo;
  }

  isEnel() {
    return  this.current_organization?.name.toLowerCase() === ('ENEL').toLowerCase();
  }
}
