import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  private _units: any = [
    { type: "point" , key: "POINT", value: 'Point', symbol: 'point'},
    { type: "score" , key: "SCORE", value: 'Score', symbol: 'score'},
    { type: "currency", key: "EUR", value: 'Euro', symbol: '€'},
    { type: "currency", key: "USD", value: 'US Dollar', symbol: '$'},
    { type: "currency", key: "AUD", value: 'Australian Dollar', symbol: '$'},
    { type: "currency", key: "GBP", value: 'British Pound Sterling', symbol: '£'},
    { type: "financialflows", key: "EURO/HOUR", value: 'Euro / Hour', symbol: '€/Hour'},
    { type: "financialflows", key: "EURO/MINUTE", value: 'Euro / Minute', symbol: '€/Minute'},
    { type: "financialflows", key: "EURO/DAY", value: 'Euro / Day', symbol: '€/Day'},
    { type: "financialflows", key: "EURO/WEEK", value: 'Euro / Week', symbol: '€/Week'},
    { type: "financialflows", key: "EURO/MONTH", value: 'Euro / Month', symbol: '€/Month'},
    { type: "financialflows", key: "EURO/YEAR", value: 'Euro / Year', symbol: '€/Year'},
    { type: "financialflows", key: "USD/HOUR", value: 'US Dollar / Hour', symbol: '$/Hour'},
    { type: "financialflows", key: "USD/MINUTE", value: 'US Dollar / Minute', symbol: '$/Minute'},
    { type: "financialflows", key: "USD/DAY", value: 'US Dollar / Day', symbol: '$/Day'},
    { type: "financialflows", key: "USD/WEEK", value: 'US Dollar / Week', symbol: '$/Week'},
    { type: "financialflows", key: "USD/MONTH", value: 'US Dollar / Month', symbol: '$/Month'},
    { type: "financialflows", key: "USD/YEAR", value: 'US Dollar / Year', symbol: '$/Year'},
    { type: "financialflows", key: "AUD/HOUR", value: 'Australian Dollar / Hour', symbol: '$/Hour'},
    { type: "financialflows", key: "AUD/MINUTE", value: 'Australian Dollar / Minute', symbol: '$/Minute'},
    { type: "financialflows", key: "AUD/DAY", value: 'Australian Dollar / Day', symbol: '$/Day'},
    { type: "financialflows", key: "AUD/WEEK", value: 'Australian Dollar / Week', symbol: '$/Week'},
    { type: "financialflows", key: "AUD/MONTH", value: 'Australian Dollar / Month', symbol: '$/Month'},
    { type: "financialflows", key: "AUD/YEAR", value: 'Australian Dollar / Year', symbol: '$/Year'},
    { type: "financialflows", key: "GBP/HOUR", value: 'British Pound / Hour', symbol: '£/Hour'},
    { type: "financialflows", key: "GBP/MINUTE", value: 'British Pound / Minute', symbol: '£/Minute'},
    { type: "financialflows", key: "GBP/DAY", value: 'British Pound / Day', symbol: '£/Day'},
    { type: "financialflows", key: "GBP/WEEK", value: 'British Pound / Week', symbol: '£/Week'},
    { type: "financialflows", key: "GBP/MONTH", value: 'British Pound / Month', symbol: '£/Month'},
    { type: "financialflows", key: "GBP/YEAR", value: 'British Pound / Year', symbol: '£/Year'},
    { type: "percentage", key: "PERCENTAGE", value: 'Percentage', symbol: '%'},
    { type: "percentageovertime", key: "PERCENTAGE/YEAR", value: 'Percentage / Year', symbol: '%/Year'},
    { type: "percentageovertime", key: "PERCENTAGE/MONTH", value: 'Percentage / Month', symbol: '%/Month'},
    { type: "percentageovertime", key: "PERCENTAGE/WEEK", value: 'Percentage / Week', symbol: '%/Week'},
    { type: "percentageovertime", key: "PERCENTAGE/DAY", value: 'Percentage / Day', symbol: '%/Day'},
    { type: "percentageovertime", key: "PERCENTAGE/HOUR", value: 'Percentage / Hour', symbol: '%/Hour'},
    { type: "percentageovertime", key: "PERCENTAGE/MINUTE", value: 'Percentage / Minute', symbol: '%/Minute'},
    { type: "duration", key: "YEAR", value: 'Year', symbol: 'Year'},
    { type: "duration", key: "MONTH", value: 'Month', symbol: 'Month'},
    { type: "duration", key: "WEEK", value: 'Week', symbol: 'Week'},
    { type: "duration", key: "DAY", value: 'Day', symbol: 'Day'},
    { type: "duration", key: "HOUR", value: 'Hour', symbol: 'Hour'},
    { type: "duration", key: "MINUTE", value: 'Minute', symbol: 'Minute'},
    { type: "manhour", key: "MANHOUR/YEAR", value: 'Man Hour / Year', symbol: 'ManHour/Year'},
    { type: "manhour", key: "MANHOUR/MONTH", value: 'Man Hour / Month', symbol: 'ManHour/Month'},
    { type: "manhour", key: "MANHOUR/WEEK", value: 'Man Hour / Week', symbol: 'ManHour/Week'},
    { type: "manhour", key: "MANHOUR/DAY", value: 'Man Hour / Day', symbol: 'ManHour/Day'},
    { type: "manhour", key: "MANHOUR/HOUR", value: 'Man Hour / Hour', symbol: 'ManHour/Hour'},
    { type: "quantity", key: "QUANTITY", value: 'Quantity', symbol: 'Quantity'},
    { type: "quantity", key: "QUANTITY/YEAR", value: 'Quantity / Year', symbol: 'Quantity/Year'},
    { type: "quantity", key: "QUANTITY/MONTH", value: 'Quantity / Month', symbol: 'Quantity/Month'},
    { type: "quantity", key: "QUANTITY/DAY", value: 'Quantity / Day', symbol: 'Quantity/Day'},
    { type: "quantity", key: "QUANTITY/HOUR", value: 'Quantity / Hour', symbol: 'Quantity/Hour'},
    { type: "quantity", key: "QUANTITY/MINUTE", value: 'Quantity / Minute', symbol: 'Quantity/Minute'},

    { type: "distance", key: "DISTANCE/M", value: 'Meter', symbol: 'm'},
    { type: "distance", key: "DISTANCE/CM", value: 'Centimeter', symbol: 'cm'},
    { type: "distance", key: "DISTANCE/KM", value: 'Kilometer', symbol: 'km'},

    { type: "weight", key: "g", value: 'Gram', symbol: 'g'},
    { type: "weight", key: "kg", value: 'kilogram', symbol: 'kg'}
  ];

  constructor() { }


  getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }


  get units() {
    return this._units.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllUnitsForSelect() {
    return this._units.slice(0);
  }


  /**
   *
   * @param key Restituisce il valore di una unità di misura se la trova
   */
  public getUnitValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._units, key);
  }

}
