<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="userFullName">{{
      "generic.loaded_evidences_of"
        | translate
      }} {{ userFullName }}</span>
    <span *ngIf="!userFullName">{{
        "components.decision-table.new-decision-table.compile-proposal.loaded-evidences"
          | translate
      }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div>
  <div class="container-fluid">
    <div class="col-12">
      <div class="box flex-column border-0" style="background-color: #F1F2F2">
        <table *ngIf="attachments.length > 0" mat-table [dataSource]="attachments" class="mat-elevation-z8">

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.title"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element">
              <a [href]="element.link" target="_blank">{{element.title}}</a></td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.type"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
          </ng-container>

          <ng-container matColumnDef="loaded_at">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.upload_date"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.created_at | formatdate | date : 'short'}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
