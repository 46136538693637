import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-formatted-text-preview',
  template: `
    <div class="formatted-text-preview" [innerHTML]="safeHtml"></div>
  `,
  styles: [`
    .formatted-text-preview {
      width: 100%;
    }
  `]
})
export class FormattedTextPreviewComponent implements OnChanges {
  @Input() content: string = '';
  safeHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.content);
    }
  }
}
