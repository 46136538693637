<div class="container">
  <mat-tab-group #tabGroup mat-stretch-tabs mat-align-tabs="center">
    <mat-tab [label]="'generic.details' | translate">
      <div class="mt-5">
        <app-survey-details
          *ngIf="survey_id"
          [surveyId]="survey_id"
          (canceled)="backToProject($event)"
          (surveyChanged)="onSurveyChanged($event)"
        />
      </div>
    </mat-tab>
    <mat-tab [label]="'components.responses.responses' | translate" *ngIf="survey && !survey?.is_draft">
      <app-survey-all-responses [survey]="survey" #allResponsesComponent/>
    </mat-tab>
    <mat-tab [label]="'generic.team' | translate" *ngIf="survey?.visibility_type === 'PRIVATE' && !survey?.is_draft">
      <div class="mt-5">
        <app-survey-team [members]="survey?.members" [surveyId]="survey_id"/>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
