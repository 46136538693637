<form #form="ngForm" (submit)="onSubmit(form)" novalidate>

  <div class="container-fluid">
    <div class="container px-0" >
      <div>
        <ng-container>
          <div class="row">
            <div class="col-10">
              <mat-form-field class="col-12 input-big" appearance="fill">
                <input matInput name="queryString" placeholder="{{'generic.buttons.search' | translate}}" [(ngModel)]="modelSearch.queryString" required>
              </mat-form-field>
            </div>
            <div class="col-2">
              <button mat-flat-button color="primary" type="submit" [disabled]="loading" (click)="onSubmit(form)">{{'generic.buttons.search' | translate}}</button>
            </div>
          </div>

          <div class="row" *ngIf="loading">
            <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
          </div>

          <div class="row" *ngIf="pubmedResults.articles">
            <mat-table [dataSource]="pubmedResults.articles" style="width: 100%;" class="table-primary">

              <ng-container matColumnDef="ArticleTitle">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 70% !important">{{'components.attachment.pubmed-attachment.article-title' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: 0 0 70% !important"><a target="_blank" [href]="element.link">{{element.ArticleTitle}}</a></mat-cell>
              </ng-container>
              <ng-container matColumnDef="Authors">
                <mat-header-cell *matHeaderCellDef>{{'components.attachment.pubmed-attachment.authors' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-icon aria-hidden="false" matTooltip="{{element.Authors}}">groups</mat-icon>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="PubYear">
                <mat-header-cell *matHeaderCellDef>{{'components.attachment.pubmed-attachment.pubyear' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.PubYear}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"><button mat-button color="primary" (click)="addAttachment(element)" ><small>{{'generic.buttons.add' | translate}}</small> <mat-icon>add</mat-icon></button></mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>

          <mat-paginator *ngIf="pubmedResults.articles"
            [length]="pubmedResults.total_items"
            [pageSize]="10"
            (page)="pageChanged($event)"
          >
          </mat-paginator>

        </ng-container>
      </div>
    </div>
  </div>
</form>
