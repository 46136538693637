import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../services/user.service";

@Component({
  selector: 'app-multiobjective-responses-explain',
  templateUrl: './multiobjective-responses-explain.component.html',
  styleUrls: ['./multiobjective-responses-explain.component.scss']
})
export class MultiobjectiveResponsesExplainComponent implements OnInit {


  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId;

  loading: boolean = false;

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table:any;
  organization:any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];
  skipInstructions = false;
  evaluations: any = [];

  numOfCriteriaQuantitative: number = 0;
  numOfCriteriaQualitative: number = 0;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private userService: UserService, private unitService: UnitsService, private criteriaService: CriteriaService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {

    this.loading = true;
    if (this.allDecisionInfo) {

      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      this.secondary_target = this.allDecisionInfo.secondary_target;
      this.framework_criteria = this.allDecisionInfo.framework_criteria;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;

      this.init();
    }

    this.loading = false;

    // this.doSomething(changes.categoryId.currentValue);

  }

  init() {

    const skipInstructions = JSON.parse(localStorage.getItem('skipInstructions')) || [];
    const userId = this.userService.userDetails.id;
    this.skipInstructions = skipInstructions.find(instruction => {
      return instruction?.id === userId && instruction?.multiobjective === true;
    });

    if (this.skipInstructions){
      this.router.navigate(['../weight-target'], { relativeTo: this.activeRoute });
    }

    this.loading = true;

    //  setto i pesi per gli obiettivi secondari sul primario
    for (let target of this.secondary_target) {

      let evaluation_target_finded = this.evaluations.weigth_targets.find(x => x.target_id == target.id);
      if (evaluation_target_finded) {
        target.evaluation = evaluation_target_finded;
      } else {
        target.evaluation = { weight: '-' };
      }
    }

    for (let target of this.secondary_target) {
      target.framework_criteria = JSON.parse(JSON.stringify(this.framework_criteria));
      let evaluation_target_filtered = this.evaluations.weigth_criteria.filter(x => x.target_id == target.id);
      for(let criteria of target.framework_criteria) {
        let evaluation_criteria_finded = evaluation_target_filtered.find(x => x.framework_criteria_id == criteria.id);
        if (evaluation_criteria_finded) {
          criteria.evaluation = evaluation_criteria_finded;
        } else {
          criteria.evaluation = { weight: '-' };
        }
      }
    }

    //  setto le confidence per i criteri di ogni proposta
    for (let proposal of this.proposals) {
      for (let criteria of proposal.criteria) {
        let response = this.evaluations.answers.find(x => x.criteria_id == criteria.id);
        if (response) {
          criteria.answer = response;
        } else {
          criteria.answer = { confidence: '-' }
        }
      }
    }

    //  setto il numero dei criteri da valutare
    if (this.proposals.length > 0) {
      this.numOfCriteriaQualitative = this.proposals[0].criteria.filter(x => this.criteriaService.criteriaIsQualitative(x.typology)).length;
      this.numOfCriteriaQuantitative = this.proposals[0].criteria.filter(x => this.criteriaService.criteriaIsQuantitative(x.typology)).length;
    }

    this.loading = false;
  }

  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  goToEvaluation() {
    const skipInstructions = JSON.parse(localStorage.getItem('skipInstructions')) || [];

    const userId = this.userService.userDetails.id;
    const foundIndex = skipInstructions.findIndex(instruction => instruction.id === userId);

    if (foundIndex !== -1) {
      skipInstructions[foundIndex].multiobjective = true;
    } else {
      const newInstruction = {
        id: userId,
        consensus: false,
        multiobjective: true,
        multicriteria: false,
      };
      skipInstructions.push(newInstruction);
    }
    localStorage.setItem('skipInstructions', JSON.stringify(skipInstructions));

    this.router.navigate(['../weight-target'], { relativeTo: this.activeRoute });
  }

}
