<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'components.decision-table.new-decision-table.compile-proposal.import-team-members-from-chart' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<div class="container my-5" *ngIf="dataSource?.data?.length > 0 && !loading">
  <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('generic.' + column) | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column !== 'action' && column !== 'created_at'">{{element[column]}}</span>
        <div *ngIf="column === 'action'">
          <button  mat-button color="primary" [disableRipple]="true"> {{'tooltip.show' | translate}}</button>
          <button mat-button color="primary" [disableRipple]="true" (click)="importOrganizationUsers(element)"> {{'tooltip.add' | translate}}</button>
        </div>
        <div *ngIf="column === 'created_at'">
          {{ element[column] | date :'shortDate' }}
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" *ngIf="element.organization_users?.data.length" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
<!--            <mat-form-field>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>-->
            <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.organization_users" matSort>
              <ng-container matColumnDef="{{innerColumn}}" *ngFor="let innerColumn of innerDisplayedColumns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ ('generic.' + innerColumn) | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element[innerColumn]}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" [class.example-element-row]="element.organization_users?.data.length"
        [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
<div class="row justify-content-center my-5" *ngIf="dataSource?.data?.length == 0 && !loading">
  {{ "modals.import-organization-charts.no-results" | translate }}
</div>
