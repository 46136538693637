<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate class="container bg-white">
  <div mat-dialog-content class="m-3">
      <div class="row">
        <div class="col-12 mt-4">
          <h1 mat-dialog-title class="pl-3 pt-3 m-0">{{ 'generic.privacy.updated_privacy_policy' | translate }}</h1>
          <h5 class="pl-3 p-1 m-0" style="color: rgba(128, 128, 128, 0.671);">{{ lastUpdateDate | formatdate | date : 'shortDate' }}</h5>
        </div>
        <div *ngFor="let document of documents; let i = index">
          <div class="col-12 mt-4" *ngIf="i == firstNotAcceptDocumentIndex">
            <hr>
            <h1 mat-dialog-title class="pl-3 pt-3 m-0">{{ 'generic.privacy.only_read_documents' | translate }}</h1>
          </div>
          <div class="col-12">
            <section class="my-3 pl-3">
              <p>{{ document.name }}. <a [href]="document.link" target="_blank">{{ 'generic.privacy.read_more' | translate }}</a>.</p>
<!--              <p>We’re updated our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a> . Now’s a great chance to review them. <a [href]="document.link">Read more about it</a>.</p>-->
            </section>
          </div>
          <div class="col-12" *ngIf="document.to_accept">
            <section class="my-3 pl-3">
              <mat-checkbox [(ngModel)]="document.accepted" name="privacy_accepted">{{ 'generic.privacy.document_accepted' | translate }}</mat-checkbox>
            </section>
          </div>
        </div>
        <div class="col-12">
          <button
            class="button-primary my-4 mr-3 float-right"
            mat-button
            [disabled]="!formIsValid()"
            (click)="onSubmit()"
          >
            <i class="icon-Forward-arrow-small"></i>
            <label>
              {{ "generic.accept-documents" | translate }}
            </label>
          </button>
        </div>
      </div>
  </div>
</form>
