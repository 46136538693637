import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from 'src/app/services/user.service';
import {OrganizationService} from 'src/app/services/organization.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ModalYourOrganizationsComponent
} from 'src/app/modals/modal-your-organizations/modal-your-organizations.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {AlertService} from "../../../services/general/alert.service";

export interface DocumentsData {
  name: string;
  version: any;
  publication_date: string;
  actions: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  currentLang = 'it';

  organizations: any;

  loading = false;

  user: any;
  documentsPreparedData = [];

  displayedColumns: string[] = ['name', 'version', 'publication_date', 'actions'];
  dataSource: MatTableDataSource<unknown>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private translateService: TranslateService,
    private router: Router,
    private alert: AlertService) {
  }

  ngOnInit(): void {
    this.router.navigate(['/organization/' + this.userService.userDetails.current_organization_id + '/user/' + this.userService.getCurrentOrganizationUser()?.id])
    this.init();
  }

  init() {
    //  recupero le informazioni dell'utente corrente
    this.getUserService().getUser();
    this.initDocumentsDataSource();
    this.documentsPreparedData = [...this.dataSource.data];
  }


  initDocumentsDataSource(): any {
    this.dataSource = new MatTableDataSource(this.userAcceptedDocuments);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  get userAcceptedDocuments() {
    return this.userService.userInfo.acceptedDocuments;
  }

  getUserService() {
    return this.userService;
  }

  revokeDocument(id) {
    this.loading = true;
    this.alert.showQuestionOnScreen('warning', this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.user-profile.component.revoke-document-alert'), () => {
    this.userService.revokeDocument(id, (result) => {
      this.loading = false;
      location.href = '/';
    }, () => {
      this.loading = false;
    });
    }, () => {});
  }

  useLanguage(language: string): void {
    this.translateService.use(language);
    this.currentLang = language;
    this.userService.saveLanguage(language, (result) => {
    });
  }

  openDialogYourOrganizations(organizations = undefined) {
    this.dialog.open(ModalYourOrganizationsComponent, {
      panelClass: 'medium-dialog',
      data: {
        organizations,
        // certificate : certificate
      }
    });

    // dialogRef.afterClosed().subscribe((result:any) =>{
    //   if (result?.event != null){
    //     this.getCurriculum();
    //   }
    // })
  }

}
