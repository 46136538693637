import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {WorkingSectorsService} from 'src/app/services/config/workingSectors.service';
import {FrameworkService} from 'src/app/services/framework.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {UserService} from 'src/app/services/user.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-new-decision-table-select-framework',
  templateUrl: './new-decision-table-select-framework.component.html',
  styleUrls: ['./new-decision-table-select-framework.component.scss']
})
export class NewDecisionTableSelectFrameworkComponent implements OnInit {

  @Output() frameworkSelectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goBackEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() frameworkState: any;

  searchDone = false;
  loading: boolean = false;
  userorganization: any = [];
  currentorganization: any;

  private maxNumOfFrameworkShowed = 4;
  currentNumOfFrameworkShowed: number = this.maxNumOfFrameworkShowed;

  searchTimeout: any;
  searchModel: any = {
    sector_key: undefined,
    subsector_key: undefined,
    analysis_type: undefined,
    searchKeywords: undefined
  }

  componentState = {
    frameworkSelected: null,
    model: {
      decision_name: null,
      organization_id: null,
      delivery_date: null,
      framework_id: null,
    },
    allFrameworksCount: 0,
    frameworkFilteredList: [],
  };


  constructor(private userService: UserService, private frameworkService: FrameworkService, private toast: ToastService,
              private workingSectorService: WorkingSectorsService, private analysisMethodsService: AnalysisMethodsService, private processRolesService: ProcessRolesService, private translateService: TranslateService) {
  }

  ngOnInit(): void {

    this.init();
  }

  init() {

    //  inizializzo i dati dell'utente
    this.userorganization = this.userService.getAllOrganizations();

    //  setto il framework selezionato se è già presente
    if (this.frameworkState) {
      this.componentState = JSON.parse(JSON.stringify(this.frameworkState));
      this.searchDone = this.componentState.frameworkFilteredList.length > 0;
    }

    this.currentorganization = this.userService.getCurrentOrganization();

    //  ENEL: se l'organizzazione corrente è enel allora devo impostare la data odierna
    if (this.currentorganization.customs?.profile && this.currentorganization.customs.profile == 'enel') {
      this.componentState.model.delivery_date = new Date();
    }

    if (this.currentorganization.customs?.profile && this.currentorganization.customs.profile == 'alexion') {
      this.componentState.model.decision_name = "Case " + this.currentorganization?.decision_tables_count;
      this.componentState.model.delivery_date = new Date();
    }

    this.componentState.model.organization_id = this.currentorganization.id;

    //  Carico i framework al momento che viene inizializzato il componente
    this.frameworkService.all((result) => {
      this.componentState.frameworkFilteredList = [...result];
      this.searchDone = true;
    });
  }

  /**
   * Gestione della ricerca di un framework al cambiamento di un parametro
   */
  onChangeSearchFilter(filterType: string = '') {
    this.loading = true;
    this.componentState.frameworkSelected = null;

    if (filterType == 'sector')
      this.searchModel.subsector_key = null;

    this.frameworkService.search(
      {
        'sector': this.searchModel.sector_key,
        'subsector': this.searchModel.subsector_key,
        'analysis': this.searchModel.analysis_type,
        'searchkey': this.searchModel.searchKeywords
      }, (response) => {
        this.loading = false;
        this.componentState.frameworkFilteredList = [...response];
        this.searchDone = true;
      }, () => {
        this.loading = false;
      });

  }

  onClickSearch() {
    this.onChangeSearchFilter();
  }

  onSearchInputChange() {
    // Annulla il timeout esistente, se presente
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    // Imposta un nuovo timeout di 500ms
    this.searchTimeout = setTimeout(() => {
      this.onClickSearch();
    }, 500);
  }


  /**
   * Gestione dei box dei settori
   */
  getSubsectorsArrayFiltered(sectorKey) {
    return this.workingSectorService.getAllsubsectorsForSelectBySectorKey(sectorKey);
  }

  /**
   * Gestione del click su di un framework
   */
  onClickFramework(framework_index) {
    this.componentState.frameworkSelected = JSON.parse(JSON.stringify(this.componentState.frameworkFilteredList[framework_index]));

    //  setto l'id del framework selezionato
    if (this.componentState.frameworkSelected?.id) {
      this.componentState.model.framework_id = this.componentState.frameworkSelected.id;
    }
  }

  onClickResetFilters() {

    this.searchModel = {
      sector_key: undefined,
      subsector_key: undefined,
      analysis_type: undefined,
      searchKeywords: undefined
    }

    this.init();
  }


  /**
   * Evento di submit di tutta la pagina
   */
  onSubmit(form: NgForm) {

    //  validazione dei dati
    if (!this.componentState.model.decision_name) {
      this.toast.error(this.translateService.instant("generic.warning"), this.translateService.instant("components.decision-table.new-decision-table.select-framework.error-name-decision-table"));
      return;
    }
    if (!this.componentState.model.organization_id) {
      this.toast.error(this.translateService.instant("generic.warning"), this.translateService.instant("components.decision-table.new-decision-table.select-framework.error-selected-organization"));
      return;
    }
    if (!this.componentState.model.framework_id) {
      this.toast.error(this.translateService.instant("generic.warning"), this.translateService.instant("components.decision-table.new-decision-table.select-framework.error-decision-scheme"));
      return;
    }
    // if (!this.searchValid) { this.toast.error(this.translateService.instant("generic.warning"),this.translateService.instant("components.decision-table.new-decision-table.select-framework.error-search-filter-decision-schema")); return; }

    // La commento perchè ho modificato la ricerca dei filtri.
    // if (!this.searchValid) { this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.decision-table.new-decision-table.select-framework.error-search-filter-decision-schema')); return; }

    if (!form.form.valid) {
      return;
    }

    //  invio l'evento di salvataggio del framework selezionato
    this.frameworkSelectedEvent.emit(this.componentState);
  }


  backButtonOnClick(event) {
    event.preventDefault();
    this.goBackEvent.emit(true);
  }


  public getWorkingSectorService() {
    return this.workingSectorService;
  }

  public getAnalysisMethodsService() {
    return this.analysisMethodsService;
  }

  public getProcessRolesService() {
    return this.processRolesService;
  }


  onClickShowMore() {
    this.currentNumOfFrameworkShowed += this.maxNumOfFrameworkShowed;
  }

}
