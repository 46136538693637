import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/general/alert.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { ResponseService } from 'src/app/services/response.service';

@Component({
  selector: 'app-multiobjective-responses-weight-criteria',
  templateUrl: './multiobjective-responses-weight-criteria.component.html',
  styleUrls: ['./multiobjective-responses-weight-criteria.component.scss']
})
export class MultiobjectiveResponsesWeightCriteriaComponent implements OnInit {

  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId: any;
  @Output() responseWeightCriteriaSavedEvent = new EventEmitter();

  loading: boolean = false;


  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table:any;
  organization:any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];

  evaluations: any = [];

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router,
    private toast: ToastService, private alert: AlertService,private translateService:TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

    this.loading = true;
    if (this.allDecisionInfo && this.evaluationSessionId) {

      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      this.secondary_target = this.allDecisionInfo.secondary_target;
      this.framework_criteria = this.allDecisionInfo.framework_criteria;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;

      this.init();
    }

    this.loading = false;
  }

  init() {

    this.loading = true;

    for (let target of this.secondary_target) {
      target.framework_criteria = JSON.parse(JSON.stringify(this.framework_criteria));
      let evaluation_target_filtered = this.evaluations.weigth_criteria.filter(x => x.target_id == target.id);
      for(let criteria of target.framework_criteria) {
        let evaluation_criteria_finded = evaluation_target_filtered.find(x => x.framework_criteria_id == criteria.id);
        if (evaluation_criteria_finded) {
          criteria.evaluation = evaluation_criteria_finded;
        } else {
          criteria.evaluation = { weight: 0 };
        }
      }
    }

    this.loading = false;
  }


  /** Verifica se sono rispettati tutti i valori prima di inviarli */
  verifySubmit(saveAsDraf:boolean = false) {

    //  la confidence può essere anche 0
    if (!this.criteriaEvaluationValid) {
      this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("components.responses.multiobjectives.alert-criteria-value-zero") , (ok) => {
        this.onSubmit(saveAsDraf);
      })
      return;
    }

    //  invio le risposte
    this.onSubmit(saveAsDraf);
  }

  onSubmit(saveAsDraf:boolean = false) {

    this.loading = true;

    this.responseService.saveEvaluationCriteria(this.evaluationSessionId, {evaluation_criteria_by_target: this.secondary_target, saveAsDraft: saveAsDraf}, (result) => {
      this.toast.success("Weights",this.translateService.instant("generic.weights-criteria-saved"));
      this.loading = false;

      if (saveAsDraf) {
        this.responseWeightCriteriaSavedEvent.emit(true);
      } else {
        this.router.navigate(['../responses-evaluation'], { relativeTo: this.activeRoute });
      }
    }, (error) => {
      this.loading = false;
    })
  }

  translate (string) {
    return this.translateService.instant(string);
  }

  /** valida i pesi dei criteri sugli obiettivi */
  get criteriaEvaluationValid () {

    for (let target of this.secondary_target) {
      for (let criteria of target.framework_criteria) {
        if (criteria.evaluation.weight == 0) {
          return false;
        }
      }
    }

    return true;
  }


  getIconColorForEvaluation(framework_criteria: any) {

    let controlColor = "green-color";

    for (let criteria of framework_criteria) {
      if (criteria.evaluation.weight == 0)
        controlColor = "orange-color"
    }

    return controlColor;

  }

}
