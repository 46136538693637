import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  SurveyAllResponsesComponent
} from '../../../components/surveys/survey-all-responses/survey-all-responses.component';
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-survey-details-page',
  templateUrl: './survey-details-page.component.html',
  styleUrls: ['./survey-details-page.component.scss']
})
export class SurveyDetailsPageComponent implements OnInit {
  @ViewChild('allResponsesComponent') allResponsesComponent: SurveyAllResponsesComponent;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  survey_id = null;
  survey = null;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.survey_id = params.survey_id;
    });
  }

  onSurveyChanged(survey) {
    this.survey = survey;
  }

  backToProject(survey) {
    if (survey){
      if (survey?.organization_project_id) {
        this.router.navigate(['/projects/' + survey.organization_project_id]);
      }else{
        this.router.navigate(['/']);
      }
    }
  }
}
