import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-add-attachment',
  templateUrl: './modal-add-attachment.component.html',
  styleUrls: ['./modal-add-attachment.component.scss']
})
export class ModalAddAttachmentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalAddAttachmentComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  attachmentData: any = {};

  ngOnInit(): void {

    if (this.data && Object.keys(this.data).length > 0) {
      this.attachmentData = JSON.parse(JSON.stringify(this.data));
    }
  }

  /**
   * Setto un elemento nella lista degli allegati
   * @param event
   */
  attachElement (evidence) {
    this.attachmentData.attachmentObject = evidence;
    this.dialogRef.close(this.attachmentData);
  }


  closeDialogue(event: any = null) {

    this.dialogRef.close({event:event});
  }
}
