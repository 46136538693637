import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { ToastService } from 'src/app/services/general/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html',
  styleUrls: ['./file-attachment.component.scss']
})
export class FileAttachmentComponent implements OnInit {

  @Input() data: any;
  @Output() attachElement: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;

  url = environment.apiUrl + 'upload/tmp-proposal-file';
  formData: FormData;
  files: UploadFile[];
  uploadedFiles = [];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  humanizeFileSize: number = 0;
  dragOver: boolean;
  options: UploaderOptions;

  constructor(
    private translateService: TranslateService,
    private toast: ToastService
  ) {
    this.options = { concurrency: 1, maxUploads: 3, maxFileSize: 5000000};
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    this.humanizeFileSize = this.options.maxFileSize / (1024*1024);
  }


  ngOnInit(): void {

    //  setto l'url per il caricamento dei file
    this.url = environment.apiUrl + this.data.uploadUrl;

    if (this.data.maxUploadsFile)
      this.options.maxUploads = this.data.maxUploadsFile;
  }

  onUploadOutput(output: UploadOutput): void {

    this.loading = true;

    if (output.type === 'allAddedToQueue') {
      const event: UploadInput = {
        type: 'uploadAll',
        url: this.url,
        withCredentials: true,
        method: 'POST',
      };

      this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      this.files.push(output.file);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'cancelled' || output.type === 'removed') {
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      this.toast.alert(this.translateService.instant("components.attachment.file-attachment.alert-files-requirements"));
      this.loading = false;
    } else if (output.type == 'done') {

      if (output.file.responseStatus == 201 || output.file.responseStatus === 200) {
        this.uploadedFiles.push(output.file.response);
        this.checkFiles();
      } else {
        //  cancello l'upload
        this.loading = false;

        this.toast.alert(this.translateService.instant("components.attachment.file-attachment.alert-files-requirements"));
      }
    }

    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
  }

  checkFiles() {
    this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
    if (this.files.length == 0) {
      this.loading = false;
      this.attachElement.emit(this.uploadedFiles);
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.url,
      withCredentials: true,
      method: 'POST',
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

    /**
   * Premuto il bottone per aggiungere un articolo
   * @param element
   */
    addAttachment(element) {
      this.attachElement.emit(element);
    }

}
