import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';
import {environment} from 'src/environments/environment';
import {Subject, Subscription} from 'rxjs';
import {UserTypeService} from './config/user-types.service';
import {TranslateService} from '@ngx-translate/core';

// const name_favoriteOrganization = 'P_ORG';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  organizationSubscription: Subscription;

  public userInfo: any;
  public userDetails: any;
  public userContacts: any;
  public userPhone: any;
  public userMemmbers: any;
  public userLoggedIn = false;
  private userOrganizations: any = [];

  private organizationChanged = new Subject<any>();
  public currentOrganization;
  public currentUserType;
  public lastVisitedEvaluationIdQuestionnaire = null;

  constructor(private backend: BackendService, private userTypeService: UserTypeService, private translateService: TranslateService) {

    this.organizationSubscription = this.organizationChanged.subscribe(() => {
      this.setUserTypeForOrganization(this.currentOrganization.id);
    });
  }

  ngOnDestroy() {
    this.organizationSubscription.unsubscribe();
  }

  //  risetta la lingua dell'utente così da poter settare la lingua in base anche all'organizzazione dell'utente
  resetCurrentLanguage() {
    if (this.currentOrganization?.customs?.profile == 'enel') {
      this.translateService.use(this.userDetails.language + '-enel');
    } else if (this.currentOrganization?.customs?.profile == 'alexion') {
      this.translateService.use(this.userDetails.language + '-alexion');
    } else if (this.currentOrganization?.customs?.profile == 'pfizer') {
      this.translateService.use(this.userDetails.language + '-pfizer');
    } else {
      this.translateService.use(this.userDetails.language);
    }
  }


  /**
   * Recupera le informazioni legate all'azienda per l'utente corrente
   * se questo ha tra le sue organizzazioni quella indicata
   */
  public getOrganizzationForCurrentUser(organizationId) {
    const userorganization = this.getAllOrganizations();
    return userorganization.find((x) => x.id == organizationId);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllOrganizations() {
    return this.userOrganizations.slice(0);
  }

  /**
   * Restituisce l'organizzazione corrente dell'utente
   * @returns
   */
  public getCurrentOrganization() {
    return this.currentOrganization;
  }

  /**
   * Controlla e gestisce l'organizzazione preferita dell'utente
   */
  public setPreferredOrganization(organization_id: any, success_callback = (data: any) => {
  }) {
    // console.log('setPreferredOrganization');
    if (this.userDetails.current_organization_id != organization_id) {
      this.saveCurrentOrganization(organization_id, success_callback);
    }

    const findOrganization = this.userOrganizations.find(
      (x) => x.id == organization_id
    );
    if (!findOrganization) {
      this.currentOrganization = this.userOrganizations[0];
    } else {
      this.currentOrganization = findOrganization;
    }

    this.organizationChanged.next(void 0);
  }

  public getCurrentOrganizationUser() {
    return this.getOrganizationUserByOrganizationId(this.userDetails.current_organization_id);
  }

  public getOrganizationUserByOrganizationId(organization_id) {
    return this.userInfo.organization_user.find((org_usr) => org_usr.organization_id === organization_id);
  }

  public getCurrentRole() {
    const usr = this.getCurrentOrganizationUser();
    return usr.role;
  }

  public getCurrentPermissions() {
    const usr = this.getCurrentOrganizationUser();
    const tmp = [...usr.permissions, ...usr.role_permissions];
    return Array.from(new Set(tmp.map(obj => obj.name))).map(name => {
      return tmp.find(obj => obj.name === name);
    });
  }

  public getPreferredOrganization() {
    if (this.userDetails.current_organization_id != null) {
      this.currentOrganization = this.userOrganizations.find(
        (x) => x.id == this.userDetails.current_organization_id
      );
      if (this.currentOrganization) {
        return this.currentOrganization;
      }
    }
    return this.userOrganizations[0];
  }

  /**
   * Aggiorna una organizzazione per l'utente
   * @param organizationUpdated
   */
  public updateOrganization(organizationUpdated) {
    const organizationIndex = this.userOrganizations.findIndex(
      (x) => x.id == organizationUpdated.id
    );
    if (organizationIndex >= 0) {
      this.userOrganizations[organizationIndex] = organizationUpdated;
    }

    if (this.currentOrganization.id == organizationUpdated.id) {
      this.currentOrganization = organizationUpdated;
      this.organizationChanged.next(void 0);
    }
  }

  /**
   * Aggiorna ruolo
   * @param roleUpdated
   */
  public updateOrganizationRole(roleUpdated) {
    const roleIndex = this.userOrganizations.findIndex(x => x.id == roleUpdated.id);
    if (roleIndex >= 0) {
      this.userOrganizations[roleIndex] = roleUpdated;
    }

    if (this.currentOrganization.id == roleUpdated.id) {
      this.currentOrganization = roleUpdated;
      this.organizationChanged.next(void 0);
    }
  }

  /**
   * Restituisce l'organizzazione tramite l'id
   */
  public getOrganizationByID(organization_id: number) {
    const organization = this.userOrganizations.find(
      (x) => x.id == organization_id
    );
    if (organization) {
      return organization;
    }
    return null;
  }

  /**
   * Setta il tipo di utente per l'organizzazione corrente e l'utente corrente
   * @param organization_id
   * @returns
   */
  public setUserTypeForOrganization(organization_id: number) {
    const userRelation = this.userMemmbers.find(
      (x) => x.organization_id == organization_id
    );
    if (userRelation && userRelation.user_type) {
      this.currentUserType = userRelation.user_type;
    } else {
      this.currentUserType = this.userTypeService.getGuestKey();
    }
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllProfessionalRolesForCurrentOrganization() {
    return this.currentOrganization?.roles;
  }

  /**
   * Restituisco i ruoli per una organizazione in particolare
   * @param organization_id
   * @returns
   */
  public getProfessionalRolesForOrganization(organization_id) {
    return this.userOrganizations.find((x) => x.id == organization_id).roles;
  }

  /**
   * Salvo un nuovo ruolo appena aggiunto
   * @param organization_id
   * @param professionalRole
   */
  public setNewRoleForOrganization(organization_id, professionalRole) {
    const organizationIndex = this.userOrganizations.findIndex(
      (x) => x.id == organization_id
    );
    if (organizationIndex >= 0) {
      this.userOrganizations[organizationIndex].roles.push(professionalRole);
    }
  }

  /**
   * Redirect dell'utente verso il server esterno di login
   */
  public loginUser() {
    // let returnUrlEncoded = encodeURIComponent(environment.loginReturnUrl);
    const returnUrlEncoded = encodeURIComponent(window.location.href);
    window.location.replace(
      environment.loginUrl + '?return_url=' + returnUrlEncoded
    );
  }

  /**
   * Logout dell'utente
   */
  public logoutUser() {
    const returnUrlEncoded = encodeURIComponent(environment.logoutReturnUrl);
    window.location.replace(
      environment.logoutUrl + '?return_url=' + returnUrlEncoded
    );
  }

  /**
   * Restituisce le informazioni dell'utente attualmente loggato
   */
  public getUser(
    callbackSuccess: any = () => {
    },
    callbackError: any = () => {
    }
  ) {

    this.backend.get('user').subscribe(
      (result: any) => {
        this.userInfo = result;
        this.userDetails = result.details;
        this.userContacts = result.contacts;
        this.userMemmbers = result.organization_user;
        this.userOrganizations = result.organizations;
        // this._workExperiences = result.works

        //  setto l'organizzazione preferita
        const prefOrg = this.getPreferredOrganization();
        this.setPreferredOrganization(prefOrg.id);

        if (this.userDetails.current_organization_id == null) {
          // console.log('saveCurrentOrganization');
          this.saveCurrentOrganization(this.getCurrentOrganization().id);
        }
        this.userLoggedIn = true;
        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }

  public revokeDocument(id, callbackSuccess: any = () => {
                        },
                        callbackError: any = () => {
                        }) {
    this.backend.destroy('user/revoke-document/' + id).subscribe(
      (result: any) => {
        callbackSuccess(result);
      },
      (error) => callbackError(error)
    );
  }


  /**
   * Recupera il ruolo e i permessi per un utente corrente
   * @param success_callback
   * @param error_callback
   */
  getCurrentUserPermissions(
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }) {
    this.backend.get(`user/permissions`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }


  public getPrimaryPhone() {
    if (this.userContacts) {
      return this.userContacts.find((x) => x.type == 'PRIMARYPHONE');
    }
    return '';
  }

  /**
   * Resitituisce tutti i tavoli decisionali dov'è coinvolto l'utente corrente
   * @param success_callback
   * @param error_callback
   */
  public getUserDecisionTables(
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get('user/decision-tables').subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public changePassword(model, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {
    this.backend.post('user/change-password/', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public resetPassword(model, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {
    this.backend.post('user/reset-password', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  // CURRICULUM UTENTE

  /**
   * Recupera id del curriculum
   * @param id_curriculum
   * @param success_callback
   * @param error_callback
   */
  public getCurriculum(
    id_curriculum: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`curriculum/` + id_curriculum).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Recupera esperienza lavorativa
   * @param id_work
   * @param id_curriculum
   * @param success_callback
   * @param error_callback
   */
  public getWorkExperience(
    curriculum_id: any,
    work_id: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`curriculum/${curriculum_id}/work/${work_id}`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Salva esperienza lavorativa completa
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public saveWorkExperience(
    curriculum_id: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post(`curriculum/${curriculum_id}/work`, model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public acceptDocuments(documents: any, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {
    this.backend.post(`documents/accept`, documents).subscribe((result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      });
  }

  /**
   * Salva esperienza lavorativa completa
   * @param work_id
   * @param id_curriculum
   * @param success_callback
   * @param error_callback
   */
  public deleteWorkExperience(
    curriculum_id,
    work_id,
    success_callback = (data: any) => {
    },
    error_callback = (data: any) => {
    }
  ) {
    this.backend
      .destroy(`curriculum/${curriculum_id}/work/${work_id}`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Aggiorno i dati per un utente di una organizzazione
   * @param id_curriculum
   * @param work_id
   * @param success_callback
   * @param error_callback
   */
  public updateWorkExperience(
    curriculum_id: any,
    work_id,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .put(`curriculum/${curriculum_id}/work/${work_id}`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Restituisco le esperienze lavorative per un curriculum in particolare
   * @param curriculum_id
   * @returns
   */

  /**
   * Salva formazione completa
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public saveEducation(
    curriculum_id: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post(`curriculum/${curriculum_id}/education`, model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Aggiorno i dati per un utente di una organizzazione
   * @param id_curriculum
   * @param education_id
   * @param success_callback
   * @param error_callback
   */
  public updateEducation(
    curriculum_id: any,
    education_id,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .put(`curriculum/${curriculum_id}/education/${education_id}`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Salva esperienza lavorativa completa
   * @param education_id
   * @param id_curriculum
   * @param success_callback
   * @param error_callback
   */
  public deleteEducation(
    curriculum_id,
    education_id,
    success_callback = (data: any) => {
    },
    error_callback = (data: any) => {
    }
  ) {
    this.backend
      .destroy(`curriculum/${curriculum_id}/education/${education_id}`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Restituisco la formazione per un curriculum in particolare
   * @param curriculum_id
   * @returns
   */

  /**
   * Salva certificato completo
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public saveCertificate(
    curriculum_id: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`curriculum/${curriculum_id}/certification`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Aggiorno i dati per un utente di una organizzazione
   * @param id_curriculum
   * @param certificate_id
   * @param success_callback
   * @param error_callback
   */
  public updateCertificate(
    curriculum_id: any,
    certificate_id,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .put(`curriculum/${curriculum_id}/certification/${certificate_id}`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Salva esperienza lavorativa completa
   * @param certificate_id
   * @param id_curriculum
   * @param success_callback
   * @param error_callback
   */
  public deleteCertificate(
    curriculum_id,
    certificate_id,
    success_callback = (data: any) => {
    },
    error_callback = (data: any) => {
    }
  ) {
    this.backend
      .destroy(`curriculum/${curriculum_id}/certification/${certificate_id}`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Salva lingua predefinita dell'utente
   * @param language
   * @param success_callback
   * @param error_callback
   */
  public saveLanguage(language, success_callback: any = (data: any) => {
  }, error_callback = (data: any) => {
  }) {
    this.backend.post('user/update-language', {language}).subscribe(result => {
        this.userDetails.language = language;
        this.resetCurrentLanguage();
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  //
  public saveCurrentOrganization(
    organization_id,
    success_callback: any = (data: any) => {
    },
    error_callback = (data: any) => {
    }
  ) {
    this.backend
      .post('user/update-organization', {
        current_organization_id: organization_id,
      })
      .subscribe(
        (result) => {
          this.userDetails.current_organization_id = organization_id;
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  hasPermissionInCurrentOrganization(perm) {
    return this.getCurrentPermissions().some((permissions) => permissions.name === perm);
  }

  public currentOrganizationHasFeature(feature: string): boolean {
    return this.getCurrentOrganization()?.customs?.features?.[feature];
  }
}
