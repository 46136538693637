import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';

@Injectable({
  providedIn: 'root'
})
export class FrameworkService {

  constructor(private backend: BackendService) { }

  /**
   * Ricerca di più framework tramite alcune chiavi predefinite
   * @param searchModel
   * @param success_callback
   * @param error_callback
   */
  public search (searchModel: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post('framework/search', searchModel).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Recupera un framework
   * @param id_framework
   * @param success_callback
   * @param error_callback
   */
  public get(id_framework: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get('framework/' + id_framework).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Salva un framework completo
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public save(model: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post('framework', model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });

  }

  /**
   * Aggiorna un framework solo con alcuni dati parziali
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public update(id_framework: string, model: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.put(`framework/${id_framework}`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Recupero tutti i framework
   * @param success_callback
   * @param error_callback
   */
  public all(success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get('framework/all').subscribe(result => {
      success_callback(result);
    }, (error) => {
      this.backend.showErrors(error);
      error_callback(error);
    });
  }



  /**
   * Scarico il file excel dei del framework
   * @param id_framework
   */
  public donwloadData(id_framework = null) {

    if (!id_framework) {
      this.backend.download(`framework/export-data`, 'framework_export');
    }
  }

}
