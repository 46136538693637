import {Component, OnInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import value from '*.json';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal-privacy-policy',
  templateUrl: './modal-privacy-policy.component.html',
  styleUrls: ['./modal-privacy-policy.component.scss']
})

export class ModalPrivacyPolicyComponent implements OnInit {
  @Input() documents = [];

  loading = false;

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.documents.forEach(document => {
      if (document.to_accept) {
        document.accepted = false;
      }
    });
    this.documents.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    this.documents.sort((a, b) => {
      if (a.to_accept && !b.to_accept) {
        return -1;
      } else if (!a.to_accept && b.to_accept) {
        return 1;
      }
      return 0;
    });
  }

  get documentsToSubmit() {
    const documents = [];
    this.documents.forEach((document) => {
      documents.push({id: document.id});
    });
    return documents;
  }

  get firstNotAcceptDocumentIndex() {
    for (let i = 0; i < this.documents.length; i++) {
      if (!this.documents[i].to_accept) {
        return i;
      }
    }
    return null;
  }


  onSubmit() {
    if (this.formIsValid()) {
      this.loading = true;
      const data = {
        documents: this.documentsToSubmit
      };
      this.userService.acceptDocuments(data, (result) => {
        this.loading = false;
        window.location.reload()
      }, (error) => (this.loading = false));
    }
  }

  get documentsToAccept() {
    return this.documents.filter((document) => {
      return document.to_accept;
    });
  }

  formIsValid() {
    let accepted = true;
    this.documentsToAccept.forEach((document) => {
      if (!document.accepted) {
        accepted = false;
      }
    });
    return accepted;
  }

  get lastUpdateDate() {
    return this.documents[this.documents.length - 1].created_at;
  }

  protected readonly indexedDB = indexedDB;
}
