<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container">

      <!-- Sezione alert -->
      <ng-container *ngIf="!loading">
        <mat-card *ngIf="!evaluation_session?.collecting" style="background-color: red; color: white;">
          {{'components.responses.decision-table-closed' | translate}}
        </mat-card>
        <mat-card *ngIf="response_stamps?.completed" style="background-color: red; color: white;">
          {{'components.responses.responses-already-sent' | translate}}
        </mat-card>
      </ng-container>

      <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
          <mat-label>{{'generic.organization' | translate}}</mat-label>
          <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
        <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
        <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : 'Data di consegna non definita'" placeholder="Data di consegna" readonly>
      </mat-form-field>
  </div>
</div>

<!-- Blocco 1 -->
<div class="container mt-4">
    <div class="row">
      <div class="col-12 mb-4">
        Il metodo scelto per questo tavolo decisionale è l'<b>analisi multiobiettivo</b>.
      </div>
      <div class="col-12 mb-4">
        Il metodo Analisi Multiobiettivo prevede una struttura di valutazione fatta da un obiettivo principale, degli obiettivi secondari da cui dipende il raggiungimento dell'obiettivo principale ed una serie di criteri con cui sono state descritte le stategie proposte.
      </div>
      <div class="col-12 mb-4">
        Per questo tavolo decisionale è stato indentificato come obiettivo principale:
      </div>

      <!-- Obiettivo principale -->
      <div class="col-12 px-2" *ngIf="primary_target.length > 0">
          <div class="box px-0 box-two-row border-0 bg-transparent" *ngFor="let primaryTarget of primary_target">
              <mat-label class="box-title px-3">{{'components.proposals.new-proposal.primary' | translate}}</mat-label>
              <mat-form-field class="input-primary col-12 col-lg-6">
                <input matInput [ngModel]="primaryTarget.title" placeholder="{{'generic.title' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                  <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                  <input matInput [ngModel]="(primaryTarget.date| formatdate | date: 'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                <input matInput placeholder="{{'generic.unit' | translate}}" [ngModel]="getUnitsService().getUnitValueByKey(primaryTarget.measure_unit)" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                <input matInput placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(primaryTarget.evaluation_criteria)" readonly>
              </mat-form-field>
          </div>
      </div>

      <div class="col-12 col-lg-6 px-0 mb-0" *ngIf="secondary_target.length > 0">
        <div class="col-12 mb-4">
          Mentre come obiettivi secondari:
        </div>
        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
            <mat-label class="box-title px-3 mb-0">Secondari</mat-label>
        </div>
      </div>

      <div class="row">
        <div class="col-6 px-2" *ngFor="let targetSecondary of secondary_target; let i = index">
          <ng-container>
            <div class="box px-0 box-two-row" style="border-left: 0;">
              <mat-form-field class="input-primary col-7">
                <input matInput name="proposal_target_secondary_title{{i}}" [ngModel]="targetSecondary.title" placeholder="{{'generic.title' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="datepicker-primary col-5" appearance="fill">
                <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                <input matInput [ngModel]="(targetSecondary.date| formatdate | date: 'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-7">
                <input matInput name="proposal_target_secondary_evaluation_criteria{{i}}" placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(targetSecondary.evaluation_criteria)" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-5" appearance="fill">
                <mat-label>Unità</mat-label>
                <input matInput placeholder="{{'generic.unit' | translate}}" [ngModel]="getUnitsService().getUnitValueByKey(targetSecondary.measure_unit)" readonly>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        Le proposte oggetto della valutazione sono descritte attraverso uno schema che contiene {{numOfCriteriaQualitative}} criteri qualitativi e {{numOfCriteriaQuantitative}} quantitativi.
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        Stai per rispondere a 3 questionari:
      </div>
    </div>

    <!-- Primo step -->
    <div class="col-12 col-lg-6 px-0 mb-0">
      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
          <mat-label class="box-title px-3 mb-0">Primo</mat-label>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        Nel primo devi valutare quando ogni obiettivo secondario è rilevante per raggiungere l'obiettivo principale su una scala da 0 (assolutamente non importante) a 100 (assolutamente rilevante)
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 mb-0">
      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
          <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
      </div>
    </div>

    <!-- Esempio di peso per obiettivo secondario su primario -->
    <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
      <div class="container">
          <mat-expansion-panel class="px-0" expanded>
              <div class="row">
                  <small class="col-12">"{{'generic.evaluation' | translate}}"</small>
                  <div class="col-12">Nome dell'obiettivo secondario</div>
                  <mat-slider style="pointer-events: none;" class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [value]="65" [thumbLabel]="true" color="primary"></mat-slider>
                  <div class="d-flex justify-content-between w-100 mb-2 px-2">
                      <small class="float-left">Poco importante</small>
                      <small class="float-right">Molto importante</small>
                  </div>
              </div>
          </mat-expansion-panel>
      </div>
  </div>

  <!-- Secondo step -->
  <div class="col-12 col-lg-6 px-0 mb-0 mt-4">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Secondo</mat-label>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      Nel secondo questionario dovrai valutare quanto ogni criterio incida sul raggiungimento di ogni singolo obiettivo secondario su una scala da 0 a 100.
    </div>
  </div>

  <div class="col-12 col-lg-6 px-0 mb-0">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
    </div>
  </div>

  <!-- Esempio di peso del criterio sull'obiettivo secondario -->
  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
    <div class="container">
      <mat-accordion class="accordion" multi [togglePosition]="'before'">
        <mat-expansion-panel class="px-0" expanded>
          <ng-container>
            <div class="row border-bottom-grey pt-0 pb-4 my-3">
              <small class="col-12">"{{'generic.evaluation' | translate}}"</small>
              <div class="col-12">Nome del criterio</div>
              <mat-slider style="pointer-events: none;" class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [thumbLabel]="true" [value]="48" color="primary"></mat-slider>
              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                  <small class="float-left">Poco importante</small>
                  <small class="float-right">Molto importante</small>
              </div>
          </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Terzo step -->
  <div class="col-12 col-lg-6 px-0 mb-0 mt-4">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Terzo</mat-label>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      Nel terzo questionario dovrai assegnare un punteggio ad ogni proposta rispetto ai soli criteri qualitativi identificati. Per ogni valutazione devi fornire un grado di confidenza che esprime quanto sei sicuro della tua valutazione: da 0 (assolutamente non sicuro) a 100 (assolutamente certo). Dovrai fornire un feedback che spiega il motivo della tua valutazione e potrai allegare delle evidenze come file o sfruttando le banche dati connesse a Butterfly. Alla fine del questionario potrai fornire delle proposte alternative a quelle che hai valutato.
    </div>
  </div>

  <div class="col-12 col-lg-6 px-0 mb-0">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
    </div>
  </div>


  <!-- Esempio di assegnazione del punteggio alle varie proposte -->
  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
    <div class="container">
        <mat-accordion class="accordion" multi [togglePosition]="'before'">
          <mat-expansion-panel class="px-0" expanded>
            <div class="row border-bottom-grey pt-0 pb-4 my-3">
                <small class="col-12">"{{'generic.evaluation' | translate}}" Proposta 1</small>
                <mat-slider style="pointer-events: none;" class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [value]="20" [thumbLabel]="true" color="primary"></mat-slider>
                <div class="d-flex justify-content-between w-100 mb-2 px-2">
                    <small class="float-left">Poco importante</small>
                    <small class="float-right">Molto importante</small>
                </div>
                <div class="col-12 links-container">
                  <button style="pointer-events: none;" mat-button color="primary">
                      <i class="icon-Add mr-2"></i>
                      {{'components.responses.add-feedback' | translate}}
                  </button>
                </div>
            </div>
            <div class="row border-bottom-grey pt-0 pb-4 my-3">
                <small class="col-12">"{{'generic.evaluation' | translate}}" Proposta 2</small>
                <mat-slider style="pointer-events: none;" class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [value]="63" [thumbLabel]="true" color="primary"></mat-slider>
                <div class="d-flex justify-content-between w-100 mb-2 px-2">
                    <small class="float-left">Poco importante</small>
                    <small class="float-right">Molto importante</small>
                </div>
                <div class="col-12 links-container">
                  <button style="pointer-events: none;" mat-button color="primary">
                      <i class="icon-Add mr-2"></i>
                      {{'components.responses.add-feedback' | translate}}
                  </button>
              </div>
            </div>
            <div class="row border-bottom-grey pt-0 pb-4 my-3">
              <small class="col-12">Quanto sei sicuro della tua valutazione?</small>
              <mat-slider style="pointer-events: none;" class="w-100 mt-2 cdk-focused" min="0" max="100" step="1" [value]="83"  [thumbLabel]="true" color="primary"></mat-slider>
              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                <small class="float-left">Assolutamente non sicuro</small>
                <small class="float-right">Assolutamente certo</small>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
    </div>
  </div>


  <!-- Final step -->
  <div class="row mt-4">
    <div class="col-12 mb-4">
      Alla fine del questionario potrai avanzare proposte alternative che saranno oggetto di una nuova sessione di valutazione.
    </div>
  </div>

  <div class="col-12 col-lg-6 px-0 mb-0">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
    </div>
  </div>

  <!-- Esempio proposta di nuove revisioni -->
  <div class="col-12 links-container">
    <button style="pointer-events: none;" mat-button color="primary">
      <i class="icon-Add mr-2"></i>
      Vuoi suggerire una nuova proposta?
    </button>
    <!-- TODO_MICHELE: aggiungere la revisione delle proposte -->
      <button style="pointer-events: none;" mat-button color="primary">
          <i class="icon-re_edit mr-2"></i>
          Vuoi revisionare le proposte?
      </button>
  </div>


  <!-- Info di fine pagina -->
  <div class="row mt-4">
    <div class="col-12 mb-4">
      <span style="font-size: 16px;" *ngIf="evaluation_session?.evaluation_end == null"><b>Non è definita una data di chiusura del questionario, completalo il prima possibile</b></span>
      <span style="font-size: 16px;" *ngIf="evaluation_session?.evaluation_end != null"><b>Devi compilare il questionario entro il giorno {{evaluation_session?.evaluation_end | formatdate | date: 'shortDate'}}</b></span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      Hai la possibilità di salvare in bozza e riprendere il lavoro entro la data di consegna.
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      Buon lavoro!
    </div>
  </div>

  <!-- Bottoni di azione -->
  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button [routerLink]="['../welcome']">
      <i class="icon-Backward-arrow-small"></i>
      Indietro
    </button>
    <button type="submit" class="button-primary float-right" [disabled]="loading" mat-button (click)="goToEvaluation()">
      <i class="icon-Forward-arrow-small"></i>
      Avvia la valutazione
    </button>
  </div>

</div>
