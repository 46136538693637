import { Injectable } from '@angular/core';
import {BackendService} from "./general/backend.service";
import {ToastService} from "./general/toast.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private backend: BackendService, private toast: ToastService, private translateService: TranslateService) {
  }

  public getProjectsByOrganizationId(organization_id: string, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {

    this.backend.get(`organization/${organization_id}/projects/`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public getProject(project_id: string, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {

    this.backend.get(`project/${project_id}/`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  public createProject(organization_id: string, model: any, success_callback = (data: any) => {
    }, error_callback = (error: any) => {
    }) {

      this.backend.post(`organization/${organization_id}/projects/`, model).subscribe(result => {
        this.toast.success(
          this.translateService.instant("pages.home.component.check-decision-table"),
          this.translateService.instant("pages.projects.created-successfully")
        );
        success_callback(result);
      }, (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      });
    }

  public updateProject(project_id: string, model: any, success_callback = (data: any) => {
  }, error_callback = (error: any) => {
  }) {

    this.backend.put(`project/${project_id}/`, model).subscribe(result => {
      this.toast.success(
        this.translateService.instant("pages.home.component.check-decision-table"),
        this.translateService.instant("pages.projects.updated-successfully")
      );
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

}
