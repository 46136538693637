import { Component, OnInit,Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/general/toast.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal-add-education',
  templateUrl: './modal-add-education.component.html',
  styleUrls: ['./modal-add-education.component.scss']
})
export class ModalAddEducationComponent implements OnInit {

  loading: boolean = false;

  id_curriculum: any;
  curriculumInfo : any = {};

  model: any = {
    educations : [],
    certificate: '',
    course_study : '',
    date_start: '',
    date_end : '',
    date_end_selected : false,
    school : '',
    description : ''
  }

  constructor(
    public dialogRef: MatDialogRef<ModalAddEducationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private toast: ToastService,
    private userService : UserService,
    private translateService : TranslateService
    ) { }

  ngOnInit(): void {
    if(this.data.education != null && this.data.education != undefined)
    {this.model = JSON.parse(JSON.stringify(this.data.education))
      this.model.date_end == null ? this.model.date_end_selected = true : this.model.date_end_selected = false}
  }

  disableDateEnd() {
    this.model.date_end_selected = !this.model.date_end_selected;
  }

  onSubmit(form: NgForm) {

    if(this.model.certificate == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-education.component.error-enter-qualification'));
      return;
    }

    if(this.model.course_study == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-education.component.error-enter-course-study'));
      return;
    }
    if(this.model.date_start == '' ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-curriculum.component.error-enter-date-start'));
      return;
    }

    if(this.model.school == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-education.component.error-enter-school-uni'));
      return;
    }

    if(this.model.date_end_selected == true) {
      this.model.date_end = null
    }

    if (!form.form.valid)
    return;

    if (this.model.id) {
      this.updateEducation();
      return;
    }

    this.userService.saveEducation(this.data.curriculum_id,this.model, (result) => {
      this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-education.component.success-education-saved"));
      this.loading = false;
      this.closeDialogue('saved');
    }, (error) => {
      this.loading = false;
      this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-education.component.error-education-saved"));
    })

}


updateEducation() {
  this.loading = true;
  this.userService.updateEducation(this.data.curriculum_id, this.model.id , this.model , (result) => {
    this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-education.component.success-education-updated"));
    this.loading = false;
    this.closeDialogue('updated');
  },(error) =>
  {
    this.loading = false
    this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-education.component.error-education-updated"));
  });
}



closeDialogue(event: any = null) {

  this.dialogRef.close({event:event});
}

}
