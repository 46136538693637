import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../services/organization.service";
import {ConfigService} from "../../services/config.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, NgForm} from "@angular/forms";
import {UserTypeService} from "../../services/config/user-types.service";

@Component({
  selector: 'app-modal-organization-chart',
  templateUrl: './modal-organization-chart.component.html',
  styleUrls: ['./modal-organization-chart.component.scss']
})
export class ModalOrganizationChartComponent implements OnInit {
  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'actions'];
  usersData: any = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<ModalOrganizationChartComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService, private configService: ConfigService,
    private userTypeService: UserTypeService,
  ) {
  }

  loading: boolean = false;
  model: any = {
  };
  organization_id: string;

  ngOnInit(): void {
    this.data = JSON.parse(JSON.stringify(this.data))
    if (this.data.users && this.data.chart?.organization_users) {
      this.data.chart?.organization_users.forEach((user) => {
        const x = this.data.users.find((tmp_user) => user.id === tmp_user.id);
        if (x) {
          x.selected = true;
        }
      });
    }
    if (this.data && this.data.users && this.data.users.length > 0) {
      this.usersData = new MatTableDataSource(this.data.users);
      if(this.data.chart){
        this.model = {...this.data.chart};
      }
    }
    this.organization_id = this.data?.organization_id;
  }

  onSubmit(form: NgForm) {

    if (form.invalid) {
      return;
    }

    this.loading = true;
    this.model = {
      ...this.model,
      organization_users: this.selectedUsers.map((user) => user.id),
    }

    if (this.model.id) {
      this.organizationService.updateOrganizationChart(this.organization_id, this.model, (result) => {
          this.loading = false;
          this.closeDialogue({event: 'added', data: this.model});
        }, () => {
          this.loading = false;
        }
      );
    } else {
      this.organizationService.saveOrganizationChart(this.organization_id, this.model, (result) => {
          this.loading = false;
          this.closeDialogue({event: 'added', data: this.model});
        }, () => {
          this.loading = false;
        }
      );


    }

  }

  closeDialogue(event: any = null) {

    this.dialogRef.close({event: event});
  }

  checkElement(element) {
    element.selected = !element.selected;
  }

  get selectedUsers () {
    return this.usersData.data.filter((user) => user.selected)
  }
}

