import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessRolesService {

  private _process_roles: any = [
    { key: 'RESPONSIBLE', value: 'Responsible'},
    { key: 'ACCOUNTABLE', value: 'Accountable'},
    { key: 'CONSULTED', value: 'Consulted'},
    { key: 'INFORMED', value: 'Informed'}
  ];

  constructor() { }

  getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }


  get process_roles() {
    return this._process_roles.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllProcessRoles() {
    return this._process_roles.slice(0);
  }

  /**
   * Restituisce il valore in base alla chiave
   * @param key
   */
  public getProcessRolesValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._process_roles, key);
  }


  /**
   * Indica se il ruolo passato è Responsible o no
   * @param process_role_key
   * @returns
   */
  public isResponsible(process_role_key: string): boolean {
    return process_role_key.toUpperCase() == "RESPONSIBLE";
  }


  /**
   * Indica se il ruolo passato è Accountable o no
   * @param process_role_key
   * @returns
   */
  public isAccountable(process_role_key: string): boolean {
    return process_role_key.toUpperCase() == "ACCOUNTABLE";
  }

  /**
   * Indica se il ruolo passato è Consulted o no
   * @param process_role_key
   * @returns
   */
  public isConstulted(process_role_key: string): boolean {
    return process_role_key.toUpperCase() == "CONSULTED";
  }

}
