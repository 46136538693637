import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { lowerCase } from 'lodash';

@Directive({
  selector: '[appHideIfOrganization]'
})
export class HideIfOrganizationDirective {
  private orgNames: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {}

  @Input()
  set appHideIfOrganization(names: string) {
    this.orgNames = names.split(',').map(name => lowerCase(name.trim()));
    this.updateView();
  }

  private updateView() {
    const organizationName = lowerCase(this.userService?.currentOrganization?.customs?.profile);

    if (this.orgNames.includes(organizationName)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
