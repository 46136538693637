<form (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container-fluid box-sub-header">
    <div class="container">
      <ng-container *ngIf="!id && currentOrganization?.customs?.profile == 'enel'">
        <button class="mr-3 mb-1" mat-flat-button color="primary" (click)="onClickUploadFile()">
          {{'modal.modal-upload-framework.title' | translate}}
        </button>
        <button mat-button color="primary" (click)="onClickDownloadExampleData()">
          <mat-icon aria-hidden="false">description</mat-icon>
          {{ "scarica documento di esempio" | translate }}
        </button>
      </ng-container>
      <mat-form-field class="col-12 input-big">
        <input matInput name="title" placeholder="{{'pages.frameworks.component.placeholders.insert-decisional-schema-name' | translate}}" [(ngModel)]="model.title" required autocomplete="off">
      </mat-form-field>

      <mat-form-field class="col-6 col-sm-auto col-lg-3 select-custom-button" appearance="fill">
        <mat-label>{{'generic.organization' | translate}}</mat-label>
        <mat-select disabled name="organization" [(ngModel)]="currentOrganization.id" required>
          <mat-option [value]="currentOrganization.id" >{{ currentOrganization.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>


  <!-- Blocco 1 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              {{'pages.frameworks.component.analysis-methodology' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option">
              <mat-grid-list cols="12" rowHeight="100px">
                <ng-container *ngFor="let method of dynamicAnalysisMethods; let i = index">
                  <mat-grid-tile colspan="6" (click)="!id ? analysisOnClick(i) : ''">
                    <mat-radio-button class="col-12" name="analysis{{i}}" [value]="false" [checked]="method.enabled">{{method.title}}</mat-radio-button>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="5">
                    <div class="description-container">
                      {{method.description}}
                    </div>
                  </mat-grid-tile>
                  <mat-grid-tile class="icon-info-custom" colspan="1">
                    <i #tooltip="matTooltip" matTooltip="{{method.tipDescription}}" matTooltipPosition="above" matTooltipHideDelay="10000">i</i>
                  </mat-grid-tile>
                </ng-container>
              </mat-grid-list>
            </mat-radio-group>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 2 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              {{'generic.sector' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row settore">

            <div class="col-12 box" *ngFor="let sector of model.sectors; let i = index">
              <ng-container>
                <mat-form-field class="select-primary" appearance="fill">
                  <mat-label>{{'generic.sector' | translate}}</mat-label>
                  <mat-select #sectorInput name="sector{{i}}" [(ngModel)]="sector.sector_key" required>
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let sector of getWorkingSectorService().getAllSectorsForSelect()" [value]=sector.key>{{sector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="select-primary ml-3 col-6" appearance="fill">
                  <mat-label>{{'generic.subsector' | translate}}</mat-label>
                  <mat-select name="subsector{{i}}" [(ngModel)]="sector.subsector_key" [disabled]="!sectorInput.value">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let subsector of getSubsectorsArrayFiltered(sectorInput.value)" [value]="subsector.key">{{subsector.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="icons-container" (click)="removeSector(i)">
                  <i class="icon-cestino grey-color float-right"></i>
                </div>
              </ng-container>
            </div>

            <button type="button" class="col-12 big-button" mat-raised-button (click)="addSector()">
              <i class="icon-big_add"></i>
              {{'pages.frameworks.component.buttons.add-sector' | translate}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 3 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">3</span>
              {{'generic.description' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 box flex-column my-0 pb-0 pt-3 px-3">
              <mat-form-field>
                <mat-label>{{'generic.description' | translate}}</mat-label>
                <textarea class="description" matInput rows="5" name="purpose_description" [(ngModel)]="model.purpose_description" placeholder="{{'pages.frameworks.component.placeholders.insert-schema-target'| translate}}" required></textarea>
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 4 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">4</span>
              {{'generic.objectives' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
            <div class="row">
              <div class="col-12 px-2">
                <div class="box px-0">
                  <mat-form-field class="input-primary col-8 pt-2">
                    <input matInput name="primary_target" [(ngModel)]="model.primary_target.title" placeholder="{{'pages.frameworks.component.placeholders.insert-primary-objective' | translate}}" required autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="select-primary col-4" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.evaluation-criterion' | translate}}</mat-label>
                    <mat-select name="primary_target_evaluation" [(ngModel)]="model.primary_target.evaluation_criteria" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-6 px-2" *ngFor="let target of model.secondary_targets; let i = index">
                <ng-container>
                  <div class="box px-0">
                    <mat-form-field class="input-primary col-7 pt-2">
                      <input matInput name="secondary_targets{{i}}" [(ngModel)]="target.title" placeholder="{{'pages.frameworks.component.placeholders.insert-secondary-objective' | translate}}" required autocomplete="off">
                    </mat-form-field>
                    <mat-form-field class="select-primary col-5" appearance="fill">
                      <mat-label>{{'pages.frameworks.component.evaluation-criterion' | translate}}</mat-label>
                      <mat-select name="secondary_target_evaluation{{i}}" [(ngModel)]="target.evaluation_criteria" required>
                        <mat-option value="" selected></mat-option>
                        <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <i class="icon-cestino grey-color right-bottom" (click)="removeSecondaryTarget(i)"></i>
                  </div>
                </ng-container>
              </div>

              <div class="col-6 px-2">
                <div type="button" class="box-button" (click)="addSecondaryTarget()">
                  <p class="my-0">
                    <i class="icon-Add mx-2"></i>
                    {{'pages.frameworks.component.buttons.add-secondary-objective' | translate}}
                  </p>
                </div>
              </div>
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 5 -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <!-- TODO_ALESSANDRO: in seguito al cambiamento della tavola non è pià possibile vedere il pulsante di cancellazione
                              per risolvere ho dovuto sovrascrivere l'overflow-->
        <mat-expansion-panel expanded style="overflow: visible;">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">5</span>
              {{'generic.criteria' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container px-0">
            <div class="row box" *ngFor="let criterio of model.criteria; let i = index">

              <!-- Tipologia e descrizione del criterio -->
              <ng-container>
                <div class="col-12 d-flex">
                  <div class="col-auto box-index">
                    {{i + 1}}
                  </div>
                  <mat-form-field class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.data-criterion' | translate}}</mat-label>
                    <mat-select name="criteria_typology{{i}}" [(ngModel)]="criterio.typology" required (selectionChange)="onChangeCriteriaTypology(i)">
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criteriaType of getCriteriaService().getAllCriteriaTypeForSelect()" [value]="criteriaType.key">{{criteriaType.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.measure-unit' | translate}}</mat-label>
                    <mat-select name="criteria_unit{{i}}" [(ngModel)]="criterio.measure_unit" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let unit of getUnitsService().getAllUnitsForSelect()" [value]="unit.key">{{unit.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)" class="select-primary col-3 px-0" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.classification-criterion' | translate}}</mat-label>
                    <mat-select name="criteria_evaluation{{i}}" [(ngModel)]="criterio.evaluation" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="icons-container">
                  <i class="icon-cestino grey-color float-right" (click)="removeCriteria(i)"></i>
                </div>
                <div class="col-12 d-flex ml-3">
                  <mat-form-field class="input-primary col-12 pt-2">
                    <input matInput name="criteria_description{{i}}" [(ngModel)]="criterio.title" placeholder="{{'generic.description' | translate}}" required autocomplete="off">
                  </mat-form-field>
                </div>
              </ng-container>

              <!-- Campi aggiuntivi -->
              <ng-container *appShowIfOrganization="'enel,alexion'">
                <div class="col-12 d-flex ml-3">
                  <mat-form-field class="input-primary col-3 pt-2" *appShowIfOrganization="'enel'">
                    <input matInput name="criteria_item_id{{i}}" [(ngModel)]="criterio.additional.item_id" placeholder="{{'generic.item-id' | translate}}" required autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="criteria_section{{i}}" [(ngModel)]="criterio.additional.section" placeholder="{{'generic.section' | translate}}" required autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="criteria_sub-section{{i}}" [(ngModel)]="criterio.additional.sub_section" placeholder="{{'generic.sub-section' | translate}}" required autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3 pt-2" *appShowIfOrganization="'enel'">
                    <input matInput name="check_n{{i}}" [(ngModel)]="criterio.additional.check_n" placeholder="{{'Check_n' | translate}}" autocomplete="off">
                  </mat-form-field>
                </div>
                <div class="col-12 d-flex ml-3" *appShowIfOrganization="'enel'">
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="criteria_preliminary_check{{i}}" [(ngModel)]="criterio.additional.preliminary_check" placeholder="{{'generic.preliminary-check' | translate}}" autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="requirements{{i}}" [(ngModel)]="criterio.additional.requirements" placeholder="{{'generic.requirements' | translate}}" autocomplete="off">
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3 pt-2">
                    <input matInput name="reference{{i}}" [(ngModel)]="criterio.additional.reference" placeholder="{{'generic.reference' | translate}}" autocomplete="off">
                  </mat-form-field>
                </div>
              </ng-container>

              <div class="col-12 d-flex ml-3 mt-3"*ngIf="getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">
                <ng-container>
                  <mat-radio-group>
                    <mat-radio-button *ngFor="let option of criterio.options; let criterioOptionIndex = index" [value]="false" [disabled]="true">
                      <ng-container>
                        <mat-form-field appearance="fill" style="width: 400px;">
                          <input class="already-back" matInput name="option_title{{i}}_{{criterioOptionIndex}}" [(ngModel)]="option.title">
                          <mat-icon *ngIf="criterioOptionIndex < criterio.options.length -1" matSuffix style="cursor: pointer;" (click)="removeCriteriaOption(i, criterioOptionIndex)">close</mat-icon>
                          <mat-icon *ngIf="criterioOptionIndex == criterio.options.length -1" matSuffix></mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="ml-3">
                          <input class="already-back" placeholder="{{'generic.weight' | translate}}" matInput name="option_title{{i}}_{{criterioOptionIndex}}_weight" [(ngModel)]="option.weight">
                        </mat-form-field>
                        <button *ngIf="criterioOptionIndex == criterio.options.length -1" type="button" mat-button color="primary" (click)="addCriteriaOption(i)">
                          <i class="icon-Add primary"></i>
                          {{'pages.frameworks.component.buttons.add-criteria-option' | translate}}
                        </button>
                      </ng-container>
                    </mat-radio-button>
                  </mat-radio-group>
                </ng-container>
                </div>
              </div>
            <button type="button" class="col-12 big-button" mat-raised-button (click)="addCriteria()">
              <i class="icon-big_add"></i>
              {{'pages.frameworks.component.buttons.add-criterion' | translate}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


  <!-- Blocco 6 -->
  <div class="accordion-container container-fluid px-0" *ngIf="criteriaTargetsWeightsEnabled">
    <div class="container-fluid px-0">
      <mat-accordion class="accordion pb-0" multi>
        <mat-expansion-panel expanded class="pb-0">
          <mat-expansion-panel-header class="container">
            <mat-panel-title>
              <span class="index">6</span>
              {{'generic.weights' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="container-fluid bg-white px-0 mx-n-24 w-100 border-top-grey border-bottom-grey"
          >
            <div class="container py-4 px-5">
              <div
                class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0"
              >
                <div class="container px-0">
                  <p class="title">
                    {{
                    "pages.frameworks.component.ask-framework-weights"
                      | translate
                    }}
                  </p>
                  <mat-radio-group
                    class="col-12 mat-radio-box px-0"
                    aria-label="Select an option"
                    name="start_new_evaluation"
                    [(ngModel)]="weights_is_setted"
                  >
                    <mat-grid-list cols="12" rowHeight="100px">
                      <ng-container>
                        <mat-grid-tile
                          colspan="6"
                          (click)="choiceSetWeights(true)"
                        >
                          <mat-radio-button [value]="true" class="col-12">{{
                            "generic.answers.yes" | translate
                            }}</mat-radio-button>
                        </mat-grid-tile>
                        <mat-grid-tile
                          colspan="6"
                          (click)="choiceSetWeights(false)"
                        >
                          <mat-radio-button [value]="false" class="col-12">{{
                            "generic.answers.no" | translate
                            }}</mat-radio-button>
                        </mat-grid-tile>
                      </ng-container>
                    </mat-grid-list>
                  </mat-radio-group>
                </div>
              </div>
              <div
                *ngIf="weights_is_setted"
                class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0"
              >
                <div class="container px-0">
                  <p class="title">
                    {{
                    "pages.frameworks.component.ask-framework-weights-typology"
                      | translate
                    }}
                  </p>
                  <mat-radio-group
                    class="col-12 mat-radio-box px-0"
                    aria-label="Select an option"
                    name="start_new_evaluation"
                    [(ngModel)]="weights_mandatories"
                  >
                    <mat-grid-list cols="12" rowHeight="100px">
                      <ng-container>
                        <mat-grid-tile
                          colspan="6"
                          (click)="choiceSetMandatoriesWeights(false)"
                        >
                          <mat-radio-button [value]="false" class="col-12">{{
                            "generic.weights-suggestion" | translate
                            }}</mat-radio-button>
                        </mat-grid-tile>
                        <mat-grid-tile
                          colspan="6"
                          (click)="choiceSetMandatoriesWeights(true)"
                        >
                          <mat-radio-button [value]="true" class="col-12">{{
                            "generic.mandatories" | translate
                            }}</mat-radio-button>
                        </mat-grid-tile>
                      </ng-container>
                    </mat-grid-list>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="container px-0" *ngIf="weights_is_setted && weights_mandatories !== null">
            <app-criteria-targets-weight-update (weightsUpdated)="weightsUpdated($event)" [stringify_framework_model]="stringifyModel"/>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Blocco 6 -->
  <!-- TODO: DA IMPLEMENTARE -> vincoli tra i criteri del framework -->
  <!-- <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">6</span>
              {{'pages.frameworks.component.assign-constraints' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="container px-0">

              TODO_MICHELE: testo descrizione da farci dire da Enzo
              <div class="box-info mb-4">
                <i class="icon-warning-triangle float-left col-2 flex-column big-icon"></i>
                <p class="description col-10">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>

              <div class="row mx-0 row-vincolo" *ngFor="let constraint of model.constraints; let i = index">
                <ng-container>
                  <mat-form-field class="col-6 col-lg-3 select-custom-button" appearance="fill">
                    <mat-label class="purple">{{'pages.frameworks.component.binding-criteria' | translate}}</mat-label>
                    <mat-select name="constraint.criteria_1_order{{i}}" [(ngModel)]="constraint.criteria_1_order" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterio of model.criteria; let j = index" [value]="j">{{j+1}}</mat-option>
                    </mat-select>
                    <span class="label-bottom" *ngIf="model.criteria[constraint.criteria_1_order]">{{getCriteriaService().getCriteriaTypeValueByKey(model.criteria[constraint.criteria_1_order].typology)}}</span>
                  </mat-form-field>
                  <mat-form-field class="col-6 col-lg-3 select-custom-button" appearance="fill">
                    <mat-label class="purple">{{'pages.frameworks.component.bound-criteria' | translate}}</mat-label>
                    <mat-select name="constraint.criteria_2_order{{i}}" [(ngModel)]="constraint.criteria_2_order" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let criterio of model.criteria let z = index" [value]="z">{{z+1}}</mat-option>
                    </mat-select>
                    <span class="label-bottom" *ngIf="model.criteria[constraint.criteria_2_order]">{{getCriteriaService().getCriteriaTypeValueByKey(model.criteria[constraint.criteria_2_order].typology)}}</span>
                  </mat-form-field>
                  <div class="col-11 col-lg-5 box flex-column pb-0 pt-3 px-3">
                    <mat-form-field>
                      <mat-label>{{'generic.description' | translate}}</mat-label>
                      <textarea class="description" matInput rows="3" name="constraint.description{{i}}" [(ngModel)]="constraint.description" placeholder="{{'pages.frameworks.component.placeholders.explain-constraint' | translate}}" required></textarea>
                    </mat-form-field>
                  </div>
                  <div class="col-1 icons-container" (click)="removeConstraint(i)">
                    <i class="icon-cestino grey-color"></i>
                  </div>
                </ng-container>
              </div>

              <button type="button" class="col-12 big-button" [disabled]="model.criteria.length < 2" mat-raised-button (click)="addConstraint()">
                <i class="icon-big_add"></i>
                {{'pages.frameworks.component.buttons.add-constraints' | translate}}
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div> -->


  <!-- Blocco 7 Da completare in futuro: funzionalità assegnazione predefinita del team al framework sulla base del ruolo professionale (CTO, CEO ecc...) nell'organizzazione -->
<!--  <div class="accordion-container container-fluid px-0">
    <div class="container-fluid px-0">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="container">
            <mat-panel-title>
              <span class="index">6</span>
              {{'generic.team' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row py-4 two-radio-box mt-0">
            <div class="container">
              <p class="title">{{'pages.frameworks.component.ask-adding-roles' | translate}}</p>
              <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option" name="option_role" [(ngModel)]="model.options_role">
                <mat-grid-list cols="12" rowHeight="100px">
                  <ng-container>
                    <mat-grid-tile colspan="6" (click)="optionRoleOnClick(true)">
                      <mat-radio-button [value]="true" class="col-12">{{'generic.answers.yes' | translate}}</mat-radio-button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="6" (click)="optionRoleOnClick(false)">
                      <mat-radio-button [value]="false" class="col-12">{{'generic.answers.no' | translate}}</mat-radio-button>
                    </mat-grid-tile>
                  </ng-container>
                </mat-grid-list>
              </mat-radio-group>
            </div>
          </div>

          <div class="row py-4 two-radio-box mt-0" *ngIf="model.options_role">
            <div class="container">
              <p class="title">{{'pages.frameworks.component.roles-must-be' | translate}}</p>
              <mat-radio-group class="col-12 mat-radio-box px-0" aria-label="Select an option" name="option_role_required" [(ngModel)]="model.options_role_required">
                <mat-grid-list cols="12" rowHeight="100px">
                  <ng-container>
                    <mat-grid-tile colspan="6">
                      <mat-radio-button [value]="true" (click)="optionRoleRequiredOnClick(true)" class="col-12">{{'generic.answers.mandatory' | translate}}</mat-radio-button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="6">
                      <mat-radio-button [value]="false" (click)="optionRoleRequiredOnClick(false)" class="col-12">{{'generic.answers.just-suggestion' | translate}}</mat-radio-button>
                    </mat-grid-tile>
                  </ng-container>
                </mat-grid-list>
              </mat-radio-group>
            </div>
          </div>

          <div class="container py-4" *ngIf="question_role_satisfied && model.options_role == true">

            <div class="col-12 box box-two-row" *ngFor="let role of model.roles; let i = index">
              <ng-container>
                <div class="row">
                  <mat-form-field class="select-primary col-5 col-lg-3 px-1" appearance="fill">
                    <mat-label>{{'generic.roles.professional-role' | translate}}</mat-label>
                    <mat-select #teamProfessionalInput name="professional_role{{i}}" [(ngModel)]="role.organization_professional_role_id">
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let professionalRole of professionalRoles" [value]=professionalRole.id>{{professionalRole.role}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="select-primary col-5 col-lg-3 px-1" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.role-in-process' | translate}}</mat-label>
                    <mat-select name="process_role{{i}}" [(ngModel)]="role.process_role" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let processRole of process_roles" [value]="processRole.key">{{getProcessRolesService().getProcessRolesValueByKey(processRole.key)}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="toggle-container col-4" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.able-to-complete-evaluation' | translate}}</mat-label>
                    <div>
                      <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                      <mat-slide-toggle class="toggle" name="complete_process_enabled{{i}}" [(ngModel)]="role.complete_process_enabled" >{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="toggle-container col-3" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.able-to-revise' | translate}}</mat-label>
                    <div>
                      <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                      <mat-slide-toggle class="toggle" name="review_enabled{{i}}" [(ngModel)]="role.review_enabled" >{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                    </div>
                  </div>
                  <div class="toggle-container col-4" appearance="fill">
                    <mat-label>{{'pages.frameworks.component.able-to-add-proposal' | translate}}</mat-label>
                    <div>
                      <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                      <mat-slide-toggle class="toggle" name="provide_new_proposal_enabled{{i}}" [(ngModel)]="role.provide_new_proposal_enabled" >{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                    </div>
                  </div>
                </div>

                <div class="icons-container" (click)="removeRole(i)">
                  <i class="icon-cestino grey-color float-right"></i>
                </div>
              </ng-container>
            </div>

            <button type="button" class="col-12 big-button" mat-raised-button (click)="addRole()">
              <i class="icon-Add"></i>
              {{'pages.frameworks.component.buttons.add-role' | translate}}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>-->
  <!-- Blocco 7 -->
  <div class="accordion-container container-fluid px-0" *ngIf="userService.hasPermissionInCurrentOrganization('framework.update')">
    <div class="container-fluid px-0">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="container">
            <mat-panel-title>
              <span class="index">
                <ng-container *ngIf="!criteriaTargetsWeightsEnabled">6</ng-container>
                <ng-container *ngIf="criteriaTargetsWeightsEnabled">7</ng-container>
              </span>
              {{'pages.frameworks.framework.search-options' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row py-4 two-radio-box mt-0">
            <div class="container">
              <div class="row my-3 px-4">
                <div class="toggle-container col-6 d-inline-block" appearance="fill">
                  <mat-label>{{"pages.frameworks.option.public"| translate}}</mat-label>
                  <div>
                    <span class="mr-2">{{"generic.answers.no" | translate}}</span>
                    <mat-slide-toggle class="toggle" name="framework_public" [(ngModel)]="model.public">{{ "generic.answers.yes" | translate }}</mat-slide-toggle>
                  </div>
                </div>
                <div class="toggle-container col-6 d-inline-block" appearance="fill">
                  <mat-label>{{"pages.frameworks.option.hide"| translate}}</mat-label>
                  <div>
                    <span class="mr-2">{{"generic.answers.no" | translate}}</span>
                    <mat-slide-toggle class="toggle" name="framework_hidden" [(ngModel)]="model.hidden">{{ "generic.answers.yes" | translate }}</mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <div class="buttons-container container py-4">
    <button type="button" class="button-primary float-left" mat-button [routerLink]="['/home']">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
    </button>
    <button type="submit" *ngIf="id" [disabled]="loading" class="button-primary float-right" mat-button (click)="onSubmitUpdate()">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.update' | translate}}
    </button>
    <button type="submit" *ngIf="!id" [disabled]="loading" class="button-primary float-right" mat-button (click)="onSubmit()">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save' | translate}}
    </button>
  </div>
</form>
