<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{'modals.modal-new-proposal.rename-title' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

  <!-- Blocco 1 - Obiettivi -->
  <div class="container-fluid">
    <div class="container">
      <div class="col-12 px-2">
        <div class="box flex-column my-5 pb-0 pt-3 px-3">
                <mat-form-field>
                  <mat-label>{{
                    "generic.title" | translate
                    }}</mat-label>
                  <textarea
                    class="description"
                    matInput
                    resizeToFitContent="true"
                    name="title"
                    placeholder="{{
                              'generic.title'
                                | translate
                            }}"
                    [(ngModel)]="title"
                  >
                          </textarea>
                </mat-form-field>
        </div>
      </div>
      <button
        class="button-primary float-right mb-4"
        mat-button
        (click)="closeDialogue(title)"
        [disabled]="!isEdited()"
      >
        <i class="icon-Forward-arrow-small"></i>
        <label>
          {{ "generic.buttons.save" | translate }}
        </label>
      </button>
              </div>
            </div>
</form>
