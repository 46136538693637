import {Component} from '@angular/core';
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  loading = false;

  constructor(private userService: UserService) {
  }

  changePassword() {
    if (this.newPassword === this.confirmPassword) {
      const data = {
        current_password: this.currentPassword,
        new_password: this.newPassword
      };
      this.loading = true;

      this.userService.changePassword(data, (result) => {
        this.loading = false;
        alert("cambiata");
      }, () => {
        this.loading = false;
        alert("errore");
      });
    }
  }
}
