<div class="container-fluid my-5">
  <div class="row mx-auto justify-content-center mt-5 p-5">
    <div class="col-4 d-flex flex-column align-items-center" *ngFor="let organization of getUserService().getAllOrganizations(), let i = index" style="max-width: 100%;">
      <img *ngIf="organization?.logo_link" [src]="organization?.logo_link" [ngClass]=" getUserService().currentOrganization.id === organization.id ? 'border-custom' : '' " (click)="changeCurrentOrganization(organization.id)" class="organization-logo" [routerLink]="['/home']" alt="">
      <img *ngIf="!organization?.logo_link" src="assets/img/placeholder-img-organization.jpg" [ngClass]=" getUserService().currentOrganization.id === organization.id ? 'border-custom' : '' " (click)="changeCurrentOrganization(organization.id)" class="organization-logo" [routerLink]="['/home']" alt="">
      <h3 (click)="changeCurrentOrganization(organization.id)" class="mt-2" [routerLink]="['/home']">{{organization.name}}</h3>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn-custom shadow px-5 py-2" [routerLink]="['../new']">{{'pages.organizations.organization-management.component.organization-create' | translate}}</button>
  </div>
</div>

