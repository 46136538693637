import {Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScriptLoaderService } from 'angular-google-charts';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { AnalysisMethodsService } from 'src/app/services/config/analysis-methods.service';
import { ProcessRolesService } from 'src/app/services/config/process-roles.service';
import { SuggestionService } from 'src/app/services/config/suggestion.service';
import { DecisionService } from 'src/app/services/decision.service';
import {ModalDashboardAiExplanationComponent} from 'src/app/modals/modal-dashboard-ai-explanation/modal-dashboard-ai-explanation.component';
import {ModalEditWeightsComponent} from 'src/app/modals/modal-edit-weights/modal-edit-weights.component';
import {MatDialog} from '@angular/material/dialog';
import {OrganizationService} from '../../../services/organization.service';

import html2canvas from 'html2canvas';
import {map, startWith} from 'rxjs/operators';
import {AiService} from '../../../services/ai.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface OrganizationInfo {
  id: string | number;
  customs?: {
    features?: {
      virtual_assistant?: boolean;
    };
  };
}

@Component({
  selector: 'app-decision-table-dashboard',
  templateUrl: './decision-table-dashboard.component.html',
  styleUrls: ['./decision-table-dashboard.component.scss'],
})
export class DecisionTableDashboardComponent implements OnInit {
  private _activeRouteSubscription: Subscription;
  @Input() isLoadingEmitter: any;

  id: any;
  decision_table: any = {};

  constructor(
    private activeRoute: ActivatedRoute,
    private loaderService: ScriptLoaderService,
    private decisionService: DecisionService,
    private suggestionService: SuggestionService,
    private processRoleService: ProcessRolesService,
    private analysisService: AnalysisMethodsService,
    private organizationService: OrganizationService,
    private aiService: AiService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) {

    this.isVirtualAssistantEnabled$ = this.organizationInfoSubject.pipe(
      startWith(null),
      map(info => Boolean(info?.customs?.features?.virtual_assistant))
    );

  }

  loading: boolean = false;
  loadingComponent: boolean = false;

  evaluation_sessions_by_row: number = 5;
  private evaluation_sessions_array: any[] = [];
  evaluation_sessions_row_1 = [];
  evaluation_sessions_row_2 = [];

  currentEvaluationSessionNumber;
  currentEvaluationSessionDashboard: any = {};

  // organizationInfo: any = {};

  private organizationInfoSubject = new BehaviorSubject<OrganizationInfo | null>(null);
  isVirtualAssistantEnabled$: Observable<boolean>;
  response: string;
  renderedHTML: SafeHtml = '';
  disabledCopyButton: boolean = true;

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(
      (routeParams) => {
        this.id = routeParams.id_decision;

        //  recupero le informazioni per questo tavolo decisionale
        this.getDecisionTable();


      }
    );
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  /**
   * Recupera la dashboard a partire dall'id della sessione di valutazione da guardare
   * @param evaluation_session_id
   */
  getDashboardforSession(evaluation_session_id, callback) {
    // getDashboardforSession(evaluation_session_id, callback: any = {}) {

    this.decisionService.getDashboardForSession(
      evaluation_session_id,
      (response) => {
        this.currentEvaluationSessionDashboard = response;

        //  rimuovo tutti i criteri quantitativi in modo da non visualizzarli in tabella e nei risultati
        this.currentEvaluationSessionDashboard.proposals.forEach((proposal) => {
          proposal.criteria = proposal.criteria.filter(
            (x) => x.typology == 'QUALITATIVE'
          );
        });

        callback(true);
      },
      () => {
        callback(false);
      }
    );
  }

  takeScreenshot() {
    html2canvas(document.body).then(canvas => {
      const screenshot = canvas.toDataURL('image/png');

      // Convert canvas to blob and download captured screen
      // canvas.toBlob(blob => {
      //   if (blob) {
      //     // Create a temporary URL for the blob
      //     const url = window.URL.createObjectURL(blob);
      //
      //     // Create a link element and trigger download
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.download = 'chart_screenshot.png';
      //     link.click();
      //
      //     // Clean up
      //     window.URL.revokeObjectURL(url);
      //
      //     // Convert blob to base64 and send to backend
      //     const reader = new FileReader();
      //     reader.onloadend = () => {
      //       const base64data = reader.result as string;
      //       // this.sendScreenshotToBackend(base64data);
      //     };
      //     reader.readAsDataURL(blob);
      //   }
      // }, 'image/png');

      this.isLoadingEmitter = true;

      this.aiService.dashboardExplanation(screenshot, (result) => {

        this.loading = false;
        this.response = result.analysis;

        this.updateHTML();
        this.disabledCopyButton = false;
        this.isLoadingEmitter = false;

        const dialogRef = this.dialog.open(ModalDashboardAiExplanationComponent, {
          panelClass: 'medium-dialog',
          data: {
            response: this.response,
            // organization_id: this.currentOrganization['id'],
          }
        });

        dialogRef.afterClosed().subscribe(attachmentData => {
          if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
            // this.init();
          }
        });
      });
    });
  }

  onClickModalDashboardAiExplanation() {
    this.takeScreenshot();
  }

  updateHTML() {
    const html = this.convertMarkdownToHTML(this.response);
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private convertMarkdownToHTML(markdown: string): string {
    // This is a very basic conversion. You'd want to expand this for a full Markdown implementation.
    let html = markdown
      .replace(/#{1,6}\s?([^\n]+)/g, (match, p1, offset, string) => {
        const level = match.trim().startsWith('######') ? 6 :
                      match.trim().startsWith('#####') ? 5 :
                      match.trim().startsWith('####') ? 4 :
                      match.trim().startsWith('###') ? 3 :
                      match.trim().startsWith('##') ? 2 : 1;
        return `<h${level}>${p1}</h${level}>`;
      })
      .replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>')
      .replace(/(\*|_)(.*?)\1/g, '<em>$2</em>')
      .replace(/\n/g, '<br>');
    return html;
  }

  /** Recupera le info del tavolo decisionale */
  getDecisionTable() {
    this.loading = true;

    this.decisionService.get(
      this.id,
      (result: any) => {
        this.decision_table = JSON.parse(JSON.stringify(result));
        this.evaluation_sessions_array = JSON.parse(
          JSON.stringify(this.decision_table.evaluation_sessions)
        );

        //  recupero le informazioni per far partire una nuova valutazione
        this.currentEvaluationSessionNumber =
          this.decision_table.evaluation_sessions[
            this.decision_table.evaluation_sessions.length - 1
          ].evaluation_session;

        this.evaluation_sessions_row_1 = this.evaluation_sessions_array.splice(
          0,
          this.evaluation_sessions_by_row
        );
        this.evaluation_sessions_row_2 = this.evaluation_sessions_array;

        let lastSession =
          this.decision_table.evaluation_sessions[
            this.decision_table.evaluation_sessions.length - 1
          ];
        if (lastSession) {
          this.decisionService.getDecisionTableResult(
            this.decision_table.id,
            lastSession.id,
            (tableResult) => {
              //  passo i risultati se ci sono
              this.decision_table.decisionTableResult = tableResult;

              //  recupero tutte le informazioni aggiuntive della sessione di valutazione
              this.getDashboardforSession(lastSession.id, (callback) => {
                this.loading = false;

                if (callback == true) this.loadingComponent = true;
                this.getCurrentOrganization();
              });
            }
          );
        }
      },
      () => (this.loading = false)
    );
  }

  getCurrentOrganization() {
    return this.organizationService.getOrganization(this.decision_table.organization_id, (result: OrganizationInfo) =>  {
          this.organizationInfoSubject.next(result);
          this.loading = false;

        }, () => {
          this.loading = false;
          // this.isLoadingEmitter = false;
        });
  }

  /**
   * Evento di pressione su una sessione di valutazione nella timeline
   * @param index
   */
  onClickEvaluationSession(index) {
    //  se sto cambiando sessione di valutazione allora ridisegno tutto e scarico i dati aggiornati per la nuova sessione
    if (
      this.decision_table.evaluation_sessions[index].evaluation_session !=
      this.currentEvaluationSessionNumber
    ) {
      this.currentEvaluationSessionNumber =
        this.decision_table.evaluation_sessions[index].evaluation_session;

      this.getDashboardforSession(
        this.decision_table.evaluation_sessions[index].id,
        (callback) => {
          if (callback) {
            this.loading = false;
            this.loadingComponent = true;
          }
        }
      );
    }
  }

  getAnalysisService() {
    return this.analysisService;
  }

  public getSuggestionService() {
    return this.suggestionService;
  }

  get weights_from_framework_or_table_exists () {
    return this.currentEvaluationSessionDashboard?.used_weights && !this.currentEvaluationSessionDashboard?.settings?.weights_provided_users;
  }

  editSessionWeights(edit_mode=true) {
    const dialogRef = this.dialog.open(ModalEditWeightsComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        evaluation_session_id: this.currentEvaluationSessionDashboard?.used_weights?.evaluation_session_id,
        decision_table: this.decision_table,
        edit_mode
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.decision_table.weights_is_setted = true;
      }
    });
  }
}
