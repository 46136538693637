import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/services/general/toast.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { OrganizationTypeService } from 'src/app/services/config/organization-type.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddAttachmentComponent } from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import { Subscription } from 'rxjs';
import { WorkingSectorsService } from 'src/app/services/config/workingSectors.service';
import { AlertService } from 'src/app/services/general/alert.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  organizationInfo : any = {};
  organization_id: any;

  sectors: any = [];

  private maxNumOfSectorShowed: number = 8;
  currentNumOfSectorsShowed: number = this.maxNumOfSectorShowed;

  constructor(private toast: ToastService, private typologiesService: OrganizationTypeService, private dialog: MatDialog,
    private activeRoute: ActivatedRoute,private organizationService : OrganizationService, private workingSectors: WorkingSectorsService,
    private alert: AlertService, private userService: UserService, private router: Router, private translateService : TranslateService) {}

  ngOnInit(): void {

    this.sectors = this.workingSectors.sectors;

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  init() {

    this.loading = true;
    this.organizationService.getOrganization(this.organization_id, (result) =>  {
      this.organizationInfo = result;
      this.prepareSector();
      this.loading = false;
    }, () => this.loading = false)
  }


  /**
   * Prepara i settori contenuti nel model dell'organizzazione
   */
  prepareSector() {

    this.organizationInfo.sectors.forEach(element => {
      let findIndex = this.sectors.findIndex(x => x.key == element.sector);
      if (findIndex >= 0) {
        this.sectors[findIndex].selected = true;
      }
    });
  }



  openDialog() {
    let dialogRef = this.dialog.open(ModalAddAttachmentComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result : ${result}`);
    });
  }

  public getTypologiesService() {
    return this.typologiesService;
  }



  clickEvent(sector : any){
    sector.selected = !sector.selected;

    if (this.organizationInfo.id) {
      if (sector.selected == true){
        this.organizationService.addOrganizationSector(this.organizationInfo.id, sector.key, (result) => {
          this.organizationInfo.sectors.push(result);
          this.toast.success(this.translateService.instant("generic.sector"),this.translateService.instant("pages.organizations.organization.component.success-sector-added"));
        }, () => {
          this.toast.error(this.translateService.instant("generic.sector"),this.translateService.instant("pages.organizations.organization.component.error-sector-added"));
        });
      }
      else {
        let organizationRoleIndex = this.organizationInfo.sectors.findIndex(x => x.sector == sector.key);
        this.organizationService.removeOrganizationSector(this.organizationInfo.id, this.organizationInfo.sectors[organizationRoleIndex].id, () => {
          this.organizationInfo.sectors.splice(organizationRoleIndex, 1);
          this.toast.success(this.translateService.instant("generic.sector"),this.translateService.instant("pages.organizations.organization.component.success-sector-removed"));
        }, () => {
          this.toast.error(this.translateService.instant("generic.sector"),this.translateService.instant("pages.organizations.organization.component.error-sector-removed"));
        });
      }
    }
  }

  onClickShowMore() {
    this.currentNumOfSectorsShowed += this.maxNumOfSectorShowed;
  }

  getUserService() {
    return this.userService;
  }


  onSubmit(form: NgForm) {

    if(!this.organizationInfo.name) {
      this.toast.error(this.translateService.instant("generic.warning"),this.translateService.instant("pages.organizations.organization.component.error-organization-name"));
      return;
    }

    if (!this.organizationInfo.typology) {
      this.toast.error(this.translateService.instant("generic.warning"),this.translateService.instant("pages.organizations.organization.component.error-organization-typology"));
      return;
    }

    if (!form.form.valid)
      return;

    this.organizationInfo.sectors = [];

    this.sectors.forEach(el => {
      if(el.selected == true){
        this.organizationInfo.sectors.push(el.key);
      }
    });

    if(this.organizationInfo.sectors.length <= 0){
      this.toast.error(this.translateService.instant("generic.warning"), this.translateService.instant("pages.organizations.organization.component.error-sector-choice"));
      return;
    }

    if (this.organizationInfo.id) {
      this.updateOrganizazion();
      return
    }

    //  salvo una nuova organizzazione
    this.saveNewOrganization();
    this.router.navigate(['/home'], {relativeTo: this.activeRoute});
  }


  /**
   * Aggiorna una organizzazione già creata
   */
  updateOrganizazion() {

    this.loading = true;
    this.organizationService.updateOrganization(this.organizationInfo, (result) => {
      this.userService.updateOrganization(result);
      this.init();
      this.toast.success(this.translateService.instant("generic.organization"),this.translateService.instant("pages.organizations.organization.component.success-data-organization-updated"));
    },() =>
    {
      this.toast.error(this.translateService.instant("generic.organization"),this.translateService.instant("pages.organizations.organization.component.error-save-organization"));
      this.loading = false
    });
  }


  /**
   * Salva una nuova organizzazione
   */
  saveNewOrganization() {

    this.loading = true;
    this.organizationService.saveOrganization(this.organizationInfo, (result) => {

      this.organizationInfo = result;

      //  quando salvo una nuova organizzazione devo aggiornare anche i dati dell'utente
      this.userService.getUser((user) => {
        this.alert.showQuestionWithSuccess("",this.translateService.instant("pages.organizations.organization.component.organization-created-change-organization"), (ok) => {
          this.userService.setPreferredOrganization(this.organizationInfo.id);
        });

        this.loading = false;
        this.router.navigate([`../${this.organizationInfo.id}/show`], {relativeTo: this.activeRoute});
      }, () => {
        this.loading = false
        this.toast.error(this.translateService.instant("generic.organization"), this.translateService.instant("pages.organizations.organization.component.error-data-organization"));
        });

    }, () => {
      this.loading = false
      this.toast.error(this.translateService.instant("generic.organization"),this.translateService.instant("pages.organizations.organization.component.error-save-organization"));
    });
  }



  /**
   * Aggiungo un logo all'organizzazione
   */
  onClickUploadLogo() {

    let uploadUrl = 'upload/tmp-organization-logo';
    if (this.organizationInfo.id)
      uploadUrl = `upload/organization/${this.organizationInfo.id}/logo`

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'Upload Logo',
        attachmentType: 'FILE',
        maxUploadsFile: 1,
        organization_id: this.organizationInfo.id,
        uploadUrl: uploadUrl
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {

      if (attachmentData?.attachmentObject){
        if (this.organizationInfo.id) {
          this.userService.updateOrganization(attachmentData.attachmentObject[0]);
          this.toast.success(this.translateService.instant("generic.organization"),this.translateService.instant("pages.organizations.organization.component.success-logo-added"));
          this.init();
        }
        else {
          this.organizationInfo.attachment_files = attachmentData.attachmentObject[0];
          this.organizationInfo.logo_link = attachmentData.attachmentObject[0].link;
        }
      }
    });

  }
}
