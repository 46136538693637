import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationService } from 'src/app/services/organization.service';

import { faFile, faTimes, faPlay } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from 'src/app/services/config.service';
import { slideInAnimation } from 'src/app/_animations/slideId.animation';

@Component({
  selector: 'app-modal-template-email',
  templateUrl: './modal-template-email.component.html',
  styleUrls: ['./modal-template-email.component.scss'],
  animations: [slideInAnimation],
})
export class ModalTemplateEmailComponent implements OnInit {

  faFile = faFile;
  faTimes = faTimes;
  faPlay = faPlay;

  constructor(
    public dialogRef: MatDialogRef<ModalTemplateEmailComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService, private configService: ConfigService
  ) { }

  loading: boolean = false;
  loadingSearch: boolean = false;
  isFirstSearch: boolean = false;
  model: any = {
    attachments: []
  };
  organization_id: string;

  searchAttachmentBox: boolean = false;

  templates: any = [];
  displayedColumns: string[] = ['title', 'actions'];
  templatesData: any = new MatTableDataSource([]);

  ngOnInit(): void {

    if (this.data && Object.keys(this.data).length > 0) {
      this.organization_id = this.data.organization_id;
      if (this.data.template) {
        this.model = this.data.template;
      }
    }
  }


  onSubmit(form: NgForm) {

    if (form.invalid) {
      return;
    }

    if (this.model.id) {
      this.loading = true;
      this.organizationService.updateTemplateEmail(this.organization_id, this.model, (result) => {
        this.loading = false;
        this.closeDialogue({event: 'added', data: this.model});
        }, () => {
          this.loading = false;
        }
      );
    }
    else {
      this.loading = true;
      this.organizationService.saveTemplateEmail(this.organization_id, this.model, (result) => {
        this.loading = false;
        this.closeDialogue({event: 'added', data: this.model});
        }, () => {
          this.loading = false;
        }
      );
    }

  }

  timerSearch: any = null;
  onsearch(searchText: string) {

    if (searchText == '' || searchText.length < 3) {
      return;
    }

    if (this.timerSearch != null) {
      clearTimeout(this.timerSearch);
    }

    this.timerSearch = setTimeout(() => {
      this.performSearch(searchText);
    }, 500);
  }


  /**
   * Effettua una ricerca tra i documenti dell'organizzazione
   * @param search
   */
  performSearch(search: string) {

    this.loadingSearch = true;
    this.organizationService.searchOrganizationDocument(this.organization_id, search, (result) => {
        this.loadingSearch = false;
        this.isFirstSearch = true;

        this.templates = result;
        this.templatesData = new MatTableDataSource(result);
      }, () => this.loadingSearch = false
    );
  }


  searchAttachment(value) {

    if (value == false) {
      this.clearSearch();
    }

    this.searchAttachmentBox = value;
  }

  clearSearch() {
    this.isFirstSearch = false;
    this.templates = [];
    this.templatesData = new MatTableDataSource([]);
    this.model.searchText = '';
  }

  /**
   * Mostra un documento
   * @param element
   */
  showDocument(element) {

    if (element.id && element.link) {
      window.open(element.link);
    }
  }

  addDocument(element) {

    if (element.id) {
      if (!this.model.attachments.find(item => item.id == element.id)) {
        this.model.attachments.push(element);
        this.clearSearch();
        this.searchAttachmentBox = false;
      }
    }
  }

  removeDocument(document) {

    if (document.id) {
      let findIndex = this.model.attachments.findIndex(item => item.id == document.id);
      if (findIndex > -1) {
        this.model.attachments.splice(findIndex, 1);
      }
    }
  }

  closeDialogue(event: any = null) {

    this.dialogRef.close({event:event});
  }


  public getConfigService() {
    return this.configService;
  }

}
