import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class CriteriaService {

  constructor(private translateService: TranslateService) { }

  private _criteria: any = [
    { type: "single" , key: "HIGHESTISTHEBEST", value: this.translateService.instant('service.criteria.highestisthebest')},
    { type: "single" , key: "LOWESTISTHEBEST", value: this.translateService.instant('service.criteria.lowestisthebest')},
    // { type: "bytarget", key: "THECLOSESTTO", value: 'Il più vicino è il migliore'},
  ];

  private _criteriaType: any = [
    { key: "QUALITATIVE", value: this.translateService.instant('service.criteria.qualitative') },
    { key: "QUANTITATIVE", value: this.translateService.instant('service.criteria.quantitative') },
    { key: "MULTIPLECHOICE", value: this.translateService.instant('service.criteria.multiplechoice') },
  ]


  /** Controllo se il criterio è quantitativo */
  public criteriaIsQuantitative(criteriaKey: string) {

    if (criteriaKey && criteriaKey == 'QUANTITATIVE')
        return true;

    return false;
  }

  /** Controllo se il criterio è qualitativo */
  public criteriaIsQualitative(criteriaKey: string) {

    if (criteriaKey && criteriaKey == 'QUALITATIVE')
        return true;

    return false;
  }

  /** Controllo se il criterio è a scelta multipla */
  public criteriaIsMultipleChoice(criteriaKey: string) {

    if (criteriaKey && criteriaKey == 'MULTIPLECHOICE')
      return true;

    return false;
  }

  getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }


  get criteria() {
    return this._criteria.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllCriteriaForSelect() {
    return this._criteria.slice(0);
  }

  public getCriteriaValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._criteria, key);
  }






  get criteriaType() {
    return this._criteriaType.splice(0);
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllCriteriaTypeForSelect() {
    return this._criteriaType.slice(0);
  }


  public getCriteriaTypeValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._criteriaType, key);
  }

}
