import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(private backend: BackendService) { }

    /**
   * Recupera il riassunto di una sessione di valutazione
   * @param id_evaluation_session
   * @param success_callback
   * @param error_callback
   */
  public getSummary(id_evaluation_session: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get(`response/${id_evaluation_session}`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Recupera la situazione dei pesi degli obiettivi per una determinata sessione di valutazione
   * @param success_callback
   * @param error_callback
   */
  public getTargetEvaluation(id_evaluation_session: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get(`response/${id_evaluation_session}/targets`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  /**
   * Recupera la situazione dei pesi degli obiettivi per una determinata sessione di valutazione
   * @param success_callback
   * @param error_callback
   */
  public getCriteriaEvaluation(id_evaluation_session: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get(`response/${id_evaluation_session}/criteria`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Salva il peso degli obiettivi secondari sui primari per una sessione di valutazione
   * @param id_evaluation_session
   * @param targets_evaluated
   * @param success_callback
   * @param error_callback
   */
  public saveEvaluationTargets(id_evaluation_session:any, targets_evaluated: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post(`response/${id_evaluation_session}/weight/targets` ,targets_evaluated).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }

  /**
   * Salva i pesi dei criteri sugli obiettivi per una sessione di valutazione
   * @param id_evaluation_session
   * @param criteria_evaluated
   * @param success_callback
   * @param error_callback
   */
  public saveEvaluationCriteria(id_evaluation_session, criteria_evaluated: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post(`response/${id_evaluation_session}/weight/criteria` ,criteria_evaluated).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }


  /**
   * Salva i pesi delle proposal
   * @param id_evaluation_session
   * @param proposal_evaluated
   * @param success_callback
   * @param error_callback
   */
  public saveEvaluationProposal(id_evaluation_session, proposal_evaluated: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post(`response/${id_evaluation_session}/weight/proposals` ,proposal_evaluated).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }


  /**
   * Salvo le risposte comprese di feedback
   * @param id_evaluation_session
   * @param responses
   * @param success_callback
   * @param error_callback
   */
  public saveResponses(id_evaluation_session, responses: any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post(`response/${id_evaluation_session}/answers` ,responses).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }


  /**
   * Chiude la sessione di riposte dell'utente
   * @param id_evaluation_session
   * @param success_callback
   * @param error_callback
   */
  public closeResponse(id_evaluation_session, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.get(`response/${id_evaluation_session}/close`).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }


  /**
   * Rimuove un attachment passando il tipo l'id e il tipo
   * @param id_evaluation_session
   * @param attachment_id
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public removeAttachment(id_evaluation_session, model:any, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.delete(`response/${id_evaluation_session}/delete-attachment`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    })
  }


  /**
   * Setta un evento basato sul timer dell'utente
   * @param id_evaluation_session
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public setTimerEvent(id_evaluation_session, model, success_callback = (data: any) => { }) {

    this.backend.post(`response/${id_evaluation_session}/timer-event`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
    })
  }



  /**
   * Evento di apertura di un allegato
   * @param id_evaluation_session
   * @param model
   * @param success_callback
   */
  public setAttachmentEvent(id_evaluation_session, model, success_callback = (data: any) => { }) {

    this.backend.post(`response/${id_evaluation_session}/attachment-event`, model).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
    })
  }
}
