import {Component, OnInit} from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {UserTypeService} from 'src/app/services/config/user-types.service';
import {OrganizationService} from 'src/app/services/organization.service';
import {ToastService} from "../../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";
import { MatDialog } from '@angular/material/dialog';
import { ModalResetPasswordComponent } from 'src/app/modals/modal-reset-password/modal-reset-password.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-all-users-management',
  templateUrl: './all-users-management.component.html',
  styleUrls: ['./all-users-management.component.scss']
})
export class AllUsersManagementComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  users: any = [];
  organization_id: any;

  user: any;

  searchTerm = '';

  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'roles', 'user_type', 'reset-password', 'actions'];
  usersData: any = new MatTableDataSource([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activeRoute: ActivatedRoute, private organizationService: OrganizationService, private userTypeService: UserTypeService, private router: Router, private toast: ToastService, private transateService: TranslateService,
              private dialog: MatDialog, private translateService: TranslateService, public userService: UserService) {
  }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }


  init() {

    this.loading = true;
    this.organizationService.allOrganizationUsers(this.organization_id, (result) => {
      this.users = result;

      this.usersData = new MatTableDataSource(result);
      this.loading = false;
    }, () => this.loading = false);
  }


  /** cerca nella tabella in base a nome cognome e email */
  onSearch() {

    if (this.searchTerm.length == 0) {
      this.usersData = new MatTableDataSource(this.users);
      return;
    }

    this.usersData = this.users.filter(element => {
      if (this.searchTerm) {

        if (element?.user?.details?.first_name && element?.user?.details?.first_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }

        if (element?.user?.details?.last_name && element?.user?.details?.last_name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }

        if (element.user.name && element.user.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }

        if (element.user.email && element.user.email.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }

        return false;
      }
    });

  }

  onClearSearch() {
    this.usersData = this.users;
    this.usersData = new MatTableDataSource(this.users);
  }


  public getUserTypes() {
    return this.userTypeService;
  }

  // reset password utente
  onClickResetPassword(userId: number) {

    this.user = this.users.find((user: any) => {
      if (user.id == userId)
        return user;
    })

    const dialogRef = this.dialog.open(ModalResetPasswordComponent, {
      panelClass: 'medium-dialog',
      data: {
        user: this.user
      }
    })

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }

}




