import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementPlanService {

  constructor(private backend: BackendService) { }


  /**
   * Salva un task
   */
  public saveTask(task, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.post(`management-plan/${task.decision_management_plan_id}/create`, {task: task}).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }


  /**
   * Aggiorna un task
   */
  public updateTask(task, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.put(`management-plan/${task.decision_management_plan_id}/update/${task.id}`, {task: task}).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }

  /**
   * Cancella un task
   */
  public deleteTask(task, success_callback = (data: any) => { }, error_callback = (error: any) => { } ) {

    this.backend.delete(`management-plan/${task.decision_management_plan_id}/delete/${task.id}`, {task: task}).subscribe(result => {
      success_callback(result);
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      error_callback(error);
    });
  }



}
