<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'modals.modal-edit-weights.title' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>
<app-criteria-targets-weight-update
  *ngIf="inputData?.decision_table"
  [decisionTable]="inputData?.decision_table"
  [editMode]="inputData?.edit_mode"
  (weightsUpdated)="closeDialogue($event)"
  [evaluation_session_id]="inputData?.evaluation_session_id"
  (close)="closeDialogue()"
/>
