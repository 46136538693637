import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AnalysisMethodsService} from '../../../services/config/analysis-methods.service';
import {ProcessRolesService} from '../../../services/config/process-roles.service';
import {UserService} from '../../../services/user.service';
import {OrganizationService} from '../../../services/organization.service';
import {DecisionService} from '../../../services/decision.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SurveyService} from '../../../services/surveys.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ModalSurveyDetailsComponent} from "../../../modals/modal-survey-details/modal-survey-details.component";

@Component({
  selector: 'app-all-surveys',
  templateUrl: './all-surveys.component.html',
  styleUrls: ['./all-surveys.component.scss']
})
export class AllSurveysComponent implements OnInit{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() projectId: any;
  @Input() input_surveys: any;
  constructor(
    public userService: UserService,
    public organizationService: OrganizationService,
    public decisionService: DecisionService,
    public route: ActivatedRoute,
    public router: Router,
    public surveyService: SurveyService,
    public dialog: MatDialog,
  ) {
  }

  dataSource: MatTableDataSource<SurveyData>;
  columnsToDisplay = ['title', 'created_at', 'created_by_user', 'visibility_type', 'action'];

  loading = false;
  surveys = [];

  searchModel: any = {
    startDate: undefined,
    endDate: undefined,
    name: ''
  };
  typologiesList: string[] = [
    'Private',
    'Public'
  ];
  selectedTypologies = new FormControl('');

  ngOnInit(): void {
    if (this.input_surveys) {
      this.setDataSourceTable(JSON.parse(JSON.stringify(this.input_surveys)));
    } else {
      this.getAllSurveys();
    }
  }

  public getAllSurveys() {
    this.loading = true;
    this.surveyService.getSurveysByProjectId(this.projectId, (result) => {
      this.setDataSourceTable(result);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  public setDataSourceTable(surveys) {
    this.surveys = surveys;
    this.dataSource = new MatTableDataSource<SurveyData>(surveys);
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 100);
  }

  public getCreatorFullNameBySurvey(survey) {
    const userDetails = survey.created_by_user.details;

    if (userDetails.first_name && userDetails.last_name) {
      return userDetails.first_name + ' ' + userDetails.last_name;
    } else {
      return survey.created_by_user.email;
    }
  }

  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
  }

  applyFilterToDataSource() {
    this.dataSource.data = [...this.surveys];
    if (this.searchModel.name.length > 0) {
      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        return item.title
          .toLowerCase()
          .includes(this.searchModel.name.toLowerCase());
      });
    }
    if (this.searchModel.startDate) {
      const startDateObj = new Date(this.searchModel.startDate);

      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        const createdAt = new Date(item.created_at);
        return createdAt >= startDateObj;
      });
    }

    if (this.searchModel.endDate) {
      const endDateObj = new Date(this.searchModel.endDate);
      this.dataSource.data = this.dataSource.filteredData.filter((item) => {
        // @ts-ignore
        const closedAt = new Date(item?.closed_at);
        return closedAt <= endDateObj;
      });
    }

    if (this.selectedTypologies.value.length > 0) {
      this.dataSource.data = this.dataSource.filteredData.filter(
        (item) => {
          return this.selectedTypologies.value.includes(this.capitalizeFirstLetter(item?.visibility_type));
        }
      );
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onClickResetFilters() {

    this.searchModel = {
      startDate: undefined,
      endDate: undefined,
      name: ''
    };

    this.selectedTypologies = new FormControl('');

    this.dataSource.data = [...this.surveys];
  }

  addNewSurvey() {
    const dialogRef = this.dialog.open(ModalSurveyDetailsComponent, {
      panelClass: 'fullscreen-dialog',
      disableClose: true,
      data: {
        projectId: this.projectId,
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined && result?.id) {
        this.router.navigate(['surveys/' + result?.id]);
      }
    });
  }
}

export interface SurveyData {
  title: string;
  created_at: Date;
  created_by_user: number;
  visibility_type: string;
  action: string;
}

