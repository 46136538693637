import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProcessRolesService } from 'src/app/services/config/process-roles.service';
import { DecisionService } from 'src/app/services/decision.service';
import { ResponseService } from 'src/app/services/response.service';
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-responses-thank-you',
  templateUrl: './responses-thank-you.component.html',
  styleUrls: ['./responses-thank-you.component.scss']
})
export class ResponsesThankYouComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  id: any;

  decision_table: any;
  organization:any
  response_stamps: any

  isDecisionCreator: boolean = false;

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private userService: UserService,
    private decisionService: DecisionService, private processRoleService: ProcessRolesService, private router: Router) { }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_evaluation_session;
      if (this.id) {
          this.getSummary();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  /** Recupero tutte le info delle risposte */
  getSummary() {
    this.loading = true;
    this.responseService.getSummary(this.id, (result) => {

      this.decision_table = result.decision_table;
      this.organization = result.decision_table.organization;
      this.response_stamps = result.response_stamps;

      this.checkUserRoleOnDecision();

      this.loading = false;
    }, () => {
      this.loading = false;
      this.router.navigate(['/home']);
    })
  }

  /** Contolla il ruolo dell'utente per mandarlo al tavolo decisionale se è possibile */
  checkUserRoleOnDecision() {
    this.loading = true;
    this.decisionService.getDecisionTableSessionRolesForUser(this.decision_table.id, this.id, (result) => {

      result.forEach(role => {
        if (this.processRoleService.isResponsible(role) || this.processRoleService.isAccountable(role)) {
          this.isDecisionCreator = true;
        }
      });

      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

}
