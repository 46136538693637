import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DecisionService } from 'src/app/services/decision.service';
import { ModalAddAttachmentComponent } from '../modal-add-attachment/modal-add-attachment.component';

@Component({
  selector: 'app-modal-report-attachment',
  templateUrl: './modal-report-attachment.component.html',
  styleUrls: ['./modal-report-attachment.component.scss']
})
export class ModalReportAttachmentComponent implements OnInit {

  loading: boolean = false;
  modalData: any;

  attachments: any = [];
  allAttachments: any = [];

  displayedColumns: string[] = ['title', 'opened'];

  constructor(public dialogRef: MatDialogRef<ModalAddAttachmentComponent>, @Inject(MAT_DIALOG_DATA) public data: {},
  private decisionService: DecisionService) { }

  ngOnInit(): void {

    if (this.data && Object.keys(this.data).length > 0) {
      this.modalData = JSON.parse(JSON.stringify(this.data));
      this.getAttachmentEventsForTeamMember();
    }
  }

  init() {
    this.allAttachments = [];
    this.attachments.attachment_files.forEach(file => {
      file.event = this.attachments.attachment_events.find(x => x.attachment_id == file.id);
      this.allAttachments.push(file);
    });
    this.attachments.attachment_pubmed.forEach(pubmed => {
      pubmed.event = this.attachments.attachment_events.find(x => x.attachment_id == pubmed.id);
      this.allAttachments.push(pubmed);
    });

  }

  getAttachmentEventsForTeamMember() {

    this.loading = true;
    this.decisionService.getAttachmentEvent(this.modalData.proposal_criteria_id, this.modalData.team_member_id, (response) => {

      this.attachments = response;
      this.init();

      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }
}
