import { Component, OnInit,Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/general/toast.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-add-work-exp',
  templateUrl: './modal-add-work-exp.component.html',
  styleUrls: ['./modal-add-work-exp.component.scss']
})

export class ModalAddWorkExpComponent implements OnInit {

  loading: boolean = false;

  model: any = {
    works : [],
    qualification: '',
    company : '',
    locality : '',
    date_start: '',
    date_end : '',
    date_end_selected : false,
    description : ''
  }

  constructor(
    public dialogRef: MatDialogRef<ModalAddWorkExpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private toast: ToastService,
    private userService: UserService,
    private translateService : TranslateService
    ) { }

  ngOnInit(): void {
  if(this.data.workExperience != null && this.data.workExperience != undefined)
    {this.model = JSON.parse(JSON.stringify(this.data.workExperience))
      this.model.date_end == null ? this.model.date_end_selected = true : this.model.date_end_selected = false
    }
  };

  disableDateEnd() {
    this.model.date_end_selected = !this.model.date_end_selected;
  }

  onSubmit(form: NgForm) {

    if(this.model.qualification == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-work-exp.component.error-enter-qualification'));
      return;
    }

    if(this.model.company == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-work-exp.component.error-enter-company'));
      return;
    }
    if(this.model.locality == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-work-exp.component.error-enter-locality'));
      return;
    }
    if(this.model.date_start == '') {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-work-exp.component.error-enter-date-start'));
      return;
    }
    // if(this.model.date_end == '' ) {
    //   this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-curriculum.component.error-enter-date-end'));
    //   return;
    // }

    if(this.model.date_end_selected == true) {
      this.model.date_end = null
    }

    if (!form.form.valid)
    return;

    if (this.model.id) {
      this.updateWorkExperience();
      return;
    }

      this.userService.saveWorkExperience(this.data.curriculum_id,this.model, (result) => {
        this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-work-exp.component.success-workexp-saved"));
        this.loading = false;
          this.closeDialogue('saved');

      }, (error) => {
        this.loading = false;
        this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-work-exp.component.error-workexp-saved"));
      })
  }

  updateWorkExperience() {

    this.loading = true;
    this.userService.updateWorkExperience(this.data.curriculum_id, this.model.id , this.model , (result) => {
      this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-work-exp.component.success-workexp-updated"));
      this.loading = false;
      this.closeDialogue('updated');
    },(error) =>
    {
      this.loading = false
      this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-work-exp.component.error-workexp-updated"));
    });
  }



  closeDialogue(event: any = null) {

    this.dialogRef.close({event:event});
  }
}
