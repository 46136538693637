import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import { DecisionService } from 'src/app/services/decision.service';
import { NgForm } from '@angular/forms';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-decision-table-closing',
  templateUrl: './decision-table-closing.component.html',
  styleUrls: ['./decision-table-closing.component.scss']
})
export class DecisionTableClosingComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  decision_targets : any = []
  decision_targets_closed: any = [];

  decision_id: any;

  model : any = {
    primary_target: {},
    secondary_targets: [],
  }

  constructor(private activeRoute: ActivatedRoute , private decisionService : DecisionService,private unitsService: UnitsService, private criteriaService: CriteriaService,private toast: ToastService,private translateService : TranslateService) { }

  ngOnInit(): void {
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.decision_id = routeParams.id_decision;
      if (this.decision_id) {
        this.init();
      }
    });
  }


  init() {

    this.loading = true;
    this.decisionService.getTableTargets(this.decision_id ,(result) => {
      this.decision_targets = result;

      //  TODO_FRANCESCO: parte che recupera dalle api le informazioni del closing
      {

        this.decision_targets_closed = [];

        this.prepareTargets();
        this.loading = false;
      }

    }, () => this.loading = false);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  prepareTargets () {

    this.model.primary_target = this.decision_targets.find(x => x.type == 'primary');
    this.model.secondary_targets = this.decision_targets.filter(x => x.type == 'secondary');

    //  TODO_FRANCESCO: compilare il primary target
    let target_closed = this.decision_targets_closed.find(x => x.target_id == this.model.primary_target.id);
    if(target_closed)
      this.model.primary_target.achived = target_closed;
      else {
        this.model.primary_target.achived = {
          data: undefined,
          valore: undefined,
          target_id: this.model.primary_target.id
        }
      }



    this.model.secondary_targets.forEach(secondary_target => {
      let targets_closed = this.decision_targets_closed.find(x => x.target_id == secondary_target.id);
      if (targets_closed)
        secondary_target.achived = targets_closed;
      else {
        secondary_target.achived = {
          data: undefined,
          valore: undefined,
          target_id: secondary_target.id
        }
      }
    });
  }

  public getUnitsService() {
    return this.unitsService;
  }
  public getCriteriaService() {
    return this.criteriaService;
  }
  public getDecisionService() {
    return this.decisionService;
  }


  onSubmit(form: NgForm) {

    let arrayAchived = [];

    // TODO_TRADUZIONI
    if (this.model.primary_target.achived) {
      arrayAchived.push(this.model.primary_target.achived);
    }

    this.model.secondary_target.forEach(secondary_target => {
      if (secondary_target.achived)
        arrayAchived.push(secondary_target.achived);
    });

    //  TODO_FRANCESCO: inviare al server


    // if(this.targetPrimary.achived.planned_achived_date == null) {
    //   this.toast.error(this.translateService.instant("generic.warning"),
    //   this.translateService.instant("pages.decision-tables.decision-table-closing.component.error-planned_achived_date"));
    //   return;
    // }
    // // TODO_TRADUZIONI
    // if(this.modelPlanned.actual_achived_value == null) {
    //   this.toast.error(this.translateService.instant("generic.warning"),
    //   this.translateService.instant("pages.decision-tables.decision-table-closing.component.error-planned_achived_date"));
    //   return;
    // }

    if (!form.form.valid)
    return;

    this.saveTargetAchived();
  }

  saveTargetAchived(){}



}
