import {Component, Inject, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../services/organization.service";
import {ConfigService} from "../../services/config.service";
import {UserTypeService} from "../../services/config/user-types.service";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-modal-import-organization-charts',
  templateUrl: './modal-import-organization-charts.component.html',
  styleUrls: ['./modal-import-organization-charts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ModalImportOrganizationChartsComponent implements OnInit {
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<OrganizationUser>>;

  dataSource: MatTableDataSource<OrganizationChart>;
  organizationChartsData: OrganizationChart[] = [];
  columnsToDisplay = ['title', 'created_at', 'members_count', 'action'];
  innerDisplayedColumns = ['name', 'email'];
  expandedElement: OrganizationChart;


  loading: boolean = false;
  organizationCharts = [];
  constructor(
    public dialogRef: MatDialogRef<ModalImportOrganizationChartsComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {
      selectedTeamMembers: [],
      organizationId: null,
    },
    private cd: ChangeDetectorRef,
    private organizationService: OrganizationService, private configService: ConfigService,
    private userService: UserService,
    private userTypeService: UserTypeService,
  ) {
  }

  ngOnInit(): void {
    this.data = JSON.parse(JSON.stringify(this.data))
    this.init();
  }

  init() {
    this.loading = true;
    this.organizationService.allOrganizationCharts(this.userService.currentOrganization.id, (result) => {
      this.organizationCharts = result;
      result.forEach(organizationChart => {
        organizationChart.members_count = organizationChart.organization_users.length;
        const tmpOrgUsrs = [];
        organizationChart.organization_users.forEach((org_usr) => {
          tmpOrgUsrs.push({...org_usr, ...org_usr.user})
        })
        organizationChart.organization_users = tmpOrgUsrs;
        if (organizationChart.organization_users && Array.isArray(organizationChart.organization_users) && organizationChart.organization_users.length) {
          this.organizationChartsData = [...this.organizationChartsData, {...organizationChart, organization_users: new MatTableDataSource(organizationChart.organization_users)}];
        } else {
          this.organizationChartsData = [...this.organizationChartsData, organizationChart];
        }
      });
      this.dataSource = new MatTableDataSource(this.organizationChartsData);
      this.dataSource.sort = this.sort;
      this.loading = false;
    });
  }

  toggleRow(element: OrganizationChart) {
    element.organization_users && (element.organization_users as MatTableDataSource<OrganizationUser>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.cd.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<OrganizationUser>).sort = this.innerSort.toArray()[index]);
  }

  applyFilter(filterValue: string) {
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<OrganizationUser>).filter = filterValue.trim().toLowerCase());
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }

  importOrganizationUsers(element) {
    this.closeDialogue(element?.organization_users?.data.map((data) => {
      return {...data, imported_by_organization_chart_id: element.id}
    }))
  }
}

export interface OrganizationChart {
  title: string;
  created_at: string;
  members_count: string;
  organization_users?: OrganizationUser[] | MatTableDataSource<OrganizationUser>;
}

export interface OrganizationUser {
  first_name: string;
  last_name: string;
  email: string;
}

export interface OrganizationChartsDataSource {
  title: string;
  created_at: string;
  members_count: string;
  organization_users?: MatTableDataSource<OrganizationUser>;
}
