import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../services/decision.service';

@Component({
  selector: 'app-modal-add-contest',
  templateUrl: './modal-add-contest.component.html',
  styleUrls: ['./modal-add-contest.component.scss']
})
export class ModalAddContestComponent {
  dataModel = {
    title: '',
    description: '',
  };

  constructor(public dialogRef: MatDialogRef<ModalAddContestComponent>, @Inject(MAT_DIALOG_DATA) public data: {decision_table_id: null}, private decisionService: DecisionService) {
  }

  onSubmit() {
    this.decisionService.addNewContest(this.data.decision_table_id, this.dataModel, (result) => {
      this.closeDialogue(result);
    });
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }
}
