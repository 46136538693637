<app-loading [loading]="isGeneratingPdf"></app-loading>

<form (keydown.enter)="$event.preventDefault()" novalidate>
  <!-- Blocco 1 - Obiettivi -->

  <div id="to-print">

    <div class="container-fluid accordion-container" *ngIf="isEnel()">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">1</span>
                {{ "generic.details" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="container-fluid box-sub-header pb-0" style="background-color: #F1F2F2" *ngIf="!loading">

              <div class="container" style="background-color: white">
                <div class="container-fluid accordion-container p-0">
                  <mat-accordion class="accordion m-0 bg-white p-0" hideToggle>
                    <mat-expansion-panel expanded class="m-0 bg-white p-0 border-0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                  <span
                    class="font-weight-bold">Checklist</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="container ">
                        <p>
                          {{ currentProposal.title }}
                        </p>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div class="container-fluid accordion-container p-0">
                  <mat-accordion class="accordion m-0 bg-white p-0" hideToggle>
                    <mat-expansion-panel expanded class="m-0 bg-white p-0 border-0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                  <span
                    class="font-weight-bold">{{ "pages.frameworks.component.decisional-schema-target" | translate }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="container">
                        <p>
                          {{ decision_table?.problem_statement?.thesis }}
                        </p>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <div class="container-fluid accordion-container p-0" style="background-color: white">
                  <mat-accordion class="accordion mt-3" hideToggle style="background-color: white">
                    <mat-expansion-panel expanded class="m-0 bg-white p-0 border-0">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                  <span
                    class="font-weight-bold">{{ "pages.responses.welcome.problem-statement-context" | translate }}</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="container">
                        <p>
                          {{ decision_table?.problem_statement?.context }}
                        </p>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>


    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">2</span>
                {{ "generic.objectives" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row pl-4 header-title">
              <!-- <p class="mb-0 mr-2">Tempo impiegato per la compilazione</p> -->
              <!-- <span class="main-color text-uppercase">1 ORA</span> -->
            </div>

            <div class="row">
              <div class="col-12 px-2" *ngIf="currentProposal.primary_target">
                <div class="box px-0 box-two-row">
                  <mat-label class="box-title px-3">{{
                      "components.proposals.new-proposal.primary" | translate
                    }}
                  </mat-label>
                  <mat-form-field class="input-primary col-6">
                    <input
                      matInput
                      name="target_primary_title"
                      [ngModel]="currentProposal.primary_target.title"
                      placeholder="{{ 'generic.title' | translate }}"
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field class="datepicker-primary col-3">
                    <mat-label>{{
                        "components.proposals.new-proposal.due-date" | translate
                      }}
                    </mat-label>
                    <input
                      matInput
                      name="target_primary_date"
                      class="ng-trim-ignore color-default"
                      [ngModel]="
                      currentProposal.primary_target.date != null
                        ? (currentProposal.primary_target.date
                          | formatdate
                          | date : 'shortDate')
                        : 'non definita'
                    "
                      placeholder="{{
                      'components.proposals.new-proposal.due-date' | translate
                    }}"
                      disabled
                    />
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3">
                    <mat-label>{{
                        "generic.measure-unit" | translate
                      }}
                    </mat-label>
                    <input
                      matInput
                      name="target_primary_unit"
                      [ngModel]="
                      getUnitsService().getUnitValueByKey(
                        currentProposal.primary_target.measure_unit
                      )
                    "
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6">
                    <input
                      matInput
                      name="target_primary_objective"
                      placeholder="{{
                      'components.proposals.new-proposal.target-value'
                        | translate
                    }}"
                      [ngModel]="
                      getCriteriaService().getCriteriaValueByKey(
                        currentProposal.primary_target.evaluation_criteria
                      )
                    "
                      readonly
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="input-primary col-3"
                    *ngIf="currentProposal.primary_target.expected_value"
                  >
                    <input
                      matInput
                      name="proposal_target_primary_expected_value"
                      placeholder="{{
                      'components.proposals.new-proposal.desidered-value'
                        | translate
                    }}"
                      [ngModel]="currentProposal.primary_target.expected_value"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="input-primary col-3 font-weight-bold"
                    *ngIf="currentProposal.primary_target.index_overall"
                  >
                    <input
                      matInput
                      class="font-weight-bold purple-color"
                      name="overall_index"
                      placeholder="{{
                      'components.proposals.new-proposal.index-overall'
                        | translate
                    }}"
                      [ngModel]="currentProposal.primary_target.index_overall"
                    />
                  </mat-form-field>
                  <i class="icon-circle_info top-right grey-color"></i>
                </div>
              </div>

              <div
                class="col-6 px-2"
                *ngFor="
                let target of currentProposal?.secondary_targets;
                let i = index
              "
              >
                <ng-container>
                  <div class="box px-0 box-two-row">
                    <mat-label class="box-title px-3"> {{
                        "components.proposals.new-proposal.secondary" | translate
                      }}
                    </mat-label>
                    <!-- <h4>
                      <mat-form-field class="input-primary col-6">
                        <em>
                        <input
                          matInput
                          name="secondary_targets{{ i }}"
                          [(ngModel)]="target.title"
                          readonly
                        />
                        </em>
                        <strong>Value: </strong> <em>{{target?.chartData?.value}}</em>
                      </mat-form-field>
                    </h4> -
                    ->

                    <!-- <mat-form-field class="select-primary col-6" appearance="fill">
                                  <mat-label>{{'components.proposals.new-proposal.evaluation-criteria' | translate}}</mat-label>
                                  <mat-select name="secondary_target_evaluation{{i}}" [(ngModel)]="target.evaluation_criteria" required>
                                    <mat-option value="" selected></mat-option>
                                    <mat-option *ngFor="let criterion of getCriteriaService().getAllCriteriaForSelect()" [value]="criterion.key">{{criterion.value}}</mat-option>
                                  </mat-select>
                                </mat-form-field> -->

                    <mat-form-field class="input-primary col-10">
                      <mat-label>
                        {{ 'generic.title' | translate }}
                      </mat-label>
                      <em>
                        <input
                          matInput
                          name="secondary_targets{{ i }}"
                          [(ngModel)]="target.title"
                          readonly
                        />
                      </em>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-2">
                      <mat-label>{{
                          "generic.value" | translate
                        }}
                      </mat-label>
                      <em>
                        <input
                          matInput
                          name="target_primary_unit"
                          value="{{target?.chartData?.value}}"
                          readonly
                        />
                      </em>
                    </mat-form-field>
                    <div class="col-12">
                      <div
                        style="width: 100%"
                        id="chart_div_target_{{ target.title }}"
                      ></div>

                    </div>
                    <i class="icon-circle_info top-right grey-color"></i>
                  </div>
                </ng-container>
              </div>

              <!-- Text Area -->
              <!-- <div class="col-12 px-2">
                              <div class="box flex-column mb-0 pb-0 pt-3 px-3">
                                  <mat-form-field>
                                      <mat-label>Feedback</mat-label>
                                      <textarea class="description" matInput rows="3" name=""
                                          placeholder="Inserire feedback"></textarea>
                                  </mat-form-field>
                              </div>
                          </div> -->
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Blocco 2 - Criteri -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="index">3</span>
                {{ "generic.criteria" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row pl-4 header-title">
              <!-- <p class="mb-0 mr-2">Tempo impiegato per la compilazione</p> -->
              <!-- <span class="main-color text-uppercase">1 ORA</span> -->
            </div>

            <div
              class="container-fluid accordion-container mt-3 accordion-inner"
              *ngFor="let criterio of currentProposal.criteria; let i = index"
            >
              <ng-container>
                <div class="container">
                  <mat-accordion class="accordion" multi>
                    <mat-expansion-panel [expanded]="true">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <mat-icon
                            class="yellow-color"
                            *ngIf="
                            getSuggestionService().isAlertSuggestion(
                              criterio.results?.suggestion
                            )
                          "
                            aria-hidden="false"
                          >report_problem
                          </mat-icon
                          >
                          &nbsp;
                          {{ criterio.title }}
                          <!--                        <span class="float-right ml-auto font-weight-bold mr-2">-->
                          <!--                                        <i class="icon-circle_info mr-2"></i>-->
                          <!--                          {{ getCriteriaService().getCriteriaTypeValueByKey(criterio.typology) }}-->
                          <!--                          <span class="yellow-color"-->
                          <!--                                *ngIf="criterio.is_revisioned">| {{ 'generic.revisioned' | translate }}</span>-->
                          <!--                        </span>-->
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="row">
                        <div class="ml-auto my-3">
                          <button
                            *ngIf="criterio.modifyEnabled && criterionIsChanged(criterio)"
                            class="size-small text-white mr-2"
                            mat-flat-button
                            color="secondary"
                            (click)="saveCriterionValue(criterio)"
                            [disabled]="decision_table.closing_date != null"
                          >
                            {{ "generic.buttons.save" | translate }}
                          </button>
                          <button
                            *ngIf="!criterio.modifyEnabled && isContestProposalAccepted(proposal)"
                            class="size-small text-white"
                            mat-flat-button
                            color="secondary"
                            (click)="onClickModifyDescription(criterio)"
                            [disabled]="decision_table.closing_date != null"
                          >
                            {{ "generic.buttons.modify" | translate }}
                          </button>
                          <button
                            *ngIf="criterio.modifyEnabled"
                            class="size-small danger text-white"
                            mat-flat-button
                            (click)="onClickCancelDescription(criterio)"
                          >
                            {{ "generic.cancel" | translate }}
                          </button>
                        </div>
                      </div>
                      <!-- Text Area -->
                      <div class="col-12 px-2">
                        <div class="box flex-column my-0 pb-0 pt-3 px-3">
                          <ng-container *ngIf="getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">
                            <div *ngIf="criterio?.options?.length > 0" class="row">
                              <div class="col-8">
                                <small class="col-12 m-0 p-0 text-muted">{{ "generic.options" | translate }}</small>
                                <ng-container *ngIf="isEnel()">
                                  <mat-radio-group class="col-12" disabled>
                                    <div class="row" *ngFor="let option of criterio.options; let o = index">
                                      <div class="col-12">
                                        <mat-radio-button disabled [value]="option.id"

                                                          [checked]="option.title === criterio?.response[0]?.option_selected">{{ option.title }}
                                        </mat-radio-button>
                                      </div>
                                    </div>
                                  </mat-radio-group>
                                </ng-container>
                                <ng-container *ngIf="!isEnel()">
                                  <ng-container *ngFor="let option of criterio.options; let o = index">
                                    <div class="col-8 mt-2 p-0">
                                      {{ o + 1 }}. {{ option.title }}  - <span class="display-percentage"
                                                                               [ngClass]="option?.isBiggest ? 'purple-color': ''"
                                                                               *ngIf="option.responsePercentage">{{ option.responsePercentage }}
                                      %</span>
                                      <!-- <mat-form-field class="full-width" appearance="fill">
                                        <input style="vertical-align: super;" matInput [(ngModel)]="option.title" name="criteria_options{{o}}" required>
                                      </mat-form-field> -->
                                    </div>
                                    <!-- peso associato alla singola opzione -->
                                    <div class="col-2 mt-2">
                                      <!-- <mat-form-field class="full-width" appearance="fill">
                                        <mat-label>{{'generic.weight' | translate}}</mat-label>
                                        <input type="number" matInput [(ngModel)]="option.weight" name="options_weight{{i}}_{{o}}" placeholder="0" required>
                                      </mat-form-field> -->
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </div>
                              <div class="col-4" *ngIf="!isEnel()">
                                <ng-container *ngIf="criterio?.response?.length > 0">
                                  <p class="text-center py-5"
                                     *ngIf="loadingGraphics">{{ "components.proposals.new-proposal.loading-graph"| translate }}</p>
                                  <div style="width: 100%" id="pie_chart_{{ criterio.id }}"></div>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container
                            *ngIf="
                            !getCriteriaService().criteriaIsMultipleChoice(
                              criterio.typology
                            )
                          "
                          >
                            <mat-form-field *ngIf="criterio.typology === 'QUALITATIVE'">
                              <mat-label>{{
                                  "generic.description" | translate
                                }}
                              </mat-label>
                              <textarea
                                class="description"
                                matInput
                                required
                                resizeToFitContent="true"
                                name="criterio_description{{ i }}"
                                placeholder="{{
                                'components.proposals.new-proposal.placeholders.insert-description'
                                  | translate
                              }}"
                                [(ngModel)]="criterio.value"
                                [disabled]="!criterio.modifyEnabled"
                              ></textarea>
                            </mat-form-field>


                            <mat-form-field *ngIf="criterio.typology === 'QUANTITATIVE'">
                              <mat-label>{{
                                  "generic.numeric-value" | translate
                                }}
                              </mat-label>
                              <input matInput name="criterio_description{{i}}"
                                     [type]="'number'"
                                     [(ngModel)]="criterio.value" required autocomplete="off"
                                     [disabled]="!criterio.modifyEnabled"
                              />
                            </mat-form-field>

                          </ng-container>
                        </div>
                        <!-- <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                              <mat-form-field>
                                                  <mat-label>Descrizione</mat-label>
                                                  <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                      placeholder="Inserire descrizione"></textarea>
                                              </mat-form-field>
                                          </div> -->
                      </div>

                      <!-- Parte dedicata all'aggiunta degli allegati -->
                      <div class="row pt-4 pb-0 my-3" *ngIf="!isEnel()">
                        <div class="col-12 mt-3">
                          <mat-label
                            class="label-box font-weight-bold"
                            *ngIf="criterio?.attachment?.length > 0"
                          >
                            {{
                              "components.proposals.new-proposal.loaded-evidences"
                                | translate
                            }}
                          </mat-label>
                          <div
                            class="attachment-row"
                            *ngFor="let attachment of criterio.attachment"
                          >
                          <span class="name">
                            <i class="icon-pdf"></i>
                            <a [href]="attachment.link" target="_blank">{{
                                attachment.title
                              }}</a>
                          </span>
                            <span *ngIf="criterio.modifyEnabled" class="size float-right"
                                  (click)="removeAttachment(attachment, criterio)">
                            {{ "generic.buttons.delete" | translate }}
                              <i class="icon-cestino main-color"></i>
                          </span>
                          </div>
                          <div
                            class="col-12 links-container"
                            [hidden]="decision_table.closing_date != null || !criterio.modifyEnabled"
                          >
                            <button
                              mat-button
                              color="primary"
                              [matMenuTriggerFor]="menuAttachment"
                            >
                              <i class="icon-upload mr-2"></i>
                              {{
                                "components.proposals.new-proposal.add-new-evidences"
                                  | translate
                              }}
                            </button>
                            <mat-menu #menuAttachment="matMenu">
                              <button
                                mat-menu-item
                                (click)="
                                onClickAddNewEvidence(criterio, 'PUBMED')
                              "
                              >
                                <mat-icon aria-hidden="false"
                                >file_upload
                                </mat-icon
                                >
                                Pubmed
                              </button>
                              <button
                                mat-menu-item
                                (click)="onClickAddNewEvidence(criterio, 'FILE')"
                              >
                                <mat-icon aria-hidden="false"
                                >file_upload
                                </mat-icon
                                >
                                File
                              </button>
                            </mat-menu>
                          </div>
                        </div>
                      </div>

                      <!-- Non ci sono grafici per elementi quantitativi -->
                      <div
                        class="row"
                        *ngIf="
                        getCriteriaService().criteriaIsQualitative(
                          criterio.typology
                        )
                      "
                      >
                        <div class="col-6">
                          <p class="text-center py-5" *ngIf="loadingGraphics">
                            {{
                              "components.proposals.new-proposal.loading-graph"
                                | translate
                            }}
                          </p>
                          <div
                            style="width: 100%"
                            id="chart_div_{{ criterio.title }}"
                          ></div>
                        </div>
                        <div class="col-6">
                          <p class="text-center py-5" *ngIf="loadingGraphics">
                            {{
                              "components.proposals.new-proposal.loading-graph"
                                | translate
                            }}
                          </p>
                          <p class="text-center">
                            {{
                              "modals.modal-new-proposal.points-avg" | translate
                            }}
                          </p>
                          <p class="text-center py-2" style="font-size: 50px">
                            {{ criterio?.chartData?.avg_weight }}
                          </p>
                        </div>
                      </div>

                      <div class="row" *ngIf="
                          getCriteriaService().criteriaIsQualitative(
                            criterio.typology
                          ) && getSuggestionService().getConsensusSuggestValueByKey(
                              criterio.results?.suggestion
                            )
                        ">
                        <!-- Non ci sarà suggerimento per gli elementi quantitativi -->
                        <mat-form-field
                          class="input-primary col-6 mt-3"
                        >
                          <mat-label>
                            {{ "generic.hint" | translate }}
                            <i class="icon-circle_info grey-color ml-2"></i>
                          </mat-label>
                          <input
                            matInput
                            name="risk_suggestion{{ i }}"
                            [ngModel]="
                            getSuggestionService().getConsensusSuggestValueByKey(
                              criterio.results?.suggestion
                            )
                          "
                            class="{{
                            getSuggestionService().getClassSuggestionForCriteria(
                              criterio.results
                            )
                          }}"
                            readonly
                          />
                        </mat-form-field>
                      </div>

                      <!-- Non ci sarà tabella delle risposte per elementi quantitativi -->

                      <div
                        class="row"
                        *ngIf="
                        criterio.response &&
                        getCriteriaService().criteriaIsQualitative(
                          criterio.typology
                        )
                      "
                      >
                        <!-- Tabella Feedback -->
                        <div
                          class="col-12 px-2"
                          *ngIf="criterio.response.length > 0"
                        >
                          <mat-table
                            [dataSource]="criterio.response"
                            class="table-primary"
                          >
                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.name" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index">
                              <span
                                *ngIf="element?.team_member?.name && showPrivateInfo; else prova">{{ element?.team_member?.name }}</span>
                                <ng-template #prova>
                                  <span>{{ "generic.user" | translate }} {{ i + 1 }}</span>
                                </ng-template>
                              </mat-cell>
                            </ng-container>

                            &lt;!&ndash; Sentiment Column &ndash;&gt;
                            <ng-container matColumnDef="sentiment">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{ "generic.sentiment" | translate }}
                              </mat-header-cell>
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-icon
                                  aria-hidden="false"
                                  *ngIf="element.sentiment_score == 0"
                                >sentiment_neutral
                                </mat-icon
                                >
                                <mat-icon
                                  aria-hidden="false"
                                  style="color: #00bc1d"
                                  *ngIf="element.sentiment_score > 0"
                                >sentiment_satisfied_alt
                                </mat-icon
                                >
                                <mat-icon
                                  aria-hidden="false"
                                  style="color: #ff0000"
                                  *ngIf="element.sentiment_score < 0"
                                >sentiment_very_dissatisfied
                                </mat-icon
                                >
                              </mat-cell>
                            </ng-container>

                            <!-- Feedback Column -->
                            <ng-container matColumnDef="feedback">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="flex: 0 0 50% !important"
                              >{{
                                  "generic.feedback" | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="flex: 0 0 50% !important"
                              >
                                {{ element.feedback }}
                              </mat-cell>
                            </ng-container>

                            <!-- Score Column -->
                            <ng-container matColumnDef="score">
                              <mat-header-cell *matHeaderCellDef
                              >Value
                              </mat-header-cell
                              >
                              <mat-cell *matCellDef="let element">
                                <mat-chip-list>
                                  <mat-chip
                                    style="justify-content: center"
                                    *ngIf="element.evaluation_proposal"
                                  >{{
                                      element.evaluation_proposal.weight
                                    }}
                                  </mat-chip
                                  >
                                  <mat-chip
                                    style="justify-content: center"
                                    *ngIf="!element.evaluation_proposal"
                                  >no value
                                  </mat-chip
                                  >
                                </mat-chip-list>
                              </mat-cell>
                            </ng-container>

                            <!-- Confidence Column -->
                            <ng-container matColumnDef="confidence">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "components.proposals.new-proposal.confidence"
                                    | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-chip-list
                                >
                                  <mat-chip>{{
                                      element.confidence
                                    }}
                                  </mat-chip>
                                </mat-chip-list
                                >
                              </mat-cell>
                            </ng-container>

                            <!-- evidence Column -->
                            <ng-container matColumnDef="evidence">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "components.proposals.new-proposal.evidences"
                                    | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                                (click)="element?.attachment_expert_pubmed.length + element?.attachment_expert_files.length > 0 ? showExpertEvideces(element) : ''"
                              >
                                <mat-chip-list
                                >
                                  <mat-chip>{{
                                      element?.attachment_expert_pubmed.length +
                                      element?.attachment_expert_files.length
                                    }}
                                  </mat-chip>
                                </mat-chip-list
                                >
                              </mat-cell>
                            </ng-container>

                            <!-- allegati aperti Column -->
                            <ng-container matColumnDef="attachment_events">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "components.proposals.new-proposal.attachment-opened"
                                    | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-chip-list
                                >
                                  <mat-chip
                                    style="cursor: pointer"
                                    *ngIf="element.attachment_events"
                                    (click)="element?.attachment_events.length > 0 ? onClickAttachmentEventList(element) : ''"
                                  >{{
                                      element?.attachment_events.length
                                    }}
                                  </mat-chip
                                  >
                                </mat-chip-list
                                >
                              </mat-cell>
                            </ng-container>

                            <mat-header-row
                              *matHeaderRowDef="displayedColumns"
                            ></mat-header-row>
                            <mat-row
                              *matRowDef="let row; columns: displayedColumns"
                            ></mat-row>
                          </mat-table>
                        </div>

                        <!-- Text Area -->
                        <!-- <div class="col-12 px-2">
                            <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                <mat-form-field>
                                    <mat-label>Descrizione</mat-label>
                                    <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                        placeholder="Inserire descrizione"></textarea>
                                </mat-form-field>
                            </div>
                        </div> -->
                      </div>

                      <!-- Tabella per elementi a scelta multipla -->

                      <div class="row mb-2"
                           *ngIf="criterio.response && getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">
                        <!-- Tabella Feedback-->
                        <div class="col-12 px-2" *ngIf="criterio.response.length > 0 || isEnel()">
                          <mat-table [dataSource]="isEnel() ? [{}] : criterio.response" class="table-primary">
                            <!-- Check n Column -->
                            <ng-container matColumnDef="item_id">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.item-id" | translate
                                }}
                              </mat-header-cell>

                              <mat-cell *matCellDef="let element">
                                <span>{{ criterio?.additional?.item_id }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Section Column -->
                            <ng-container matColumnDef="section">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.section" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index">
                                <span>{{ criterio?.additional?.section }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Subsection Column -->
                            <ng-container matColumnDef="sub_section">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.sub-section" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index">
                                <span>{{ criterio?.additional?.sub_section }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Check n Column -->
                            <ng-container matColumnDef="check_n">
                              <mat-header-cell *matHeaderCellDef>
                                Check_n
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index">
                                <span>{{ criterio?.additional?.check_n }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.name" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index">
                              <span
                                *ngIf="element?.team_member?.name && showPrivateInfo; else privateTemplate">{{ element?.team_member?.name }}</span>
                                <ng-template #privateTemplate>
                                  <span>{{ "generic.user" | translate }} {{ i + 1 }}</span>
                                </ng-template>
                              </mat-cell>
                            </ng-container>

                            <!-- Sentiment Column -->
                            <ng-container matColumnDef="sentiment">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{ "generic.sentiment" | translate }}
                              </mat-header-cell>
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-icon
                                  aria-hidden="false"
                                  *ngIf="element.sentiment_score == 0"
                                >sentiment_neutral
                                </mat-icon
                                >
                                <mat-icon
                                  aria-hidden="false"
                                  style="color: #00bc1d"
                                  *ngIf="element.sentiment_score > 0"
                                >sentiment_satisfied_alt
                                </mat-icon
                                >
                                <mat-icon
                                  aria-hidden="false"
                                  style="color: #ff0000"
                                  *ngIf="element.sentiment_score < 0"
                                >sentiment_very_dissatisfied
                                </mat-icon
                                >
                              </mat-cell>
                            </ng-container>

                            <!-- Feedback Column -->
                            <ng-container matColumnDef="feedback">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="flex: 0 0 30% !important"
                              >{{
                                  "generic.feedback" | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="flex: 0 0 30% !important"
                              >
                                {{ element.feedback }}
                              </mat-cell>
                            </ng-container>

                            <!-- Draft Action Column -->
                            <ng-container matColumnDef="draft_action" *ngIf="!isEnel()">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="flex: 0 0 30% !important"
                              >{{
                                  "generic.draft-action" | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="flex: 0 0 30% !important"
                              >
                                {{ element.draft_action }}
                              </mat-cell>
                            </ng-container>

                            <!-- Option Column -->
                            <ng-container matColumnDef="option_value">
                              <mat-header-cell *matHeaderCellDef>{{
                                  "generic.options" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element">
                                <mat-chip-list>
                                  <mat-chip
                                    [matTooltip]="element.option_selected"
                                    [matTooltipPosition]="'above'"
                                    style="justify-content: center"
                                    *ngIf="element"
                                  >{{ element.option_selected_index }}
                                  </mat-chip
                                  >
                                  <mat-chip
                                    style="justify-content: center"
                                    *ngIf="!element"
                                  >no value
                                  </mat-chip
                                  >
                                </mat-chip-list>
                              </mat-cell>
                            </ng-container>

                            <!-- evidence Column -->
                            <ng-container matColumnDef="evidence" *ngIf="!isEnel()">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "components.proposals.new-proposal.evidences"
                                    | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-chip-list
                                >
                                  <mat-chip>{{
                                      element?.attachment_expert_pubmed.length +
                                      element?.attachment_expert_files.length
                                    }}
                                  </mat-chip>
                                </mat-chip-list
                                >
                              </mat-cell>
                            </ng-container>

                            <!-- allegati aperti Column -->
                            <ng-container matColumnDef="attachment_events" *ngIf="!isEnel()">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "components.proposals.new-proposal.attachment-opened"
                                    | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <mat-chip-list
                                >
                                  <mat-chip
                                    style="cursor: pointer"
                                    *ngIf="element.attachment_events"
                                    (click)="element?.attachment_events.length > 0 ? onClickAttachmentEventList(element) : ''"
                                  >{{
                                      element?.attachment_events.length
                                    }}
                                  </mat-chip
                                  >
                                </mat-chip-list
                                >
                              </mat-cell>
                            </ng-container>

                            <mat-header-row
                              *matHeaderRowDef="displayedColumnsMultipleChoice"
                            ></mat-header-row>
                            <mat-row
                              *matRowDef="
                              let row;
                              columns: displayedColumnsMultipleChoice
                            "
                            ></mat-row>
                          </mat-table>
                        </div>

                        <!-- Text Area -->
                        <!-- <div class="col-12 px-2">
                                              <div class="box flex-column my-0 pb-0 pt-3 px-3 bordered">
                                                  <mat-form-field>
                                                      <mat-label>Descrizione</mat-label>
                                                      <textarea class="description" matInput rows="2" name="criterio_description{{i}}"
                                                          placeholder="Inserire descrizione"></textarea>
                                                  </mat-form-field>
                                              </div>
                                          </div> -->
                      </div>

                      <ng-container
                        *ngIf="isEnel() && criterio?.response?.length > 0 && criterio?.response[0].feedback !== criterio?.response[0]?.option_selected">
                        <div class="my-5">
                          <div class="row justify-content-center">
                            <span class="font-weight-bold text-center"
                                  style="color: #9169DB">{{ "generic.feedback" | translate }}</span>
                          </div>
                          <mat-form-field class="w-100 mt-3" appearance="fill">
                            <textarea matInput disabled [value]="criterio.response[0].feedback"></textarea>
                          </mat-form-field>
                        </div>
                      </ng-container>

                      <div class="row justify-content-center" *ngIf="criterio?.tasks?.length > 0">
                      <span
                        class="font-weight-bold my-4"
                        style="color: #9169DB;">Task collegate</span>

                        <!-- Tabella Tasks-->
                        <div class="col-12 px-2">

                          <mat-table
                            [dataSource]="criterio.tasks"
                            class="table-primary"
                          >
                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                              <mat-header-cell *matHeaderCellDef style="justify-content: center">{{
                                  "generic.action" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index" style="justify-content: center">
                                <span>{{ element?.action }}</span>
                              </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="end_date">
                              <mat-header-cell *matHeaderCellDef style="justify-content: center">{{
                                  "generic.end_date" | translate
                                }}
                              </mat-header-cell>
                              <mat-cell *matCellDef="let element; let i = index" style="justify-content: center">
                                <span>{{ element?.end_date | date }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Responsible Column -->
                            <ng-container matColumnDef="responsible">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "pages.task-management.component.responsible" | translate
                                }}
                              </mat-header-cell
                              >
                              <mat-cell
                                *matCellDef="let element"
                                style="justify-content: center"
                              >
                                <span>{{ element?.assigned_user_name }}</span>
                              </mat-cell>
                            </ng-container>

                            <!-- Responsible Column -->
                            <ng-container matColumnDef="status">
                              <mat-header-cell
                                *matHeaderCellDef
                                style="justify-content: center"
                              >{{
                                  "generic.status.status" | translate
                                }}
                              </mat-header-cell
                              >

                              <div class="row flex-column">
                                <mat-cell *matCellDef="let row">
                                  <mat-chip-list>
                                    <mat-chip role="button" selectable="true" style="justify-content:center;"
                                              [matTooltip]="taskStatusTooltip(row)" [matTooltipPosition]="'below'"
                                              [ngStyle]="{'background-color': taskStatusColor(row)}"
                                              class="text-white cursor">
                                      <mat-icon *ngIf="taskStatus(row) === 'COMPLETED'">check_circle</mat-icon>
                                      <mat-icon *ngIf="taskStatus(row) === 'PENDING'">access_time</mat-icon>
                                      <mat-icon *ngIf="taskStatus(row) === 'OVERDUE'">highlight_off</mat-icon>

                                    </mat-chip>
                                    <button color="primary" mat-icon-button
                                            *ngIf="taskStatus(row) !== 'COMPLETED'"
                                            matTooltip="{{'generic.not-done' | translate}}"
                                            [matTooltipPosition]="'below'" (click)="checkTaskCompleted(row)">
                                      <mat-icon>check_circle</mat-icon>
                                    </button>
                                  </mat-chip-list>
                                </mat-cell>
                              </div>
                            </ng-container>

                            <mat-header-row
                              *matHeaderRowDef="tasksDisplayedColumns"
                            ></mat-header-row>
                            <mat-row
                              *matRowDef="let row; columns: tasksDisplayedColumns"
                            ></mat-row>
                          </mat-table>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

<!--    <div class="row mt-5 justify-content-center">-->
<!--      <h3><b>Data chiusura Sessione:</b> 29/05/25 18:00 UTC+0</h3>-->
<!--    </div>-->

<!--    <div class="row mt-2 justify-content-center">-->
<!--      <h3><b>Data stampa report:</b> 21/05/25 17:00 UTC+0</h3>-->
<!--    </div>-->

  </div>
  <!-- Buttons -->
  <div class="buttons-container container py-4">
    <button
      class="button-primary float-left"
      mat-button
      (click)="updateProposal()"
      *ngIf="!isInEvalutationSession && loggedUserDetails.id == proposal.proposed_by.user_id"
      [disabled]="decision_table.closing_date != null"
    >
      <i class="icon-Backward-arrow-small"></i>
      {{ "generic.buttons.save-and-back" | translate }}
    </button>

    <button
      class="button-primary float-right"
      mat-button
      (click)="onClickSaveAs(currentProposal.title)"
      [disabled]="decision_table.closing_date != null"
      *ngIf="isContestProposalAccepted(proposal)"
    >
      <i class="icon-Forward-arrow-small"></i>
      <label>
        {{ "components.proposals.new-proposal.save-and-add-proposal" | translate }}
      </label>
    </button>
    <button
      class="button-danger float-right mr-5"
      mat-button
      (click)="deleteProposal()"
      *ngIf="!proposalIsRevisioned(proposal) && proposal.evaluation_session_id == null && decision_table?.closing_date == null && loggedUserDetails.id == proposal.proposed_by?.user_id"
    >
      <i class="icon-Forward-arrow-small"></i>
      <label>
        {{ "components.proposals.new-proposal.delete-proposal" | translate }}
      </label>
    </button>
  </div>
</form>
