import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('true' , style({ opacity: 1, transform: 'scale(1)' })),
      state('false', style({ opacity: 0, transform: 'scale(0)'  })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ],
})

export class OnboardingComponent implements OnInit {

  counter: number = 1;
  doneButton: boolean = false;

  model: any = {
    first_name: '',
    last_name: '',
    password: '',
    confirmed_password: '',
    organization_name: ''
  }

  constructor(
    private toast: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.init();
  }

  init() {
  }

  increaseNumb() {

    if (this.counter === 1 && !this.model.first_name) {
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-name'));
        return;
    } else if (this.counter === 2 && !this.model.last_name) {
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-surname'));
        return;
    } else if (this.counter === 3 && !this.model.password || this.counter === 3 && !this.model.confirmed_password) {
        // TODO_TRADUZIONE_ERRORE
        this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.user.single-user-management.component.error-password'));
        return;
    } else {
      this.counter = this.counter + 1;
    }

    if (this.counter === 4) {
      this.doneButton = true;
    }
  }

  decreaseNumb() {
    if (this.counter > 1) {
      this.counter = this.counter - 1;
    }

    if(this.counter < 4){
      this.doneButton = false;
    }
  }

  onSubmit(form: NgForm) {

    if (!this.model.organization_name) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.organizations.organization.component.error-organization-name'));
    }

    if (!form.form.valid)
      return;

    this.router.navigate(['/home'], {relativeTo: this.activeRoute});
    // TODO_TRADIZIONE
    this.toast.info("Butterfly Decisions", `Ciao ${this.model.first_name}, grazie per la registrazione.\n Butterfly Decisions è ora pronto per l'utilizzo`);
  }

}
