import { Component, OnInit,Inject }  from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationService } from 'src/app/services/organization.service';
import {MatTableDataSource} from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-modal-your-organizations',
  templateUrl: './modal-your-organizations.component.html',
  styleUrls: ['./modal-your-organizations.component.scss']
})
export class ModalYourOrganizationsComponent implements OnInit {
  organizations :any [] = [];

  organization_id: any;
  private _activeRouteSubscription: Subscription;
  users : any = [];

  displayedColumns: string[] = ['roles'];

  loading: boolean = false;

  usersData : any = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<ModalYourOrganizationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService,
    private userService: UserService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  init() {
    this.loading = true;
    this.organizationService.allOrganizationUsers(this.organization_id ,(result) => {
      this.users = result;
      this.usersData = new MatTableDataSource(this.users);
      this.loading = false;
    }, () => this.loading = false);
  }

  public getUserService() {
    return this.userService;
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close({event:event});
  }

}
