import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ScriptLoaderService} from 'angular-google-charts';
import {ProcessRolesService} from 'src/app/services/config/process-roles.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {DecisionService} from 'src/app/services/decision.service';
import {ModalShowEvidencesComponent} from "../../../../modals/modal-show-evidences/modal-show-evidences.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalShowProposalsComponent} from "../../../../modals/modal-show-proposals/modal-show-proposals.component";

@Component({
    selector: 'app-consensus-decision-table-dashboard',
    templateUrl: './consensus-decision-table-dashboard.component.html',
    styleUrls: ['./consensus-decision-table-dashboard.component.scss']
})
export class ConsensusDecisionTableDashboardComponent implements OnInit, OnChanges {

    @Input() decision_table: any;
    @Input() evaluation_session_dashboard: any;

    currentDecisionTable: any;
    currentEvaluationSessionDashboard: any;

    constructor(private activeRoute: ActivatedRoute,
                private loaderService: ScriptLoaderService,
                private decisionService: DecisionService,
                private suggestionService: SuggestionService, private processRoleService: ProcessRolesService, private dialog: MatDialog) {
    }


    loadingGraphics: boolean = true;

    // feedbackData:[] = [];
    // criteriaData: [] = [];

    displayedColumnsCriteria: string[] = ['criteriatitle', 'suggest', 'vote', 'risk', 'contradiction'];
    displayedColumns: string[] = ['expert', 'answers', 'time', 'cost', 'total_cost', 'new_proposal', 'evidence'];

    ngOnInit(): void {

        //  inizializzo i componenti
        if (this.decision_table && this.evaluation_session_dashboard) {
            this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
            this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
        }

        //  recupero le informazioni per questo tavolo decisionale
        this.getDecisionTableData();

        //  carico i grafici
        this.loaderService.loadChartPackages('corechart').subscribe(() => {

            this.loadingGraphics = false;
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
        this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
        this.init();
    }


    getDecisionTableData() {
        this.init();
    }


    init() {

        if (this.loadingGraphics) {
            setTimeout(() => {
                this.init();
            }, 200);
            return;
        } else {

            this.prepareAnswers();
            this.prepareChartContradiction();
            this.prepareChartResponse();
        }
    }


    /**
     * Prepara le risposte filtrando solo quelle che sono state completate dagli esperti
     */
    prepareAnswers() {

        this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
            proposal.criteria.forEach(criterio => {
                let responseFiltered: any = [];
                criterio.response.forEach(response => {
                    if (response?.team_member?.response_stamps && response?.team_member?.response_stamps.completed == true)
                        responseFiltered.push(response);
                });
                criterio.response_filtered = responseFiltered;
            });
        });
    }

  showSuggestedProposals(proposals) {
    const data = {
      suggestedProposals: proposals,
      decisionTable: this.decision_table,
    };
    this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
  }


    /**
     * Prepara il grafico delle risposte in percentuale alla scelta fatta dagli utenti
     */
    prepareChartContradiction() {

        this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
            proposal.criteria.forEach(criterio => {
                if (criterio.response.length > 0) {
                    criterio.response_agree_count = criterio.response_filtered.filter(x => x.consent_value == 'AGREE').length;
                    criterio.response_disagree_count = criterio.response_filtered.filter(x => x.consent_value == 'DISAGREE').length;
                    criterio.response_abstained_count = criterio.response_filtered.filter(x => x.consent_value == 'ABSTAINED').length;
                    criterio.total_response = criterio.response_filtered.length;

                    if (criterio.total_response > 0) {
                        criterio.response_agree_perc = Math.floor((criterio.response_agree_count / criterio.total_response) * 100);
                        criterio.response_disagree_perc = Math.floor((criterio.response_disagree_count / criterio.total_response) * 100);
                        criterio.response_abstained_perc = Math.floor((criterio.response_abstained_count / criterio.total_response) * 100);
                    }
                }
            });
        });
    }

    /**
     * Prepara il grafico delle delle risposte complessive da parte degli esperti
     */
    prepareChartResponse() {

        //  setto l'array con tutti i membri presenti e vedo proposta per proposta se hanno risposto a tutte le domande
        this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
            //  TODO_MICHELE: dal backend devono arrivare solo i membri che sono consulted
            proposal.team_members = proposal.team_members.filter(x => this.processRoleService.isConstulted(x.process_role));

            // proposal.team_members = proposal.team_members.filter(x => x.process_role == 'CONSULTED');
            // proposal.criteria.forEach(criterio => {
            //   criterio.response.forEach(response => {
            //     if (response.team_member.process_role == "CONSULTED") {
            //       if (proposal.team_members.filter(x => x.email == response.team_member.email).length <= 0)
            //         proposal.team_members.push(response.team_member);
            //     }
            //   });
            // });
        });
    }

  showExpertEvideces(data) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: data?.name || data?.email,
        attachments: data.attachment_expert_pubmed.concat(data.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }

    public getSuggestionService() {
        return this.suggestionService;
    }

}
