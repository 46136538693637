<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

  <!-- Eseperienze Lavorative -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">1</span>{{'pages.user.curriculum.component.work-exp' | translate}}</mat-panel-title></span>
        </mat-expansion-panel-header>

        <div class="row" *ngIf="works.length > 0; else noWorkAdded">
          <div class="col-12">
            <ng-container *ngFor="let work of works">
              <mat-card class="box p-4">
                <div class="row d-flex align-items-center">
                  <div class="col-12 col-lg-3">
                    <p class="m-0">{{'generic.qualification' | translate}}</p>
                    <h3><strong>{{work.qualification}}</strong></h3>
                  </div>
                  <div class="col-12 col-lg-3">
                    <p class="m-0">{{'generic.date' | translate}}</p>
                    <h3><strong>{{work.date_start}} <br> {{work.date_end}}</strong></h3>
                  </div>
                  <div class="col-12 col-lg-3">
                    <p class="m-0">{{'generic.locality' | translate}}</p>
                    <h3><strong>{{work.locality}}</strong></h3>
                  </div>
                  <div class="col-lg-2">
                    <p class="m-0">{{'generic.company' | translate}}</p>
                    <h3><strong>{{work.company}}</strong></h3>
                  </div>
                  <mat-icon class="grey-color edit mx-3" (click)="openDialogAddWork(work)">edit</mat-icon>
                  <i class="icon-cestino grey-color float-right m-0" (click)="removeWorkExperience(work.id)"></i>
                  <div *ngIf="work.description" class="col-12 d-flex justify-content-end align-items-center">
                    <p class="m-0 text-color-custom">{{'generic.description' | translate}}</p>
                    <div id="descriptionWorkBtn" class="mat-select-arrow" (click)="work.visible = !work.visible"></div>
                  </div>
                </div>
                <!-- DESCRIZIONE -->
                <div [hidden]="!work.visible" class="row p-4 mt-3 background-custom">
                  <div class="col-12 d-flex justify-content-start">
                    <mat-icon class="mr-2 text-color-custom">short_text</mat-icon>
                    <p class="text-color-custom">{{work.description}}</p>
                  </div>
                </div>
              </mat-card>
            </ng-container>
          </div>
        </div>

        <ng-template #noWorkAdded class="row">
          <div class="col-12 text-center">
            <p class="p-5 p-custom">{{'pages.user.curriculum.component.no-work-exp' | translate}}</p>
          </div>
        </ng-template>

        <button type="button" class="col-12 big-button my-2" mat-raised-button (click)="openDialogAddWork()">
          <i class="icon-big_add"></i>
          {{'pages.user.curriculum.component.add-work-exp' | translate}}
        </button>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

  <!-- FORMAZIONE -->
    <div class="container-fluid accordion-container">
      <div class="container">
        <mat-accordion class="accordion">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">2</span>{{'generic.formation' | translate}}</mat-panel-title></span>
            </mat-expansion-panel-header>

            <div class="row" *ngIf="educations.length > 0; else noEducationAdded">
              <div class="col-12">
                <ng-container *ngFor="let education of educations">
                  <mat-card class="box p-4">
                    <div class="row d-flex align-items-center">
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'pages.user.curriculum.component.edu-quali' | translate}}</p>
                        <h3><strong>{{education.certificate}}</strong></h3>
                      </div>
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'pages.user.curriculum.component.course-study' | translate}}</p>
                        <h3><strong>{{education.course_study}}</strong></h3>
                      </div>
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'generic.date' | translate}}</p>
                        <h3><strong>{{education.date_start}} <br> {{education.date_end}}</strong></h3>
                      </div>
                      <div class="col-lg-2">
                        <p class="m-0">{{'pages.user.curriculum.component.school-uni' | translate}}</p>
                        <h3><strong>{{education.school}}</strong></h3>
                      </div>
                      <mat-icon class="grey-color edit mx-3" (click)="openDialogAddEducation(education)">edit</mat-icon>
                      <i class="icon-cestino grey-color float-right m-0" (click)="removeEducation(education.id)"></i>
                    </div>
                    <div *ngIf="education.description" class="col-12 d-flex justify-content-end align-items-center">
                      <p class="m-0 text-color-custom">{{'generic.description' | translate}}</p>
                      <div id="descriptionBtn" class="mat-select-arrow" (click)="education.visible = !education.visible"></div>
                    </div>
                    <!-- DESCRIZIONE -->
                    <div [hidden]="!education.visible" class="row p-4 mt-3 background-custom">
                      <div class="col-12 d-flex justify-content-start">
                        <mat-icon class="mr-2 text-color-custom">short_text</mat-icon>
                        <p class="text-color-custom">{{education.description}}</p>
                      </div>
                    </div>
                  </mat-card>
              </ng-container>

              </div>
            </div>

            <ng-template #noEducationAdded class="row">
              <div class="col-12 text-center">
                <p class="p-5 p-custom">{{'pages.user.curriculum.component.no-formation' | translate}}</p>
              </div>
            </ng-template>

            <button type="button" class="col-12 big-button my-2" mat-raised-button (click)="openDialogAddEducation()">
              <i class="icon-big_add"></i>
              {{'pages.user.curriculum.component.add-formation' | translate}}
            </button>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

  <!-- LICENZE E CERTIFICAZIONI -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">3</span>{{'pages.user.curriculum.component.licenses-certifi' | translate}}</mat-panel-title></span>
          </mat-expansion-panel-header>

            <div class="row" *ngIf="certificates.length > 0; else noCertificatesAdded">
              <div class="col-12">
                <ng-container *ngFor="let certificate of certificates">
                  <mat-card class="box p-4">
                    <div class="row d-flex align-items-center">
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'generic.sector' | translate}}</p>
                        <h3><strong>{{certificate.license}}</strong></h3>
                      </div>
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'generic.release-date' | translate}}</p>
                        <h3><strong>{{certificate.date_start}}</strong></h3>
                      </div>
                      <div class="col-12 col-lg-3">
                        <p class="m-0">{{'generic.exp-date' | translate}}</p>
                        <!-- TODO_TRADUZIONE -->
                        <h3 class="p-custom" *ngIf="!certificate.date_end"><strong>Nessuna scadenza</strong></h3>
                        <h3><strong>{{certificate.date_end}}</strong></h3>
                      </div>
                      <div class="col-lg-2">
                        <p class="m-0">{{'pages.user.curriculum.component.issuing-organization' | translate}}</p>
                        <h3><strong>{{certificate.organization}}</strong></h3>
                      </div>
                      <mat-icon class="grey-color edit mx-3" (click)="openDialogAddCertificate(certificate)">edit</mat-icon>
                      <i class="icon-cestino grey-color float-right m-0" (click)="removeCertificate(certificate.id)"></i>
                    </div>
                    <div *ngIf="certificate.description" class="col-12 d-flex justify-content-end align-items-center">
                      <p class="m-0 text-color-custom">{{'generic.description' | translate}}</p>
                      <div id="descriptionBtn" class="mat-select-arrow"  (click)="certificate.visible = !certificate.visible"></div>
                    </div>
                    <!-- DESCRIZIONE -->
                    <div [hidden]="!certificate.visible" class="row p-4 mt-3 background-custom">
                      <div class="col-12 d-flex justify-content-start">
                        <mat-icon class="mr-2 text-color-custom">short_text</mat-icon>
                        <p class="text-color-custom">{{certificate.description}}</p>
                      </div>
                    </div>
                  </mat-card>
              </ng-container>


              </div>
            </div>

            <ng-template #noCertificatesAdded class="row">
              <div class="col-12 text-center">
                <p class="p-5 p-custom">{{'pages.user.curriculum.component.no-lice-certi' | translate}}</p>
              </div>
            </ng-template>

          <button type="button" class="col-12 big-button my-2" mat-raised-button (click)="openDialogAddCertificate()">
            <i class="icon-big_add"></i>
            {{'pages.user.curriculum.component.add-lice-certi' | translate}}
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
    <div class="buttons-container container py-4">
      <button class="button-primary float-left" mat-button [routerLink]="['../../']" routerLinkActive="router-link-active">
          <i class="icon-Backward-arrow-small"></i>
          {{'generic.buttons.back' | translate}}
      </button>
    </div>
</form>
