import {Component, OnInit} from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {OrganizationService} from 'src/app/services/organization.service';
import {ToastService} from "../../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/general/alert.service';
import { ModalTemplateEmailComponent } from 'src/app/modals/modal-template-email/modal-template-email.component';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  selector: 'app-template-mail-management',
  templateUrl: './template-mail-management.component.html',
  styleUrls: ['./template-mail-management.component.scss']
})
export class TemplateMailManagementComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  templates: any = [];
  organization_id: any;

  searchTerm = '';

  displayedColumns: string[] = ['name', 'type', 'actions'];
  templatesData: any = new MatTableDataSource([]);

  faTrash = faTrash

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activeRoute: ActivatedRoute, private organizationService: OrganizationService, private dialog: MatDialog,
    private alert: AlertService, private translateService: TranslateService, private toast: ToastService, private configService:ConfigService) {
  }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }


  init() {

    this.loading = true;
    this.organizationService.allOrganizationTemplatesEmail(this.organization_id, (result) => {
      this.templates = result;
      this.templatesData = new MatTableDataSource(result);
      this.loading = false;
    }, () => this.loading = false);
  }


  /** cerca nella tabella in base a nome cognome e email */
  onSearch() {

    if (this.searchTerm.length == 0) {
      this.templatesData = new MatTableDataSource(this.templates);
      return;
    }

    var documentFiltered = this.templates.filter(element => {
      if (this.searchTerm) {
        if (element?.name && element?.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      }
    });

    this.templatesData = new MatTableDataSource(documentFiltered);
  }

    /** Pulisco la ricerca */
    onClearSearch() {
      this.searchTerm = '';
      this.templatesData = new MatTableDataSource(this.templates);
    }



  /**
   * Aggiungo un nuovo template email
   */
  addNewTemplate() {
    const dialogRef = this.dialog.open(ModalTemplateEmailComponent, {
      panelClass: 'medium-dialog',
      data: {
        organization_id: this.organization_id,
      }
    })

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }

  showTemplate(element)
  {
    const dialogRef = this.dialog.open(ModalTemplateEmailComponent, {
      panelClass: 'medium-dialog',
      data: {
        organization_id: this.organization_id,
        template: element
      }
    })

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }


  /**
   * Cancella un template dalla lista
   * @param element
   */
  deleteTemplate(element) {

  if (element.id) {
      this.alert.showQuestionWithError(this.translateService.instant("generic.alert-delete-template-email"), "", (res) => {
        if (res) {
          //  salvo sempre il task e recupro nuovamente il management plan
          this.organizationService.deleteTemplateEmail(this.organization_id, element.id, () => {

            this.init();
              this.toast.success(this.translateService.instant("generic.mail-template"), this.translateService.instant("components.all-email-template-alert.deleted-document.success"));

            }, () => {
              this.toast.error(this.translateService.instant("generic.mail-template"), this.translateService.instant("components.all-email-template-alert.deleted-document.error"));
          });
        }
      });
    }
  }


  getConfigService() {
    return this.configService;
  }

}




