<ng-container *ngIf="decision_table?.framework.analysis_type">
  <app-multiobjective-responses-weight-targets *ngIf="getAnalysisService().isMultiobjectiveByType(decision_table?.framework.analysis_type)" [evaluationSessionId]="id" [allDecisionInfo]="allInfo" (responseWeightTargetSavedEvent)="weightTargetSaved()"></app-multiobjective-responses-weight-targets>
</ng-container>

<!-- <ng-container *ngIf="decision_table?.framework.analysis_type">
  <app-multiobjective-responses-weight-targets *ngIf="getAnalysisService().isMulticriteriaByType(decision_table?.framework.analysis_type)" [evaluationSessionId]="id" [allDecisionInfo]="allInfo" (responseWeightTargetSavedEvent)="weightTargetSaved()"></app-multiobjective-responses-weight-targets>
</ng-container> -->

<!-- Non ci sono pesi dei target per la multicriterio -->

<!-- Non ci sono pesi dei target per la consensus -->
