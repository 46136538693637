<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate></form>

<div class="container-fluid container-custom">
  <div class="container d-flex justify-content-center align-items-center">
    <div class="row mt-5">
      <div class="col-lg-12 card text-center shadow mb-5">
        <h1 class="color-green">Registrati</h1>
        <mat-form-field class="my-2">
        <input required matInput type="text" name="name" [(ngModel)]="model.name">
        <mat-placeholder class="placeholder">
            Nome
        </mat-placeholder>
        </mat-form-field>
        <br>
        <mat-form-field class="my-2">
          <input required matInput type="text" name="surname" [(ngModel)]="model.surname">
          <mat-placeholder class="placeholder">
            Cognome
          </mat-placeholder>
        </mat-form-field>
        <br>
        <mat-form-field class="my-2">
          <input required matInput type="email" name="email" [(ngModel)]="model.email">
          <mat-placeholder class="placeholder">
            E-mail
          </mat-placeholder>
        </mat-form-field>
        <br>
        <mat-form-field class="my-2">
          <input required matInput type="password" name="password" [(ngModel)]="model.password">
          <mat-placeholder class="placeholder">
            Password
          </mat-placeholder>
        </mat-form-field>
        <br>
        <mat-form-field class="my-2">
          <input required matInput type="password" name="confirm_password" [(ngModel)]="model.confirm_password">
          <mat-placeholder class="placeholder">
            Conferma Password
          </mat-placeholder>
        </mat-form-field>
        <div class="col-12">
          <button class="btn-actions rounded my-2" [routerLink]="['/']">Indietro</button>
        </div>
        <div class="col-12">
          <button class="btn-actions background-green rounded" [disabled]="loading" (click)="onSubmit(form)">Accedi</button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="container d-flex justify-content-center">
    <div class="row">
      <button class="icon-container mr-5" [routerLink]="['/']"><i class="icon-custom icon-Backward-arrow"></i></button>
      <button class="icon-container ml-5" mat-button [disabled]="loading" (click)="onSubmit(form)"><i class="icon-custom icon-Forward-arrow"></i></button>
    </div>
  </div> -->

</div>
