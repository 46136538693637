<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container background-filters py-3 px-5 mt-3">
  <div class="row justify-content-between">
    <div class="purple-color">
      <h2>{{'generic.search-for' | translate}}</h2>
    </div>
    <div>
      <button class="clear-filter py-2 px-4" (click)="onClickResetFilters()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
    </div>
  </div>
  <div class="row d-flex justify-content-between align-items-center m-0">
    <div class="col-12 col-md-3">
      <mat-form-field class="datepicker-primary wp-normal color-default mx-2" appearance="fill" mx-2>
        <mat-label>{{ 'generic.start_date' | translate }}</mat-label>
        <input matInput class="ng-trim-ignore" (dateChange)="applyFilterToDataSource()" [placeholder]="'GG/MM/AAAA' | translate" [matDatepicker]="pickerStartDate" [(ngModel)]="searchModel.startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-3">
      <mat-form-field class="datepicker-primary wp-normal color-default" appearance="fill">
        <mat-label>{{ 'generic.end_date' | translate }}</mat-label>
        <input matInput class="ng-trim-ignore" (dateChange)="applyFilterToDataSource()" [placeholder]="'GG/MM/AAAA' | translate" [matDatepicker]="pickerEndDate" [(ngModel)]="searchModel.endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
    </div>
<!--    <div class="col-12 col-md-3">
      <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
        <mat-label>{{ "components.decision-table.new-decision-table.select-framework.placeholder.roles" | translate }}</mat-label>
        <mat-select [formControl]="toppings" multiple (selectionChange)="applyFilterToDataSource()">
          <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>-->
    <div class="col-12 col-md-3">
      <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
        <mat-label>{{ "pages.frameworks.component.analysis-methodology" | translate }}</mat-label>
        <mat-select [formControl]="selectedMethods" multiple (selectionChange)="applyFilterToDataSource()">
          <mat-option *ngFor="let method of methods" [value]="method">{{ method.title | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row d-flex justify-content-between mx-0 mt-2">
    <div class="col-12 col-md-12 mt-4">
      <mat-form-field class="full-width">
        <input matInput name="searchKeywords" [(ngModel)]="searchModel.name" type="text" placeholder="{{'pages.frameworks.all-frameworks.component.search-name' | translate}}" (keyup)="applyFilterToDataSource()">
        <mat-icon class="with-cursor" *ngIf="searchModel.name" matSuffix aria-label="Clear" (click)="searchModel.name=''; applyFilterToDataSource()">close</mat-icon>
        <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="applyFilterToDataSource()">search</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<div *ngIf="decision_tables.length == 0" class="container">
  <div class="row">
    <div class="col-12 text-center mb-4 mt-2">
      <p>{{'pages.projects.project-detail.no-decision-tables' | translate}}</p>
    </div>
    <div class="col-12 d-flex justify-content-center my-5">
      <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" [routerLink]="[getDecisionCreateRoute()]">
        <mat-icon class="mr-2">play_circle_outlined</mat-icon>
        {{'pages.projects.project-detail.start-decision-table' | translate}}
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf="decision_tables.length > 0">
  <div class="container px-0">
    <div class="row">
      <div class="col-12 d-flex justify-content-end align-items-center mb-4 mt-2">
        <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" [routerLink]="[getDecisionCreateRoute()]">
          <mat-icon class="mr-2">play_circle_outlined</mat-icon>
          {{'pages.projects.project-detail.start-decision-table' | translate}}
        </button>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort #decisionTablesSort="matSort">

        <ng-container matColumnDef="decision_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.name' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.decision_name }} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="closing_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip selectable="false" selected color="accent" *ngIf="!element.closing_date; else tableOpened">{{'generic.status.closed' | translate}}</mat-chip>
              <ng-template #tableOpened><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="scheme_type">
          <th mat-header-cell *matHeaderCellDef>{{ 'pages.frameworks.component.analysis-methodology' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ analysisMethodsService.getAnalysisMethodValueByType(element.framework.analysis_type) | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">

          <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="row my-3 justify-content-center">
              <button mat-button color="primary" class="ml-2"
                      *ngIf="userService.getCurrentOrganizationUser() !== 'GUEST'"
                      [routerLink]="['/decision/'+element.id+'/show']" routerLinkActive="router-link-active">
                <small>{{'generic.buttons.go-to-decision-table' | translate}}</small>
                <mat-icon>forward</mat-icon>
              </button>
              <mat-chip-list>
                <mat-chip [ngStyle]="{ 'background-color': (expandedElement === element && expandedElementListType === expandibleTypes.contests) ? '#6a42b9' : '' }" *ngIf="element?.contests_count > 0"  selectable="true" selected class="ml-auto mr-auto" color="primary" (click)="toggleRow(element, expandibleTypes.contests)">{{'components.decision-table.show-decision-table.contests-title' | translate}}</mat-chip>
                <mat-chip [ngStyle]="{ 'background-color': (expandedElement === element && expandedElementListType === expandibleTypes.sessions) ? '#6a42b9' : '' }" *ngIf="element?.evaluation_sessions_count > 0" selectable="true" selected color="primary" class="ml-auto mr-auto" (click)="toggleRow(element, expandibleTypes.sessions)">Sessioni</mat-chip>
              </mat-chip-list>
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

                <ng-template [ngIf]="expandedElementListType === expandibleTypes.sessions">
                  <table mat-table [dataSource]="element.evaluation_sessions" class="mb-4">

                  <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef > {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> <span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list>
                        <mat-chip selectable="false" selected color="accent" *ngIf="!element.evaluation_end; else evaluationSessionOpen">{{'generic.status.closed' | translate}}</mat-chip>
                        <ng-template #evaluationSessionOpen><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
                      </mat-chip-list>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>{{ 'generic.roles.role' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="row flex-column">
                      <ng-container *ngFor="let role of row.roles" >
                        <span [ngClass]="row.roles.length > 1 ? 'my-3' : 'mb-3'">
                          {{ role | titlecase }}
                        </span>
                      </ng-container>
                      </div>
                    </td>
                  </ng-container>

                    <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="row flex-column">
                        <ng-container *ngFor="let role of row.roles">
                          <button class="col-6 ml-auto mr-auto" [ngClass]="row.roles.length > 1 ? 'my-2' : 'mb-3'" *ngIf="!processRoleService.isConstulted(role)" mat-button
                                  color="primary"
                                  [routerLink]="['/decision/'+element.id+'/show']" routerLinkActive="router-link-active">
                            <small>{{'generic.buttons.go-to-table' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                          <button class="col-6 mr-auto ml-auto" [ngClass]="row.roles.length > 1 ? 'my-2' : 'mb-3'" *ngIf="processRoleService.isConstulted(role)" mat-button
                                  color="primary"
                                  [routerLink]="['/responses/'+row.id+'/welcome']"
                                  routerLinkActive="router-link-active">
                            <small
                              *ngIf="row.has_answered || row.evaluation_end">{{'components.responses.view_questionnaire_response' | translate}}</small>
                            <small *ngIf="!row.has_answered && !row.evaluation_end">{{'components.responses.ask-for-response' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                        </ng-container>

                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                </table>
                </ng-template>

                <ng-template [ngIf]="expandedElementListType === expandibleTypes.contests">
                  <table mat-table [dataSource]="element.contests" class="mb-4">

                    <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef> {{ 'generic.title' | translate }} </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.title }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="created_at">
                      <th mat-header-cell *matHeaderCellDef> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                      <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef> <span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
                      <td mat-cell *matCellDef="let element">
                        <mat-chip-list>
                          <mat-chip selectable="false" selected color="accent" *ngIf="!element.closing_date; else contestOpened">{{'generic.status.closed' | translate}}</mat-chip>
                          <ng-template #contestOpened><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
                        </mat-chip-list>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="row">

                          <button class="ml-auto mr-auto mb-3" *ngIf="!element.closing_date" mat-button
                                  color="primary"
                                  [routerLink]="['/contests/propose/' + element.token ]" routerLinkActive="router-link-active">
                            <small>{{'generic.take-part' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="innerDisplayedContestColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerDisplayedContestColumns;"></tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" [class.example-element-row]="element?.evaluation_sessions_count"
            [class.example-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                     #decisionTablesPaginator
                   aria-label="Select page of periodic elements">
    </mat-paginator>
    </div>
  </div>
  </ng-container>
