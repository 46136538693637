<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{'pages.home.component.check-decision-table' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="projects?.length == 0" class="container">
          <div class="row">
            <div class="col-12 text-center mb-4 mt-2">
              <p>{{'pages.decision-tables.all-decision-table.component.no-decision-tables' | translate}}</p>
            </div>
            <div class="col-12 d-flex justify-content-center my-5">
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewProject()">
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{'pages.decision-tables.all-decision-table.component.new-decision-table' | translate}}
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="projects?.length > 0">
          <div class="container px-0">
            <div class="row">
              <div class="col-12 d-flex justify-content-end align-items-center mb-4 mt-2">
                <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewProject()">
                  <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                  {{'pages.decision-tables.all-decision-table.component.new-decision-table' | translate}}
                </button>
              </div>
            </div>
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.name' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
                </ng-container>

                <ng-container matColumnDef="surveys_count">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "pages.projects.component.surveys" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.surveys_count }} </td>
                </ng-container>

                <ng-container matColumnDef="decision_tables_count">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "pages.projects.component.decision-table" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.decision_tables_count }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-button color="primary" class="ml-2"
                            [routerLink]="['/projects/'+element.id]" routerLinkActive="router-link-active">
                      <small>{{'generic.buttons.go-to-table' | translate}}</small>
                      <mat-icon>forward</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
              </table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                             #paginator
                             aria-label="Select page of periodic elements">
              </mat-paginator>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.home' | translate}}
  </button>
</div>
