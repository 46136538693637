<div class="mb-3">
  <button class="btn-custom border-0 d-flex align-items-center px-5 ml-auto py-2 justify-content-end" (click)="toggleAddNewTeamMemberMode()" [ngClass]="singleTeamMemberToAddActive ? 'bg-danger' : ''">
    <fa-icon class="mr-2" *ngIf="!singleTeamMemberToAddActive" [icon]="faPlusCircle"></fa-icon>
    <fa-icon class="mr-2" *ngIf="singleTeamMemberToAddActive" [icon]="faTimes"></fa-icon>
    <span *ngIf="!singleTeamMemberToAddActive">{{'components.decision-table.new-decision-table.compile-proposal.add-team-member' | translate}}</span>
    <span *ngIf="singleTeamMemberToAddActive">{{'generic.cancel' | translate}}</span>
  </button>
</div>
<div class="box box-two-row mb-5" *ngIf="singleTeamMemberToAddActive">
  <app-single-team-member
    [removable]="false"
    [context]="'survey-team'"
    [addButton]="true"
    (addedTeamMember)="onAddTeamMember($event)"
    [surveyId]="surveyId"
  />
</div>

<ng-container *ngIf="dataSource">
  <mat-table [dataSource]="dataSource" class="table-primary">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> {{ 'generic.name' | translate }} </mat-header-cell>
      <div class="row flex-column">
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> {{ 'generic.email' | translate }} </mat-header-cell>
      <div class="row flex-column">
        <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="response_status">
      <mat-header-cell
        *matHeaderCellDef> {{'components.decision-table.decision-table-dashboard.answers-to-form' | translate}} </mat-header-cell>
      <div class="row flex-column">
        <mat-cell *matCellDef="let row">
          <mat-chip selectable="false"
                    *ngIf="row?.responses?.length > 0"
                    style="justify-content:center; background-color: #00bc1d;"
                    class="text-white p-1 px-2 rounded">{{'generic.answers.yes' | translate}}</mat-chip>
          <mat-chip selectable="false" *ngIf="!row?.responses || row?.responses?.length == 0"
                    style="justify-content:center; background-color: #ff0000;"
                    class="text-white p-1 px-2 rounded">{{'generic.answers.no' | translate}}</mat-chip>
        </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="email_status">
      <mat-header-cell
        *matHeaderCellDef> {{'components.decision-table.decision-table-dashboard.email-status' | translate}} </mat-header-cell>

      <div class="row flex-column">
        <mat-cell *matCellDef="let row">
          <mat-chip-list>
            <mat-chip (click)="showEmailResponses(row)" role="button" selectable="true" style="justify-content:center;" [matTooltip]="emailStatusByTeamMember(row)" [matTooltipPosition]="'below'" [ngStyle]="{'background-color':emailColorByTeamMember(row)}" class="text-white cursor">
              <fa-icon [icon]="faEnvelope"></fa-icon>
            </mat-chip>
            <button (click)="resendInvitation(row)" color="primary" mat-icon-button matTooltip="{{'components.decision-table.decision-table-dashboard.tooltip.resend-email' | translate}}" [matTooltipPosition]="'below'">
              <fa-icon [icon]="faBullhorn"></fa-icon>
            </button>
          </mat-chip-list>
        </mat-cell>
      </div>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </mat-table>
  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>

</ng-container>
