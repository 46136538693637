<h1 mat-dialog-title>{{'modals.modal-report-attachment.component.report-evidence' | translate}}</h1>
<div mat-dialog-content>

  <div class="container-fluid">
    <div class="container px-0" >
      <div>
        <ng-container>

          <div class="row" *ngIf="loading">
            <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
          </div>

          <div class="row" *ngIf="allAttachments">
            <mat-table [dataSource]="allAttachments" style="width: 100%;" class="table-primary">

              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 70% !important">{{'components.decision-table.new-decision-table.compile-proposal.title' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element" style="flex: 0 0 70% !important"><a target="_blank" [href]="element.link">{{element.title}}</a></mat-cell>
              </ng-container>
              <ng-container matColumnDef="opened">
                <mat-header-cell *matHeaderCellDef>{{'generic.status.open' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.event" aria-hidden="false" style="color: #00bc1d;">done</mat-icon>
                  <mat-icon *ngIf="!element.event" aria-hidden="false" style="color: #ff0000;">cancel</mat-icon>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>

        </ng-container>
      </div>
    </div>
  </div>
</div>
