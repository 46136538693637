import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UserTypeService {

  private _userTypes: any = [
    { key: "ADMIN", value: 'Admin'},
    { key: "GUEST", value: 'Guest'},
    { key: "MEMBER", value: 'Member'},
  ];

  constructor() { }

    /** Controllo se l'utente è un amministratore */
  public IsAdmin(userKey: string) {

    if (userKey && userKey == 'ADMIN')
        return true;

    return false;
  }

  /** Controllo se l'utente è un membro di una organizzazione */
  public IsMember(userKey: string) {

    if (userKey && userKey == 'MEMBER')
        return true;

    return false;
  }

  /** Controllo se l'utente è un utente guest */
  public IsGuest(userKey: string) {

    if (userKey && userKey == 'GUEST')
        return true;

    return false;
  }

  public getMemberKey() {
    return 'MEMBER';
  }

  public getGuestKey() {
    return 'GUEST';
  }

  private getValueByKey(container, key) {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key.toString().toLowerCase() == key.toString().toLowerCase())
          return found = e.value;
      });
    }

    return found;
  }



  public getUserTypeValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByKey(this._userTypes, key);
  }

}
