import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganizationService } from 'src/app/services/organization.service';
import { AlertService } from 'src/app/services/general/alert.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/general/toast.service';

@Component({
  selector: 'app-organization-add-role',
  templateUrl: './organization-add-role.component.html',
  styleUrls: ['./organization-add-role.component.scss']
})
export class OrganizationAddRoleComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading: boolean = false;

  role: any;

  organization_id: any;
  role_id: any;

  model: any = {
    role : null,
    description : null
  };


  professionalRoles : any[]

  constructor(private router : Router, private activeRoute: ActivatedRoute,
            private alert: AlertService, private organizationService:OrganizationService,
            private userService :UserService,private translateService: TranslateService,
            private toast : ToastService) { }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      this.role_id = routeParams.id_role;
      if (this.organization_id && this.role_id) {
        this.init();
      }
    });

    this.professionalRoles = this.userService.getProfessionalRolesForOrganization(this.organization_id);
  }

  init() {
    this.getRole();
  }


  getRole() {
    this.loading = true;

    this.organizationService.getOrganizationRole(this.organization_id, this.role_id, (result) =>{
      this.role = result;

      this.model.id = this.role.id;
      this.model.role = this.role.role;
      this.model.description = this.role.description;

      this.loading = false;

    })
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  onSubmit(form: NgForm) {

    if(this.model.role == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.organizations.organization-add-role.component.error-role'));
      return;
    }

    if(this.model.description == null ) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('pages.organizations.organization-add-role.component.error-description'));
      return;
    }

    if (!form.form.valid || !this.model.role)
    return;


    if (this.model.id) {
      this.updateOrganizationRole();
      return;
    }
    this.saveNewRole();
  }


  saveNewRole() {

    if ( this.professionalRoles.filter(x => x.role == this.model.role).length == 0 ) {
    this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("pages.organizations.organization-add-role.component.seleted-role-not-exist") , () => {
      this.model.organization_id = this.organization_id;
      this.loading = true;
      this.organizationService.saveOrganizationRole(this.organization_id,this.model, (result) => {
        this.userService.setNewRoleForOrganization(this.organization_id, result);
        this.toast.success(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.organizations.organization-add-role.component.success-save-role'));
        this.loading = false;
        this.router.navigate(['../'], { relativeTo: this.activeRoute });
      }, (error) => {
        this.loading = false;
        this.toast.error(this.translateService.instant('generic.roles.role'), this.translateService.instant('pages.organizations.organization-add-role.component.error-save-role'));
      })
    })
    }else {
      this.toast.error(this.translateService.instant("generic.roles.role"),this.translateService.instant("pages.organizations.organization-add-role.component.error-role-already-taken"));
    }
  }

  // aggiorno un ruolo
  updateOrganizationRole() {
      if ( this.professionalRoles.filter(x => x.role == this.model.role).length == 0 ) {
      this.loading = true;
      this.organizationService.updateOrganizationRole(this.organization_id, this.role_id, this.model , (result) => {
        this.userService.updateOrganizationRole(result);
        this.toast.success(this.translateService.instant("generic.roles.role"),this.translateService.instant("pages.organizations.organization-add-role.component.success-role-updated"));
        this.loading = false;
        this.router.navigate(['../'], { relativeTo: this.activeRoute });
      },(error) =>
      {
        this.loading = false
        this.toast.error(this.translateService.instant("generic.roles.role"),this.translateService.instant("pages.organizations.organization-add-role.component.error-role-updated"));
      });
    }else {
      this.toast.error(this.translateService.instant("generic.roles.role"),this.translateService.instant("pages.organizations.organization-add-role.component.error-role-already-taken"));
    }
  }
}
