export const environment = {
  production: true,
  testing: true,
  version: '2023.02.22',
  webUrl: 'https://beestesting.butterflydecisions.com/',
  apiUrl: 'https://beestesting.butterflydecisions.com/api/',
  loginUrl: 'https://beestesting.butterflydecisions.com/oauth/redirect',
  loginReturnUrl: 'https://testing.butterflydecisions.com/#/login',
  logoutUrl: 'https://beestesting.butterflydecisions.com/oauth/logout',
  logoutReturnUrl: 'https://testing.butterflydecisions.com/',

  checkInactivityTime: 180000,
  trackEventTime: 60000,
};
