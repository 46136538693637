import {Component, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {ActivatedRoute} from "@angular/router";
import {OrganizationService} from "../../../services/organization.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertService} from "../../../services/general/alert.service";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../../services/general/toast.service";
import {ConfigService} from "../../../services/config.service";
import {ModalTemplateEmailComponent} from "../../../modals/modal-template-email/modal-template-email.component";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  ModalOrganizationChartComponent
} from "../../../modals/modal-organization-chart/modal-organization-chart.component";
@Component({
  selector: 'app-organization-charts-management',
  templateUrl: './organization-charts-management.component.html',
  styleUrls: ['./organization-charts-management.component.scss']
})
export class OrganizationChartsManagementComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  charts: any = [];
  organization_id: any;
  users = [];
  searchTerm = '';

  displayedColumns: string[] = ['title', 'created_at', 'members_count', 'actions'];
  chartsData: any = new MatTableDataSource([]);

  faTrash = faTrash

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activeRoute: ActivatedRoute, private organizationService: OrganizationService, private dialog: MatDialog,
              private alert: AlertService, private translateService: TranslateService, private toast: ToastService, private configService:ConfigService) {
  }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }


  init() {
    this.loading = true;
    this.organizationService.allOrganizationCharts(this.organization_id, (result) => {
      this.charts = result;
      this.chartsData = new MatTableDataSource(result);
    });
    this.organizationService.allOrganizationUsers(this.organization_id ,(result) => {
      this.users = result;
      this.loading = false;
    }, () => this.loading = false);
  }


  /** cerca nella tabella in base a nome cognome e email */
  onSearch() {

    if (this.searchTerm.length == 0) {
      this.chartsData = new MatTableDataSource(this.charts);
      return;
    }

    var documentFiltered = this.charts.filter(element => {
      if (this.searchTerm) {
        if (element?.title && element?.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      }
    });

    this.chartsData = new MatTableDataSource(documentFiltered);
  }

  /** Pulisco la ricerca */
  onClearSearch() {
    this.searchTerm = '';
    this.chartsData = new MatTableDataSource(this.charts);
  }



  /**
   * Aggiungo un nuovo template email
   */
  addNewChart() {
    const dialogRef = this.dialog.open(ModalOrganizationChartComponent, {
      panelClass: 'medium-dialog',
      data: {
        organization_id: this.organization_id,
        users: this.users.filter((user) => !user.deleted_at)
      }
    })

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }

  showChart(element)
  {
    const dialogRef = this.dialog.open(ModalOrganizationChartComponent, {
      panelClass: 'medium-dialog',
      data: {
        organization_id: this.organization_id,
        chart: element,
        users: this.users.filter((user) => !user.deleted_at)
      }
    })

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
        this.init();
      }
    });
  }


  /**
   * Cancella un template dalla lista
   * @param element
   */
  deleteChart(element) {

    if (element.id) {
      this.alert.showQuestionWithError(this.translateService.instant("generic.alert-delete-organization-chart"), "", (res) => {
        if (res) {
          this.organizationService.deleteOrganizationChart(this.organization_id, element.id, () => {

            this.init();
            this.toast.success(this.translateService.instant("generic.mail-template"), this.translateService.instant("components.all-email-template-alert.deleted-document.success"));

          }, () => {
            this.toast.error(this.translateService.instant("generic.mail-template"), this.translateService.instant("components.all-email-template-alert.deleted-document.error"));
          });
        }
      });
    }
  }


  getConfigService() {
    return this.configService;
  }

}
