import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {OrganizationService} from '../../services/organization.service';
import {ProcessRolesService} from '../../services/config/process-roles.service';
import {SurveyService} from '../../services/surveys.service';
import {ToastService} from '../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-single-team-member',
  templateUrl: './single-team-member.component.html',
  styleUrls: ['./single-team-member.component.scss']
})
export class SingleTeamMemberComponent implements OnInit{
  @Input() team_member: any;
  @Input() removable = true;
  @Input() context: string = null;
  @Input() surveyId = null;
  @Input() process_role_field = true;
  @Input() addButton = false;
  @Output() removeTeamMemberEvent = new EventEmitter<number>();
  @Output() addedTeamMember = new EventEmitter<number>();

  loading = false;
  timerEmailUpdate;
  teamMember = {};
  suggestedTeamMemberUsers = [];
  professionalRoles = [];
  process_roles = [];
  constructor(
    public userService: UserService,
    public dialog: MatDialog,
    public organizationService: OrganizationService,
    public processRolesService: ProcessRolesService,
    public surveyService: SurveyService,
    public toast: ToastService,
    public translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.setTeamMemberByContext();
    this.professionalRoles =
      this.userService.getAllProfessionalRolesForCurrentOrganization();
    this.process_roles = this.processRolesService.getAllProcessRoles();
  }
  removeTeamMember() {
    this.removeTeamMemberEvent.emit();
  }

  onClickSearchUser(teamMember: any) {
    let email = '';
    if (teamMember?.email !== null && teamMember.email.length > 0) {
      email = teamMember?.email;
    }
    this.organizationService.getOrganizationUsersByEmail(
      email,
      this.userService.currentOrganization.id,
      (result) => {
        this.suggestedTeamMemberUsers = result.map(result => result.user);
      },
    );
  }

  setUserInfoByEmail(event, team_member) {
    const userFinded = this.suggestedTeamMemberUsers.find((user) => event.option.value.email === user.email);
    let full_name = '';
    if (userFinded?.details?.first_name && userFinded?.details.last_name){
      full_name = userFinded?.details.first_name + ' ' + userFinded?.details.last_name;
    }else{
      full_name = userFinded?.name;
    }
    team_member.name = full_name || '';
    team_member.email = userFinded.email;

    this.suggestedTeamMemberUsers = [];
  }

  displayFn(option: any): string {
    return option.email || '';
  }

  onInputSearchUserChange(value: any, team_member) {
    team_member.email = value;
    if (this.timerEmailUpdate == null) {

      this.timerEmailUpdate = setTimeout(() => {
        this.timerEmailUpdate = null;
        if (value == '') {
          this.suggestedTeamMemberUsers = [];
        } else {
          this.organizationService.getOrganizationUsersByEmail(
            value,
            this.userService.currentOrganization.id,
            (result) => {
              this.suggestedTeamMemberUsers = result.map(result => result.user);
            },
          );
        }
      }, 800);
    }
  }

  getOrganizationChart(id) {
    return this.organizationService.organizationCharts.find((org_chart) => {
      return id === org_chart.id;
    });
  }

  addSurveyTeamMember(form) {

    if (!form.form.valid)
      return;

    this.loading = true;
    this.surveyService.addNewSurveyTeamMember(this.surveyId, this.teamMember, (result) => {
      this.loading = false;
      this.addedTeamMember.emit(result);
      this.toast.success(
        this.translateService.instant('generic.evaluation-session'),
        this.translateService.instant(
          'components.decision-table.new-decision-table.compile-proposal.team-member-added-successfully'
        )
      );
    }, () => {
      this.loading = false;
    });
  }

  setTeamMemberByContext() {
    if (this.context === 'survey-team') {
      this.teamMember = {
        name: '',
        email: '',
      };
    }
  }

}
