import {Component} from '@angular/core';
import {UserService} from "./services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Decisions Frontend';
  constructor(public userService: UserService) {
  }


  ngOnInit() {

    //  fix per impostare lo scale del viewport solo se il dispositivo ha una risoluzione inferiore a 768
    if (window.innerWidth < 768) {
      let viewport = document.querySelector("meta[name=viewport]");
      let content = "width=device-width, initial-scale=1.0";
      if (!viewport) {
        viewport = document.createElement('meta');
        viewport['name'] = "viewport";
        viewport.setAttribute('content', content);
        document.getElementsByTagName('head')[0].appendChild(viewport);
      } else {
        content = "width=768, initial-scale=2.0";
        viewport.setAttribute('content', content);
      }
    }
  }
}
