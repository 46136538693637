<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{'generic.info' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-12 px-2" *ngIf="contest.description">
            <div class="box px-0 box-two-row">
              <mat-label class="box-title px-3">{{'generic.description' | translate}}</mat-label>
              <mat-form-field class="input-primary col-12 mt-2">
                <textarea class="description" readonly matInput [value]="contest.description" rows="5"
                          placeholder=""></textarea>
              </mat-form-field>
              <i class="icon-circle_info top-right grey-color"></i>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- Blocco 3 -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            {{
            "generic.proposals"
              | translate
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-5">
          <div *ngIf="proposalsDataSource && proposalsDataSource?.data.length == 0">
            <div class="row">
              <div class="col-12 text-center mb-4 mt-2">
                <p>
                  {{'generic.no-proposals' | translate}}</p>
              </div>
            </div>
          </div>
          <div>
            <mat-table *ngIf="proposalsDataSource && proposalsDataSource?.data.length > 0" [dataSource]="proposalsDataSource" matSort
                       class="table-primary">

              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 30% !important;" > {{ 'generic.title' | translate }} </mat-header-cell>
                <div class="row flex-column" style="flex: 0 0 30% !important;">
                  <mat-cell *matCellDef="let row" class="text-left justify-content-start" > {{row.title}} </mat-cell>
                </div>
              </ng-container>

              <ng-container matColumnDef="proposed_by">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 30% !important">{{"components.decision-table.show-decision-table.proposed-by" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let row" class="text-left justify-content-start pl-5" style="flex: 0 0 30% !important;">
                  <div class="content-grid">
                    <img
                      class="avatar-img"
                      src="assets/img/user-placeholder.jpg"
                    />
                    <div>
                      <span class="d-block text-left">{{ row?.proposed_by?.name }}</span>
                      <small class="d-block text-left text-muted">{{ row?.proposed_by?.email}}</small>
                      <small class="d-block text-left text-muted">{{ processRolesService.getProcessRolesValueByKey(row?.proposed_by?.process_role)}}</small>
                    </div>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="proposed_at">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 10% !important"> {{ 'components.contests.show-contest.component.proposed-at' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: 0 0 10% !important"> {{ row.created_at | date:'shortDate'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 10% !important"> {{ 'generic.status.status' | translate }} </mat-header-cell>
                <div class="row flex-column">
                  <mat-cell *matCellDef="let row" style="flex: 0 0 10% !important">
                    <mat-chip selectable="false" selected color="primary" class="p-1 rounded"
                              *ngIf="row.is_contest_proposal_accepted">{{ "components.contests.show-contest.component.accepted" | translate }}</mat-chip>
                    <mat-chip *ngIf="!row.is_contest_proposal_accepted" selectable="false" class="p-1 rounded" selected
                              color="accent">{{ "components.contests.show-contest.component.pendind" | translate }}</mat-chip>
                  </mat-cell>
                </div>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef style="flex: 0 0 20% !important"
                                 class="text-center">{{ 'generic.actions' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let row" style="flex: 0 0 20% !important">
                  <div>
                    <button class="mb-1" mat-button (click)="showProposal(row)"
                            color="primary">
                      <small>{{'generic.buttons.view-proposal' | translate}}</small>
                      <mat-icon>forward</mat-icon>
                    </button>


                    <button class="my-2" mat-button (click)="acceptProposal(row)"
                            *ngIf="!row.is_contest_proposal_accepted"
                            color="primary">
                      <small>{{'generic.buttons.accept-proposal' | translate}}</small>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="proposalDisplayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: proposalDisplayedColumns;"></mat-row>

            </mat-table>
            <mat-paginator *ngIf="!proposalsDataSource || (proposalsDataSource && proposalsDataSource?.data.length > 0)" #proposalsPaginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
