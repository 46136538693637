import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  constructor(private translateService: TranslateService) { }

  private _consensusSuggestion: any = [
    {
      type: "DISCARD",
      key: "-1",
      value: this.translateService.instant('service.suggestion.discard')
    },
    {
      type: "REVISE",
      key: "0",
      value: this.translateService.instant('service.suggestion.review')
    },
    {
      type: "ACCEPT",
      key: "1",
      value: this.translateService.instant('service.suggestion.accept')
    }
  ]


  getValueByTypeOrKey(container, keyToSearch, typeOrKey = 'type', returnedValue = 'value') {

    let found = "";

    if (keyToSearch != null && typeof keyToSearch != "undefined") {
      container.forEach(e => {
        if (e[typeOrKey] == keyToSearch)
          return found = e[returnedValue];
      });
    }

    return found;
  }


  getConsensusSuggestValueByKey (key: string) {
    if (key == null || typeof key == "undefined")
      return '';

    return this.getValueByTypeOrKey(this._consensusSuggestion, key, 'key');
  }


  //  se il suggerimento richiede attenzione da parte dell'utente
  isAlertSuggestion(key: string) {
    if (key == null || typeof key == "undefined")
      return false;

    if (this.isSuggestDiscard(key) || this.isSuggestReview(key))
      return true;

    return false;
  }

  //   il suggerimento dice di scartare
  isSuggestDiscard(key: string) {
    if (key == null || typeof key == "undefined")
      return false;

    if (key.toString() == "-1") {
      return true;
    }
    return false;
  }
  //   il suggerimento dice di accettare
  isSuggestAccept(key: string) {
    if (key == null || typeof key == "undefined")
      return false;

    if (key.toString() == "1") {
      return true;
    }

    return false;
  }
  //   il suggerimento dice di scartare
  isSuggestReview(key: string) {
    if (key == null || typeof key == "undefined")
      return false;

    if (key.toString() == "0")
      return true;

    return false;
  }



  //  recupero la classe css che identifica il colore in base al suggerimento dato
  public getClassSuggestionForCriteria(criteria_result: any) {

    if (criteria_result != null && typeof criteria_result.suggestion != undefined) {

      if (this.isSuggestDiscard(criteria_result.suggestion)) {
        return "red-color";
      }
      if (this.isSuggestReview(criteria_result.suggestion)) {
        return "orange-color";
      }
      if (this.isSuggestAccept(criteria_result.suggestion)) {
        return "green-color";
      }
    }
  }


}
