<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<app-consensus-new-proposal
  *ngIf="loadingComponent && getAnalysisService().isConsensusByType(decision_table?.framework.analysis_type)"
  [proposal]="currentProposal"
  [framework]="framework"
  [decision_table]="decision_table"
  [showPrivateInfo]="showPrivateInfo"
  (addNewEvidenceEvent)="onClickAddNewEvidence($event)"
  (addAttachmentListEvent)="onClickAttachmentEventList($event)"
  (saveAndBackEvent)="saveAndBack($event)"
  (saveAndAddEvent)="saveAndAdd($event)"
></app-consensus-new-proposal>

<app-multiobjective-new-proposal
  *ngIf="loadingComponent && getAnalysisService().isMultiobjectiveByType(decision_table?.framework.analysis_type)"
  [proposal]="currentProposal"
  [framework]="framework"
  [decision_table]="decision_table"
  [current_organization]="current_organization"
  [showPrivateInfo]="showPrivateInfo"
  (addNewEvidenceEvent)="onClickAddNewEvidence($event)"
  (addAttachmentListEvent)="onClickAttachmentEventList($event)"
  (saveAndBackEvent)="saveAndBack($event)"
  (saveAndAddEvent)="saveAndAdd($event)"
  [isGeneratingPdf]="isGeneratingPdf"
  #pdfreport
></app-multiobjective-new-proposal>

<app-multicriteria-new-proposal
  *ngIf="loadingComponent && getAnalysisService().isMulticriteriaByType(decision_table?.framework.analysis_type)"
  [proposal]="currentProposal"
  [framework]="framework"
  [decision_table]="decision_table"
  [showPrivateInfo]="showPrivateInfo"
  (addNewEvidenceEvent)="onClickAddNewEvidence($event)"
  (addAttachmentListEvent)="onClickAttachmentEventList($event)"
  (saveAndBackEvent)="saveAndBack($event)"
  (saveAndAddEvent)="saveAndAdd($event)"
></app-multicriteria-new-proposal>


<div style="
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;">

  <div class="print-button text-center mb-3"
       (click)="savePDF()" *ngIf="currentProposal?.criteria?.length > 0 && isEnel()">
  <span>
    <button mat-icon-button class="ml-auto">
      <mat-icon>print</mat-icon>
    </button>
  </span>
  </div>

  <div class="anonymize-button" *ngIf="!currentProposal.anonymous_survey_enabled && answered()"
       (click)="toggleAnonymousProposalInfo()">
    <span *ngIf="showPrivateInfo">{{ 'generic.hide-private-data' | translate }}</span>
    <span *ngIf="!showPrivateInfo">{{ 'generic.show-private-data' | translate }}</span>
  </div>
</div>

