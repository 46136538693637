import {Component, OnInit, ViewChild} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ProjectsService} from '../../../services/projects.service';
import {ModalAddProjectComponent} from "../../../modals/modal-add-project/modal-add-project.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ModalSurveyDetailsComponent} from "../../../modals/modal-survey-details/modal-survey-details.component";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<ProjectsData>;
  columnsToDisplay = ['title', 'created_at', 'surveys_count', 'decision_tables_count', 'actions'];


  constructor(private userService: UserService,
              private projectService: ProjectsService,
              private dialog: MatDialog,
              private router: Router
              ) { }

  loading = false;
  projects = [];
  organization_id = null;

  ngOnInit(): void {
    this.organization_id = this.userService.getCurrentOrganizationUser()?.organization_id;
    if (this.organization_id) {
      this.init();
    }
  }

  public init() {
    this.getProjects();
  }

  public getProjects() {
    this.loading = true;
    this.projectService.getProjectsByOrganizationId(this.organization_id, (result) => {
      this.projects = result.map((project) => {
        project.title = project.title.charAt(0).toUpperCase()
          + project.title.slice(1);
        return project;
      });
      this.dataSource = new MatTableDataSource<ProjectsData>(result);
      setTimeout(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      }, 100);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  public addNewProject() {
    const dialogRef = this.dialog.open(ModalAddProjectComponent, {
      panelClass: 'medium-dialog',
      data: {
        organization_id: this.organization_id
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== null && result !== undefined && result?.id) {
        this.router.navigate(['projects/' + result?.id]);
      }
    });
  }
  public getUserService() {
    return this.userService;
  }

}


export interface ProjectsData {
  title: string;
  created_at: Date;
  surveys_count: number;
  decision_tables_count: number;
  action: string;
}
