<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="contest.title">{{ contest.title }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<app-show-contest-component *ngIf="contest" [contest]="contest" [decision_table]="decision_table"/>
