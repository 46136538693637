<!-- Gestione delle sessioni di valutazione concluse -->
<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            {{'components.decision-table.decision-table-dashboard.closed-evaluation-sessions' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngFor="let proposal of currentEvaluationSessionDashboard.proposals; let i = index">
          <div class="row">

            <div class="col-12 col-lg-6 px-0 mb-0">
              <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                <mat-label class="box-title px-3 mb-0">{{'generic.proposal' | translate}}
                  : {{proposal.title}}</mat-label>
              </div>
            </div>

            <div class="container px-0">

              <div class="row">
                <div class="col-12">
                  <div class="box-info mb-4 position-relative">

                    <mat-table [dataSource]="proposal.criteria" style="width: 100%;" class="table-primary">

                      <ng-container matColumnDef="criteriatitle">
                        <mat-header-cell
                          *matHeaderCellDef>{{'components.decision-table.decision-table-dashboard.criterium-title' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.title}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="suggest">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'generic.hint' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">
                          <mat-chip-list>
                            <mat-chip *ngIf="getSuggestionService().isSuggestAccept(element?.results?.suggestion)"
                                      selectable="false"
                                      style="justify-content:center; pointer-events:none; background-color: #00bc1d;"
                                      class="text-white">{{getSuggestionService().getConsensusSuggestValueByKey(element.results.suggestion)}}</mat-chip>
                            <mat-chip *ngIf="getSuggestionService().isSuggestReview(element?.results?.suggestion)"
                                      selectable="false"
                                      style="justify-content:center; pointer-events:none; background-color: #FFAD4A;"
                                      class="text-white">{{getSuggestionService().getConsensusSuggestValueByKey(element.results.suggestion)}}</mat-chip>
                            <mat-chip *ngIf="getSuggestionService().isSuggestDiscard(element?.results?.suggestion)"
                                      selectable="false"
                                      style="justify-content:center; pointer-events:none; background-color: #ff0000;"
                                      class="text-white">{{getSuggestionService().getConsensusSuggestValueByKey(element.results.suggestion)}}</mat-chip>
                          </mat-chip-list>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="vote">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'generic.votes' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">
                          <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                            <div class="progress-bar bg-success" role="progressbar"
                                 [style.width.%]="element.response_agree_perc">{{element.response_agree_count}}</div>
                            <div class="progress-bar bg-danger" role="progressbar"
                                 [style.width.%]="element.response_disagree_perc">{{element.response_disagree_count}}</div>
                            <div class="progress-bar bg-secondary" role="progressbar"
                                 [style.width.%]="element.response_abstained_perc">{{element.response_abstained_count}}</div>
                          </div>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="risk">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'generic.certainty-degree-index' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">
                          <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                            <div *ngIf="element?.results?.certainty_degree !== null" class="progress-bar bg-primary"
                                 role="progressbar"
                                 [style.width.%]="element?.results?.certainty_degree">{{element?.results?.certainty_degree}}
                              %
                            </div>
                            <div *ngIf="element?.results?.certainty_degree === null"
                                 class="progress-bar-init ml-auto mr-auto">N/A
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="contradiction">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'generic.contradiction-degree-index' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center; text-align: center;">
                          <div class="progress" style="width: 100%; height: 25px; margin: 10px;">
                            <div *ngIf="element?.results?.contradiction_degree !== null" class="progress-bar bg-danger"
                                 role="progressbar"
                                 [style.width.%]="element?.results?.contradiction_degree">{{element?.results?.contradiction_degree}}
                              %
                            </div>
                            <div *ngIf="element?.results?.contradiction_degree === null"
                                 class="progress-bar-init ml-auto mr-auto">N/A
                            </div>
                          </div>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumnsCriteria"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumnsCriteria;"></mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>

        <ng-container>
          <!-- Tempo impiegato per la compilazione del questionario -->
          <!-- <div class="row pl-4 header-title">
            <p class="mb-0 mr-2">Tempo impiegato per la compilazione</p>
            <span class="main-color text-uppercase">9 ORE</span> &nbsp;
            <p class="mb-0 mr-2">Valore totale</p>
            <span class="main-color text-uppercase">2250 €</span>
          </div> -->

          <div class="row" *ngIf="currentEvaluationSessionDashboard?.proposals?.length > 0">
            <div class="col-12 col-lg-6 px-0 mb-0">
              <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                <mat-label
                  class="box-title px-3 mb-0">{{'components.decision-table.decision-table-dashboard.invited-experts' | translate}}</mat-label>
              </div>
            </div>
            <div class="container px-0">
              <div class="row">
                <div class="col-12 px-2">
                  <div class="mb-4 position-relative">
                    <mat-table [dataSource]="currentEvaluationSessionDashboard.proposals[0].team_members"
                               style="width: 100%;" class="table-primary">

                      <ng-container matColumnDef="expert">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.invited-experts' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">{{element.name}} </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="answers">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.answers-to-form' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">
                          <mat-chip-list>
                            <mat-chip selectable="false"
                                      *ngIf="element.response_stamps && element.response_stamps.completed == true"
                                      style="justify-content:center; background-color: #00bc1d;"
                                      class="text-white">{{'generic.answers.yes' | translate}}</mat-chip>
                            <mat-chip selectable="false"
                                      *ngIf="element.response_stamps && element.response_stamps.completed == false"
                                      style="justify-content:center; background-color: #bca900;"
                                      class="text-white">{{'generic.answers.draft' | translate}}</mat-chip>
                            <mat-chip selectable="false" *ngIf="!element.response_stamps"
                                      style="justify-content:center; background-color: #ff0000;"
                                      class="text-white">{{'generic.answers.no' | translate}}</mat-chip>
                          </mat-chip-list>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="time">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.evaluation-time' | translate}} </mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">
                          <ng-container *ngIf="element.response_stamps?.time_spent !== null">
                                    <span *ngIf="element.response_stamps?.time_spent > 0">
                                      {{element.response_stamps?.time_spent | minuteSeconds}}
                                    </span>
                            <span *ngIf="element.response_stamps?.time_spent === 0">< 1 min</span>
                          </ng-container>
                          <span *ngIf="element.response_stamps?.time_spent === null">N/A</span>
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="cost">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.hourly-cost' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">
                          <!--                                  {{element.cost}}-->
                          N/A
                        </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="total_cost">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.estimated-evaluation-value' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content: center; text-align: center;">
                          <!--                                  {{element.total_cost}}-->
                          N/A
                        </mat-cell>
                      </ng-container>

                      <!--                              <ng-container matColumnDef="evaluation_session">
                                                      <mat-header-cell *matHeaderCellDef style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.evaluation-session' | translate}}</mat-header-cell>
                                                      <mat-cell *matCellDef="let element" style="justify-content:center; text-align: center;">{{currentEvaluationSessionNumber}}</mat-cell>
                                                    </ng-container>-->

                      <ng-container matColumnDef="new_proposal">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.new-proposals-formulation' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center">
                          <mat-chip-list>
                            <mat-chip selectable="false"
                                      *ngIf="element.proposals_created.length > 0; else NoNewProposal"
                                      (click)="showSuggestedProposals(element.proposals_created)">{{'generic.answers.yes' | translate}}</mat-chip>
                            <ng-template #NoNewProposal>
                              <mat-chip selectable="false">{{'generic.answers.no' | translate}}</mat-chip>
                            </ng-template>
                          </mat-chip-list>
                        </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="evidence">
                        <mat-header-cell *matHeaderCellDef
                                         style="justify-content:center; text-align: center;">{{'components.decision-table.decision-table-dashboard.evidence-attached' | translate}}</mat-header-cell>
                        <mat-cell *matCellDef="let element" style="justify-content:center"
                                  (click)="element?.attachment_expert_pubmed.length + element?.attachment_expert_files.length > 0 ? showExpertEvideces(element) : ''">
                          <mat-chip-list>
                            <mat-chip selectable="false"
                                      *ngIf="element.attachment_expert_pubmed.length > 0 || element.attachment_expert_files.length > 0; else NoEvidence">{{'generic.answers.yes' | translate}}</mat-chip>
                            <ng-template #NoEvidence>
                              <mat-chip selectable="false">{{'generic.answers.no' | translate}}</mat-chip>
                            </ng-template>
                          </mat-chip-list>
                        </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                    </mat-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
