import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-show-evidences',
  templateUrl: './modal-show-evidences.component.html',
  styleUrls: ['./modal-show-evidences.component.scss']
})
export class ModalShowEvidencesComponent {
  userFullName: any;
  displayedColumns = ['name', 'type', 'loaded_at'];
  attachments = [];

  constructor(public dialogRef: MatDialogRef<ModalShowEvidencesComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    userFullName: '',
    attachments: []
  }) {
    this.userFullName = data.userFullName;
    this.attachments = data.attachments;
  }

  public closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }

}
