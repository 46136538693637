import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import Quill from 'quill';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true
    }
  ]
})
export class RichTextEditorComponent implements OnInit, ControlValueAccessor {

  @ViewChild('editor', { static: true }) editorElement: ElementRef | undefined;
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() formGroup: FormGroup | undefined;
  @Input() controlName: string = '';

  @Output() contentChange: EventEmitter<string> = new EventEmitter<string>();

  public quill: Quill | undefined;
  private value: string = '';
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor() {}

  ngOnInit(): void {
    this.initQuill();
  }

  private initQuill(): void {
    if (this.editorElement) {
      this.quill = new Quill(this.editorElement.nativeElement, {
        theme: 'snow',
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['link']
          ]
        }
      });

      this.quill.on('text-change', () => {
        const html = this.quill!.root.innerHTML;
        this.value = html;
        this.onChange(html);
        this.onTouched();
        this.contentChange.emit(html);
        if (this.formGroup && this.controlName) {
          this.formGroup.get(this.controlName)?.setValue(html);
        }
      });
    }
  }

  writeValue(value: string): void {
    this.value = value || '';
    if (this.quill) {
      this.quill.root.innerHTML = this.value;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.quill) {
      this.quill.enable(!isDisabled);
    }
  }
}
