<div class="row" *ngIf="loading">
    <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
  </div>
  <!-- Gestione delle sessioni di valutazione concluse -->
<ng-container *ngIf="decision_tables.length > 0">
  <div class="container px-0">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort #decisionTablesSort="matSort">

        <ng-container matColumnDef="decision_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.name' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.decision_name }} </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="closing_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip selectable="false" selected color="accent" *ngIf="!element.closing_date; else tableOpened">{{'generic.status.closed' | translate}}</mat-chip>
              <ng-template #tableOpened><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="scheme_type">
          <th mat-header-cell *matHeaderCellDef>{{ 'pages.frameworks.component.analysis-methodology' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{ analysisMethodsService.getAnalysisMethodValueByType(element.framework.analysis_type) | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">

          <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="row my-3 justify-content-center">
              <ng-container *ngIf="element.evaluation_sessions && element.evaluation_sessions_count">
                <button mat-button color="primary" class="ml-2"
                  [routerLink]="['/responses/'+element.evaluation_sessions[element.evaluation_sessions_count-1].id+'/welcome']" routerLinkActive="router-link-active">
                  <small>{{'components.responses.ask-for-response' | translate}}</small>
                  <mat-icon>forward</mat-icon>
                </button>
                <!-- <mat-chip-list>
                  <mat-chip [ngStyle]="{ 'background-color': (expandedElement === element && expandedElementListType === expandibleTypes.sessions) ? '#6a42b9' : '' }" *ngIf="element?.evaluation_sessions_count > 0" selectable="true" selected color="primary" class="ml-auto mr-auto" (click)="toggleRow(element, expandibleTypes.sessions)">Sessioni</mat-chip>
                </mat-chip-list> -->
              </ng-container>
              <!-- <button mat-button color="primary" class="ml-2"
                      *ngIf="userService.getCurrentOrganizationUser() !== 'GUEST'"
                      [routerLink]="['/decision/'+element.id+'/show']" routerLinkActive="router-link-active">
                <small>{{'generic.buttons.go-to-table' | translate}}</small>
                <mat-icon>forward</mat-icon>
              </button> -->
              <!-- <mat-chip-list>
                <mat-chip [ngStyle]="{ 'background-color': (expandedElement === element && expandedElementListType === expandibleTypes.contests) ? '#6a42b9' : '' }" *ngIf="element?.contests_count > 0"  selectable="true" selected class="ml-auto mr-auto" color="primary" (click)="toggleRow(element, expandibleTypes.contests)">{{'components.decision-table.show-decision-table.contests-title' | translate}}</mat-chip>
                <mat-chip [ngStyle]="{ 'background-color': (expandedElement === element && expandedElementListType === expandibleTypes.sessions) ? '#6a42b9' : '' }" *ngIf="element?.evaluation_sessions_count > 0" selectable="true" selected color="primary" class="ml-auto mr-auto" (click)="toggleRow(element, expandibleTypes.sessions)">Sessioni</mat-chip>
              </mat-chip-list> -->
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

                <ng-template [ngIf]="expandedElementListType === expandibleTypes.sessions">
                  <table mat-table [dataSource]="element.evaluation_sessions" class="mb-4">

                  <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef > {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> <span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
                    <td mat-cell *matCellDef="let element">
                      <mat-chip-list>
                        <mat-chip selectable="false" selected color="accent" *ngIf="!element.evaluation_end; else evaluationSessionOpen">{{'generic.status.closed' | translate}}</mat-chip>
                        <ng-template #evaluationSessionOpen><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
                      </mat-chip-list>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef>{{ 'generic.roles.role' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="row flex-column">
                      <ng-container *ngFor="let role of row.roles" >
                        <span [ngClass]="row.roles.length > 1 ? 'my-3' : 'mb-3'">
                          {{ role | titlecase }}
                        </span>
                      </ng-container>
                      </div>
                    </td>
                  </ng-container>

                    <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
                    <td mat-cell *matCellDef="let row">
                      <div class="row flex-column">
                        <ng-container *ngFor="let role of row.roles">
                          <button class="col-6 ml-auto mr-auto" [ngClass]="row.roles.length > 1 ? 'my-2' : 'mb-3'" *ngIf="!processRoleService.isConstulted(role)" mat-button
                                  color="primary"
                                  [routerLink]="['/decision/'+element.id+'/show']" routerLinkActive="router-link-active">
                            <small>{{'generic.buttons.go-to-table' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                          <button class="col-6 mr-auto ml-auto" [ngClass]="row.roles.length > 1 ? 'my-2' : 'mb-3'" *ngIf="processRoleService.isConstulted(role)" mat-button
                                  color="primary"
                                  [routerLink]="['/responses/'+row.id+'/welcome']"
                                  routerLinkActive="router-link-active">
                            <small
                              *ngIf="row.has_answered || row.evaluation_end">{{'components.responses.view_questionnaire_response' | translate}}</small>
                            <small *ngIf="!row.has_answered && !row.evaluation_end">{{'components.responses.ask-for-response' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                        </ng-container>

                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                </table>
                </ng-template>

                <ng-template [ngIf]="expandedElementListType === expandibleTypes.contests">
                  <table mat-table [dataSource]="element.contests" class="mb-4">

                    <ng-container matColumnDef="title">
                      <th mat-header-cell *matHeaderCellDef> {{ 'generic.title' | translate }} </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.title }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="created_at">
                      <th mat-header-cell *matHeaderCellDef> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
                      <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                      <th mat-header-cell *matHeaderCellDef> <span class="ml-3">{{ 'generic.status.status' | translate }}</span></th>
                      <td mat-cell *matCellDef="let element">
                        <mat-chip-list>
                          <mat-chip selectable="false" selected color="accent" *ngIf="!element.closing_date; else contestOpened">{{'generic.status.closed' | translate}}</mat-chip>
                          <ng-template #contestOpened><mat-chip selectable="false" selected color="primary">{{'generic.status.opened' | translate}}</mat-chip></ng-template>
                        </mat-chip-list>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.action' | translate }}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="row">

                          <button class="ml-auto mr-auto mb-3" *ngIf="!element.closing_date" mat-button
                                  color="primary"
                                  [routerLink]="['/contests/propose/' + element.token ]" routerLinkActive="router-link-active">
                            <small>{{'generic.take-part' | translate}}</small>
                            <mat-icon>forward</mat-icon>
                          </button>
                        </div>
                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="innerDisplayedContestColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerDisplayedContestColumns;"></tr>
                  </table>
                </ng-template>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" [class.example-element-row]="element?.evaluation_sessions_count"
            [class.example-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                      #decisionTablesPaginator
                    aria-label="Select page of periodic elements">
    </mat-paginator>
    </div>
  </div>
</ng-container>