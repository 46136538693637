<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>

  <div class="container-fluid box-sub-header py-3 pt-lg-5">
    <div class="container">

      <!-- Sezione alert -->
      <ng-container *ngIf="!loading">
        <mat-card *ngIf="evaluation_session && !evaluation_session?.collecting" style="background-color: red; color: white;">
          {{'components.responses.decision-table-closed' | translate}}
        </mat-card>
        <mat-card *ngIf="response_stamps?.completed" style="background-color: red; color: white;">
          {{'components.responses.responses-already-sent' | translate}}
        </mat-card>
      </ng-container>

        <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
              <mat-label>{{'generic.organization' | translate}}</mat-label>
              <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
          </mat-form-field>
          <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
              <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
              <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? evaluation_session?.evaluation_end : translate('components.responses.undefined-due-date')" placeholder="{{'components.responses.due-date' | translate}}" readonly>
          </mat-form-field>
    </div>
  </div>

  <!-- Blocco 1 - Criteri proposte -->
  <div class="container-fluid accordion-container px-0 px-md-3">
    <div class="container">
        <mat-accordion class="accordion" multi>
            <mat-expansion-panel expanded>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span class="index">1</span>
                        {{'generic.proposal' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="container px-0">

                    <!-- <div class="col-12 col-lg-6 px-0 mb-0">
                        <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                            <mat-label class="box-title px-3 mb-0">Criteri rispetto agli obiettivi secondari</mat-label>
                        </div>
                    </div> -->

                      <div *ngFor="let proposal of model.proposals; let i = index">
                        <ng-container>
                          <div class="row">
                            <mat-form-field class="col-12 input-big">
                              <input matInput name="proposal_name{{i}}" [(ngModel)]="proposal.title" [placeholder]="!proposal_id ? ('components.responses.insert-proposal-name' | translate) : ''"  required autocomplete="off">
                            </mat-form-field>
                          </div>
                          <ng-container *ngFor="let criterio of proposal.criteria; let j = index">
                            <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
                                <div class="container">
                                    <mat-accordion class="accordion" multi [togglePosition]="'before'">
                                      <mat-expansion-panel class="px-0" expanded>
                                          <mat-expansion-panel-header>
                                              <mat-panel-title>
                                                  <span>{{criterio.title}}</span>
<!--                                                  <i class="icon-circle_info d-flex"></i>-->
<!--                                                  <i class="icon-checkmark abs-tr mx-0" [ngClass]="getIconColorForCriteria(criterio)"></i>-->
                                              </mat-panel-title>
                                          </mat-expansion-panel-header>



                                          <div class="row">
                                              <mat-form-field class="input-primary col-12 col-md-6 mb-2">
                                                  <mat-label>{{'components.responses.data-proposal' | translate}}</mat-label>
                                                  <input matInput name="criteria_typology{{i}}{{j}}" [ngModel]="getCriteriaService().getCriteriaTypeValueByKey(criterio.typology)" readonly>
                                              </mat-form-field>
                                            <div class="ml-auto my-3">
                                              <button
                                                *ngIf="criterio.modifyEnabled && proposal_id && criterionIsChanged(criterio)"
                                                class="size-small text-white mr-2"
                                                mat-flat-button
                                                color="secondary"
                                                (click)="saveCriterionValue(criterio)"
                                                [disabled]="decision_table.closing_date != null"
                                              >
                                                {{ "generic.buttons.save" | translate }}
                                              </button>
                                              <button
                                                *ngIf="!criterio.modifyEnabled && proposal_id"
                                                class="size-small text-white"
                                                mat-flat-button
                                                color="secondary"
                                                (click)="onClickModifyDescription(criterio)"
                                                [disabled]="decision_table.closing_date != null"
                                              >
                                                {{ "generic.buttons.modify" | translate }}
                                              </button>
                                              <button
                                                *ngIf="criterio.modifyEnabled && proposal_id"
                                                class="size-small danger text-white"
                                                mat-flat-button
                                                (click)="onClickCancelDescription(criterio)"
                                              >
                                                {{ "generic.cancel" | translate }}
                                              </button>
                                            </div>
                                              <mat-form-field *ngIf="criterio.measure_unit" class="input-primary col-12 col-md-6 mb-2">
                                                  <mat-label>{{'generic.measure-unit' | translate}}</mat-label>
                                                  <input matInput name="criteria_unit{{i}}{{j}}" [ngModel]="getUnitsService().getUnitValueByKey(criterio.measure_unit)" readonly>
                                              </mat-form-field>
                                              <mat-form-field class="input-primary col-12 mb-2 px-3">
                                                <input matInput name="criteria_value{{i}}{{j}}"
                                                  [placeholder]="getPlaceholderString(criterio.typology)"
                                                  [type]="getCriteriaService().criteriaIsQuantitative(criterio.typology) ? 'number' : 'text'"
                                                       [disabled]="!criterio.modifyEnabled && proposal_id"
                                                  [(ngModel)]="criterio.value" required autocomplete="off"
                                                >
                                              </mat-form-field>
                                          </div>
                                      </mat-expansion-panel>
                                </mat-accordion>
                              </div>
                            </div>
                          </ng-container>
                      </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button [routerLink]="proposal_id ? ['../../responses-evaluation'] : ['../responses-evaluation']">
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.go-back' | translate}}
    </button>
    <button class="button-primary float-right" mat-button [disabled]="!revisionProposalIsChanged()" (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        {{'generic.buttons.save-and-continue' | translate}}
    </button>
  </div>
</form>
