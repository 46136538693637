<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
  <!-- Blocco 1 - Obiettivi -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">1</span>
              <span *ngIf="contest">{{'generic.info' | translate}}</span>
              <span *ngIf="!contest">{{'generic.objectives' | translate}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 px-2" *ngIf="model.primary_target || contest">
              <div class="box px-0 box-two-row">
                <ng-container *ngIf="!contest;">

                  <mat-label
                    class="box-title px-3">{{'components.proposals.new-proposal.primary' | translate}}</mat-label>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="target_primary_title" [ngModel]="model.primary_target.title"
                           placeholder="{{'generic.title' | translate}}" readonly>
                  </mat-form-field>
                  <mat-form-field class="datepicker-primary col-3">
                    <mat-label>{{'components.proposals.new-proposal.due-date' | translate}}</mat-label>
                    <input matInput name="target_primary_date" class="ng-trim-ignore color-default"
                           [ngModel]="model.primary_target.date != null ? (model.primary_target.date| formatdate | date:'shortDate') : 'non definita'"
                           placeholder="{{'components.proposals.new-proposal.due-date' | translate}}" disabled>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-3">
                    <mat-label>{{'generic.measure-unit' | translate}}</mat-label>
                    <input matInput name="target_primary_unit"
                           [ngModel]="getUnitsService().getUnitValueByKey(model.primary_target.measure_unit)" readonly>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6">
                    <input matInput name="target_primary_objective"
                           placeholder="{{'components.proposals.new-proposal.target-value' | translate}}"
                           [ngModel]="getCriteriaService().getCriteriaValueByKey(model.primary_target.evaluation_criteria)"
                           readonly>
                  </mat-form-field>
                  <mat-form-field class="input-primary col-6" *ngIf="model.primary_target.expected_value">
                    <input matInput name="proposal_target_primary_expected_value"
                           placeholder="{{'components.proposals.new-proposal.desidered-value' | translate}}"
                           [ngModel]="model.primary_target.expected_value">
                  </mat-form-field>
                  <i class="icon-circle_info top-right grey-color"></i>
                </ng-container>
                <ng-container *ngIf="contest">
                  <mat-label class="box-title px-3">{{'generic.description' | translate}}</mat-label>
                  <mat-form-field class="input-primary col-12 mt-2">
                <textarea class="description" readonly matInput [value]="contest.description" rows="5"
                          placeholder=""></textarea>
                  </mat-form-field>
                  <i class="icon-circle_info top-right grey-color"></i>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="!contest">
              <div class="col-6 px-2" *ngFor="let targetSecondary of model.secondary_targets; let i = index">
                <ng-container>
                  <div class="box px-0 box-two-row">
                    <mat-label
                      class="box-title px-3">{{'components.proposals.new-proposal.secondary' | translate}}</mat-label>
                    <mat-form-field class="input-primary col-7">
                      <input matInput name="proposal_target_secondary_title{{i}}" [ngModel]="targetSecondary.title"
                             placeholder="{{'generic.title' | translate}}" readonly>
                    </mat-form-field>
                    <mat-form-field class="datepicker-primary col-5">
                      <mat-label>{{'components.proposals.new-proposal.due-date' | translate}}</mat-label>
                      <input matInput name="proposal_target_secondary_date{{i}}" class="ng-trim-ignore color-default"
                             [ngModel]="targetSecondary.date != null ? (targetSecondary.date| formatdate | date:'shortDate')  : 'non definita'"
                             placeholder="{{'components.proposals.new-proposal.due-date' | translate}}" disabled>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-7">
                      <input matInput name="proposal_target_secondary_evaluation_criteria{{i}}"
                             placeholder="{{'components.proposals.new-proposal.target-value' | translate}}"
                             [ngModel]="getCriteriaService().getCriteriaValueByKey(targetSecondary.evaluation_criteria)">
                    </mat-form-field>
                    <mat-form-field class="input-primary col-5">
                      <mat-label>{{'generic.measure-unit' | translate}}</mat-label>
                      <input matInput name="proposal_target_secondary_criteria_unit{{i}}"
                             [ngModel]="getUnitsService().getUnitValueByKey(targetSecondary.measure_unit)" readonly>
                    </mat-form-field>
                    <mat-form-field class="input-primary col-6" *ngIf="targetSecondary.expected_value">
                      <mat-label>{{'components.proposals.new-proposal.desidered-value' | translate}}</mat-label>
                      <input matInput name="proposal_target_secondary_expected_value{{i}}"
                             [ngModel]="targetSecondary.expected_value">
                    </mat-form-field>
                    <i class="icon-circle_info top-right grey-color"></i>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Blocco 2 - Creazione Proposta -->
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion" multi>
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="index">2</span>
              {{'generic.proposal' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container px-0">
            <div *ngFor="let proposal of model.proposals; let i = index">
              <ng-container>
                <div class="row">
                  <mat-form-field class="col-12 input-big">
                    <input matInput name="proposal_name{{i}}" [(ngModel)]="proposal.title"
                           placeholder="{{'components.proposals.new-proposal.placeholders.insert-proposal-name' | translate}}"
                           required autocomplete="off">
                  </mat-form-field>
                </div>
                <div class="row px-2">
                  <div
                    class="container-fluid accordion-container mt-3 accordion-inner"
                    *ngFor="let criterio of proposal.criteria; let j = index"
                  >
                    <ng-container>
                      <div class="container">
                        <mat-accordion class="accordion" multi>
                          <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                {{ criterio.title }}
                                <span class="float-right ml-auto font-weight-bold mr-2">
                                        <i
                                          class="icon-circle_info"></i>{{ getCriteriaService().getCriteriaTypeValueByKey(criterio.typology) }}</span>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- Text Area -->
                            <div class="col-12 px-2 mt-5">
                              <div class="box flex-column my-0 pb-0 pt-3 px-3"
                                   *ngIf="!getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">

                                <!-- CAMPO PER QUALITATIVO -->
                                <mat-form-field *ngIf="getCriteriaService().criteriaIsQualitative(criterio.typology)">
                                  <mat-label>{{
                                    "generic.description" | translate
                                    }}</mat-label>
                                  <textarea
                                    class="description"
                                    matInput
                                    required
                                    resizeToFitContent="true"
                                    name="criterio_description{{ i }}"
                                    placeholder="{{
                                'components.proposals.new-proposal.placeholders.insert-description'
                                  | translate
                              }}"
                                    [(ngModel)]="criterio.value"
                                  ></textarea>
                                </mat-form-field>


                                <!-- CAMPO PER QUANTITATIVO -->
                                <ng-container *ngIf="getCriteriaService().criteriaIsQuantitative(criterio.typology)">
                                  <div class="flex row">

                                    <mat-form-field class="col-6">
                                      <mat-label>{{
                                        "generic.numeric-value" | translate
                                        }}</mat-label>
                                      <input matInput name="criterio_description{{i}}"
                                             [type]="'number'"
                                             [(ngModel)]="criterio.value" required autocomplete="off"
                                      />
                                    </mat-form-field>
                                    <mat-form-field class="input-primary col-4 text-center">
                                      <mat-label>{{'generic.measure-unit' | translate}}</mat-label>
                                      <input matInput name="criteria_unit{{i}}_{{j}}"
                                             [ngModel]="getUnitsService().getUnitValueByKey(criterio.measure_unit)"
                                             readonly>
                                    </mat-form-field>
                                  </div>

                                </ng-container>
                              </div>
                            </div>


                            <ng-container *ngIf="currentOrganization?.customs?.profile == 'enel'">
                              <div class="col-12 d-flex ml-3 my-2">
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="criteria_item_id{{i}}_{{j}}"
                                         [ngModel]="criterio.additional.item_id"
                                         placeholder="{{'generic.item-id' | translate}}" readonly>
                                </mat-form-field>
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="criteria_section{{i}}_{{j}}"
                                         [ngModel]="criterio.additional.section"
                                         placeholder="{{'generic.section' | translate}}" readonly>
                                </mat-form-field>
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="criteria_sub-section{{i}}_{{j}}"
                                         [ngModel]="criterio.additional.sub_section"
                                         placeholder="{{'generic.sub-section' | translate}}" readonly>
                                </mat-form-field>
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="check_n{{i}}_{{j}}" [ngModel]="criterio.additional.check_n"
                                         placeholder="{{'Check_n' | translate}}" readonly>
                                </mat-form-field>
                              </div>
                              <div class="col-12 d-flex ml-3">
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="criteria_preliminary_check{{i}}_{{j}}"
                                         [ngModel]="criterio.additional.preliminary_check"
                                         placeholder="{{'generic.preliminary-check' | translate}}" readonly>
                                </mat-form-field>
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="requirements{{i}}_{{j}}"
                                         [ngModel]="criterio.additional.requirements"
                                         placeholder="{{'generic.requirements' | translate}}" readonly>
                                </mat-form-field>
                                <mat-form-field class="input-primary col-3 pt-2">
                                  <input matInput name="reference{{i}}_{{j}}" [ngModel]="criterio.additional.reference"
                                         placeholder="{{'generic.reference' | translate}}" readonly>
                                </mat-form-field>
                              </div>
                            </ng-container>

                            <div class="box flex-column my-0 pb-0 pt-3 px-3"
                                 *ngIf="getCriteriaService().criteriaIsMultipleChoice(criterio.typology)">
                              <mat-form-field class="col-12">
                                <mat-label>{{"generic.description" | translate}}</mat-label>
                                <textarea
                                  class="description"
                                  matInput
                                  resizeToFitContent="true"
                                  name="criterio_description{{ i }}"
                                  placeholder=""
                                  [(ngModel)]="criterio.value"
                                ></textarea>
                              </mat-form-field>
                              <div class="col-8">
                                <small class="col-12 m-0 p-0 text-muted">{{"generic.options" | translate}}</small>
                                <ng-container *ngFor="let option of criterio.options; let o = index">
                                  <div class="col-8 mt-2 p-0">
                                    {{o + 1}}. {{option.title}} - {{'generic.weight' | translate}} {{option.weight}}
                                    <!-- <mat-form-field class="full-width" appearance="fill">
                                      <input style="vertical-align: super;" matInput [(ngModel)]="option.title"
                                            name="criteria{{j}}_options{{o}}" readonly>
                                    </mat-form-field> -->
                                  </div>
                                  <div class="col-2 mt-2">
                                    <!-- <mat-form-field class="full-width" appearance="fill">
                                      <mat-label>{{'generic.weight' | translate}}</mat-label>
                                      <input type="number" matInput [(ngModel)]="option.weight"
                                            name="options_weight{{i}}_{{j}}_{{o}}" placeholder="0" readonly>
                                    </mat-form-field> -->
                                  </div>
                                </ng-container>
                              </div>
                            </div>

                            <div class="col-11 mt-4 ">
                              <mat-label class="label-box font-weight-bold" *ngIf="criterio.attachment.length > 0">
                                {{'components.proposals.new-proposal.loaded-evidences' | translate}}
                              </mat-label>

                              <div class="attachment-row"
                                   *ngFor="let attachment of criterio.attachment; let attachIndex = index">
                                      <span class="name">
                                          <i class="icon-pdf"></i>
                                          <a [href]="attachment.link" target="_blank">{{attachment.title}}</a>
                                      </span>
                                <span class="size float-right">
                                          {{'generic.buttons.delete' | translate}}
                                  <i class="icon-cestino main-color"
                                     (click)="onClickRemoveEvidence(attachIndex, criterio)"></i>
                                      </span>
                              </div>

                              <!-- {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}} -->
                              <div class="col-12 links-container">
                                <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
                                  <i class="icon-upload mr-2"></i>
                                  {{'generic.buttons.load-evidences' | translate}}
                                </button>
                                <mat-menu #menuAttachment="matMenu">
                                  <button mat-menu-item (click)="onClickAddNewEvidence(criterio, 'PUBMED')">
                                    <mat-icon aria-hidden="false">file_upload</mat-icon>
                                    Pubmed
                                  </button>
                                  <button mat-menu-item (click)="onClickAddNewEvidence(criterio, 'FILE')">
                                    <mat-icon aria-hidden="false">file_upload</mat-icon>
                                    File
                                  </button>
                                </mat-menu>
                              </div>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Buttons -->
  <div class="buttons-container container py-4">
    <button *ngIf="!contest" class="button-primary float-left" mat-button (click)="GoBack()">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.cancel-and-back' | translate}}
    </button>
    <button class="button-primary float-right" mat-button [disabled]="loading" (click)="onSubmit(form)">
      <i class="icon-Forward-arrow-small"></i>
      {{'components.proposals.new-proposal.save-and-add-proposal' | translate}}
    </button>
  </div>
</form>
