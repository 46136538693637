import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-proposal-rename',
  templateUrl: './modal-proposal-rename.component.html',
  styleUrls: ['./modal-proposal-rename.component.scss']
})
export class ModalProposalRenameComponent implements OnInit {
  title = '';
  initialTitle = '';
  constructor(public dialogRef: MatDialogRef<ModalProposalRenameComponent>, @Inject(MAT_DIALOG_DATA) public data: {proposalTitle: ''}){
    this.title = data.proposalTitle;
    this.initialTitle = data.proposalTitle;
  }

  ngOnInit(): void {
  }

  isEdited() {
    return this.title !== this.initialTitle;
  }

  public closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }

}
