<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container">
    <div class="row mt-5">
      <div class="col-12 col-md-8">
        <h3 class="h3-custom green mb-4">
          {{'pages.organizations.organization-add-role.component.definition-role' | translate}}
        </h3>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label
            >{{'generic.roles.role' | translate}}
            </mat-label>
            <input matInput  [(ngModel)]="model.role" required name="role" type="text">
          </mat-form-field>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="box flex-column my-5 pt-3">
            <mat-form-field appearance="legacy">
              <mat-label>
                {{'generic.description' | translate}}
              </mat-label>
                <input matInput [(ngModel)]="model.description" required name="description" type="text">
            </mat-form-field>
          </div>
        </div>
      </div>
  </div>
  <hr>
  <!-- <div class="container">
    <div class="row mt-4">
      <div class="col-12 col-md-6">
        <h3 class="my-2 green mb-4">
          {{'pages.organizations.organization-add-role.component.create-learning-curve' | translate}}
        </h3>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label>
              Lorem
              </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label>
              Lorem
              </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label>
              Lorem
              </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label>
              Lorem
              </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="box flex-column my-3 pt-3">
          <mat-form-field appearance="legacy">
            <mat-label>
              Lorem
              </mat-label>
            <input matInput>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6 my-5 py-5">
        <img class="img-fluid shadow ciao" src="https://via.placeholder.com/627x430" alt="">
        <h5 class="text-custom mt-4"><mat-icon>info</mat-icon>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nam ea laboriosam et quasi non ipsa aperiam ut magni nobis, dolorum pariatur nostrum aliquid id in, ex eius, numquam nulla inventore.</h5>
      </div>
    </div>
  </div> -->
  <div class="buttons-container container py-4 mt-5">
    <button type="button" class="button-primary float-left" mat-button [routerLink]="['../']">
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.back' | translate}}
    </button>
    <button type="button" class="button-primary float-right" mat-button [disabled]="loading" (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        {{'generic.buttons.save' | translate}}
    </button>
  </div>
</form>

