import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Scroll } from 'src/app/_helpers/scroll/scroll.component';

@Component({
  selector: 'app-decision-table',
  templateUrl: './decision-table.component.html',
  styleUrls: ['./decision-table.component.scss'],
})
export class DecisionTableComponent implements OnInit {
  step: number = 1;
  frameworkSelectionState: any;
  problemStatementState: any;
  project_id: any;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((paramMap) => {
      this.project_id = paramMap['project_id'];
    });
  }

  //  evento scatenato dal bottone indietro della selezione di un framework
  goToHome() {
    this.router.navigate(['/projects/' + this.project_id]);
  }

  //  evento scatenato dal bottone indietro della crezione di una proposta
  goToFrameworkSelection() {
    this.step--;
    Scroll.toTop();
  }

  //  evento scatenato dal save dello step di selezione del framework
  frameworkSelected(frameworkState) {
    if (frameworkState)
      this.frameworkSelectionState = JSON.parse(JSON.stringify(frameworkState));

    //  previene il fallimento della ricerca del form da parte del browser
    setTimeout(() => {
      this.step++;
      Scroll.toTop();
    }, 100);
  }

  goToProblemStatementSelection() {
    this.step--;
    Scroll.toTop();
  }

  //  evento scatenato dal save dello step di compilazione del problem statement
  problemStatementCompleted(problemStatement) {
    if (problemStatement)
      this.problemStatementState = JSON.parse(JSON.stringify(problemStatement));

    //  previene il fallimento della ricerca del form da parte del browser
    setTimeout(() => {
      this.step++;
      Scroll.toTop();
    }, 100);
  }
}
