import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {filter, map, mergeMap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    ) { }

  version = environment.version;
  footerHomeClass = '';

  ngOnInit(): void {



    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        if(event['isHome'] || event['isErrorPage'] || event['isBootstrap']) {
          this.footerHomeClass = 'footer-home';
        } else {
          this.footerHomeClass = '';
        }
      });

  }



}
