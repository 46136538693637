import { Component, OnInit,Inject }  from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/general/toast.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-add-certificate',
  templateUrl: './modal-add-certificate.component.html',
  styleUrls: ['./modal-add-certificate.component.scss']
})
export class ModalAddCertificateComponent implements OnInit {

  loading: boolean = false;

  model: any = {
    certificates : [],
    license: '',
    date_start: '',
    date_end : '',
    date_end_selected : false,
    organization : '',
    description : ''
  }

  constructor(
    public dialogRef: MatDialogRef<ModalAddCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private toast: ToastService,
    private userService : UserService,
    private translateService : TranslateService
    ) { }

  ngOnInit(): void {
    if(this.data.certificate != null && this.data.certificate != undefined)
    {this.model = JSON.parse(JSON.stringify(this.data.certificate))
      this.model.date_end == null ? this.model.date_end_selected = true : this.model.date_end_selected = false
    }
  }

  disableDateEnd() {
    this.model.date_end_selected = !this.model.date_end_selected;
  }


  onSubmit(form: NgForm) {

    if(this.model.license == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-certificate.component.error-enter-certification'));
      return;
    }

    if(this.model.date_start == '' ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-certificate.component.error-enter-release-date'));
      return;
    }
    // if(this.model.date_end == '' ) {
    //   this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-certificate.component.error-enter-exp-date'));
    //   return;
    // }
    if(this.model.organization == null ) {
      this.toast.error(this.translateService.instant('generic.warning'),this.translateService.instant('modals.modal-add-certificate.component.error-enter-issuing-organization'));
      return;
    }

    if(this.model.date_end_selected == true) {
      this.model.date_end = null
    }

    if (!form.form.valid)
    return;

    if (this.model.id) {
      this.updateCertificate();
      return;
    }

    this.userService.saveCertificate(this.data.curriculum_id,this.model, (result) => {
      this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-certificate.component.success-certificate-saved"));
      this.loading = false;

      this.closeDialogue('saved');

    }, (error) => {
      this.loading = false;
      this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-certificate.component.error-certificate-saved"));
    })
  }

  updateCertificate() {

    if(!this.model.date_end_selected){
      this.model.date_end_selected = this.model.date_end_selected;
    }

    this.loading = true;
    this.userService.updateCertificate(this.data.curriculum_id, this.model.id , this.model , (result) => {
      this.toast.success(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-certificate.component.success-certicate-updated"));
      this.loading = false;
      this.closeDialogue('updated');
    },(error) =>
    {
      this.loading = false
      this.toast.error(this.translateService.instant("generic.user"),this.translateService.instant("modals.modal-add-certificate.component.error-certificate-updated"));
    });
  }



  closeDialogue(event: any = null) {

    this.dialogRef.close({event:event});
  }

}
