<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">
        <span *ngIf="!model.id">{{'generic.new' | translate}}</span>
        <span *ngIf="model.id">{{'generic.edit' | translate}}</span>
        {{'modal.modal-template-email' | translate}}</h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p role="button" (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-form-field class="input-big full-width p-0">
            <input matInput name="template_name" [(ngModel)]="model.name" placeholder="{{'modal.template-email.title' | translate}}" required autocomplete="off">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-5 mb-4">
          <mat-form-field class="select-primary col-3 full-width p-0">
            <mat-label>{{'modal.template-email.tipology' | translate}}</mat-label>
            <mat-select name="type"
                        [(ngModel)]="model.type" required>
              <mat-option value="" selected></mat-option>
              <mat-option *ngFor="let tt of getConfigService().emailTemplates | keyvalue"
                          [value]="tt.key">{{tt.value | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-12">
          <div class="box box-border mt-3">
            <mat-form-field class="col-12 p-0">
              <mat-label>{{'modal.template-email.email-text' | translate}}</mat-label>
              <textarea class="description" matInput name="email_text" [(ngModel)]="model.email_text" rows="3" placeholder="{{'modal.template-email.email-text' | translate}}"></textarea>
            </mat-form-field>
          </div>
        </div> -->
        <div class="col-12 col-md-7">
          <div class="row">
            <ng-container *ngFor="let document of model.attachments">
              <div class="col-4 mb-4 text-center">
                <div class="box-custom">
                  <div class="icon-container"><fa-icon [icon]="faFile"></fa-icon></div>
                  <small class="text-dark mt-2 text-center text-container">{{document.title}}</small>
                </div>
                <button class="btn-small" mat-raised-button color="warn" (click)="removeDocument(document)"><fa-icon [icon]="faTimes"></fa-icon> {{'generic.remove' | translate}}</button>
              </div>
            </ng-container>
            <div class="col-4 mb-4 text-center">
              <div role="button" class="box-custom add-attachment" (click)="searchAttachment(!searchAttachmentBox)">
                <div class="icon-container"><i class="icon-big_add"></i></div>
                <small class="text-dark mt-2 text-center text-container">{{'modal.template-email.add-attachment' | translate}}</small>
              </div>
              <div *ngIf="searchAttachmentBox" class="icon-custom"><fa-icon [icon]="faPlay"></fa-icon></div>
            </div>
          </div>
        </div>
      </div>

      <div [@slideIn] *ngIf="searchAttachmentBox">
        <mat-card>
          <div class="row" >
            <div class="col-12">
              <mat-form-field class="input-big full-width p-0">
                <input matInput name="search_name" [(ngModel)]="model.searchText" placeholder="{{'Cerca allegato da inserire nella mail' | translate}}" autocomplete="off" (keyup)="onsearch($event.target.value)">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 px-2 mb-4">
              <mat-table *ngIf="!loading && templatesData && templatesData?.data && templatesData.data.length > 0" [dataSource]="templatesData" class="table-primary">

                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <mat-header-cell *matHeaderCellDef>{{'generic.title' | translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let element" [style]="{'text-decoration': element.deleted_at ? 'line-through' : ''}">
                    <span *ngIf="element?.title"><a role="button" (click)="showDocument(element)" >{{element?.title}}</a></span>
                  </mat-cell>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let element" class="d-flex py-3" style="justify-content:right;">
                    <button mat-button color="primary" [disableRipple]="true" (click)="addDocument(element)">
                      <small>{{'modal.template-add-document' | translate}}</small>
                      <mat-icon>forward</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <!-- No data-->
              <div class="col-12 text-center my-2 p-2" *ngIf="!loading && isFirstSearch && templatesData && templatesData?.data?.length === 0">
                <h3 class="color-green">{{'pages.document.all-docuements-table.component.no-filtered-documents-tables' | translate}}</h3>
              </div>
            </div>
          </div>
          <mat-card-actions>
            <button type="button" mat-button class="button-primary button-custom" (click)="searchAttachment(false)">{{'generic.cancel' | translate}}</button>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingSearch"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
    <div class="buttons-container container py-5 mb-3">
      <button class="button-primary float-right" *ngIf="!searchAttachmentBox" [disabled]="loading" mat-button (click)="onSubmit(form)">
          <i class="icon-Forward-arrow-small"></i>
          <span *ngIf="!model.id">{{'generic.buttons.save' | translate}}</span>
          <span *ngIf="model.id">{{'generic.buttons.modify' | translate}}</span>
      </button>
    </div>
  </form>
</div>
