import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/general/toast.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  constructor(private userService: UserService, private organizationService: OrganizationService, private toast: ToastService) { }

  organizations: any [] = [];

  ngOnInit(): void {

  }

  public getUserService() {
    return this.userService;
  }

  public changeCurrentOrganization(organization_id) {
    const organization = this.userService.getOrganizationByID(organization_id);
    this.checkCustomOrganizations(organization);
    this.userService.setPreferredOrganization(organization_id);
    const preferredOrg = this.userService.currentOrganization.name;
    this.userService.resetCurrentLanguage();
    this.toast.success('Success', `"${preferredOrg}" è stata impostata come organizzazione corrente`);
  }

  checkCustomOrganizations(organization){
    const org_usr = this.userService.getOrganizationUserByOrganizationId(organization?.id);
    if (organization?.customs?.profile === 'alexion'){
      if (!JSON.parse(localStorage.getItem('order_number_alexion_logged'))){
        this.userService.setPreferredOrganization(organization.id, () => {
          window.location.reload();
        });
      }
    }
  }

}
