import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {ModalAddAttachmentComponent} from "../../../../modals/modal-add-attachment/modal-add-attachment.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-new-decision-table-compile-problem-statement',
  templateUrl: './new-decision-table-compile-problem-statement.component.html',
  styleUrls: ['./new-decision-table-compile-problem-statement.component.scss']
})
export class NewDecisionTableCompileProblemStatementComponent implements OnInit {

  @Output() problemStatementCompletedEvent: EventEmitter<any> = new EventEmitter();
  @Output() goBackEvent: EventEmitter<boolean> = new EventEmitter();
  @Input() problemStatementModel: any;

  editorForm: FormGroup;

  loading: boolean = false;

  model: any = {
    problemStatementDescription: "Inserire una descrizione di che cos'è il problem statement",
    additional_context_data: [],
    problem_statement_attachments: []
  }

  constructor(private fb: FormBuilder, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.editorForm = this.fb.group({
      thesis: ['', Validators.required],
      context: ['', Validators.required],
    });

    this.init();
  }

  init() {

    //  setto il problem statement selezionato se è già presente
    if (this.problemStatementModel) {
      this.model = JSON.parse(JSON.stringify(this.problemStatementModel));
    }
    this.editorForm.patchValue({
        thesis: this.model.problem_statement_tesi || '',
        context: this.model.problem_statement_contesto || '',
      });
  }

  // onSubmit(form: NgForm) {
  onSubmit() {

    if (this.editorForm.valid) {
      this.model = {
        ...this.model,
        ...this.editorForm.value,
      };

      this.problemStatementCompletedEvent.emit(this.model);
    }

  }

  backButtonOnClick(event) {
    event.preventDefault();
    this.goBackEvent.emit(true);
  }

  removeAttachment(attachment) {
    const index = this.model?.problem_statement_attachments.indexOf(attachment);

    if (index !== -1) {
      this.model?.problem_statement_attachments.splice(index, 1);
    }
  }

  addAttachment(type) {

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: type,
        uploadUrl: 'upload/tmp-problem-statement-file'
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {

      if (attachmentData) {

        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }

  addPubmedAttachment(attachmentPubmed) {
    this.model?.problem_statement_attachments?.push({
      type: 'PUBMED',
      PMID: attachmentPubmed?.attachmentObject?.PMID,
      title: attachmentPubmed?.attachmentObject?.ArticleTitle,
      link: attachmentPubmed?.attachmentObject?.link
    });
  }


  /**
   * Setto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {

    for (const fileAttached of attachmentFile.attachmentObject) {
      this.model?.problem_statement_attachments?.push({
        id: fileAttached.id,
        type: 'FILE',
        ext: fileAttached.ext,
        size: fileAttached.size,
        title: fileAttached.title,
        path: fileAttached.path,
        link: fileAttached.link
      });
    }
  }
}
