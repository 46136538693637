import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';

@Component({
  selector: 'app-multiobjective-responses-summary',
  templateUrl: './multiobjective-responses-summary.component.html',
  styleUrls: ['./multiobjective-responses-summary.component.scss']
})
export class MultiobjectiveResponsesSummaryComponent implements OnInit {

  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId;

  loading: boolean = false;

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table:any;
  organization:any
  primary_target: any = [];
  secondary_target: any = [];
  framework_criteria: any = [];
  proposals: any = [];

  evaluations: any = [];

  constructor(private unitService: UnitsService, private criteriaService: CriteriaService, private translateService: TranslateService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {

    this.loading = true;
    if (this.allDecisionInfo) {

      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      this.secondary_target = this.allDecisionInfo.secondary_target;
      this.framework_criteria = this.allDecisionInfo.framework_criteria;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;

      this.init();
    }

    this.loading = false;

    // this.doSomething(changes.categoryId.currentValue);

  }

  init() {

    this.loading = true;

    //  setto i pesi per gli obiettivi secondari sul primario
    for (let target of this.secondary_target) {

      let evaluation_target_finded = this.evaluations.weigth_targets.find(x => x.target_id == target.id);
      if (evaluation_target_finded) {
        target.evaluation = evaluation_target_finded;
      } else {
        target.evaluation = { weight: '-' };
      }
    }

    for (let target of this.secondary_target) {
      target.framework_criteria = JSON.parse(JSON.stringify(this.framework_criteria));
      let evaluation_target_filtered = this.evaluations.weigth_criteria.filter(x => x.target_id == target.id);
      for(let criteria of target.framework_criteria) {
        let evaluation_criteria_finded = evaluation_target_filtered.find(x => x.framework_criteria_id == criteria.id);
        if (evaluation_criteria_finded) {
          criteria.evaluation = evaluation_criteria_finded;
        } else {
          criteria.evaluation = { weight: '-' };
        }
      }
    }

    //  setto le confidence per i criteri di ogni proposta
    for (let proposal of this.proposals) {
      for (let criteria of proposal.criteria) {
        let response = this.evaluations.answers.find(x => x.criteria_id == criteria.id);
        if (response) {
          criteria.answer = response;
        } else {
          criteria.answer = { confidence: '-' }
        }
      }
    }

    this.loading = false;
  }


  translate (string) {
    return this.translateService.instant(string);
  }


  getUnitsService() {
    return this.unitService;
  }

  getCriteriaService() {
    return this.criteriaService;
  }

}
