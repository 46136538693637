import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ProjectsService} from '../../../services/projects.service';
import {MatTableDataSource} from '@angular/material/table';
import {ProjectsData} from '../all-projects/projects.component';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderTitleService} from '../../../services/general/header-title.service';
import {ModalSurveyDetailsComponent} from '../../../modals/modal-survey-details/modal-survey-details.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  constructor(public userService: UserService,
              private projectService: ProjectsService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private headerTitleService: HeaderTitleService,
              private router: Router,
              private translateService: TranslateService
    ) { }

  loading = false;
  project_id = null;
  project = null;
  projectFieldUpdatable = ['title', 'description'];
  projectUpdateModel = {
    title: '',
    description: '',
  };

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.project_id = params.project_id;
      this.init();
    });
  }

  public init() {
    this.getProject();
  }

  public getProject() {
    this.loading = true;
    this.projectService.getProject(this.project_id, (result) => {
      this.project = result;
      this.headerTitleService.setTitle(
        this.translateService.instant('generic.table') + ': ' + result.title);
      this.setUpdateProjectModel();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  public setUpdateProjectModel(reverse = false) {
    if (!reverse) {
      this.projectUpdateModel = { ...this.project };
    } else {
      for (const field of this.projectFieldUpdatable) {
        this.project[field] = this.projectUpdateModel[field];
      }
    }
  }


  public get projectUpdateModelIsChanged() {
    for (const field of this.projectFieldUpdatable) {
      if (this.projectUpdateModel[field] !== this.project[field]) {
        return true;
      }
    }
    return false;
  }

  public updateProject() {
    this.loading = true;

    const model = this.projectFieldUpdatable.reduce((acc, field) => {
      acc[field] = this.projectUpdateModel[field];
      return acc;
    }, {});


    this.projectService.updateProject(this.project_id, model, (result) => {
      this.setUpdateProjectModel(true);
      this.headerTitleService.setTitle('Project: ' + result.title);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

}
