import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/general/toast.service';
import { ModalNewProposalComponent } from 'src/app/modals/modal-new-proposal/modal-new-proposal.component';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/general/alert.service';


@Component({
  selector: 'app-definition-roles',
  templateUrl: './definition-roles.component.html',
  styleUrls: ['./definition-roles.component.scss']
})
export class DefinitionRolesComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  organization_id: any;

  loading: boolean = false;

  roles :any [] = [];

  constructor(private dialog: MatDialog,private organizationService : OrganizationService, private userService : UserService,
    private activeRoute: ActivatedRoute, private toast: ToastService, private translateService : TranslateService,private alert: AlertService) { }


  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  init() {

    this.organizationService.allOrganizationRoles(this.organization_id, (result) => {
      this.roles = result;
    })

  }

  openDialog() {
    this.dialog.open(ModalNewProposalComponent);
  }


public getOrganizationService() {
  return this.organizationService;
}

removeRole(role_id) {

  this.alert.showQuestion(this.translateService.instant("generic.warning"),this.translateService.instant("pages.definition-roles.component.alert-role-removed"), (ok) => {
    this.loading = true;
    this.organizationService.deleteOrganizationRole(this.organization_id, role_id, () => {
      this.loading = false;
      this.toast.success(this.translateService.instant('generic.roles.roles'), this.translateService.instant('pages.definition-roles.component.role-removed'));
      this.init();
    },()=> {
      this.loading = false;
      this.toast.error(this.translateService.instant('generic.roles.roles'), this.translateService.instant('pages.definition-roles.component.error-role-removed'));
    });
  })
}


  public getUserService(){
    return this.userService
  }

}
