import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {ResponseTypesService} from 'src/app/services/config/response-types.service';
import {UnitsService} from 'src/app/services/config/units.service';
import {AlertService} from 'src/app/services/general/alert.service';
import {ToastService} from 'src/app/services/general/toast.service';
import {ResponseService} from 'src/app/services/response.service';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ModalNewProposalComponent} from '../../../../modals/modal-new-proposal/modal-new-proposal.component';
import {ModalShowProposalsComponent} from '../../../../modals/modal-show-proposals/modal-show-proposals.component';

@Component({
  selector: 'app-consensus-responses-evaluation',
  templateUrl: './consensus-responses-evaluation.component.html',
  styleUrls: ['./consensus-responses-evaluation.component.scss']
})
export class ConsensusResponsesEvaluationComponent implements OnInit {

  @Input() allDecisionInfo: any;
  @Input() evaluationSessionId: any;
  @Output() responseEvaluationSavedEvent = new EventEmitter();
  @Output() attachmentOnClickEvent = new EventEmitter();

  loading = false;

  routeBack = '../weight-criteria';

  team_member: any;
  evaluation_session: any;
  response_stamps: any;
  decision_table: any;
  organization: any;
  primary_target: any = [];
  proposals: any = [];
  initial_proposals: string;
  suggestedProposals = [];
  enabledSuggestedProposalsButton = true;

  exandedPanels = false;
  onlyNotResponsed = false;

  evaluations: any = [];

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router,
              private toast: ToastService, private criteriaService: CriteriaService, private unitService: UnitsService,
              private responseType: ResponseTypesService, private alert: AlertService, public dialog: MatDialog, private translateService: TranslateService) {
  }


  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {

    this.loading = true;
    if (this.allDecisionInfo && this.evaluationSessionId) {
      this.suggestedProposals = this.allDecisionInfo?.suggested_proposals || [];
      this.team_member = this.allDecisionInfo.team_member;
      this.evaluation_session = this.allDecisionInfo.evaluation_session;
      this.response_stamps = this.allDecisionInfo.response_stamps;
      this.decision_table = this.allDecisionInfo.decision_table;
      this.organization = this.allDecisionInfo.decision_table.organization;
      this.primary_target = this.allDecisionInfo.primary_target;
      this.proposals = this.allDecisionInfo.proposals;
      this.evaluations = this.allDecisionInfo.evaluations;
      this.evaluations.answers.forEach((answer) => {
        if (answer.confidence === -1) {
          answer.confidence = null;
        }
      });
      this.init();
    }
    this.loading = false;
  }


  init() {

    this.loading = true;

    //  setto il route back
    if (this.allDecisionInfo?.evaluation_session?.settings?.weights_provided_users == false) {
      this.routeBack = '../explain';
    }

    this.prepareCriteria();
    this.prepareAttachment();
    this.prepareExpertAttachment();

    if (this.response_stamps?.completed) {
      this.prepareAttachmentOnCompleted();
    }

    this.initial_proposals = JSON.stringify(this.proposals);

    this.loading = false;
  }


  /** per ogni proposta setto i criteri corrispondenti */
  prepareCriteria() {

    this.proposals.forEach(proposal => {
      proposal.criteria.forEach(criteria => {
        const response = this.evaluations.answers.find(x => x.criteria_id == criteria.id);
        if (response) {
          criteria.answer = response;
        } else {
          criteria.answer = {confidence: null};
        }
      });
    });
  }

  prepareAttachmentOnCompleted() {
    this.proposals.forEach((proposal) => {
      proposal.criteria.forEach((criterion) => {
        criterion.attachment_expert.push(...criterion.attachment_expert_files);
        criterion.attachment_expert.push(...criterion.attachment_expert_pubmed);
      });
    });
  }

  showSuggestedProposals() {
    const data = {
      suggestedProposals: this.suggestedProposals,
      decisionTable: this.decision_table,
    };
    this.enabledSuggestedProposalsButton = false;
    const dialogRef = this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
    dialogRef.afterClosed().subscribe((event) => {
      this.enabledSuggestedProposalsButton = true;
    });
  }

  /**
   * Setto gli allegati nei criteri
   */
  prepareAttachment() {

    this.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        criterio.attachment = [];
        if (criterio.attachment_pubmed) {
          criterio.attachment_pubmed.forEach(pubmed => {

            if (pubmed.team_member_id === undefined || (proposal.proposedby_team_member_id == pubmed.team_member_id)) {
              criterio.attachment.push(pubmed);
            }
          });
        }
        if (criterio.attachment_files) {
          criterio.attachment_files.forEach(file => {

            if (file.team_member_id === undefined || (proposal.proposedby_team_member_id == file.team_member_id)) {
              criterio.attachment.push(file);
            }
          });
        }
      });
    });
  }

  /** Setto gli allegati degli esperti nei criteri */
  prepareExpertAttachment() {

    this.proposals.forEach(proposal => {
      proposal.criteria.forEach((criterio) => {
        criterio.attachment_expert = [];

        const files = criterio.attachment_expert_files || [];
        const pubmed = criterio.attachment_expert_pubmed || [];

        criterio.attachment_expert = pubmed.concat(files);
      });
    });
  }

  cleanAttachment() {
    const modelProposal = JSON.parse(JSON.stringify(this.proposals));
    modelProposal.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        criterio.attachment_expert_pubmed = criterio.attachment_expert_pubmed.filter(x => x.isNew == true);
        criterio.attachment_expert_files = criterio.attachment_expert_files.filter(x => x.isNew == true);
      });
    });
    return modelProposal;
  }

  setCriteriaAnswerConfidence(criteria) {
    if (criteria.answer.confidence === null) {
      criteria.answer.confidence = 0;
    }
  }

  verifySubmit(saveAsDraf: boolean = false) {
    this.setConfidenceNotSetted();

    if (saveAsDraf) {
      this.confirmSubmit(saveAsDraf);
      return;
    }

    //  controllo che i pesi siano tutti validi
    if (!this.proposalsAreCompleted(this.not_answered_proposals())) {
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant('components.responses.suggest.error-new-proposal'));
      return;
    }

    //  la confidence può essere anche 0
    if (!this.criteriaConfidenceValid) {
      this.alert.showQuestion(this.translateService.instant('generic.warning'), this.translateService.instant('components.responses.consensus.alert-confidence-value-zero'), (ok) => {
        this.confirmSubmit(saveAsDraf);
        return;
      });
    }

    //  invio le risposte
    this.confirmSubmit(saveAsDraf);
  }


  /** Messaggio di conferma di invio del questionario */
  confirmSubmit(saveAsDraf: boolean = false) {

    //  se un draft non faccio la domanda di invio questionario
    if (saveAsDraf) {
      this.onSubmit(saveAsDraf);
      return;
    }

    this.alert.showQuestion(this.translateService.instant('generic.warning'), this.translateService.instant('generic.alert-questionnaire-change'), (ok) => {
      this.onSubmit(saveAsDraf);
    });
  }


  onSubmit(saveAsDraf: boolean = false) {

    this.loading = true;

    //  pulisco gli allegati per evitare che vengano duplicati
    const modelProposal = this.cleanAttachment();
    this.responseService.saveResponses(this.evaluationSessionId, {responses: this.not_answered_proposals(modelProposal), saveAsDraft: saveAsDraf}, result => {
        this.loading = false;
        if (saveAsDraf) {
          this.toast.success(this.translateService.instant('generic.responses'), this.translateService.instant('components.responses.consensus.evaluation-success-answers-saved'));
          this.responseEvaluationSavedEvent.emit(true);
        } else {
          this.loading = true;
          this.responseService.closeResponse(this.evaluationSessionId, result => {
            this.loading = false;
            this.toast.success(this.translateService.instant('generic.questionnaire'), this.translateService.instant('generic.questionnaire-sent'));
            this.router.navigate(['../thank-you'], {relativeTo: this.activeRoute});
          }, () => this.loading = false);

        }

      }, () => this.loading = false
    );
  }


  suggestProposalOnClick(proposal = null) {

    let data = {};
    this.decision_table.fromQuestionnaire = true;
    if (proposal) {
      data = {
        newProposal: false,
        decision_table: this.decision_table,
        proposal,
      };
    } else {
      data = {
        newProposal: true,
        decision_table: this.decision_table,
      };
    }

    const dialogRef = this.dialog.open(ModalNewProposalComponent, {
      panelClass: 'fullscreen-dialog',
      data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.suggestedProposals.push(result?.proposal);
      }
    });
  }


  //  evento di apertura di un allegato
  onClickAttachment(attachment: any) {
    this.attachmentOnClickEvent.emit(attachment);
  }

  not_answered_proposals(proposals = this.proposals) {
    const filteredProposals = [];
    proposals.forEach((proposal) => {
      const filteredCriteria = proposal.criteria.filter((criterion) => {
        return !criterion.answered;
      });
      if (filteredCriteria.length > 0) {
        const filteredProposal = {...proposal, criteria: filteredCriteria};
        filteredProposals.push(filteredProposal);
      }
    });
    return filteredProposals;
  }


  translate(string) {
    return this.translateService.instant(string);
  }

  /** Valida le confidence delle proposte */
  get criteriaConfidenceValid() {
    for (const proposal of this.not_answered_proposals()) {
      for (const criteria of proposal.criteria) {
        if (this.criteriaService.criteriaIsQualitative(criteria.typology) && criteria.answer.confidence == 0) {
          return false;
        }
      }
    }
    return true;
  }

  /** Valida il feedback delle proposte */
  get criteriaFeedbackValid() {
    for (const proposal of this.not_answered_proposals()) {
      for (const criteria of proposal.criteria) {
        // @ts-ignore
        if (!this.criteriaService.criteriaIsQuantitative(criteria.typology) && !criteria.answer.feedback && !criteria.answer.consent_value === 'ABSTAINED') {
          return false;
        }
      }
    }

    return true;
  }

  /** Valida le confidences */
  criteriaConfidenceIsValid() {
    for (const proposal of this.not_answered_proposals()) {
      for (const criteria of proposal.criteria) {
        if (criteria.answer.confidence === null || criteria.answer.confidence < 0) {
          return false;
        }
      }
    }

    return true;
  }

  setConfidenceNotSetted() {
    for (const proposal of this.proposals) {
      for (const criteria of proposal.criteria) {
        if (criteria.answer.confidence === null) {
          criteria.answer.confidence = 0;
        }
      }
    }
  }

  /** Valida le risposte delle proposte */
  get criteriaconsentValid() {

    for (const proposal of this.not_answered_proposals()) {
      for (const criteria of proposal.criteria) {
        if (!this.criteriaService.criteriaIsQuantitative(criteria.typology) && !criteria.answer.consent_value) {
          return false;
        }
      }
    }

    return true;
  }

  /** Restituisco il colore corretto se l'utente ha completato il criterio */
  getIconColorForCriteria(criteria: any) {
    return this.criterionIsCompleted(criteria) ? 'green-color' : 'orange-color';
  }

  criterionIsCompleted(criterion) {
    return criterion?.answered ||
    (this.criteriaService.criteriaIsQualitative(criterion?.typology) && criterion?.answer?.feedback && criterion?.answer?.consent_value);
  }

  proposalIsCompleted(proposal) {
    return proposal?.criteria?.every((criterion) => this.criterionIsCompleted(criterion));
  }

  proposalsAreCompleted(proposals = this.proposals) {
    return this.proposals?.every((proposal) => this.proposalIsCompleted(proposal));
  }


  /** Setta il valore della risposta ad Agree */
  onClickAgree(criteria: any) {
    criteria.answer.consent_value = this.responseType.getConsensusAgreeType();
  }

  /** Setta il valore della risposta a Disagree */
  onClickDisagree(criteria: any) {
    criteria.answer.consent_value = this.responseType.getConsensusDisagreeType();
  }

  /** Setta il valore della risposta ad astenuto */
  onClickAbstained(criteria: any) {
    criteria.answer.consent_value = this.responseType.getConsensusAbstainedType();
    criteria.answer.confidence = 0;
  }

  /** Verifica se l'utente non partecipa alla votazione **/
  isAbstained(criteria: any) {
    return criteria.answer.consent_value === this.responseType.getConsensusAbstainedType();
  }

  /** Aggiunge il campo feedback se non c'è */
  addFeedback(proposal_index, criteria_index) {
    this.proposals[proposal_index].criteria[criteria_index].answer.feedback = '';
  }

  /** rimuove il campo feedback */
  removeFeedback(proposal_index, criteria_index) {
    delete this.proposals[proposal_index].criteria[criteria_index].answer.feedback;
  }


  /**
   * Aggiunta di un nuovo allegato
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(proposal_index: any, criteria_index: any, attachment_type: string) {

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'modals.modal-add-attachment.add-evidences',
        attachmentType: attachment_type,
        proposal: this.proposals[proposal_index],
        criterio: this.proposals[proposal_index].criteria[criteria_index],
        uploadUrl: 'upload/tmp-expert-proposal-file',
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {
      if (attachmentData) {

        switch (attachmentData.attachmentType) {
          case 'PUBMED':
            this.addPubmedExpertAttachment(attachmentData);
            break;
          case 'FILE':
            this.addFileAttachment(attachmentData);
            break;
        }
      }
    });
  }


  /**
   * Rimozione di un allegato
   * @param attachIndex
   * @param criterio
   */
  onClickRemoveEvidence(attachIndex, criterio) {

    const attachment = criterio.attachment_expert[attachIndex];

    switch (attachment.type) {
      case 'PUBMED':
        if (attachment.isNew) {
          this.removeAttachmentExpertPubmed(attachment, criterio);
        } else {
          this.responseService.removeAttachment(this.evaluationSessionId, {
            attachment_id: attachment.id,
            type: 'PUBMED'
          }, () => {
            this.removeAttachmentExpertPubmed(attachment, criterio);
            this.toast.success(this.translateService.instant('components.responses.attached'), this.translateService.instant('components.responses.consensus.evaluation-success-remove-attachment'));
          }, () => {
            this.toast.error(this.translateService.instant('components.responses.attached'), this.translateService.instant('components.responses.consensus.evaluation-error-remove-attachment'));
          });
        }
        break;
      case 'FILE':
        if (attachment.isNew) {
          this.removeAttachmentExpertFile(attachment, criterio);
        } else {
          this.responseService.removeAttachment(this.evaluationSessionId, {
            attachment_id: attachment.id,
            type: 'FILE'
          }, () => {
            this.removeAttachmentExpertFile(attachment, criterio);
            this.toast.success(this.translateService.instant('components.responses.attached'), this.translateService.instant('components.responses.consensus.evaluation-success-remove-attachment'));
          }, () => {
            this.toast.error(this.translateService.instant('components.responses.attached'), this.translateService.instant('components.responses.consensus.evaluation-error-remove-attachment'));
          });
        }
        break;
    }
  }

  removeAttachmentExpertPubmed(attachment, criterio) {
    const pubmedIndex = criterio.attachment_expert_pubmed.findIndex(x => x.PMID == attachment.PMID);
    if (pubmedIndex >= 0) {
      criterio.attachment_expert_pubmed.splice(pubmedIndex, 1);
    }

    this.prepareExpertAttachment();
  }

  removeAttachmentExpertFile(attachment, criterio) {
    const fileIndex = criterio.attachment_expert_files.findIndex(x => x.id == attachment.id);
    if (fileIndex >= 0) {
      criterio.attachment_expert_files.splice(fileIndex, 1);
    }

    this.prepareExpertAttachment();
  }


  /**
   * Setto un allegato pubmed
   * @param attachmentPubmed
   */
  addPubmedExpertAttachment(attachmentPubmed) {

    for (const proposal of this.proposals) {
      for (const proposalCriteria of proposal.criteria) {
        if (proposalCriteria.id == attachmentPubmed.criterio.id) {
          if (!proposalCriteria.attachment_expert_pubmed) {
            proposalCriteria.attachment_expert_pubmed = [];
          }

          proposalCriteria.attachment_expert_pubmed.push({
            type: 'PUBMED',
            PMID: attachmentPubmed.attachmentObject.PMID,
            title: attachmentPubmed.attachmentObject.ArticleTitle,
            link: attachmentPubmed.attachmentObject.link,
            isNew: true,
          });

          break;
        }
      }

      this.prepareExpertAttachment();
    }
  }


  /**
   * Serto un allegato file
   * @param attachmentFile
   */
  addFileAttachment(attachmentFile) {

    for (const fileAttached of attachmentFile.attachmentObject) {
      for (const proposal of this.proposals) {
        for (const proposalCriteria of proposal.criteria) {
          if (proposalCriteria.id == attachmentFile.criterio.id) {
            if (!proposalCriteria.attachment_expert_files) {
              proposalCriteria.attachment_expert_files = [];
            }

            proposalCriteria.attachment_expert_files.push({
              id: fileAttached.id,
              type: 'FILE',
              ext: fileAttached.ext,
              size: fileAttached.size,
              title: fileAttached.title,
              path: fileAttached.path,
              link: fileAttached.link,
              isNew: true,
            });

            break;
          }
        }
      }
    }

    this.prepareExpertAttachment();
  }


  public getCriteriaService() {
    return this.criteriaService;
  }

  public getUnitsService() {
    return this.unitService;
  }

  public getResponseType() {
    return this.responseType;
  }
}

