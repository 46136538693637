<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{ "pages.projects.project-details.details" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="container my-5">
          <mat-card class="login-card">
            <mat-card-content>
              <form (submit)="updateProject()">
                <mat-form-field class="full-width">
                  <mat-label>{{ "generic.title" | translate }}</mat-label>
                  <input matInput [(ngModel)]="projectUpdateModel.title" name="title" required>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <mat-label>{{ "generic.description" | translate }}</mat-label>
                  <textarea matInput [(ngModel)]="projectUpdateModel.description" name="description"></textarea>
                </mat-form-field>
                <div class="row justify-content-center my-3">
                  <a mat-button color="warn" *ngIf="projectUpdateModelIsChanged" (click)="setUpdateProjectModel()" class="mr-2">{{"generic.buttons.cancel-and-back" | translate}}</a>
                  <button mat-raised-button color="primary" type="submit">{{"generic.update" | translate}}</button>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">2</span>
            {{ "pages.projects.component.decision-table" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-5">
          <app-all-decision-table [projectId]="project_id" [input_decision_tables]="project?.decision_tables"/>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="container-fluid accordion-container" *ngIf="project && userService.currentOrganizationHasFeature('surveys')">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">3</span>
            {{'pages.projects.component.surveys' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="my-5">
          <app-all-surveys [projectId]="project?.id" [input_surveys]="project?.surveys"/>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.home' | translate}}
  </button>
</div>

