<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<div class="row" style="background-color: #F1F2F2" *ngIf="survey">
  <div class="container mt-3" style="background-color: #F1F2F2">
    <mat-stepper [linear]="true" #stepper style="background-color: #F1F2F2">
      <mat-step *ngIf="!userService.userInfo && survey.visibility_type === 'PUBLIC'" [stepControl]="userInfoForm" #stepper>
        <form [formGroup]="userInfoForm">
          <div class="mt-5">
            <ng-template matStepLabel>{{ "generic.generic-info" | translate }}</ng-template>
            <div class="box flex-column my-0 pb-0 pt-3 px-3 border-0">
              <mat-form-field  appearance="fill">
                <mat-label>{{
                    "generic.name" | translate
                  }}</mat-label>
                <input formControlName="firstCtrl" [(ngModel)]="survey.user.name" required matInput name="survey_title">
              </mat-form-field>
            </div>
            <div class="box flex-column my-0 pb-0 pt-3 px-3 border-0">
              <mat-form-field  appearance="fill">
                <mat-label>{{
                    "generic.email" | translate
                  }}</mat-label>
                <input type="email" [email]="true" [(ngModel)]="survey.user.email" formControlName="firstCtrl"  required matInput name="survey_title">
              </mat-form-field>
            </div>
          </div>
          <div class="row justify-content-end mt-5 mx-1">
          <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" matStepperNext>
            <mat-icon class="mr-2">play_circle_outlined</mat-icon>
            {{'generic.next' | translate}}
          </button>
        </div>
        </form>
      </mat-step>
        <mat-step>
          <div class="mt-4">
            <ng-template matStepLabel>{{ "generic.introduction" | translate }}</ng-template>
            <p class="label-box font-weight-bold">{{ "components.decision-table.new-decision-table.compile-proposal.title" | translate}}</p>
            <p>{{ survey?.title }}</p>
            <ng-container *ngIf="survey.description">
              <p class="label-box font-weight-bold mt-4">{{ "generic.description" | translate}}</p>
              <p class="preserve-whitespace">{{ survey?.description }}</p>
            </ng-container>
            <ng-container *ngIf="survey?.attachments?.length > 0">
              <app-attachments-list [attachments]="survey?.attachments"/>
            </ng-container>
            <div class="row justify-content-end mt-3">
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" matStepperNext>
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{'generic.next' | translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step [label]="'generic.questions' | translate">
          <div>
            <div class="mt-5">
              <app-survey-details [surveyObj]="survey" [isResponse]="true"/>
            </div>
            <div class="row my-5" *ngIf="loading">
              <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
            </div>
            <div class="row justify-content-between mt-3">
              <button [disabled]="loading" class="btn-custom border-0 d-flex align-items-center px-5 py-2" matStepperPrevious>
                {{'generic.back' | translate}}
              </button>
              <button [disabled]="loading" class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="!loading ? onSubmit() : ''">
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{'generic.buttons.save-and-send' | translate}}
              </button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>{{ 'generic.done' | translate }}</ng-template>
          <h4 class="font-weight-bold col-12 w-100 pl-0 mt-3 text-center">
            {{ 'pages.surveys.response.submitted-successfully' | translate }}
          </h4>
        </mat-step>
    </mat-stepper>
  </div>
</div>
