import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {OrganizationService} from "../../../services/organization.service";

import { faEnvelope, faFile, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-organization-management',
  templateUrl: './organization-management.component.html',
  styleUrls: ['./organization-management.component.scss']
})
export class OrganizationManagementComponent implements OnInit {

  private _activeRouteSubscription: Subscription;
  organization_id: any;
  loading: boolean = false;
  organizationInfo = null;

  faFile = faFile;
  faEnvelope = faEnvelope;
  faGroup = faObjectUngroup;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private organizationService: OrganizationService) {
  }

  ngOnInit(): void {
    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (!this.organization_id || !Number(this.organization_id)) {
        this.router.navigate(['/home'], {replaceUrl: true});
      }
    });

    this.loading = true;
    this.organizationService.getOrganization(this.organization_id, (result) => {
      this.organizationInfo = result;
      this.loading = false;
    }, () => this.loading = false);
  }

  get userCanCreateOrganization() {
    if (this.organizationInfo) {
      return this.organizationInfo.options?.allow_organization_registration_to_users;
    }
    return false;
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription)
      this._activeRouteSubscription.unsubscribe();
  }

  protected readonly MAT_DIALOG_DEFAULT_OPTIONS = MAT_DIALOG_DEFAULT_OPTIONS;
}
