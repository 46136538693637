import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorkingSectorsService {

  private _sector: any = [
    { type: "sector", key: "DEFENSESECURITY", value: 'Defense&Security', icon: 'security'},
    { type: "sector", key: "ENGINEERING", value: 'Engineering', icon:'engineering'},
    { type: "sector", key: "SCIENCE", value: 'Science', icon: 'science'},
    { type: "sector", key: "BUSINESSMANAGEMENT", value: 'Business&Management', icon: 'business'},
    { type: "sector", key: "PUBLICGOVERNANCE", value: 'Public Governance', icon: 'admin_panel_settings'},
    { type: "sector", key: "MEDICINE", value: 'Medicine', icon: 'medical_services'},
    { type: "sector", key: "PHARMA", value: 'Pharma', icon:'local_pharmacy'},
  ];

  private _subsectors: any = [
    { type: "subsector", index: "DEFENSESECURITY", key: "MILITARYSTRATEGY", value: 'Military Strategy'},
    { type: "subsector", index: "DEFENSESECURITY", key: "CYBERSECURITY", value: 'Cybersecurity'},
    { type: "subsector", index: "DEFENSESECURITY", key: "INTELLIGENCE", value: 'Intelligence'},
    { type: "subsector", index: "DEFENSESECURITY", key: "MILITARYOPERATION", value: 'Military Operation'},
    { type: "subsector", index: "DEFENSESECURITY", key: "HOMELANDSECURITY", value: 'Homeland Security'},

    { type: "subsector", index: "ENGINEERING", key: "ITENGINEERING", value: 'Military Strategy'},
    { type: "subsector", index: "ENGINEERING", key: "INDUSTRIALENGINEERING", value: 'Mechanical Engineering'},
    { type: "subsector", index: "ENGINEERING", key: "CIVILENGINEERINGANDARCHITECTURE", value: 'Civil engineering and architecture'},
    { type: "subsector", index: "ENGINEERING", key: "CHEMICALENGINEERING", value: 'Chemical Engineering'},
    { type: "subsector", index: "ENGINEERING", key: "MATERIALENGINEERING", value: 'Material Engineering'},
    { type: "subsector", index: "ENGINEERING", key: "BIOMEDICALENGINEERING", value: 'Biomedical Engineering'},
    { type: "subsector", index: "ENGINEERING", key: "DATA", value: 'Data'},
    { type: "subsector", index: "ENGINEERING", key: "COMPUTERENGINEERING", value: 'Computer Engineering'},
    { type: "subsector", index: "ENGINEERING", key: "SYSTEMINTEGRATION", value: 'System Integration'},

    { type: "subsector", index: "SCIENCE", key: "AGRICULTURALANDVETERINARYSCIENCES", value: 'Agricultural and veterinary sciences'},
    { type: "subsector", index: "SCIENCE", key: "BIOLOGY", value: 'Biology'},
    { type: "subsector", index: "SCIENCE", key: "CHEMISTRY", value: 'Chemistry'},
    { type: "subsector", index: "SCIENCE", key: "EARTHSCIENCES", value: 'Earth sciences'},
    { type: "subsector", index: "SCIENCE", key: "INFORMATICS", value: 'Informatics'},
    { type: "subsector", index: "SCIENCE", key: "MATHEMATICSSTATISTICS", value: 'Mathematics & Statistics'},
    { type: "subsector", index: "SCIENCE", key: "PHYSICS", value: 'Physics'},
    { type: "subsector", index: "SCIENCE", key: "DATA", value: 'Data'},

    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "LAW", value: 'Law'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "FINANCE", value: 'Finance'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "STRATEGYMANAGEMENT", value: 'Strategy & Management'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "ORGANIZATIONCHANGEMANAGEMENT", value: 'Organization & Change Management'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "PROJECTMANAGEMENT", value: 'Project Management'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "HEALTHSAFETYANDENVIROMENT", value: 'Health, Safety and Enviroment'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "QAQC", value: 'QA/QC'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "PRODUCTION", value: 'Production'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "HUMANRESOURCES", value: 'Human Resources'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "MARKETINGCOMUNICATION", value: 'Marketing & Comunication'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "PROCUREMENT", value: 'Procurement'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "LOGISTICS", value: 'Logistics'},
    { type: "subsector", index: "BUSINESSMANAGEMENT", key: "HUMANRESOURCES", value: 'Human Resources'},

    { type: "subsector", index: "PUBLICGOVERNANCE", key: "PUBLICLAW", value: 'Public Law'},
    { type: "subsector", index: "PUBLICGOVERNANCE", key: "PARTICIPATORYPROCESSES", value: 'Processi Partecipativi'},
    { type: "subsector", index: "PUBLICGOVERNANCE", key: "POLICYMAKING", value: 'Policy making'},
    { type: "subsector", index: "PUBLICGOVERNANCE", key: "STRATEGICPLANNING", value: 'Strategic Planning'},

    { type: "subsector", index: "MEDICINE", key: "ANAESTHESIOLOGY", value: 'Anaesthesiology'},
    { type: "subsector", index: "MEDICINE", key: "APPLIEDMEDICALTECHNIQUES", value: 'Applied medical techniques'},
    { type: "subsector", index: "MEDICINE", key: "AUDIOLOGY", value: 'Audiology'},
    { type: "subsector", index: "MEDICINE", key: "BLOODDISEASES", value: 'Blood diseases'},
    { type: "subsector", index: "MEDICINE", key: "CARDIOVASCULARDISEASES", value: 'Cardiovascular diseases'},
    { type: "subsector", index: "MEDICINE", key: "CHILDNEUROPSYCHIATRY", value: 'Child neuropsychiatry'},
    { type: "subsector", index: "MEDICINE", key: "CLINICALPATHOLOGY", value: 'Clinical pathology'},
    { type: "subsector", index: "MEDICINE", key: "DERMATOLOGICALANDVENEROLOGICALDISEASES", value: 'Dermatological and venerological diseases'},
    { type: "subsector", index: "MEDICINE", key: "DIAGNOSTICIMAGINGANDRADIOTHERAPY", value: 'Diagnostic imaging and radiotherapy'},
    { type: "subsector", index: "MEDICINE", key: "ENDOCRINOLOGY", value: 'Endocrinology'},
    { type: "subsector", index: "MEDICINE", key: "EXPERIMENTALMEDICINEANDPATHOPHYSIOLOGY", value: 'Experimental medicine and pathophysiology'},
    { type: "subsector", index: "MEDICINE", key: "EYEDISEASES", value: 'Eye diseases'},
    { type: "subsector", index: "MEDICINE", key: "FOODSCIENCESANDDIETETICS", value: 'Food sciences and dietetics'},
    { type: "subsector", index: "MEDICINE", key: "FORENSICMEDICINE", value: 'Forensic medicine'},
    { type: "subsector", index: "MEDICINE", key: "GASTROENTEROLOGY", value: 'Gastroenterology'},
    { type: "subsector", index: "MEDICINE", key: "GENERALANDSUBSPECIALTYPAEDIATRICS", value: 'General and subspecialty paediatrics'},
    { type: "subsector", index: "MEDICINE", key: "GENERALSURGERY", value: 'General surgery'},
    { type: "subsector", index: "MEDICINE", key: "HEARTSURGERY", value: 'Heart surgery'},
    { type: "subsector", index: "MEDICINE", key: "HYGIENEANDPUBLICHEALTH", value: 'Hygiene and public health'},
    { type: "subsector", index: "MEDICINE", key: "INFECTIOUSDISEASES", value: 'Infectious diseases'},
    { type: "subsector", index: "MEDICINE", key: "INTERNALMEDICINE", value: 'Internal medicine'},
    { type: "subsector", index: "MEDICINE", key: "MAXILLOFACIALSURGERY", value: 'Maxillofacial surgery'},
    { type: "subsector", index: "MEDICINE", key: "MEDICALANDBIOTECHNOLOGYLABORATORYTECHNIQUES", value: 'Medical and biotechnology laboratory techniques'},
    { type: "subsector", index: "MEDICINE", key: "MEDICALGENETICS", value: 'Medical genetics'},
    { type: "subsector", index: "MEDICINE", key: "MEDICALHISTORY", value: 'Medical history'},
    { type: "subsector", index: "MEDICINE", key: "MEDICALONCOLOGY", value: 'Medical oncology'},
    { type: "subsector", index: "MEDICINE", key: "MEDICALSTATISTICS", value: 'Medical statistics'},
    { type: "subsector", index: "MEDICINE", key: "MICROBIOLOGYANDCLINICALMICROBIOLOGY", value: 'Microbiology and clinical microbiology'},
    { type: "subsector", index: "MEDICINE", key: "MIDWIFERY", value: 'Midwifery'},
    { type: "subsector", index: "MEDICINE", key: "MUSCULOSKELETALSYSTEMDISEASES", value: 'Musculoskeletal system diseases'},
    { type: "subsector", index: "MEDICINE", key: "NEPHROLOGY", value: 'Nephrology'},
    { type: "subsector", index: "MEDICINE", key: "NEUROLOGY", value: 'Neurology'},
    { type: "subsector", index: "MEDICINE", key: "NEUROPSYCHIATRICANDREHABILITATIONNURSINGSCIENCES", value: 'Neuropsychiatric and rehabilitation nursing sciences'},
    { type: "subsector", index: "MEDICINE", key: "NEURORADIOLOGY", value: 'Neuroradiology'},
    { type: "subsector", index: "MEDICINE", key: "NEUROSURGERY", value: 'Neurosurgery'},
    { type: "subsector", index: "MEDICINE", key: "NURSINGSCIENCESGENERALCLINICALANDPAEDIATRIC", value: 'Nursing sciences: general, clinical and paediatric'},
    { type: "subsector", index: "MEDICINE", key: "OBSTETRICSANDGYNAECOLOGY", value: 'Obstetrics and gynaecology'},
    { type: "subsector", index: "MEDICINE", key: "OCCUPATIONALMEDICINE", value: 'Occupational medicine'},
    { type: "subsector", index: "MEDICINE", key: "ORALDISEASESANDDENTISTRY", value: 'Oral diseases and dentistry'},
    { type: "subsector", index: "MEDICINE", key: "OTORHINOLARYNGOLOGY", value: 'Otorhinolaryngology'},
    { type: "subsector", index: "MEDICINE", key: "PAEDIATRIC SURGERY", value: 'Paediatric surgery'},
    { type: "subsector", index: "MEDICINE", key: "PATHOLOGY", value: 'Pathology'},
    { type: "subsector", index: "MEDICINE", key: "PHYSICAL AND REHABILITATION MEDICINE", value: 'Physical and rehabilitation medicine'},
    { type: "subsector", index: "MEDICINE", key: "PLASTIC SURGERY", value: 'Plastic surgery'},
    { type: "subsector", index: "MEDICINE", key: "PSYCHIATRY", value: 'Psychiatry'},
    { type: "subsector", index: "MEDICINE", key: "RESPIRATORY DISEASES", value: 'Respiratory diseases'},
    { type: "subsector", index: "MEDICINE", key: "RHEUMATOLOGY", value: 'Rheumatology'},
    { type: "subsector", index: "MEDICINE", key: "THORACIC SURGERY", value: 'Thoracic surgery'},
    { type: "subsector", index: "MEDICINE", key: "UROLOGY", value: 'Urology'},
    { type: "subsector", index: "MEDICINE", key: "VASCULAR SURGERY", value: 'Vascular surgery'},

    { type: "subsector", index: "PHARMA", key: "PHARMACEUTICALCHEMISTRY", value: 'Pharmaceutical chemistry'},
    { type: "subsector", index: "PHARMA", key: "PHARMACEUTICALANDTECHNOLOGICALAPPLICATIONSOFCHEMISTRY", value: 'Pharmaceutical and technological applications of chemistry'},
    { type: "subsector", index: "PHARMA", key: "PHARMACOLOGY", value: 'Pharmacology'},
    { type: "subsector", index: "PHARMA", key: "PHARMACEUTICALBIOLOGY", value: 'Pharmaceutical biology'}
  ];



  constructor() { }


  getValueByKey(container, key, returnedValue = 'value') {

    let found = "";

    if (key != null && typeof key != "undefined") {
      container.forEach(e => {
        if (e.key == key)
          return found = e[returnedValue];
      });
    }

    return found;
  }



  get sectors() {
    return JSON.parse(JSON.stringify(this._sector));
  }

  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllSectorsForSelect() {
    return this._sector.slice(0).sort((a, b) => (a.value > b.value) ? 1 : -1);
  }



  get subSectors() {
    return this._subsectors.splice(0);
  }


  /**
   * Restituisce il valore di un settore se lo trova
   * @param key
   */
  public getSectorValueByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

      return this.getValueByKey(this._sector, key);
  }

  // Restituisce icona di un settore se lo trova
  public getSectorIconByKey(key: string) {

    if (key == null || typeof key == "undefined")
      return '';

      return this.getValueByKey(this._sector, key, 'icon');
  }
  /**
   * Restituisce tutto l'array per popolare una select
   */
  public getAllSubSectorsForSelect() {
    return this._subsectors.slice(0).sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  /**
   * Restituisce l'array per poolare una select, filtrando solo i settori scelti
   */
  public getAllsubsectorsForSelectBySectorKey(sectorKey: string) {
    return this._subsectors.filter(x => x.index == sectorKey).sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

}
