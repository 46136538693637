<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container">

      <!-- Sezione alert -->
      <ng-container *ngIf="!loading">
        <mat-card *ngIf="!evaluation_session?.collecting" style="background-color: red; color: white;">
          {{'components.responses.decision-table-closed' | translate}}
        </mat-card>
        <mat-card *ngIf="response_stamps?.completed" style="background-color: red; color: white;">
          {{'components.responses.responses-already-sent' | translate}}
        </mat-card>
      </ng-container>

      <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
        <mat-label>{{'generic.organization' | translate}}</mat-label>
        <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
        <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
        <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : translate('components.responses.undefined-due-date')" placeholder="{{'components.responses.due-date' | translate}}" readonly>
      </mat-form-field>
  </div>
</div>

<div class="container-fluid my-1 bg-white">
  <div class="row container mx-auto">
      <div class="stepper-container col-12 my-4 pb-4">
          <div class="timeline">
              <div class="step-container">
                  <div class="step">1</div>
              </div>
              <div class="step-container current">
                  <div class="step">2</div>
              </div>
              <div class="step-container">
                  <div class="step">3</div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Blocco 2 -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="container">
      <mat-accordion class="accordion" multi>
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title>
                      <span class="index">2</span>
                      {{'generic.evaluation' | translate}}
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">

                  <div class="col-12 col-lg-6 px-0 mb-0" *ngIf="secondary_target.length > 0">
                      <div class="box py-0 px-2 bg-transparent border-0 mb-0">
                          <mat-label class="box-title px-3 mb-0">{{'components.responses.multiobjectives.criteria-to-secondary' | translate}}</mat-label>
                      </div>
                  </div>

                  <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left" *ngFor="let target of secondary_target">
                    <div class="container">
                      <mat-accordion class="accordion" multi [togglePosition]="'before'">
                        <mat-expansion-panel class="px-0" expanded>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span>{{target.title}}</span>
                                <i class="icon-circle_info d-flex"></i>
                                <i class="icon-checkmark abs-tr mx-0" [ngClass]="getIconColorForEvaluation(target.framework_criteria)" ></i>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-container *ngFor="let criteria of target.framework_criteria">
                            <div class="row border-bottom-grey pt-0 pb-4 my-3">
                              <small class="col-12">{{'generic.evaluation' | translate}}</small>
                              <div class="col-12">{{criteria.title}}</div>
                              <mat-slider class="w-100 mt-2 tricolor cdk-focused" min="0" max="100" step="1" [thumbLabel]="true" [(ngModel)]="criteria.evaluation.weight" color="primary"></mat-slider>
                              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                                  <small class="float-left">{{'components.responses.low-importance' | translate}}</small>
                                  <small class="float-right">{{'components.responses.high-importance' | translate}}</small>
                              </div>
                          </div>
                          </ng-container>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>


<div class="container-fluid mt-4">
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto" *ngIf="!response_stamps?.completed">
      <button class="px-5" mat-flat-button color="primary" [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit(true)" >{{'generic.buttons.save-draft' | translate}}</button>
  </div>
  <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
    <div *ngIf="response_stamps">
      <span *ngIf="response_stamps?.completed"> {{'components.responses.info-sent-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
      <span *ngIf="!response_stamps?.completed"> {{'components.responses.info-draft-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
    </div>
  </div>
</div>


<!-- Buttons -->
<div class="buttons-container container py-4" *ngIf="!response_stamps?.completed">
  <button class="button-primary float-left" mat-button [disabled]="!evaluation_session?.collecting || loading" [routerLink]="['../weight-target']">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.go-back' | translate}}
  </button>
  <button class="button-primary float-right" mat-button [disabled]="!evaluation_session?.collecting || loading" (click)="verifySubmit()">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.save-and-continue' | translate}}
  </button>
</div>
