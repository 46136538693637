<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate></form>

<div class="container-fluid container-custom">
  <div class="container d-flex justify-content-center align-items-center">
    <div class="row mt-5">
      <div class="col-lg-12 card text-center shadow">
        <h1 class="color-green">Accedi</h1>
        <mat-form-field class="my-2">
        <input required matInput type="email" name="email" class=" login-form-field" [(ngModel)]="model.email">
        <mat-placeholder class="placeholder">
            E-mail
        </mat-placeholder>
        </mat-form-field>
        <br>
        <mat-form-field class="my-2">
          <input required matInput type="password" name="password" class=" login-form-field" [(ngModel)]="model.password">
          <mat-placeholder class="placeholder">
            Password
          </mat-placeholder>
        </mat-form-field>
        <br>
        <div class="col-12">
          <button class="btn-actions rounded my-2" [routerLink]="['/']">Indietro</button>
        </div>
        <div class="col-12">
          <button class="btn-actions background-green rounded" [disabled]="loading" (click)="onSubmit(form)">Accedi</button>
        </div>
      </div>
    </div>
  </div>
</div>
