<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="header-with-actions">
      <mat-expansion-panel expanded hideToggle="true">

        <mat-expansion-panel-header class="event-disabled d-flex align-items-center">
            <mat-panel-title class="mat-expansion-panel-header-title">
              {{'pages.frameworks.all-frameworks.component.choise-framework' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container background-filters py-3 px-5 mt-3">
          <div class="row justify-content-between">
            <div class="purple-color">
              <h2>{{'generic.search-for' | translate}}</h2>
            </div>
            <div>
              <button class="clear-filter py-2 px-4" (click)="onClickResetFilters()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
            </div>
          </div>

          <!-- PRIMA ROW FILTRI-->
          <div class="row d-flex justify-content-between align-items-center m-0">
            <div class="col-12 col-md-3">
              <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                <mat-label>{{'generic.sector' | translate}}</mat-label>
                <mat-select  #sectorInput name="searchFilterSecor" [(ngModel)]="searchModel.sector_key" (selectionChange)="onChangeSearchFilter('sector')" class="color-default">
                    <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let sector of getWorkingSectorService().getAllSectorsForSelect()" [value]="sector.key">{{sector.value}}</mat-option>
                </mat-select>
            </mat-form-field>
            </div>

            <div class="col-12 col-md-3">
              <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                <mat-label>{{'generic.subsector' | translate}}</mat-label>
                <mat-select name="searchFilterSubsecor" [(ngModel)]="searchModel.subsector_key" (selectionChange)="onChangeSearchFilter()" [disabled]="!sectorInput.value">
                  <mat-option value="" selected></mat-option>
                  <mat-option *ngFor="let subsector of getSubsectorsArrayFiltered(sectorInput.value)" [value]="subsector.key">{{subsector.value}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-3">
              <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
                <mat-label>{{'pages.frameworks.component.analysis-methodology' | translate}}</mat-label>
                <mat-select name="searchFilterAnalysisType" [(ngModel)]="searchModel.analysis_type" (selectionChange)="onChangeSearchFilter()" class="color-default">
                  <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let analysis_method of getAnalysisMethodsService().getAllAnalysisMethods()" [value]="analysis_method.type">{{analysis_method.title}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- SECONDA ROW FILTRI-->
          <div class="row d-flex justify-content-between mx-0 mt-2">

            <div class="col-12 col-md-12 mt-4">
              <mat-form-field class="full-width">
                <input matInput name="searchKeywords" [(ngModel)]="searchModel.searchKeywords" type="text" placeholder="{{'pages.frameworks.all-frameworks.component.search-name' | translate}}" #searchInput>
                <mat-icon class="with-cursor" *ngIf="searchModel.searchKeywords" matSuffix aria-label="Clear" (click)="searchModel.searchKeywords=''; onClickSearch()">close</mat-icon>
                <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="onClickSearch()">search</mat-icon>
              </mat-form-field>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 d-flex justify-content-end align-items-center mt-3 mb-3">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" [routerLink]="['/framework']">
              <mat-icon class="mr-2">add_circle_outlined</mat-icon>
              {{'pages.frameworks.all-frameworks.component.new-framework' | translate}}
            </button>
          </div>
        </div>

        <!-- ELENCO DI FRAMEWORK -->
        <div class="row" *ngIf="frameworkFilteredList.length > 0; else noFrameworkFound">

          <ng-container *ngFor="let framework of frameworkFilteredList; let i = index">
            <ng-container *ngIf="(!framework.hidden || (framework.hidden && isAdmin))">
              <div class="col-12 col-md-6" *ngIf="i < currentNumOfFrameworkShowed">
                <mat-card class="mb-4 border border-0 shadow cursor-pointer" [ngClass]="framework.hidden ? 'hidden' : ''"  (click)="onClickFramework(i)">
                  <div class="d-flex justify-content-between">
                    <img *ngIf="framework?.organization?.logo_link" [src]="framework?.organization?.logo_link" alt="" class="mb-2 organization-logo">
                    <img *ngIf="!framework?.organization?.logo_link" src="assets/img/placeholder-img-organization.jpg" alt="" class="mb-2 organization-logo">
                    <div *ngIf="framework.hidden" class="d-flex purple-color">
                      <span style="margin-top: 0.15rem;">{{ 'generic.hidden' | translate }}</span>
                      <mat-icon class="ml-2">remove_red_eye</mat-icon>
                    </div>
                  </div>
                  <h2 class="purple-color"><strong>{{framework.title}}</strong></h2>
                  <p>{{framework.purpose_description}}</p>
                  <hr>
                  <div class="row">
                    <div class="col-6">
                      <p class="m-0">{{'pages.frameworks.component.analysis-methodology' | translate}}</p>
                      <h4 class="m-0 purple-color">{{getAnalysisMethodsService().getAnalysisMethodTitleByType(framework.analysis_type)}}</h4>
                    </div>
                  </div>
                  <hr>
                  <div class="row scrollbar">
                    <div class="col-6" *ngFor="let sector of framework.sectors">
                      <p class="m-0">{{'generic.sector' | translate}}</p>
                      <div class="d-flex">
                        <h4 class="m-0 purple-color">{{getWorkingSectorService().getSectorValueByKey(sector.sector_key)}}</h4>
                        <mat-icon class="mt-0 ml-1 mat-icon-sector purple-color">{{getWorkingSectorService().getSectorIconByKey(sector.sector_key)}}</mat-icon>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="d-flex justify-content-end my-3" *ngIf="frameworkFilteredList.length > currentNumOfFrameworkShowed">
          <button mat-button class="d-flex align-items-end" (click)="onClickShowMore()">{{'generic.show-more' | translate}} <mat-icon>keyboard_arrow_down</mat-icon></button>
        </div>

        <ng-template #noFrameworkFound class="row">
          <div class="col-12 text-center my-5 p-4" *ngIf="!loading">
            <h3 class="color-green">{{'pages.frameworks.all-frameworks.component.no-framework' | translate}}</h3>
            <p>{{'pages.frameworks.all-frameworks.component.search-sec-subsec-analyses' | translate}}</p>
            <p class="new-framework-p cursor-pointer" [routerLink]="['/framework']">{{'pages.frameworks.all-frameworks.component.new-framework' | translate}}</p>
          </div>
        </ng-template>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.home' | translate}}
  </button>

</div>
