<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<form #form="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
  <div class="container-fluid accordion-container">
    <div class="container">
        <mat-accordion class="accordion">
          <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
              <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">1</span>{{'pages.organizations.organization.component.name-typology-organization'| translate}} </mat-panel-title></span>
            </mat-expansion-panel-header>
            <mat-card class="box mt-3">
              <mat-form-field class="col-12 col-md-4">
                <input matInput [(ngModel)]="organizationInfo.name" required name="organization" type="text" placeholder="{{'pages.responses.responses-proposal-review.component.organization-name'| translate}}">
              </mat-form-field>
              <mat-form-field class="col-12 col-md-3 offset-md-1 select-primary wp-normal color-default" appearance="fill">
                <mat-label>{{'generic.typology'| translate}}</mat-label>
                <mat-select name="typology_organization" [(ngModel)]="organizationInfo.typology" required class="color-default">
                    <mat-option *ngFor="let typology of getTypologiesService().getAllTypologiesForSelect()" [value]="typology.key">{{typology.value}}</mat-option>
                </mat-select>
            </mat-form-field>
            </mat-card>
          </mat-expansion-panel>
        </mat-accordion>
    </div>
  </div>

  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">2</span>{{'generic.sector' | translate}} </mat-panel-title></span>
        </mat-expansion-panel-header>
          <div class="row">
            <div class="col-12 col-lg-3 col-md-6" *ngFor="let sector of sectors; let i = index">
              <ng-container *ngIf="i < currentNumOfSectorsShowed" class="container box-logo">
                <div class="box-sections" (click)="clickEvent(sector)" [ngClass]="sector.selected === true ? 'card-selected' : '' " >
                  <mat-label class="text-center">
                    <h2>{{sector.value}}</h2>
                      <mat-icon class="icon">{{sector.icon}}</mat-icon>
                  </mat-label>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="d-flex justify-content-end my-5" *ngIf="sectors.length > currentNumOfSectorsShowed">
            <button mat-button class="d-flex align-items-end" (click)="onClickShowMore()">{{'generic.show-more'| translate}} <mat-icon>keyboard_arrow_down</mat-icon></button>
          </div>
      </mat-expansion-panel>
    </mat-accordion>
    </div>
  </div>
  <div class="container-fluid accordion-container">
    <div class="container">
      <mat-accordion class="accordion">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header class="mb-4">
            <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">3</span>{{'pages.organizations.organization.component.img-organization'| translate}}  </mat-panel-title></span>
          </mat-expansion-panel-header>
          <div class="box-custom-img">
            <img class="organization-logo" *ngIf="!organizationInfo.logo_link" src="assets/img/placeholder-img-organization.jpg" alt="">
            <img class="organization-logo" *ngIf="organizationInfo.logo_link" [src]="organizationInfo.logo_link" alt="">
            <button mat-button color="primary" class="mt-2 px-5" (click)="onClickUploadLogo()">{{'pages.organizations.organization.component.upload-logo'| translate}}  &nbsp;<i class="icon-upload"></i></button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="buttons-container container py-4">
    <button type="button" class="button-primary float-left" *ngIf="!organizationInfo.id" mat-button backbutton>
        <i class="icon-Backward-arrow-small"></i>
        {{'generic.buttons.back' | translate}}
    </button>
    <button type="button" class="button-primary float-left" *ngIf="organizationInfo.id" mat-button [routerLink]="['../']" routerLinkActive="router-link-active">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
    </button>
    <button type="button" class="button-primary float-right" [disabled]="loading" mat-button (click)="onSubmit(form)">
        <i class="icon-Forward-arrow-small"></i>
        <span *ngIf="organizationInfo.id">{{'generic.buttons.update' | translate}}</span>
        <span *ngIf="!organizationInfo.id">{{'generic.buttons.save' | translate}}</span>
    </button>
  </div>
</form>
