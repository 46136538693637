<h4
  *ngIf="attachments?.length > 0"
  class="font-weight-bold col-12 w-100 pl-0 mt-3">{{ "generic.documentation-attached" | translate }}</h4>

<div *ngFor="let attachment of attachments; let i = index">
  <div class="row justify-content-between">
                                             <span class="name col-9">
                              <i class="icon-pdf mr-2"></i>
                              <a [href]="attachment.link" target="_blank">{{
                                  attachment.title
                                }}</a>
                            </span>

    <span class="col-2 align-middle mt-auto mb-auto" (click)="deleteAttachment(i)" *ngIf="editable">
                              {{ "generic.buttons.delete" | translate }}
      <i class="icon-cestino main-color ml-1"></i>
                            </span>
  </div>
</div>

<div *ngIf="buttonToAdd && editable" class="row justify-content-center">
  <button mat-button color="primary" [matMenuTriggerFor]="menuAttachment">
    <i class="icon-upload mr-2"></i>
    {{ 'components.responses.add-evidences' | translate }}
  </button>
  <mat-menu #menuAttachment="matMenu">
    <button mat-menu-item (click)="onClickAddNewEvidence('PUBMED')">
      <mat-icon aria-hidden="false">file_upload</mat-icon>
      Pubmed
    </button>
    <button mat-menu-item (click)="onClickAddNewEvidence('FILE')">
      <mat-icon aria-hidden="false">file_upload</mat-icon>
      File
    </button>
  </mat-menu>
</div>
