import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BackendService } from 'src/app/services/general/backend.service';

@Component({
  selector: 'app-pubmed-attachment',
  templateUrl: './pubmed-attachment.component.html',
  styleUrls: ['./pubmed-attachment.component.scss']
})
export class PubmedAttachmentComponent implements OnInit {

  @Output() attachElement: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;

  modelSearch: any = {
    queryString: "",
    currentPage: 1
  };

  pubmedResults: any = {};
  pubmedResultsCached: any = {};

  //  table
  displayedColumns: string[] = ['ArticleTitle', 'Authors', 'PubYear', 'actions'];

  constructor(private backend: BackendService) { }

  ngOnInit(): void { }

  onSubmit(form: NgForm) {

    if (!form.form.valid)
      return;

    //  controllo se è cambiata la stringa di ricerca
    if (form.form.controls.queryString.value) {
      this.pubmedResultsCached = {};
    }

    //  faccio la ricerca
    this.searchTearmInPublmed(this.modelSearch.queryString, this.modelSearch.currentPage);
  }


  /**
   * Cerca un determinato valore su pubmed
   * @param query
   */
  searchTearmInPublmed(query: string, page: number = 1) {

    //  restituisco i risultati cachati se ci sono
    if (this.pubmedResultsCached[page]) {
      this.pubmedResults = this.pubmedResultsCached[page];
      return;
    }

    this.loading = true;

    this.backend.post(`tools/pubmed/search`, {term: query, page: page}).subscribe((result) => {
      this.pubmedResultsCached[page] = result;
      this.pubmedResults = this.pubmedResultsCached[page];

      this.loading = false;
    }, (error) => {
      console.log(error);
      this.backend.showErrors(error);
      this.loading = false;
    })
  }


  /**
   * Evento di cambio pagina
   * @param event
   */
  pageChanged(event) {

    this.modelSearch.currentPage = event.pageIndex+1
    this.searchTearmInPublmed(this.modelSearch.queryString, this.modelSearch.currentPage);
  }



  /**
   * Premuto il bottone per aggiungere un articolo
   * @param element
   */
  addAttachment(element) {
    this.attachElement.emit(element);
  }
}
