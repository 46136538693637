import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrganizationTypeService {

  private _typology: any = [
    { key: 'SINGLEUSER', value: 'Single User'},
    { key: 'PUBLICORGANIZATION', value: 'Public Organization'},
    { key: 'RESEARCHCENTER', value: 'Research Center'},
    { key: 'PRIVATECOMPANY', value: 'Private Company'},
    { key: 'ONG', value: 'ONG'},
    { key: 'NONPROFITASSOCIATION', value: 'Non-Profit/Association'},
    { key: 'PROJECT', value: 'Project'},
    { key: 'TEMPORARYORGANIZATION', value: 'Temporary Organization'}
  ];


  get typologies() {
    return this._typology.splice(0);
  }


  public getAllTypologiesForSelect() {
    return this._typology.slice(0);
  }

  constructor() { }
}
