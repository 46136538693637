import {Component, Input, OnInit} from '@angular/core';
import {
  ModalImportOrganizationChartsComponent
} from '../../../modals/modal-import-organization-charts/modal-import-organization-charts.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-team-members-list',
  templateUrl: './team-members-list.component.html',
  styleUrls: ['./team-members-list.component.scss']
})
export class TeamMembersListComponent implements OnInit {
  @Input() members: any;

  memberIdCounter = 1;

  constructor(
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.memberIdCounter = this.members.length + 1;
  }

  removeTeamMember(id) {
    const index = this.members.findIndex(member => member.id === id);
    if (index !== undefined && index !== -1) {
      this.members.splice(index, 1);
    }
  }

  openChartsModal() {

    const dialogRef = this.dialog.open(ModalImportOrganizationChartsComponent, {
      panelClass: 'medium-dialog',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        for (let i = 0; i < this.members.length; i++) {
          const team_member = this.members[i];
          if (team_member.name === null || team_member.name === '' || team_member.email === '' || team_member.email === null) {
            this.members.splice(i, 1);
            i--;
          }
        }

        result.forEach((org_usr) => {
          const team_member = this.members.find((team_member) => team_member.email === org_usr?.user?.email);
          if (team_member) {
            team_member.imported_by_organization_chart_id = org_usr.imported_by_organization_chart_id;
          } else {
            this.members.push({
              id: this.memberIdCounter++,
              name: org_usr?.user?.name,
              email: org_usr?.user?.email,
              imported_by_organization_chart_id: org_usr.imported_by_organization_chart_id,
            });
          }
        });
      }
    });
  }

  addTeamMember() {
    this.members.push({
      id: this.memberIdCounter++,
      name: '',
      email: '',
    });
  }

}
