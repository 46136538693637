<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">
<!--        {{'modal.modal-virtual-assistant.title' | translate}}-->
        Dashboard Ai Explanation
      </h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p role="button" (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">

    <button  mat-raised-button color="primary"  class="action-button"
                  (click)="copyText(explanation)"
                  [disabled]="disabledCopyButton">
                  {{'modal.modal-virtual-assistant.btn.copyandclose' | translate}}
    </button>


    <div class="text-container" *ngIf="explanation" [innerHTML]="explanation">

    </div>

</div>
