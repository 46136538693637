import {Component, OnInit} from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalAddAttachmentComponent } from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {OrganizationService} from 'src/app/services/organization.service';
import {ToastService} from "../../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'src/app/services/general/alert.service';


@Component({
  selector: 'app-all-documents-management',
  templateUrl: './all-documents-management.component.html',
  styleUrls: ['./all-documents-management.component.scss']
})
export class AllDocumentsManagementComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  documents: any = [];
  organization_id: any;

  searchTerm = '';

  displayedColumns: string[] = ['title', 'actions'];
  documentsData: any = new MatTableDataSource([]);

  faTrash = faTrash

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private activeRoute: ActivatedRoute, private organizationService: OrganizationService, private dialog: MatDialog,
    private alert: AlertService, private translateService: TranslateService, private toast: ToastService) {
  }

  ngOnInit() {

    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.organization_id = routeParams.id_organization;
      if (this.organization_id) {
        this.init();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }


  init() {

    this.loading = true;
    this.organizationService.allOrganizationDocuments(this.organization_id, (result) => {
      this.documents = result;
      this.documentsData = new MatTableDataSource(result);
      this.loading = false;
    }, () => this.loading = false);
  }


  /** cerca nella tabella in base a nome cognome e email */
  onSearch() {

    if (this.searchTerm.length == 0) {
      this.documentsData = new MatTableDataSource(this.documents);
      return;
    }

    var documentFiltered = this.documents.filter(element => {
      if (this.searchTerm) {
        if (element?.title && element?.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
          return true;
        }
        return false;
      }
    });

    this.documentsData = new MatTableDataSource(documentFiltered);
  }

  /** Pulisco la ricerca */
  onClearSearch() {
    this.searchTerm = '';
    this.documentsData = new MatTableDataSource(this.documents);
  }


  /**
 * Aggiungo un documento all'organizzazione
 */
  onClickUploadDocument() {

    let uploadUrl = `upload/organization/${this.organization_id}/document`;

    const dialogRef = this.dialog.open(ModalAddAttachmentComponent, {
      panelClass: 'medium-dialog',
      data: {
        title: 'Upload Document',
        attachmentType: 'FILE',
        maxUploadsFile: 10,
        organization_id: this.organization_id,
        uploadUrl: uploadUrl
      }
    });

    dialogRef.afterClosed().subscribe(attachmentData => {

      if (attachmentData?.attachmentObject) {
        this.init();
        this.toast.success(this.translateService.instant("generic.document"), this.translateService.instant("components.all-documents-alert.uploaded-document.success"));
      }
    });

  }


  /**
   * Cancella un documento dalla lista
   * @param element
   */
  deleteDocument(element) {

    if (element.id) {

      this.alert.showQuestionWithError(this.translateService.instant("generic.alert-delete-document"), "", (res) => {
        if (res) {

          //  salvo sempre il task e recupro nuovamente il management plan
          this.organizationService.deleteDocument(this.organization_id, element.id, () => {
            this.init();
              this.toast.success(this.translateService.instant("generic.document"), this.translateService.instant("components.all-documents-alert.deleted-document.success"));
            }, (error) => {

              //  mostro un errore perchè il documento è legato ad un template
              if (error.status == 423) {
                var textError = this.translateService.instant("components.all-documents-alert.deleted-document.error-template-exist");
                if (error?.error && error?.error?.template_name) {
                  textError += " '" + error.error.template_name + "'";
                }
                this.alert.showAlertWithError('', textError);
              } else {
                this.toast.error(this.translateService.instant("generic.document"), this.translateService.instant("components.all-documents-alert.deleted-document.error"));
              }
          });
        }
      });

    }
  }

  /**
   * Mostra un documento
   * @param element
   */
  showDocument(element) {

    if (element.id && element.link) {
      window.open(element.link);
    }
  }

}




