import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-show-contest',
  templateUrl: './modal-show-contest.component.html',
  styleUrls: ['./modal-show-contest.component.scss']
})
export class ModalShowContestComponent {
  constructor(public dialogRef: MatDialogRef<ModalShowContestComponent>, @Inject(MAT_DIALOG_DATA) public data: {
    contest: any,
    decision_table: any
  }) {
  }


  contest: any = {};
  decision_table: any = {};

  ngOnInit(): void {
    this.contest = JSON.parse(JSON.stringify(this.data.contest));
    this.decision_table = JSON.parse(JSON.stringify(this.data.decision_table));
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }
}
