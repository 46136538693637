<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="accordion-container container-fluid px-0">
  <div class="container-fluid px-0">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header class="container">
          <span class="mat-content">
            <mat-panel-title>
              <span class="index">1</span>{{'pages.definition-roles.component.definition-roles' | translate}}
            </mat-panel-title>
          </span>
        </mat-expansion-panel-header>
        <div class="px-4" *ngIf="roles.length > 0; else noRoleFound">
          <div class="row" *ngFor="let role of roles; let i = index">
            <ng-container>
            <div class="container">
                <mat-card class="box mt-2 col-12">
                  <div class="row py-3">
                    <div class="col-12 col-md-1 d-flex align-items-center">
                      <p class="custom-size-p p-2"><strong></strong></p>
                    </div>
                    <div class="col-12 col-md-1">
                      <h3>{{'generic.roles.role' | translate}}</h3>
                      <p><strong>{{role.role}}</strong></p>
                    </div>
                    <div class="col-12 col-md-3 offset-md-1">
                      <h3>{{'generic.description' | translate}}</h3>
                      <p class="truncate"><strong>{{role.description}}</strong></p>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center color-custom">
                      <p class="view-curve-btn" (click)="role.visible = !role.visible">
                        <span *ngIf="!role.visible">{{'pages.definition-roles.component.view-learning-curve' | translate}}</span>
                        <span *ngIf="role.visible">{{'pages.definition-roles.component.hide-learning-curve' | translate}}</span>
                      </p>
                    </div>
                    <div class="col-12 col-md-2 text-center d-flex align-items-center mb-2 ">
                      <mat-icon class="grey-color" [routerLink]="['./'+role.id]" routerLinkActive="router-link-active">edit</mat-icon>
                      <div class="icons-container m-0 mx-3" (click)="removeRole(role.id)">
                        <i class="icon-cestino grey-color float-right m-0"></i>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div [hidden]="!role.visible" class="container-fluid box-full-width purple">
                <div class="row text-dark">
                  <div class="col-12 col-md-6">
                    <img class="img-fluid shadow full-width" src="https://via.placeholder.com/627x430" alt="">
                    <p class="textColorCustom mt-4"><mat-icon>info</mat-icon>
                    {{'pages.definition-roles.component.learning-curve-description' | translate}}
                    </p>
                  </div>
                  <div class="col-12 col-md-6 mt-5">
                    <div class="row">
                      <h3><strong>{{'generic.roles.role' | translate}}:</strong> <br> {{role.role}}</h3>
                    </div>
                    <div class="row">
                      <h3><strong>{{'generic.description' | translate}}:</strong></h3>
                      <p class="textColorCustom">{{role.description}}</p>
                    </div>
                  </div>
                </div>
              </div>
              </ng-container>
            </div>
          </div>

          <ng-template #noRoleFound class="row">
            <div class="col-12 text-center my-5 p-4">
              <h3>{{'pages.definition-roles.component.no-role-find' | translate}}</h3>
            </div>
          </ng-template>

          <div class="container">
            <button type="button" class="col-12 big-button my-2" mat-raised-button [routerLink]="['./new']" routerLinkActive="router-link-active">
              <i class="icon-big_add"></i>
              {{'pages.frameworks.component.buttons.add-role' | translate}}
            </button>
          </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


  <!-- Autorizzazioni framework -->
<!-- <div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="accordion">
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <span class="mat-content"><mat-panel-title class="mat-expansion-panel-header-title"><span class="index">2</span> Autorizzazioni dei ruoli sui framework</mat-panel-title></span>
        </mat-expansion-panel-header>
        <div class="alert-custom my-4">
          <p>
            In questa sezione occorrerà assegnare le autorizzazioni per singolo ruolo
          </p>
          <p>
            Step 1 : Selezionare ruolo
          </p>
          <p>
            Step 2 : Assegnare autorizzazione
          </p>
        </div>
        <p class="p-custom">Ruoli da selezionare</p>
        <mat-card class="box my-4">
          <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
            <mat-label>Ruolo</mat-label>
            <mat-select class="color-default" name="1" value="1">
                <mat-option value="1" selected>CMO</mat-option>
                <mat-option value="2" selected>CMO</mat-option>
                <mat-option value="3" selected>CMO</mat-option>
            </mat-select>
        </mat-form-field>
        </mat-card> -->
        <!-- <div class="mt-5">
          <p class="p-custom">Filtri</p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div> -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['../']">
      <i class="icon-Backward-arrow-small"></i>
      {{'generic.buttons.back' | translate}}
  </button>
  <button class="button-primary float-right" mat-button [routerLink]="['../']" routerLinkActive="router-link-active">
      <i class="icon-Forward-arrow-small"></i>
      {{'generic.buttons.confirm' | translate}}
  </button>
</div>
