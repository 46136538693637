<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container text-center">

    <ng-container *ngIf="!loading">
      <mat-card *ngIf="decision_table?.closing_date" class="my-3 ml-auto" style="background-color: red; color: white;">
        {{'components.responses.decision-table-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="!evaluation_session?.collecting && !decision_table?.closing_date" class="my-3 ml-auto col-12"
                style="background-color: red; color: white;">
        {{'components.decision-session.decision-session-closed' | translate}}
      </mat-card>
      <mat-card *ngIf="response_stamps?.completed" class="my-3 ml-auto col-12" style="background-color: red; color: white;">
        {{'components.responses.responses-already-sent' | translate}}
      </mat-card>
    </ng-container>
  </div>
  <div class="container" style="display: flex; justify-content: space-between; align-items: center;">
    <!-- Sezione alert -->

    <div class="col-12 col-lg-6 ">

      <img *ngIf="decision_table?.organization?.logo_link" [src]="decision_table?.organization?.logo_link" alt="Logo"
           style="max-height: 100px; max-width: 100px;">
      <mat-form-field class="col-7 select-custom-button" appearance="fill" *ngIf="!decision_table?.organization?.logo_link">
        <mat-label>{{'generic.organization' | translate}}</mat-label>
        <input matInput name="organization_name" [ngModel]="organization?.name"
               placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
    </div>


    <mat-form-field class="col-12 col-lg-6 datepicker-custom-button text-right" appearance="fill" *appHideIfOrganization="'alexion'">
      <mat-label>{{'components.proposals.new-proposal.due-date' | translate}}</mat-label>
      <input matInput name="evaluation_end"
             [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : 'Data di consegna non definita'"
             placeholder="Data di consegna" readonly>
    </mat-form-field>
  </div>
</div>

<!-- Blocco 1 -->
<div class="container-fluid box-sub-header pb-0" style="background-color: rgb(239, 239, 239)" *ngIf="!loading">

  <div class="container">
    <app-decision-table-details [decision_table]="decision_table" [evaluation_session]="evaluation_session"/>
  </div>

  <div class="container mt-5">
    <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto" *ngIf="!response_stamps?.completed">
      <button class="px-5" mat-flat-button color="primary" [disabled]="!evaluation_session?.collecting || loading"
              [routerLink]="nextPage">{{'components.responses.ask-for-response' | translate}}</button>
    </div>
    <div class="row justify-content-center w-100 mt-0 mb-3 mx-auto">
      <div *ngIf="response_stamps">
        <span
          *ngIf="response_stamps?.completed"> {{'components.responses.info-sent-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
        <span
          *ngIf="!response_stamps?.completed"> {{'components.responses.info-draft-date' | translate}} {{response_stamps?.last_save| formatdate | date:'short'}}</span>
      </div>
    </div>
    <div class="row justify-content-center">
      <button *ngIf="response_stamps?.completed" class="px-5" mat-flat-button color="primary"
              [routerLink]="['../responses-evaluation']">{{"components.responses.view_questionnaire_response" | translate}}
      </button>
    </div>
  </div>
</div>
