<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span *ngIf="proposalData.newProposal">{{ 'modals.modal-new-proposal.add-proposal' | translate}}</span>
    <span *ngIf="!proposalData.newProposal">{{ proposalData.proposal.title }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<app-new-proposal-blank *ngIf="newProposal" [framework]="proposalData.decision_table.framework" [decision_table]="proposalData.decision_table"  (proposalSavedEvent)="closeDialogue($event)"></app-new-proposal-blank>
<app-new-proposal *ngIf="!newProposal" [current_organization]="data.current_organization" [proposal]="proposalData.proposal" [framework]="proposalData.decision_table.framework" [decision_table]="proposalData.decision_table" (proposalSavedEvent)="closeDialogue($event)"></app-new-proposal>

