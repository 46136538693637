<div class="container-fluid box-sub-header py-3 pt-lg-5">
  <div class="container">

      <!-- Sezione alert -->
      <ng-container *ngIf="!loading">
        <mat-card *ngIf="!evaluation_session?.collecting" style="background-color: red; color: white;">
          {{'components.responses.decision-table-closed' | translate}}
        </mat-card>
        <mat-card *ngIf="response_stamps?.completed" style="background-color: red; color: white;">
          {{'components.responses.responses-already-sent' | translate}}
        </mat-card>
      </ng-container>

      <mat-form-field class="col-12 col-lg-6 select-custom-button" appearance="fill">
          <mat-label>{{'generic.organization' | translate}}</mat-label>
          <input matInput name="organization_name" [ngModel]="organization?.name" placeholder="{{'generic.organization' | translate}}" readonly>
      </mat-form-field>
      <mat-form-field class="col-12 col-lg-6 datepicker-custom-button" appearance="fill">
        <mat-label>{{'components.responses.due-date' | translate}}</mat-label>
        <!-- TODO: TRADUZIONE -->
        <input matInput name="evaluation_end" [ngModel]="evaluation_session?.evaluation_end != null ? (evaluation_session?.evaluation_end| formatdate | date: 'shortDate') : translate('Data di consegna non definita')" placeholder="Data di consegna" readonly>
      </mat-form-field>
  </div>
</div>

<!-- Blocco 1 -->
<div class="container mt-4">
    <div class="row">
      <div class="col-12 mb-4">
        Il metodo scelto per questo tavolo decisionale è <b>Analisi del Consenso</b>.
      </div>
      <div class="col-12 mb-4">
        Il metodo Analisi del Consenso prevede una struttura di valutazione fatta da un obiettivo principale ed una serie di punti di valutare con cui sono state descritte le strategie proposte da cui dipende il raggiungimento dell'obiettivo principale. Le proposte oggetto della valutazione sono descritte attraverso uno schema che contiene criteri qualitativi e quantitativi.
      </div>
      <div class="col-12 mb-4">
        Per questo tavolo decisionale è stato indentificato come obiettivo principale:
      </div>

      <!-- Obiettivo principale -->
      <div class="col-12 px-2" *ngIf="primary_target.length > 0">
          <div class="box px-0 box-two-row border-0 bg-transparent" *ngFor="let primaryTarget of primary_target">
              <mat-label class="box-title px-3">{{'components.proposals.new-proposal.primary' | translate}}</mat-label>
              <mat-form-field class="input-primary col-12 col-lg-6">
                <input matInput [ngModel]="primaryTarget.title" placeholder="{{'generic.title' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="datepicker-primary col-7 col-lg-6 px-1" appearance="fill">
                  <mat-label>{{'components.decision-table.new-decision-table.compile-proposal.achievement-date' | translate}}</mat-label>
                  <input matInput [ngModel]="(primaryTarget.date| formatdate | date: 'shortDate')" placeholder="{{'generic.date' | translate}}" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                <input matInput placeholder="{{'generic.unit' | translate}}" [ngModel]="getUnitsService().getUnitValueByKey(primaryTarget.measure_unit)" readonly>
              </mat-form-field>
              <mat-form-field class="input-primary col-6 col-lg-6 pt-1">
                <input matInput placeholder="{{'components.decision-table.new-decision-table.compile-proposal.objective-value' | translate}}" [ngModel]="getCriteriaService().getCriteriaValueByKey(primaryTarget.evaluation_criteria)" readonly>
              </mat-form-field>
          </div>
      </div>


  <div class="row">
    <div class="col-12 mb-4">
      <span>Le proposte oggetto della valutazione sono descritte attraverso uno schema che contiene {{numOfCriteriaQualitative}} criteri qualitativi.</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      <span>Stai per rispondere a un questionario dove per ogni punto oggetto della valutazione potrai esprimere ACCORDO o DiSACCORDO oppure astenerti.</span>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mb-4">
      <span><b>Esprimi il tuo grado di confidenza (da 0 a 100)</b></span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      <span>Per ogni valutazione devi fornire un <b>grado di confidenza</b> che esprime quanto sei sicuro della tua valutazione: da 0 (assolutamente non sicuro) a 100 (assolutamente certo).</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      <span>Dovrai fornire un <b>feedback</b> che spiega il motivo della tua valutazione e potrai allegare delle <b>evidenze</b> come file o sfruttando le banche dati connesse a Butterfly Decisions.</span>
    </div>
  </div>

  <div class="col-12 col-lg-6 px-0 mb-0">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
    </div>
  </div>

  <!-- Esempio di risposta al questionario del consenso -->
<div class="container-fluid accordion-container px-0 px-md-3">
  <div class="row">
      <div class="container-fluid accordion-container mt-3 accordion-inner rounded-0 bg-white arrow-left">
          <div class="container">
              <mat-accordion class="accordion" multi [togglePosition]="'before'">
                  <mat-expansion-panel class="px-0" expanded>
                      <div class="row border-bottom-grey pt-0 pb-4 my-3">
                          <small class="col-12">"{{'generic.description' | translate}}"</small>
                          <div class="col-12">Descrizione del criterio</div>
                          <div class="col-12">
                            <div class="d-flex ml-2 my-2 toogle-button-container justify-content-center">
                              <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                  <mat-button-toggle style="pointer-events: none;" [checked]="true" class="green">{{'components.responses.consensus.agree' | translate}}</mat-button-toggle>
                                  <mat-button-toggle style="pointer-events: none;" [checked]="false" class="red">{{'components.responses.consensus.disagree' | translate}}</mat-button-toggle>
                                  <mat-button-toggle style="pointer-events: none;" [checked]="false" class="yellow">{{'components.responses.consensus.abstained' | translate}}</mat-button-toggle>
                              </mat-button-toggle-group>
                          </div>

                          </div>
                          <div class="col-12 links-container">
                            <button mat-button color="primary" style="pointer-events: none;">
                                <i class="icon-Add mr-2"></i>
                                {{'components.responses.add-feedback' | translate}}
                            </button>

                            <button mat-button color="primary" style="pointer-events: none;">
                                <i class="icon-upload mr-2"></i>
                                {{'components.decision-table.new-decision-table.compile-proposal.load-evidences' | translate}}
                            </button>
                        </div>
                      </div>
                      <div class="row border-bottom-grey pt-0 pb-4 my-3">
                        <small class="col-12">Quanto sei sicuro della tua valutazione?</small>
                        <mat-slider style="pointer-events: none;" class="w-100 mt-2 cdk-focused" min="0" max="100" step="1" [value]="83"  [thumbLabel]="true" color="primary"></mat-slider>
                        <div class="d-flex justify-content-between w-100 mb-2 px-2">
                          <small class="float-left">Assolutamente non sicuro</small>
                          <small class="float-right">Assolutamente certo</small>
                        </div>
                      </div>
                  </mat-expansion-panel>
              </mat-accordion>
          </div>
      </div>
  </div>
</div>


  <!-- Final step -->
  <div class="row mt-4">
    <div class="col-12 mb-4">
      <span>Alla fine del questionario potrai avanzare proposte alternative che saranno oggetto di una nuova sessione di valutazione.</span>
    </div>
  </div>

  <div class="col-12 col-lg-6 px-0 mb-0">
    <div class="box py-0 px-2 bg-transparent border-0 mb-0">
        <mat-label class="box-title px-3 mb-0">Esempio</mat-label>
    </div>
  </div>

  <!-- Esempio proposta di nuove revisioni -->
  <div class="col-12 links-container">
    <button style="pointer-events: none;" mat-button color="primary">
      <i class="icon-Add mr-2"></i>
      Vuoi suggerire una nuova proposta?
    </button>
    <button style="pointer-events: none;" mat-button color="primary">
        <i class="icon-re_edit mr-2"></i>
        Vuoi revisionare le proposte?
    </button>
  </div>


  <!-- Info di fine pagina -->
  <div class="row mt-4">
    <div class="col-12 mb-4">
      <span style="font-size: 16px;" *ngIf="evaluation_session?.evaluation_end == null"><b>Non è definita una data di chiusura del questionario, completalo il prima possibile</b></span>
      <span style="font-size: 16px;" *ngIf="evaluation_session?.evaluation_end != null"><b>Devi compilare il questionario entro il giorno {{evaluation_session?.evaluation_end | formatdate | date: 'shortDate'}}</b></span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <span>Hai la possibilità di salvare in bozza e riprendere il lavoro entro la data di consegna.</span>
    </div>
    <div class="col-12">
      <span>Buon lavoro!</span>
    </div>
  </div>

  <!-- Bottoni di azione -->
  <div class="buttons-container container py-4">
    <button class="button-primary float-left" mat-button [routerLink]="['../welcome']">
      <i class="icon-Backward-arrow-small"></i>
      Indietro
    </button>
    <button type="submit" class="button-primary float-right" [disabled]="loading" mat-button (click)="goToEvaluation()">
      <i class="icon-Forward-arrow-small"></i>
      Avvia la valutazione
    </button>
  </div>

</div>
