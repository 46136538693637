import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CriteriaService } from 'src/app/services/config/criteria.service';
import { UnitsService } from 'src/app/services/config/units.service';
import { DecisionService } from 'src/app/services/decision.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { ResponseService } from 'src/app/services/response.service';

@Component({
  selector: 'app-responses-suggest-new-proposal',
  templateUrl: './responses-suggest-new-proposal.component.html',
  styleUrls: ['./responses-suggest-new-proposal.component.scss']
})
export class ResponsesSuggestNewProposalComponent implements OnInit {

  private _activeRouteSubscription: Subscription;

  loading = false;

  id: any;

  decision_table: any;
  evaluation_session: any;
  organization: any;
  framework_criteria: any = [];
  proposal_id: any = null;
  answered_proposal: any = null;

  model: any = {
    proposals: []
  };

  constructor(private activeRoute: ActivatedRoute, private responseService: ResponseService, private router: Router, private decisionService: DecisionService,
              private criteriaService: CriteriaService, private unitsService: UnitsService, private toast: ToastService, private translateService: TranslateService) { }

  ngOnInit(): void {

    //  controllo se sono in modifica o in creazione
    this._activeRouteSubscription = this.activeRoute.params.subscribe(routeParams => {
      this.id = routeParams.id_evaluation_session;
      this.proposal_id = routeParams.id_revision_proposal;
      if (this.proposal_id){
        this.getProposal();
      }
      if (this.id) {
          this.getSummary();
      }
    });
  }

  saveCriterionValue(criterio: any) {
    criterio.initialValue = criterio.value;
    criterio.modifyEnabled = false;
  }

  onClickModifyDescription(criterio: any) {
    criterio.modifyEnabled = true;
    criterio.initialValue = criterio.value;
  }

  criterionIsChanged(criterio: any) {
    return criterio.initialValue !== criterio.value;
  }

  onClickCancelDescription(criterio: any) {
    criterio.value = criterio.initialValue;
    criterio.modifyEnabled = false;
    criterio.criterioModified = false;
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    if (this._activeRouteSubscription) {
      this._activeRouteSubscription.unsubscribe();
    }
  }

    /** Recupero tutte le info delle risposte */
    getSummary() {
      this.loading = true;
      this.responseService.getSummary(this.id, (result) => {

        this.decision_table = result.decision_table;
        this.evaluation_session = result.evaluation_session;
        this.organization = result.decision_table.organization;
        this.framework_criteria = result.framework_criteria;
        this.prepareProposal();

        this.loading = false;
      }, () => {
        this.loading = false;
        this.router.navigate(['/home']);
      });
    }

    getProposal(){
      this.loading = true;
      this.decisionService.getProposalResponse(this.proposal_id, (result) => {
        this.loading = false;
        this.answered_proposal = result;
      }, () => {
        this.loading = false;
        this.router.navigate(['/home']);
      });
    }


    onSubmit(form: NgForm) {

      //  validazione dei dati
      this.toast.error(this.translateService.instant('generic.warning', 'components.responses.suggest.error-new-proposal'));
      if (!this.proposalsValid) { this.toast.error('Attenzione', 'Uno o più criteri sembrano non essere validi'); return; }

      if (!form.form.valid) { return; }

      this.loading = true;
      if (this.proposal_id){

        const data = this.answered_proposal;
        data.title = this.model.proposals[0].title;

        data.criteria.forEach((criterion) => {
          this.model.proposals[0].criteria.forEach((ctn) => {
            if (ctn.framework_criteria_id === criterion.framework_criteria_id){
              criterion.value = ctn.value;
            }
          });
        });


        this.decisionService.addNewRevision(this.decision_table.id, data, (result) => {
          this.loading = false;
          this.toast.success(this.translateService.instant('New proposal', 'components.responses.suggest.success-new-proposal'));
          this.toast.success('Nuova proposta', 'La proposta è stata aggiunta correttamente');
          this.router.navigate(['../../responses-evaluation'], { relativeTo: this.activeRoute });
        }, error => this.loading = false);
      }else{
        this.decisionService.addNewProposal(this.decision_table.id, this.model, (result) => {
          this.loading = false;
          this.toast.success(this.translateService.instant('New proposal', 'components.responses.suggest.success-new-proposal'));
          this.toast.success('Nuova proposta', 'La proposta è stata aggiunta correttamente');
          this.router.navigate(['../responses-evaluation'], { relativeTo: this.activeRoute });
        }, error => this.loading = false);
      }
    }



  /**
   * Setta una proposta sulla base del framework selezionato
   */
    prepareProposal() {
      this.model.proposals = [];
      this.addNewProposal();
    }


    /**
     * Aggiunge una proposal vuota
     */
    addNewProposal() {

      const newProposal = { title: null, criteria: [] };

      for (const criterio of this.framework_criteria) {
        const compiledCriterio = {
          title: criterio.title,
          typology: criterio.typology,
          measure_unit: criterio.measure_unit,
          value: null,
          framework_criteria_id: criterio.id
        };
        newProposal.criteria.push(compiledCriterio);
      }

      this.model.proposals.push(newProposal);

      if (this.proposal_id){
        this.model.proposals.forEach((proposal) => {
          proposal.criteria.forEach((criterion) => {
            this.answered_proposal.criteria.forEach((ctn) => {
              if (ctn.framework_criteria_id === criterion.framework_criteria_id){
                criterion.value = ctn.value;
              }
            });
          });
        });

        this.model.proposals[0].title = this.answered_proposal.title;
      }
    }

    /** Restituisco il colore corretto se l'utente ha completato tutti gli inserimenti dei criteri */
    getIconColorForCriteria(criteria: any) {

      let controlColor = 'green-color';

      if (criteria.value == null || criteria.value.length == 0) {
        controlColor = 'orange-color';
      }

      return controlColor;
    }


    translate(string) {
      return this.translateService.instant(string);
    }


    /** Valida le proposte create */
    get proposalsValid() {

      for (const proposal of this.model.proposals) {
        if (!proposal.title) { return false; }

        for (const proposalCriteria of proposal.criteria) {
          if (!proposalCriteria.value) { return false; }
        }
      }

      return true;
    }


    //  restituisce la stringa corretta in base al tipo di criterio
    getPlaceholderString(criteria_typology) {

      if (this.criteriaService.criteriaIsQuantitative(criteria_typology)) {
        return this.translateService.instant('components.responses.insert-numeric-value');
      }

      return this.translateService.instant('components.responses.insert-description');
    }


    public getCriteriaService() {
      return this.criteriaService;
    }
    public getUnitsService() {
      return this.unitsService;
    }

  revisionProposalIsChanged() {
      if (this.proposal_id === null){
        return true;
      }
      let changed = false;
      if (this.proposal_id && this.answered_proposal){
        this.model.proposals.forEach((proposal) => {
          proposal.criteria.forEach((criterion) => {
            this.answered_proposal.criteria.forEach((ctn) => {
              if (ctn.framework_criteria_id === criterion.framework_criteria_id){
                if (criterion.value !== ctn.value) {
                  changed = true;
                }
              }
            });
          });
        });
      }
      return changed;
  }

}
