import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ScriptLoaderService } from 'angular-google-charts';
import { ProcessRolesService } from 'src/app/services/config/process-roles.service';
import { SuggestionService } from 'src/app/services/config/suggestion.service';
import { HelperService } from 'src/app/services/general/helper.service';
import {ModalShowEvidencesComponent} from "../../../../modals/modal-show-evidences/modal-show-evidences.component";
import {MatDialog} from "@angular/material/dialog";
import {ModalShowProposalsComponent} from "../../../../modals/modal-show-proposals/modal-show-proposals.component";

@Component({
  selector: 'app-multiobjective-decision-table-dashboard',
  templateUrl: './multiobjective-decision-table-dashboard.component.html',
  styleUrls: ['./multiobjective-decision-table-dashboard.component.scss']
})
export class MultiobjectiveDecisionTableDashboardComponent implements OnInit, OnChanges {

  @Input() decision_table: any;
  @Input() evaluation_session_dashboard: any;

  currentDecisionTable: any;
  currentEvaluationSessionDashboard: any;

  constructor(
    private loaderService: ScriptLoaderService,
    private suggestionService: SuggestionService, private processRoleService: ProcessRolesService, private dialog: MatDialog) { }


  loadingGraphics: boolean = true;

  // feedbackData:[] = [];
  // criteriaData: [] = [];

  displayedColumnsCriteria: string[] = ['criteriatitle', 'suggest', 'risk', 'contradiction'];
  displayedColumns: string[] = ['expert', 'answers', 'time', 'cost', 'total_cost', 'new_proposal', 'evidence'];

  ngOnInit(): void {

    //  inizializzo i componenti
    if (this.decision_table && this.evaluation_session_dashboard) {
      this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
      this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    }

    //  recupero le informazioni per questo tavolo decisionale
    this.getDecisionTableData();

    //  carico i grafici
    this.loaderService.loadChartPackages('corechart').subscribe(() => {

      this.loadingGraphics = false;
    });
  }


  ngOnChanges(changes: SimpleChanges) {
    this.currentDecisionTable = JSON.parse(JSON.stringify(this.decision_table));
    this.currentEvaluationSessionDashboard = JSON.parse(JSON.stringify(this.evaluation_session_dashboard));
    this.init();
  }


  getDecisionTableData() {
    this.init();
  }

  showExpertEvideces(data) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: data?.name || data?.email,
        attachments: data.attachment_expert_pubmed.concat(data.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }


  init() {

    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {

      this.prepareAnswers();
      this.prepareChartContradiction();
      this.prepareChartResponse();

      this.drawChartTargets();
      this.drawChartWeightTargets();
    }
  }




  /**
   * Prepara le risposte filtrando solo quelle che sono state completate dagli esperti
   */
  prepareAnswers() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        let responseFiltered: any = [];
        criterio.response.forEach(response => {
          if (response?.team_member?.response_stamps && response?.team_member?.response_stamps.completed == true)
          responseFiltered.push(response);
        });
        criterio.response_filtered = responseFiltered;
      });
    });
  }

  showSuggestedProposals(proposals) {
    const data = {
      suggestedProposals: proposals,
      decisionTable: this.decision_table,
    };
    this.dialog.open(ModalShowProposalsComponent, {
      panelClass: 'medium-dialog',
      data
    });
  }


  /**
   * Prepara il grafico delle risposte in percentuale alla scelta fatta dagli utenti
   */
  prepareChartContradiction() {

    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      proposal.criteria.forEach(criterio => {
        if (criterio.response.length > 0) {
          criterio.response_agree_count = criterio.response_filtered.filter(x => x.consent_value == 'AGREE').length;
          criterio.response_disagree_count = criterio.response_filtered.filter(x => x.consent_value == 'DISAGREE').length;
          criterio.response_abstained_count = criterio.response_filtered.filter(x => x.consent_value == 'ABSTAINED').length;
          criterio.total_response = criterio.response_filtered.length;

          if (criterio.total_response > 0) {
            criterio.response_agree_perc = Math.floor((criterio.response_agree_count / criterio.total_response) * 100);
            criterio.response_disagree_perc = Math.floor((criterio.response_disagree_count / criterio.total_response) * 100);
            criterio.response_abstained_perc = Math.floor((criterio.response_abstained_count / criterio.total_response) * 100);
          }
        }
      });
    });
  }

  /**
   * Prepara il grafico delle delle risposte complessive da parte degli esperti
   */
  prepareChartResponse() {

    //  setto l'array con tutti i membri presenti e vedo proposta per proposta se hanno risposto a tutte le domande
    this.currentEvaluationSessionDashboard.proposals.forEach(proposal => {
      //  TODO_MICHELE: dal backend devono arrivare solo i membri che sono consulted
      proposal.team_members = proposal.team_members.filter(x => this.processRoleService.isConstulted(x.process_role));

      // proposal.team_members = proposal.team_members.filter(x => x.process_role == 'CONSULTED');
      // proposal.criteria.forEach(criterio => {
      //   criterio.response.forEach(response => {
      //     if (response.team_member.process_role == "CONSULTED") {
      //       if (proposal.team_members.filter(x => x.email == response.team_member.email).length <= 0)
      //         proposal.team_members.push(response.team_member);
      //     }
      //   });
      // });
    });
  }


  /** Disegna i grafici delle proposte */
  drawChartTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_on_target.length <= 0)
      return;

    //  recupero i punteggi per i pesi standard
    let groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    let groupedProposal = HelperService.groupBy(groupedSTD['Expert (STD)'], 'proposal');
    let groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  prendo il primo elemento dell'array e costruisco i nomi dei criteri
    let chartData: any = [[ ...["Title"], ...Object.keys(groupedTarget), ...[{role: 'annotation'}]]];
    Object.keys(groupedProposal).forEach(proposalKey => {

      //  inizializzo l'array con il nome della proposta
      let arrayOfTargetData: any = [proposalKey];

      //  itero i criteri per questa proposata creando l'array dei dati
      groupedProposal[proposalKey].forEach(target => {
        arrayOfTargetData.push({v: target.score_perc, f: `${target.score_perc}%`});
      });

      //  aggiungo una stringa vuota per poter disegnare il grafico
      arrayOfTargetData.push('');

      //  assegno all'array del grafico i dati appena completati
      chartData.push(arrayOfTargetData);
    });

    let data = google.visualization.arrayToDataTable(chartData);

    let view = new google.visualization.DataView(data);

    let chart = new google.visualization.BarChart(document.getElementById("chart_div_target"));

    chart.draw(view, {
      height: 350,
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      isStacked: true
    });
  }


  /** Disegna i grafici dei pesi degli obiettivi */
  drawChartWeightTargets() {

    //  controllo che ci siano risultati da mostrare
    if (!this.currentDecisionTable?.decisionTableResult && this.currentDecisionTable?.decisionTableResult?.results_on_target.length <= 0)
      return;

    //  recupero i punteggi per i pesi standard
    let groupedSTD = HelperService.groupBy(this.currentDecisionTable.decisionTableResult.results_on_target, 'weight_system');

    //  raggruppo per proposte e per criteri tutti i punteggi del sistema di peso "standard"
    let groupedTarget = HelperService.groupBy(groupedSTD['Expert (STD)'], 'target');

    //  costruisco i nomi dei titoli di riferimento per il grafico
    let chartData: any = [['Target', 'Score']];

    //  ciclo i target per settare i datisul diagramma
    Object.keys(groupedTarget).forEach(key => {
      chartData.push([key, groupedTarget[key][0].target_weight_perc_avg]);
    });

    let data = google.visualization.arrayToDataTable(chartData);

    let view = new google.visualization.DataView(data);

    let chart = new google.visualization.PieChart(document.getElementById("chart_div_weight_target"));

    chart.draw(view, {
      height: 350,
      legend: { position: 'top', maxLines: 3 }
    });
  }


  public getSuggestionService() {
    return this.suggestionService;
  }

}
