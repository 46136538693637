import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private backend: BackendService) {}

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public generateMagicWriterResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/generate-magic-writer-response', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public textTranslateResponse(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/text-translate', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * invia richiesta al modello AI
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public dashboardExplanation(
    model: any,
    success_callback = (data: any) => {},
    error_callback = (error: any) => {}
  ) {
    this.backend.post('ai/dashboard-explanation', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }
}
