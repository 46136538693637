import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-upload-framework',
  templateUrl: './modal-upload-framework.component.html',
  styleUrls: ['./modal-upload-framework.component.scss']
})
export class ModalUploadFrameworkComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalUploadFrameworkComponent>, @Inject(MAT_DIALOG_DATA) public data: {}) { }

  fileData: any = {};

  ngOnInit(): void {

    if (this.data && Object.keys(this.data).length > 0) {
      this.fileData = JSON.parse(JSON.stringify(this.data));
    }
  }

  /**
   * Setto un elemento nella lista degli allegati
   * @param event
   */
  uploadedFile(file) {
    this.fileData.attachmentObject = file;
    this.dialogRef.close(this.fileData);
  }


  closeDialogue(event: any = null) {

    this.dialogRef.close({event:event});
  }
}
