<div
  class="container-fluid accordion-container mt-3 accordion-inner"
>
  <mat-accordion class="accordion" multi>
    <mat-expansion-panel [expanded]="accordionOpened">
      <mat-expansion-panel-header class="py-2">
        <mat-panel-title>
              <span>
                {{ question.question }}
                <ng-container *ngIf="question.required">*</ng-container>
              </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="isResponse">
        <ng-container *ngIf="question?.description">
          <h4 class="font-weight-bold col-12 w-100 pl-0 mt-3">{{ "generic.description" | translate }}</h4>
          <p>{{ question?.description }}</p>
        </ng-container>
        <!--            <h4 class="font-weight-bold col-12 w-100 pl-0 {{ question?.description ? 'mt-4' : 'mt-3'}}">-->
        <!--              <ng-container *ngIf="surveyService.isOpenedQuestion(question.typology); else elseBlock">{{ "generic.your-answer" | translate }}</ng-container>-->
        <!--              <ng-template #elseBlock>{{ "generic.fe" | translate }}</ng-template>-->
        <!--            </h4>-->

        <ng-container *ngIf="isOpenedQuestion">
          <h4 class="font-weight-bold col-12 w-100 pl-0 mt-3">{{ "generic.your-answer" | translate }}</h4>
          <mat-form-field class="w-100 mt-2" appearance="fill" *ngIf="!isConsensusQuestion">
            <mat-label>
              {{
                "generic.your-answer" | translate
              }}
            </mat-label>
            <ng-container>
                <textarea
                  [required]="question.required"
                  class="description"
                  matInput
                  name="criterio_description{{question.id}}_response"
                  [(ngModel)]="question.response.answer"
                >
                </textarea>
            </ng-container>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="isQuantitativeQuestion">

          <div class="mt-5">
            <span class="font-weight-bold ">
            {{ "pages.frameworks.component.measure-unit" | translate | titlecase }}:</span> {{ getCustoms.measure_unit }}
          </div>

          <ng-container *ngIf="getCustoms.use_slider">
            <div class="container row pt-0 pb-4 my-3">
              <mat-slider name="criterio_quantitative_{{question.id}}_response_slider"
                          class="w-100 mt-4 {{ getCustoms.tricolor ? 'tricolor' : '' }} cdk-focused"
                          [max]="getCustoms.max"
                          [min]="getCustoms.min"
                          [step]="getCustoms.step"
                          [thumbLabel]="true"
                          [(ngModel)]="question.response.answer"
                          color="primary"
              />
              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                <ng-container *ngIf="!getCustoms.use_custom_min_max_labels; else elseBlock">
                  <small class="float-left">{{ 'components.responses.absolutely-not-sure' | translate }}</small>
                  <small class="float-right">{{ 'components.responses.absolutely-sure' | translate }}</small>
                </ng-container>
                <ng-template #elseBlock>
                  <small class="float-left">{{ getCustoms.min_label }}</small>
                  <small class="float-right">{{ getCustoms.max_label }}</small>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!getCustoms.use_slider">
            <br>
            <mat-form-field appearance="fill" class="mt-4">
              <input
                type="number"
                class="form-control"
                matInput
                name="value"
                step="1"
                [max]="getCustoms.max"
                [min]="getCustoms.min"
                [(ngModel)]="question.response.answer"
                [placeholder]="'generic.your-answer' | translate">
            </mat-form-field>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isConsensusQuestion">

          <div class="col-12 mt-5">
            <div class="d-flex ml-2 my-2 toogle-button-container justify-content-center">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="question.response.answer">
                <mat-button-toggle value="AGREE" class="green">{{ 'components.responses.consensus.agree' | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="DISAGREE"
                                   class="red">{{ 'components.responses.consensus.disagree' | translate }}
                </mat-button-toggle>
                <mat-button-toggle value="ABSTAINED" *ngIf="!question.required"
                                   class="yellow">{{ 'components.responses.consensus.abstained' | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div class="col-12 mt-5">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label class="label-box font-weight-bold">
                {{ 'generic.feedback' | translate }}
                <!-- <i class="icon-cestino grey-color abs-tr" (click)="removeFeedback(proposal_index, criteria_index)" ></i> -->
              </mat-label>
              <textarea class="description my-2" matInput required rows="3"
                        [(ngModel)]="question.response.feedback"></textarea>
            </mat-form-field>
          </div>
          <ng-container *ngIf="getCustoms.use_slider">
            <div class="container row pt-0 pb-4 my-3">
              <small class="col-12">{{ 'components.responses.ask-confidence' | translate }}</small>
              <mat-slider name="criterio_consensus_{{question.id}}_response_slider"
                          class="w-100 mt-4 {{ getCustoms.tricolor ? 'tricolor' : '' }} cdk-focused"
                          [max]="getCustoms.max"
                          [min]="getCustoms.min"
                          [step]="getCustoms.step"
                          [thumbLabel]="true"
                          [(ngModel)]="question.response.customs.confidence"
                          color="primary"
              />
              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                <ng-container *ngIf="!getCustoms.use_custom_min_max_labels; else elseBlock">
                  <small class="float-left">{{ 'components.responses.absolutely-not-sure' | translate }}</small>
                  <small class="float-right">{{ 'components.responses.absolutely-sure' | translate }}</small>
                </ng-container>
                <ng-template #elseBlock>
                  <small class="float-left">{{ getCustoms.min_label }}</small>
                  <small class="float-right">{{ getCustoms.max_label }}</small>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container class="mt-2 mb-3 row" *ngIf="isMultipleChoiceQuestion || isCheckboxQuestion">
          <div class="col-12 row">
            <h4 class="font-weight-bold col-12">{{ "generic.options" | translate }}</h4>
            <ng-container *ngIf="isMultipleChoiceQuestion">
              <mat-radio-group class="col-12" [(ngModel)]="question.response.choice">
                <div class="row" *ngFor="let choice of question.choices; let optionIndex = index">
                  <div class="col-12">
                    <mat-radio-button [value]="choice.id">{{ choice.title }}</mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </ng-container>
            <ng-container *ngIf="isCheckboxQuestion">
              <div class="col-12">
                <div class="row" *ngFor="let choice of question.choices; let optionIndex = index">
                  <div class="col-12">
                    <mat-checkbox [(ngModel)]="choice.checked">{{ choice.title }}</mat-checkbox>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <mat-form-field class="w-100 mt-2" appearance="fill" *ngIf="!isConsensusQuestion">
          <mat-label>
            {{
              "pages.surveys.responses.comment-your-answer" | translate
            }} (<span *ngIf="getCustoms.feedback_is_mandatory">{{ 'generic.required'| translate }}</span><span
            *ngIf="!getCustoms.feedback_is_mandatory">{{ "generic.optional" | translate }}</span>)
          </mat-label>
          <ng-container>
                <textarea
                  class="description"
                  matInput
                  name="criterio_description{{question.id}}_response"
                  [(ngModel)]="question.response.feedback"
                  [required]="getCustoms?.feedback_is_mandatory"
                >
                </textarea>
          </ng-container>
        </mat-form-field>

        <div class="my-3 row">
          <div class="col-12" *ngIf="question?.response?.attachments?.length > 0">
            <app-attachments-list [attachments]="question?.response?.attachments"/>
          </div>
        </div>
        <div
          class="row border-top-grey p-2 {{ survey?.attachments_enabled ? 'justify-content-between' : 'justify-content-start' }}">
          <div class="align-middle mt-auto mb-auto" style="color: #9169db; cursor: pointer"
               (click)="question?.attachments?.length > 0 ? showAttachments() : ''">
            <ng-container *ngIf="question?.attachments?.length > 0">
              <span> {{ "generic.documentation-attached" | translate }}</span>
            </ng-container>
          </div>
          <div class="my-2 row" *ngIf="survey?.attachments_enabled">
            <div class="mr-2">
              <button class="mx-1" mat-icon-button color="primary" aria-label="normal button"
                      [matMenuTriggerFor]="menuAttachment">
                <mat-icon>image</mat-icon>
              </button>
              <mat-menu #menuAttachment="matMenu">
                <button mat-menu-item (click)="onClickAddNewEvidence('PUBMED')">
                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                  Pubmed
                </button>
                <button mat-menu-item (click)="onClickAddNewEvidence('FILE')">
                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                  File
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isResponse">
        <div class="row justify-content-between">
          <div class="col-7">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{
                  "generic.title" | translate
                }}
              </mat-label>
              <input required [disabled]="!editable" [(ngModel)]="question.question" matInput
                     name="criterio_title_{{question.id}}">
            </mat-form-field>
          </div>
          <div class="col-2">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>{{
                  "generic.section" | translate
                }}
              </mat-label>
              <input [disabled]="!editable" [(ngModel)]="getCustoms.section" matInput
                     name="criterio_section_{{question.id}}">
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label>{{
                  "generic.type" | translate
                }}
              </mat-label>
              <mat-select [disabled]="!editable" required [(ngModel)]="question.typology"
                          (selectionChange)="onTypologyChange()"
                          name="criterio_typology_{{question.id}}">
                <mat-option [disabled]="!editable" *ngFor="let typology of surveyService.questionTypologies"
                            [value]="typology.id">{{ typology.title | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field *ngIf="getCustoms?.use_description" class="w-100" appearance="fill">
          <mat-label>{{
              "generic.description" | translate
            }}
          </mat-label>
          <textarea
            [disabled]="!editable"
            class="description"
            matInput
            name="criterio_description{{question.id}}"
            placeholder="{{
                            'components.proposals.new-proposal.placeholders.insert-description'
                              | translate
                          }}"
            [(ngModel)]="question.description"
          >
                        </textarea>
        </mat-form-field>
        <div *ngIf="isQuantitativeQuestion || isConsensusQuestion" class="row justify-content-between w-100">
          <ng-container *ngIf="isQuantitativeQuestion">
            <div class="col-6 flex-column">
              <div class="text-center">
                <mat-label class="col-12 label-box font-weight-bold mt-4 mb-3 pl-0">
                  {{
                    "generic.range-value"
                      | translate
                  }}
                </mat-label>
              </div>
              <div class="row">
                <div class="col-6">
                  <mat-form-field appearance="fill" class="pb-0">
                    <mat-label>{{ 'generic.min-vaue' | translate }}</mat-label>
                    <input [disabled]="!editable" type="number" required matInput [(ngModel)]="getCustoms.min"
                           name="max">
                  </mat-form-field>
                </div>
                <div class="col-6">
                  <mat-form-field appearance="fill" class="pb-0">
                    <mat-label>{{ 'generic.max-value' | translate }}</mat-label>
                    <input [disabled]="!editable" required type="number" matInput [(ngModel)]="getCustoms.max"
                           name="max">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-5 flex-column">
              <div>
                <mat-label class="col-12 label-box font-weight-bold mt-4 mb-3 pl-0">
                  {{
                    "components.decision-table.new-decision-table.compile-proposal.measure-unit"
                      | translate
                  }}
                </mat-label>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'components.decision-table.new-decision-table.compile-proposal.measure-unit' | translate }}</mat-label>
                    <mat-select name="proposal_target_primary_criteria_unit"
                                [disabled]="!editable"
                                [(ngModel)]="getCustoms.measure_unit" required>
                      <mat-option value="" selected></mat-option>
                      <mat-option *ngFor="let unit of unitsService.getAllUnitsForSelect()"
                                  [value]="unit.key">{{ unit.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="col-12 row">
            <div class="col-12 row">
              <mat-label class="col-12 label-box font-weight-bold mt-1">
                {{
                  "generic.settings"
                    | translate
                }}
              </mat-label>
              <div class="col-12 my-2" *ngIf="!isConsensusQuestion">
                <mat-label class="mr-3">{{ "modals.modal-survey-details.use-slider" | translate }}</mat-label>
                <mat-button-toggle-group class="" [disabled]="!editable" [(ngModel)]="getCustoms.use_slider"
                                         name="question_use_slider" style="  height: 38px;
  align-items: center;">
                  <mat-button-toggle [disabled]="!editable" [value]="true">{{ "generic.answers.yes" | translate }}
                  </mat-button-toggle>
                  <mat-button-toggle [disabled]="!editable" [value]="false">{{ "generic.answers.no" | translate }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div *ngIf="getCustoms.use_slider">
                <div class="col-12 my-2">

                  <mat-label class="mr-3">{{ "modals.modal-survey-details.settings.use-tricolor" | translate }}
                  </mat-label>
                  <mat-button-toggle-group class="" [disabled]="!editable" [(ngModel)]="getCustoms.tricolor"
                                           name="question_tricolor" style="  height: 38px;
  align-items: center;">
                    <mat-button-toggle [disabled]="!editable" [value]="true">{{ "generic.answers.yes" | translate }}
                    </mat-button-toggle>
                    <mat-button-toggle [disabled]="!editable" [value]="false">{{ "generic.answers.no" | translate }}
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="col-12 my-2" *ngIf="getCustoms.use_slider">
                <mat-label class="mr-3">{{ "generic.step-value" | translate | titlecase }}</mat-label>
                <mat-form-field appearance="fill" class="pb-0">
                  <input [disabled]="!editable" type="number" required matInput [(ngModel)]="getCustoms.step"
                         name="step">
                </mat-form-field>
              </div>
              <div class="col-12 my-2" *ngIf="getCustoms.use_slider">
                <mat-label class="mr-3">{{ "generic.customs-min-max-labels" | translate }}
                </mat-label>
                <mat-button-toggle-group class="" [disabled]="!editable"
                                         [(ngModel)]="getCustoms.use_custom_min_max_labels"
                                         name="question_tricolor" style="  height: 38px;
  align-items: center;">
                  <mat-button-toggle [disabled]="!editable" [value]="true">{{ "generic.answers.yes" | translate }}
                  </mat-button-toggle>
                  <mat-button-toggle [disabled]="!editable" [value]="false">{{ "generic.answers.no" | translate }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-12 w-100 my-2" *ngIf="getCustoms.use_slider && getCustoms.use_custom_min_max_labels">
                <div class="row justify-content-center">
                  <div class="row col-12 justify-content-around">
                    <mat-form-field appearance="fill" class="pb-0">
                      <mat-label>{{
                          "generic.min" | translate
                        }}
                      </mat-label>
                      <input [disabled]="!editable" required matInput [(ngModel)]="getCustoms.min_label"
                             name="min_label">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="pb-0">
                      <mat-label>{{
                          "generic.max" | translate
                        }}
                      </mat-label>
                      <input [disabled]="!editable" required matInput [(ngModel)]="getCustoms.max_label"
                             name="max_label">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 row justify-content-center mt-5 mb-1" *ngIf="getCustoms.use_slider">
            <span class="col-12 text-center font-weight-bold">{{
                "generic.preview" | translate
              }}
            </span>

            <div class="w-75 row pt-0 pb-4 my-3">
              <mat-slider
                *ngIf="!isResponse"
                class="w-100 {{ getCustoms?.tricolor ? 'tricolor' : '' }} cdk-focused mt-4"
                [max]="getCustoms?.max"
                [min]="getCustoms?.min"
                [step]="getCustoms?.step"
                [thumbLabel]="true"
                color="primary"
              />
              <div class="d-flex justify-content-between w-100 mb-2 px-2">
                <ng-container *ngIf="!getCustoms.use_custom_min_max_labels && isConsensusQuestion; else elseBlock">
                  <small class="float-left">{{ 'components.responses.absolutely-not-sure' | translate }}</small>
                  <small class="float-right">{{ 'components.responses.absolutely-sure' | translate }}</small>
                </ng-container>
                <ng-template #elseBlock>
                  <small class="float-left">{{ getCustoms.min_label }}</small>
                  <small class="float-right">{{ getCustoms.max_label }}</small>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isMultipleChoiceQuestion || isCheckboxQuestion" class="my-3 row">
          <div class="col-6 row">
            <h4 class="mb-1 font-weight-bold mb-1 col-12">{{ "generic.options" | translate }}</h4>
            <mat-radio-group class="col-12">
              <div class="row" *ngFor="let choice of question.choices; let optionIndex = index">
                <div class="col-12" *ngIf="isMultipleChoiceQuestion">
                  <mat-radio-button [value]="false" [disabled]="true">
                    <mat-form-field appearance="fill">
                      <input [disabled]="!editable" required matInput name="question_{{ question.id }}__{{choice.id}}"
                             [(ngModel)]="choice.title">
                      <mat-icon
                        *ngIf="optionIndex <= question.choices.length -1 && question.choices.length > 2 && editable"
                        matSuffix style="cursor: pointer;" (click)="removeChoice(choice.id)">close
                      </mat-icon>
                    </mat-form-field>
                  </mat-radio-button>
                </div>
                <div class="col-12" *ngIf="isCheckboxQuestion">
                  <mat-checkbox disabled [checked]="false">
                    <mat-form-field appearance="fill" class="ml-3">
                      <input [disabled]="!editable" required matInput name="question_{{ question.id }}__{{choice.id}}"
                             [(ngModel)]="choice.title">
                      <mat-icon
                        *ngIf="optionIndex <= question.choices.length -1 && question.choices.length > 2 && editable"
                        matSuffix style="cursor: pointer;" (click)="removeChoice(choice.id)">close
                      </mat-icon>
                    </mat-form-field>
                  </mat-checkbox>
                </div>
              </div>
            </mat-radio-group>
            <button class="my-2" type="button" mat-button color="primary" (click)="addChoice()" *ngIf="editable">
              <i class="icon-Add primary"></i>
              {{ 'pages.frameworks.component.buttons.add-criteria-option' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="!(!editable && question?.attachments?.length === 0)" class="row border-top-grey p-2"
             [ngClass]="question?.attachments?.length > 0 ? 'justify-content-between' : 'justify-content-end'">
          <div class="align-middle {{ editable ? 'mt-auto mb-auto' : 'mt-2' }}" style="color: #9169db; cursor: pointer"
               *ngIf="question?.attachments.length > 0" (click)="showAttachments()">
            <span> {{ "generic.documentation-attached" | translate }}</span>
          </div>
          <div class="my-2 row" *ngIf="editable">
            <div class="mr-2">
              <button class="mx-1" mat-icon-button color="primary" aria-label="normal button"
                      [matMenuTriggerFor]="menuAttachment">
                <mat-icon>image</mat-icon>
              </button>
              <mat-menu #menuAttachment="matMenu">
                <button mat-menu-item (click)="onClickAddNewEvidence('PUBMED')">
                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                  Pubmed
                </button>
                <button mat-menu-item (click)="onClickAddNewEvidence('FILE')">
                  <mat-icon aria-hidden="false">file_upload</mat-icon>
                  File
                </button>
              </mat-menu>
              <button class="mx-1" mat-icon-button color="primary" aria-label="normal button"
                      (click)="duplicateQuestion()">
                <mat-icon>file_copy</mat-icon>
              </button>
              <button (click)="removeQuestion()" mat-icon-button color="primary" aria-label="normal button" class="mx-1"
                      *ngIf="isDeletable">
                <mat-icon>delete</mat-icon>
              </button>
              <button class="mx-1" mat-icon-button color="primary" aria-label="normal button"
                      [matMenuTriggerFor]="menuSettings">
                <mat-icon>settings</mat-icon>
              </button>

              <mat-menu #menuSettings="matMenu">
                <ng-container *ngFor="let option of surveyService.selectOptions">
                  <div mat-menu-item *ngIf="option.enabledTypologies.includes(this.question.typology)"
                       (click)="settingsItemSelected($event)">
                    <mat-checkbox [(ngModel)]="getCustoms[option.key]">{{ option.label | translate }}</mat-checkbox>
                  </div>
                </ng-container>
              </mat-menu>

            </div>
            <div class="border-left-grey">
              <div class="col-2 ml-2">
                <mat-button-toggle-group [(ngModel)]="question.required" name="question_{{question.id}}_required"
                                         style="  height: 38px;
  align-items: center;">
                  <mat-button-toggle [value]="true">{{ "generic.required" | translate }}</mat-button-toggle>
                  <mat-button-toggle [value]="false">{{ "generic.optional" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
