<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'generic.proposals' | translate }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div>
  <div class="container-fluid">
    <div class="col-12">
      <div class="box flex-column border-0 container justify-content-between" style="background-color: #F1F2F2">
        <table *ngIf="suggestedProposals.length > 0" mat-table [dataSource]="suggestedProposals"
               class="mat-elevation-z8 my-5">

          <!-- Name Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.title"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element">
              <a [href]="element.link" target="_blank">{{element.title}}</a></td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.type"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.original_proposal_id">{{ 'generic.revision' | translate }}</span>
              <span *ngIf="!element?.original_proposal_id">{{ 'generic.new-proposal' | translate }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef> {{
              "generic.upload_date"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.created_at | formatdate | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="ml-auto text-right"> {{
              "generic.actions"
                | translate
              }} </th>
            <td mat-cell *matCellDef="let element" class="ml-auto text-right">
              <button mat-button
                      (click)="viewProposal(element)"
                      class="p-0"
                      color="primary">
                <small>{{'generic.view' | translate}}</small>
                <mat-icon>forward</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
