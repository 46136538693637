import { Component, Input } from '@angular/core';
import { FileViewerService } from '../../services/fileviewer.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})

export class FileViewerComponent {

  officeViewerUrl = "https://view.officeapps.live.com/op/embed.aspx?src=";
  urlSafe: SafeResourceUrl;
  viewer: string = 'url';
  useNgxDocViewer: boolean = false;

  documentContent: any;
  documentType = '';

  document_id: number;

  constructor(private fileViewerService: FileViewerService, public sanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.document_id = params['document_id'];
      this.loadDocument(this.document_id);
    });

  }

  loadDocument(id: number) {

    this.fileViewerService.getFileStream(id, (result: any) => {

      this.documentType = result.headers.get('content-type');

      if (this.documentType == 'application/json') {

        this.getContentType(result.body).then((response: string) => {

          let values: any = JSON.parse(response);

          this.documentType = values.contentType;
          this.documentContent = values.content;

          this.useNgxDocViewer = false;
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.officeViewerUrl + this.documentContent);

        });
      }
      else {

        this.documentContent = URL.createObjectURL(result.body);

        if (this.documentType == 'application/pdf' || this.documentType == 'image/jpeg' || this.documentType == 'image/png'
          || this.documentType == 'image/jpg') {

          this.useNgxDocViewer = true;
          this.viewer = 'url'

        }

      }
    });

  }

  async getContentType(blob) {
    try {
      const text = await this.readBlobAsText(blob);
      return text;
    } catch (error) {
      console.error('Error reading blob:', error);
    }
  }

  async readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const text = e.target.result;
        resolve(text);
      });

      reader.addEventListener('error', (e) => {
        reject(e);
      });

      reader.readAsText(blob);
    });
  }
}
