<div class="box px-0 box-two-row"
     *ngFor="let member of members; let i = index">
  <app-single-team-member
    [team_member]="member"
    [removable]="members.length > 1"
    (removeTeamMemberEvent)="removeTeamMember(member?.id)"
    [process_role_field]="false"
  />
</div>

<div class="row justify-content-center mb-3 mt-5">
  <button
    mat-flat-button
    color="primary"
    (click)="addTeamMember()"
  >
    {{ "components.decision-table.new-decision-table.compile-proposal.add-team-member" | translate }}
  </button>
</div>

<div class="row justify-content-center my-3">
  <button
    mat-flat-button
    color="primary"
    (click)="openChartsModal()"
  >
    {{'components.decision-table.new-decision-table.compile-proposal.import-team-members-from-chart' | translate}}
  </button>
</div>
