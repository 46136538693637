<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container">
  <div class="container">
    <mat-accordion class="header-with-actions">
      <mat-expansion-panel expanded hideToggle="true">
        <mat-expansion-panel-header class="event-disabled d-flex align-items-center">
          <mat-panel-title>
            {{'pages.template.all-organization-charts-management.component.manage-template' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="container background-filters py-3 px-5 mt-3">
          <div class="row d-flex">
            <div class="col-12 d-flex justify-content-between purple-color">
              <h2>{{'generic.search-for' | translate}}</h2>
              <button class="clear-filter py-2 px-4" (click)="onClearSearch()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
            </div>
          </div>

          <!-- SECONDA ROW FILTRI-->
          <div class="row d-flex justify-content-between mx-0 mt-0">
            <div class="col-12 col-md-12 mt-4">
              <mat-form-field class="full-width">
                <input matInput name="searchKeywords" [(ngModel)]="searchTerm" (input)="onSearch()" type="text" placeholder="{{'generic.title' | translate}}" #searchInput autocomplete="off">
                <mat-icon class="with-cursor" *ngIf="searchTerm" matSuffix aria-label="Clear" (click)="searchTerm=''; onSearch()">close</mat-icon>
                <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="onSearch()">search</mat-icon>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- ADD TEMPLATE BUTTON -->
        <div class="row" *ngIf="!loading">
          <div class="col-12 d-flex justify-content-end align-items-center">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mt-3 mb-3" (click)="addNewChart()">
              <mat-icon class="mr-2">add_circle_outlined</mat-icon>
              {{'pages.template.all-organization-charts-management.component.add-chart' | translate}}
            </button>
          </div>
        </div>

        <div class="row">

          <div class="col-12 px-2 mb-4">
            <mat-table *ngIf="!loading && chartsData && chartsData?.data && chartsData.data.length > 0" [dataSource]="chartsData" class="table-primary">

              <!-- Name Column -->
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>{{'generic.title' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span>{{ element.title }}</span>
                </mat-cell>
              </ng-container>

              <!-- Type Column -->
              <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef>{{'generic.created-at' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span>{{ element.created_at | formatdate | date : 'short' }}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="members_count">
                <mat-header-cell *matHeaderCellDef>{{'generic.members-count' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.organization_users?.length }}
                </mat-cell>
              </ng-container>

              <!-- Actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="d-flex py-3" style="justify-content:right;">
                  <button mat-button color="primary" [disableRipple]="true" (click)="showChart(element)"> {{'tooltip.show' | translate}}</button>
                  <button mat-icon-button color="warn" [disableRipple]="true" (click)="deleteChart(element)" matTooltip="{{'tooltip.delete' | translate}}" [matTooltipPosition]="'above'" ><fa-icon [icon]="faTrash"></fa-icon></button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of users"></mat-paginator> -->

          </div>
        </div>

        <!-- No data-->
        <div class="col-12 text-center my-5 p-4" *ngIf="!loading && chartsData && chartsData?.data?.length === 0">
          <h3 class="color-green">{{'pages.template.all-organization-charts-table.component.no-filtered-charts-tables' | translate}}</h3>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['../']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.back' | translate}}
  </button>
</div>
