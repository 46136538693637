<ng-template #notification let-title="title" let-content="content" >
  <div class="toast {{currentMessage.type}}" style="opacity: 1;" role="alert">
    <div class="toast-header">
      <div class="d-flex w-100">
        <!-- <i class="{{notificationIcons[currentMessage.type].icon}} {{notificationIcons[currentMessage.type].color}}"></i> -->
        <mat-icon aria-hidden="false">{{notificationIcons[currentMessage.type].icon}}</mat-icon>
        <h5 class="mb-0">{{title}}</h5>
        <i class="icon-Cancel ml-auto"></i>
      </div>
      <small class="ml-auto"></small>
      &nbsp;
    </div>
    <div *ngIf="content" class="toast-body text-dark">
      {{content}}
    </div>
  </div>
</ng-template>

<simple-notifications [options]="notificationOptions"></simple-notifications>
